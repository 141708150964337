import React, { useState, useEffect } from "react";
import styles from "./CMMC2Level.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Button } from "../../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router-dom";
import TopStepper from "../../../../components/UI/Layout/Navigation/TopStepper/TopStepper";
import stepper from "../../../../common/csetCMMCStepperMenu.json";
import { setStepper } from "../../../../common/SetStepper";
import { setActiveFormStep } from "../../../../services/Data";
import AutoCompleteDropDown from "../../../../components/UI/Form/Autocomplete/Autocomplete";
import Input from "../../../../components/UI/Form/Input/Input";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import logout from "../../../../containers/Auth/Logout/Logout";
import { UPDATE_CSET_ASSESSMENT } from "../../../../graphql/mutations/CSETAssessment";
import Alert from "../../../../components/UI/Alert/Alert";
import CloseIcon from "@mui/icons-material/Close";
import {
  CSET_URL,
  REACT_APP_PG_URL,
  PG_DEDUCT_SCAN_COUNT_API_KEY,
} from "../../../../config/index";
import { HISTORY } from "../../../../graphql/mutations/AuditTrail";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { GET_CSET_ASSESSMENT } from "../../../../graphql/queries/CSETAssessment";
import { sentry_error_catch } from "../../../../common/sentry_error_catch";
import { CANCEL_MSG, ERROR_MESSAGE } from "../../../../common/MessageConstants";
import { useErrorBoundary } from "react-error-boundary";
import { DialogBox } from "../../../../components/UI/DialogBox/DialogBox";
interface SectorValueObj {
  sectorId: number;
  sectorName: string;
  name: string;
}
interface OrganizationValue {
  organizationTypeId: number;
  organizationType: string;
  demographics: any;
}
interface RelativeEffortObj {
  demographicId: number;
  size: string;
  description: string;
}
interface IndustryValueObj {
  sectorId: number;
  sectorName: string;
  name: string;
}

export const CMMC2Level: React.FC = (props: any) => {
  const history = useNavigate();
  const location = useLocation();
  const ContentType = "application/json";
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const is_link_assessment =  Boolean(sessionStorage.getItem("is_link_authenticated")) || false;
  const [param, setParam] = useState<any>(
    location?.state[Object.keys(location?.state)[0]]
  );
  const { showBoundary } = useErrorBoundary();
  const [stepperObj, setStepperObj] = useState<any>({ path: "" });
  const [sectorList, setSectorList] = useState<any>("");
  const [industryList, setIndustryList] = useState<any>([]);
  const [sector, setSector] = useState<any>("");
  const [industry, setIndustry] = useState<any>(null);
  const [relativeEffort, setRelativeEffort] = useState<any>("");
  const [relativeEffortList, setRelativeEffortList] = useState<any>([]);
  const [demographicsObj, setDemographicsObj] = useState<any>({});
  const [organizations, setOrganizations] = useState<any>("");
  const [organization, setOrganization] = useState<any>("");
  const [business, setBusiness] = useState<any>("");
  const [organizationList, setOrganizationList] = useState<any>([]);
  const [facilitator, setFacilitator] = useState<any>("");
  const [contact, setContact] = useState<any>("");
  const [dataLoading, setDataLoading] = useState(false);
  const [level, setLevel] = useState<any>("1");
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const [formStates, setFormStates] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const stepperObject = stepper;

  const client = useApolloClient();
  const [updateAssessement] = useMutation(UPDATE_CSET_ASSESSMENT);
  const [getFacilitatorAndContactOfAssessment] =
    useLazyQuery(GET_CSET_ASSESSMENT);
  const getAllSectors = {
    options: sectorList,
    getOptionLabel: (option: SectorValueObj) => option.name,
  };

  const getAllIndustries = {
    options: industryList,
    getOptionLabel: (option: IndustryValueObj) => option.name,
  };

  const getAllRelativeEffort = {
    options: relativeEffortList,
    getOptionLabel: (option: RelativeEffortObj) => option.description,
  };

  const getAllOrganization = {
    options: organizationList,
    getOptionLabel: (option: OrganizationValue) => option.organizationType,
  };

  const enableBackdrop = () => {
    if (dataLoading) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setParam(location?.state[Object.keys(location?.state)[0]]);
    getAssessmentDetails(authToken);
  }, []);

  useEffect(() => {
    setLevel(param?.MaturityLevel);
    return () => {
      setLevel("");
    };
  }, [param]);

  useEffect(() => {
    getSectors();
    getRelativeEffort();
    getOrganizationType();
    getFacilitatorAndContact();
    if (demographicsObj.hasOwnProperty("sectorId")) {
      getIndustries(demographicsObj.sectorId);
    }
    setBusiness(demographicsObj.agency);
    setOrganizations(demographicsObj.organizationName);
  }, [demographicsObj]);

  useEffect(() => {
    setDataLoading(true);
    setStepper(
      client,
      stepperObject.maturityLevel.name,
      stepperObject.maturityLevel.value
    );
    setActiveFormStep(1);
  }, []);

  useEffect(() => {
    //for deducting 1 credit from cmmc2 flow
    if (param?.assessmentCreate === true && param?.assessmentUpdate === false) {
      handleDeduction();
    }
  }, []);

  const [auditTrail] = useMutation(HISTORY);

  const handleDeduction = async () => {
    const deductionObj = {
      partner_id: param.pgPartnerId,
      scan_type: param.groupTitle,
      uid: param.pgPartnerUserId,
      main_type:
        param.flowType.type === "Custom" || param.flowType.type === "Standard"
          ? "RAA"
          : param.flowType.type === "CMMC2"
          ? "CMMC"
          : param.flowType.type,
      timestamp: new Date().getTime() / 1000,
      target_id: param.ccAssessmentInfo.id,
    };
    await fetch(
      `${REACT_APP_PG_URL}/ob-ra-scans/api/v1/deduct-scan-count/post`,
      {
        method: "POST",
        headers: {
          "api-key": PG_DEDUCT_SCAN_COUNT_API_KEY,
          "Content-type": "application/json",
        },
        body: JSON.stringify(deductionObj),
      }
    )
      .then((response) => {
        if(response.status === 200){ 
          return response.json()
        }else{
          throw new Error(`Deduction API failed`);
        } 
      })
      .then((response) => {
        auditTrail({
          variables: {
            auditTrailInput: {
              individual: param.individualId,
              organization: param.partnerOrganizationId,
              cset_assessment: param.ccAssessmentInfo.id,
              action: {
                message: `Deduction API Successfully deducted one scan.`,
                data: response,
              },
              flowtype: param.flowType.type
              ? param.flowType.type
              : param.flowType,
              page: "CMMC2 Level Page Deduction API.",
              client: param.clientOrgId,
            },
          },
        }).then((res: any) => {
          console.log("audit trail added")
        }).catch((err:any)=> {
          Sentry.captureException(err);
        })
      })
      .catch((error: any) => {
        Sentry.captureException(error)
        auditTrail({
          variables: {
            auditTrailInput: {
              individual: param.individualId,
              organization: param.partnerOrganizationId,
              cset_assessment: param.ccAssessmentInfo.id,
              action: {
                message: `${error}.`,
                data: deductionObj,
              },
              flowtype: param.flowType.type
              ? param.flowType.type
              : param.flowType,
              page: "CMMC2 Level Page",
              client: param.clientOrgId,
            },
          },
        }).then((res: any) => {
          console.log("audit trail added")
        }).catch((err) => {
          Sentry.captureException(err);
        });
      });
  };

  const headerObj = {
    "Content-Type": ContentType,
    Authorization: authToken,
  };

  const getAssessmentDetails = async (val: any) => {
    await fetch(`${CSET_URL}api/assessmentdetail`, {
      method: "GET",
      headers: {
        "Content-Type": ContentType,
        Authorization: val,
      },
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        setLevel(data.maturityModel?.maturityTargetLevel.toString());
        getDemographicsInfo(data.id, val);
      })
      .catch((err: any) => {
        if(!is_link_assessment){
          handleCancel();
        }else{
          sentry_error_catch(err,setDataLoading,setFormStates)
        }
      });
  };

  const getDemographicsInfo = async (id: any, token: any) => {
    await fetch(`${CSET_URL}api/Demographics/?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": ContentType,
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDemographicsObj(data);
      })
      .catch((error: any) => {
        handleError(error);
      });
  };

  const handleLevelChange = (event: any, selectedValue: any) => {
    setLevel(selectedValue);
  };

  const handleSubmit = async () => {
    try{
    if (level) {
      const DemoGData = {
        industryId: demographicsObj.industryId,
        sectorId: demographicsObj.sectorId,
        size: demographicsObj.size,
        AssetValue: param.AssetValue,
        organizationName: organizations ? organizations : null,
        agency: business ? business : null,
        organizationType: demographicsObj?.organizationType
          ? demographicsObj.organizationType
          : null,
      };
      await fetch(`${CSET_URL}api/Demographics/`, {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(DemoGData),
      })
        .then((response) => response.json())
        .then((data) => {
          submitContactAndFacilitatorData();
          postCmmcLevel();
        })
        .catch((error: any) => {
          handleError(error);
        });
    } else {
      setFormStates((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: false,
        isFailed: true,
        errMessage: "Please Select Desired Maturity Level.",
      }));
    }
    }catch(err){
      showBoundary(err);
    }
  };

  const postCmmcLevel = async () => {
    await fetch(`${CSET_URL}api/MaturityLevel`, {
      method: "POST",
      headers: headerObj,
      body: level,
    })
      .then((data: any) => {
        updateAssessement({
          variables: {
            id: param.ccAssessmentInfo.id,
            assessment_type: param.ccAssessmentInfo.assessment_type,
            maturity_level: level,
          },
        })
          .then((res) => {
            if (stepperObj.path !== "/assessment-info") {
              auditTrail({
                variables: {
                  auditTrailInput: {
                    individual: param.individualId,
                    organization: param.partnerOrganizationId,
                    action: {
                      message: `Maturity Level ${level} Selected`,
                    },
                    flowtype: param.flowType.type
                      ? param.flowType.type
                      : param.flowType,
                    page: "CMMC2 Level Page",
                    client: param.clientOrgId,
                    cset_assessment: param.ccAssessmentInfo.id,
                  },
                },
              }).then((res: any) => {
                handleNext();
              }).catch((err: any) => {
                sentry_error_catch(err,setDataLoading,setFormStates)
              });
            }
          })
          .catch((err: any) => {
            sentry_error_catch(err,setDataLoading,setFormStates)
          });
      })
      .catch((error: any) => {
        handleError(error);
      });
  };

  const handleNext = () => {
    if (authToken) {
      param["MaturityLevel"] = level;
      param.assessmentCreate = false;
      param.assessmentUpdate = true;
      history(routeConstant.CMMC2_QUESTIONS, { state: { param } });
    } else {
      logout();
    }
  };

  const handleBack = () => {
    try{
    history(routeConstant.ASSESSMENT_INFO, { state: { param } });
    }catch(err){
      showBoundary(err);
    }
  };

  const handleCancel = () => {
    try{
    const val: any = {
      clientInfo: param.clientInfo,
      csetUserInfo: param.csetUserInfo,
      pgPartnerId: param.pgPartnerId,
      pgPartnerUserId: param.pgPartnerUserId,
    };
    history(routeConstant.ASSESSMENT_LIST, { state: { val } });
   }catch(err){
    showBoundary(err);
   }
  };

  const handleError = (err:any) => {
    setDataLoading(false);
    setFormStates((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  }

  const callingFromTopStepper = (event: any) => {
    setStepperObj(event);
    handleSubmit();
  };

  const handleSectorChange = (event: any, selectedValue: any) => {
    setSector(selectedValue);
    if (selectedValue) {
      getIndustries(selectedValue.sectorId);
    }
    if (selectedValue) {
      setDemographicsObj((demographicsDataObj: any) => {
        return {
          ...demographicsDataObj,
          sectorId: selectedValue.sectorId,
          industryId: null,
        };
      });
    } else {
      setIndustry(null);
      setIndustryList([]);
      setDemographicsObj((demographicsDataObj: any) => {
        return {
          ...demographicsDataObj,
          sectorId: null,
          industryId: null,
        };
      });
    }
  };
  const handleValueChange = (event: any, selectedValue: any) => {
    setOrganization(selectedValue);
    setDemographicsObj((demographicsDataObj: any) => {
      return {
        ...demographicsDataObj,
        organizationType: selectedValue
          ? selectedValue.organizationTypeId
          : null,
      };
    });
  };
  const handleFacilitatorValueChange = (event: any) => {
    setFacilitator(event.target.value);
    setDemographicsObj((demographicsDataObj: any) => {
      return {
        ...demographicsDataObj,
        facilitator: event.target.value ? event.target.value : null,
      };
    });
  };
  const handleContactValueChange = (event: any) => {
    setContact(event.target.value);
    setDemographicsObj((demographicsDataObj: any) => {
      return {
        ...demographicsDataObj,
        pointOfContact: event.target.value ? event.target.value : null,
      };
    });
  };

  const handleIndustryValueChange = (event: any, selectedValue: any) => {
    setIndustry(selectedValue);
    setDemographicsObj((demographicsDataObj: any) => {
      return {
        ...demographicsDataObj,
        sectorId: demographicsObj ? demographicsObj.sectorId : null,
        industryId: selectedValue ? selectedValue.industryId : null,
      };
    });
  };

  const handleRelativeEffortChange = (event: any, selectedValue: any) => {
    setRelativeEffort(selectedValue);
    setDemographicsObj((demographicsDataObj: any) => {
      return {
        ...demographicsDataObj,
        size: selectedValue ? selectedValue.demographicId : null,
        sectorId: demographicsObj ? demographicsObj.sectorId : null,
        industryId: demographicsObj ? demographicsObj.industryId : null,
      };
    });
  };

  const getSectors = async () => {
    try {
      await fetch(`${CSET_URL}api/Demographics/Sectors`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setDataLoading(false);
          setSectorList(data);
          const result = data.filter((obj: any) => {
            return obj.sectorId === demographicsObj.sectorId;
          });
          setSector(result[0]);
        })
        .catch((error: any) => {
          handleError(error);
        });
    } catch (err:any) {
     sentry_error_catch(err,setDataLoading,setFormStates)
    }
  };

  const getIndustries = async (id: any) => {
    try {
      setDataLoading(false);
      if (id) {
        await fetch(`${CSET_URL}api/Demographics/Sectors_Industry/${id}`, {
          method: "GET",
          headers: headerObj,
        })
          .then((response) => response.json())
          .then((data) => {
            setDataLoading(false);
            if (data && data[0]) {
              setIndustryList(data);
            }
            const result = data.filter((obj: any) => {
              return obj.industryId === demographicsObj.industryId;
            });
            setIndustry(result[0]);
          }).catch((err: any) => {
            sentry_error_catch(err,setDataLoading,setFormStates)
          });
      }
    } catch (err:any) {
      sentry_error_catch(err,setDataLoading,setFormStates)
    }
  };

  const getRelativeEffort = async () => {
    try {
      await fetch(`${CSET_URL}api/Demographics/Size`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setRelativeEffortList(data);
          const result = data.filter((obj: any) => {
            return obj.demographicId === demographicsObj.size;
          });
          setRelativeEffort(result[0]);
        })
        .catch((error: any) => {
          handleError(error);
        });
    } catch (err:any) {
      sentry_error_catch(err,setDataLoading,setFormStates)
    }
  };

  const handleAlertClose = () => {
    setFormStates((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };
  let retryAttempt = false;
  const getOrganizationType = async () => {
    fetch(`${CSET_URL}api/getOrganizationTypes`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.json())
      .then((data) => {
        setDataLoading(false);
        if (Array.isArray(data)) {
          setOrganizationList(data);
          const result = data.filter((obj: any) => {
            return obj.organizationTypeId === demographicsObj.organizationType;
          });
          setOrganization(result[0]);
        }else{
          console.log("data is not array ",data);
          if(!retryAttempt){
            retryAttempt = true;
            getOrganizationType()
          }else{
            console.log('retry attempt failed, data is still not array')
            setFormStates((formState:any) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: ERROR_MESSAGE,
            }))
          }
          setDataLoading(false);
        }
      })
      .catch((error: any) => {
        console.log("error", error);
        handleError(error);
      });
  };

  const getFacilitatorAndContact = () => {
    try {
      getFacilitatorAndContactOfAssessment({
        variables: {
          where: {
            id: param.ccAssessmentInfo.id,
          },
        },
      })
        .then((data) => {
          if (data.data.csetAssessments && data.data.csetAssessments[0]) {
            if (data.data.csetAssessments[0].facilitator) {
              setFacilitator(data.data.csetAssessments[0].facilitator);
            }
            if (data.data.csetAssessments[0].critical_point_of_contact) {
              setContact(
                data.data.csetAssessments[0].critical_point_of_contact
              );
            }
          }
        })
        .catch((err:any)=>{
          sentry_error_catch(err,setDataLoading,setFormStates)
        })
    } catch (err: any) {
      sentry_error_catch(err,setDataLoading,setFormStates)
    }
  };

  const submitContactAndFacilitatorData = () => {
    try {
      updateAssessement({
        variables: {
          id: param.ccAssessmentInfo.id,
          assessment_type: param.flowType.type,
          facilitator: demographicsObj.facilitator
            ? demographicsObj.facilitator
            : facilitator,
          criticalPointOfContact: demographicsObj.pointOfContact
            ? demographicsObj.pointOfContact
            : contact,
        },
      })
        .then((data: any) => {})
        .catch((err: any) => {
            sentry_error_catch(err,setDataLoading,setFormStates)
          });
    } catch (e) {
      console.log("Error", e);
    }
  };

  const inputChangeHandler = (event: any) => {
    if (event.target.name === "Organization") {
      setOrganizations(event.target.value);
      setDemographicsObj((demographicsObj: any) => {
        return {
          ...demographicsObj,
          organizationName: event.target.value ? event.target.value : null,
        };
      });
    } else if (event.target.name === "Business Unit/Agency") {
      setBusiness(event.target.value);
      setDemographicsObj((demographicsObjData: any) => {
        return {
          ...demographicsObjData,
          agency: event.target.value ? event.target.value : null,
        };
      });
    }
  };

  const closeDialogBox = () => {
    try{
      setOpenDialogBox(false);
    }catch(err: any){
      showBoundary(err)
    }
  };

  const cancelHandler = () => {
    try{
      setOpenDialogBox(true);
    }catch(err: any){
      showBoundary(err)
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      {enableBackdrop() ? <SimpleBackdrop /> : null}
      <Typography component="h5" variant="h1">
        Risk Assessment for {param ? param.clientInfo.name : null}
      </Typography>
      <DialogBox
        open={openDialogBox}
        handleOk={handleCancel}
        handleCancel={closeDialogBox}
        buttonOk={"Yes"}
        buttonCancel={"No"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.MainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}>
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            Please Confirm
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          <p>
            {CANCEL_MSG}{" "}
            {/* {param.assessmentInfo?.assessmentName}? */}
          </p>
        </div>
      </DialogBox>
      <Grid container spacing={0} className={styles.FooterGridWrap}>
        <Grid item xs={12} className={styles.OtherOfficeWrap}>
          {formStates.isFailed ? (
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {formStates.errMessage}
            </Alert>
          ) : null}
        </Grid>
        <Grid item xs={12} className={styles.FooterActions}>
          <div className={styles.topstepperButonsWrap}>
            <div className={styles.prevBtn}>
              <Button
                id="previous-button"
                variant="contained"
                color="primary"
                onClick={handleBack}
              >
                {"<< Previous"}
              </Button>
            </div>
            <div className={styles.topstepperButons}>
              <TopStepper
                param={param}
                handleSave={callingFromTopStepper}
                obj={stepperObj}
                submitDisabled={false}
              />
            </div>
            <div className={styles.nextBtn}>
              <Button
                id="next-button"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                {"Next >>"}
              </Button>
            </div>
          </div>
          {!is_link_assessment && (<div className={styles.crossBtn}>
            <Button
              id="cancel-button"
              variant="contained"
              color="primary"
              onClick={cancelHandler}
            >
              {"X"}
            </Button>
            <span className={styles.cancleTooltip}>Cancel</span>
          </div>)}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Paper className={styles.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <AutoCompleteDropDown
                  {...getAllSectors}
                  id="sector"
                  value={sector}
                  name="sector"
                  onChange={handleSectorChange}
                  renderInput={(
                    params:
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").StandardTextFieldProps)
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").FilledTextFieldProps)
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").OutlinedTextFieldProps)
                  ) => <Input {...params} id="sector" label="Sector" />}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AutoCompleteDropDown
                  {...getAllIndustries}
                  id="industry"
                  value={industry}
                  disabled={sector ? false : true}
                  name="industry"
                  onChange={handleIndustryValueChange}
                  renderInput={(
                    params:
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").StandardTextFieldProps)
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").FilledTextFieldProps)
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").OutlinedTextFieldProps)
                  ) => <Input {...params} id="industry" label="Industry" />}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Input
                  id="Organization"
                  name="Organization"
                  label="Organization"
                  variant="outlined"
                  value={organizations || ""}
                  onChange={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Input
                  id="Business Unit/Agency"
                  name="Business Unit/Agency"
                  label="Business Unit/Agency"
                  variant="outlined"
                  value={business || ""}
                  onChange={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AutoCompleteDropDown
                  {...getAllOrganization}
                  id="organization"
                  value={organization}
                  name="organization"
                  onChange={handleValueChange}
                  renderInput={(
                    params:
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").StandardTextFieldProps)
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").FilledTextFieldProps)
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").OutlinedTextFieldProps)
                  ) => (
                    <Input
                      {...params}
                      id="organization"
                      label="Organization Type"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  id="Facilitator"
                  name="Facilitator"
                  label="Facilitator"
                  Autocomplete="off"
                  variant="outlined"
                  value={facilitator || ""}
                  onChange={handleFacilitatorValueChange}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  id="criticalPointOfContact"
                  name="criticalPointOfContact"
                  label="Critical Point Of Contact"
                  Autocomplete="off"
                  variant="outlined"
                  value={contact || ""}
                  onChange={handleContactValueChange}
                />
              </Grid>
              <Grid item xs={12}>
                <span className={styles.CompanyFormHead}>
                  {"What is the relative expected effort for this assessment?"}
                </span>
                <div className={styles.RelativeAssessment}>
                  <AutoCompleteDropDown
                    {...getAllRelativeEffort}
                    id="relativeEffort"
                    value={relativeEffort}
                    name="Relative Effort"
                    onChange={handleRelativeEffortChange}
                    renderInput={(
                      params:
                        | (JSX.IntrinsicAttributes &
                            import("@mui/material").StandardTextFieldProps)
                        | (JSX.IntrinsicAttributes &
                            import("@mui/material").FilledTextFieldProps)
                        | (JSX.IntrinsicAttributes &
                            import("@mui/material").OutlinedTextFieldProps)
                    ) => (
                      <Input
                        {...params}
                        id="relative-effort"
                        label="Relative Effort"
                      />
                    )}
                  />
                </div>
              </Grid>
            </Grid>
            <span className={styles.CompanyFormHead}>
              {"CMMC Target Level Selection"}
            </span>
            <Grid container>
              <Grid item xs={12} md={12} lg={12} className={styles.secure_para}>
                {" "}
                Select the desired maturity level. Selecting a level will
                include all levels below it.
              </Grid>

              <Grid
                container
                spacing={1}
                className={styles.Security_Assurance_Grid}
              >
                <Grid>
                  <FormControl component="fieldset">
                    <RadioGroup
                      id="target-level"
                      row
                      aria-label="CMMC Target Level Selection"
                      name="CMMC Target Level Selection"
                      value={level}
                      onChange={handleLevelChange}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio className={styles.CompanySelect} />}
                        label="Level 1"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio className={styles.CompanySelect} />}
                        label="Level 2"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                {level === "1" ? (
                  <span className={styles.LevelHead}>
                    <h3>
                      <b>Level 1</b>
                    </h3>
                    Foundational. Consists of 17 basic practices and is
                    achievable by self-assessment.{" "}
                  </span>
                ) : (
                  <span className={styles.LevelHead}>
                    <h3>
                      <b>Level 2</b>
                    </h3>
                    Advanced. Consists of 110 practices aligned with NIST SP
                    800-171. Requires annual self-assessment for select
                    contractors or triennial third-party assessments for
                    critical national security projects. <br></br>
                    <br></br>
                    The advanced level will calculate a scorecard with the
                    Supplier Performance Risk System (SPRS) score.{" "}
                  </span>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CMMC2Level;
