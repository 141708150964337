import { gql } from "@apollo/client";

export const GET_ORG_OF_USER = gql`
  query getOrganizationOfUser($id: ID) {
    organizations(where: { contact_person_id: $id }) {
      id
      contact_id {
        id
        name
        contact_type
        user_id {
          id
          username
        }
        last_name
      }
      organization_type {
        id
        name
        sub_type {
          id
          name
        }
      }
      organization_subtype {
        id
        name
      }
      contact_person_id {
        id
        name
      }
      best_practices {
        id
        Name
      }
    }
  }
`;

//for admin panel company list page
export const GET_ORG = gql`
  query getOrganizationOfUser($where: JSON, $sort: String) {
    organizations(where: $where, sort: $sort) {
      id
      contact_id {
        id
        name
        contact_type
        user_id {
          id
          username
        }
        last_name
      }
      organization_type {
        id
        name
      }
      organization_subtype {
        id
        name
      }
      contact_person_id {
        id
        name
      }
      best_practices {
        id
        Name
      }
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query getOrganizationOfUser($where: JSON, $sort: String) {
    organizations(where: $where, sort: $sort) {
      id
      subtype
      partner_id {
        id
        name
        email
        phone
        contact_type
      }
      timezone
      subtype
      pg_id
      contact_id {
        id
        name
        contact_type
        email
        phone
        created_at
        last_name
      }
      active_status
      guest_client
      logo {
        id
        url
      }
    }
  }
`
