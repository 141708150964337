import React, { useState, useEffect } from "react";
import styles from "./TopStepper.module.css";
import { makeStyles, createStyles } from "@mui/styles";
import {
  csetTopStepperMenu,
  csetCRRTopStepperMenu,
  csetStandardTopStepperMenu,
  csetCMMCTopStepperMenu,
  csetRRATopStepperMenu,
} from "../../../../../config/menu";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { CLIENT_COMPLIANCESTEPPER } from "../../../../../graphql/mutations/ComplienceStepper";
import CSetStepper from "../../../../../common/csetStepperMenu.json";
import standardStepper from "../../../../../common/csetStandardStepperMenu.json";
import cmmcStepper from "../../../../../common/csetCMMCStepperMenu.json";
import rraStepper from "../../../../../common/csetRRAStepperMenu.json";
import crrStepper from "../../../../../common/csetCRRStepperMenu.json";
import { getActiveFormStep } from "../../../../../services/Data";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

export interface StepperProps {
  obj?: any;
  param?: any;
  handleSave?: any;
  submitDisabled?: any;
}

export const TopStepper: React.FC<StepperProps> = ({
  obj,
  param,
  handleSave,
  submitDisabled,
}: StepperProps) => {
  const CSetStepperObject = CSetStepper;
  const standardStepperObject = standardStepper;
  const cmmcStepperObject = cmmcStepper;
  const crrStepperObject = crrStepper;
  const rraStepperObject = rraStepper;
  const nextValue = useQuery(CLIENT_COMPLIANCESTEPPER);
  const removeButtonFromTopStepper = ["Results","Reports"]
  const history = useNavigate();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState<any>();
  const is_link_assessment =  Boolean(sessionStorage.getItem("is_link_authenticated")) || false;
  const [step, setStep] = useState<any>();

  function getSteps() {
    let stepArray: any = [];
    if (
      param !== undefined &&
      param !== null &&
      param.hasOwnProperty("flowType") &&
      param.flowType.type === "Standard"
    ) {
      stepArray = csetStandardTopStepperMenu;
    }
    if (
      param !== undefined &&
      param !== null &&
      param.hasOwnProperty("flowType") &&
      param.flowType.type === "Custom"
    ) {
      stepArray = csetTopStepperMenu;
    }
    if (
      param !== undefined &&
      param !== null &&
      param.hasOwnProperty("flowType") &&
      param.flowType.type === "CMMC2"
    ) {
      stepArray = csetCMMCTopStepperMenu;
    }
    if (
      param !== undefined &&
      param !== null &&
      param.hasOwnProperty("flowType") &&
      param.flowType.type === "RRA"
    ) {
      stepArray = csetRRATopStepperMenu;
    }
    if (
      param !== undefined &&
      param !== null &&
      param.hasOwnProperty("flowType") &&
      param.flowType.type === "CRR"
    ) {
      stepArray = csetCRRTopStepperMenu;
    }
    return stepArray;
  }

  useEffect(() => {
    if (activeStep !== nextValue.data?.activeStep) {
      if (
        nextValue.data.complianceStep ===
        CSetStepperObject.assessmentInformation.name
      ) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (
        nextValue.data.complianceStep === standardStepperObject.firmType.name
      ) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }

      if (
        nextValue.data.complianceStep ===
        CSetStepperObject.securityAssuranceLevel.name
      ) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (nextValue.data.complianceStep === CSetStepperObject.standards.name) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (nextValue.data.complianceStep === CSetStepperObject.questions.name) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (nextValue.data.complianceStep === CSetStepperObject.results.name) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (
        nextValue.data.complianceStep === standardStepperObject.results.name
      ) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (
        nextValue.data.complianceStep ===
        cmmcStepperObject.assessmentInformation.name
      ) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (
        nextValue.data.complianceStep === cmmcStepperObject.maturityLevel.name
      ) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (nextValue.data.complianceStep === cmmcStepperObject.questions.name) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (nextValue.data.complianceStep === cmmcStepperObject.results.name) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (nextValue.data.complianceStep === cmmcStepperObject.reports.name) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (
        nextValue.data.complianceStep ===
        crrStepperObject.assessmentDemographics.name
      ) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (nextValue.data.complianceStep === crrStepperObject.questions.name) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (nextValue.data.complianceStep === crrStepperObject.reports.name) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (nextValue.data.complianceStep === crrStepperObject.results.name) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (nextValue.data.complianceStep === CSetStepperObject.reports.name) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (
        nextValue.data.complianceStep ===
        rraStepperObject.assessmentDemographics.name
      ) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (nextValue.data.complianceStep === rraStepperObject.questions.name) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (nextValue.data.complianceStep === rraStepperObject.reports.name) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
      if (nextValue.data.complianceStep === rraStepperObject.results.name) {
        setActiveStep(nextValue.data.activeStep);
        setStep(nextValue.data.activeStep);
      }
    }
  }, [nextValue?.data?.activeStep]);

  const allSteps = getSteps();
  const handleTab = (e: any, i: number, label: any) => {
    if (!submitDisabled) {
      setStep(i);
      e.preventDefault();
      if (nextValue.data && i !== parseInt(nextValue.data.activeStep)) {
        obj.path = label.path;
        handleSave(obj);
      }
      if (nextValue?.data?.activeStep) {
        history(label.path, { state: { param } });
      }
    }
  };

  return (
    <div className={classes.root}>
      <Stepper
        className={styles.ReactStepper}
        activeStep={
          parseInt(activeStep) > getActiveFormStep()
            ? activeStep
            : getActiveFormStep()
        }>
        {allSteps.map((label: any, i: number) => (
          !is_link_assessment ? (
            <Step key={label.title} className={styles.ReactStep}>
              <StepButton
                id={label.title}
                color={Number(step) === i ? "primary" : "secondary"}
                onClick={(e: any) => handleTab(e, i, label)}>
                {label.title}
              </StepButton>
            </Step>) : (!removeButtonFromTopStepper.includes(label.title)) ? (
            <Step key={label.title} className={styles.ReactStep}>
              <StepButton
                id={label.title}
                color={Number(step) === i ? "primary" : "secondary"}
                onClick={(e: any) => handleTab(e, i, label)}>
                {label.title}
              </StepButton>
            </Step>) : null
        ))}
      </Stepper>
    </div>
  );
};

export default TopStepper;
