import React from 'react';
import { Grid, Typography, Container } from '@mui/material';
import style from './FallbackPage.module.css';
import Layout from '../Layout/Layout';
import { PARTNER_SUPORT_EMAIL } from '../../../config';

const FallbackPage: React.FC = () => {

  return (
    <Layout>
    <Container className={style.fallbackContainer}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={style.fallbackGrid}
      >
        <Grid item>
          <img
            src={process.env.PUBLIC_URL + "/1cyberboy.png"}
            alt="Something went wrong"
            className={style.fallbackImage}
          />
        </Grid>
        <Grid item>
          <Typography component="h1" variant="h1" classes={{h1:style.fallbackHeading}} gutterBottom>
            Something went wrong, Please try again.
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h1" variant="h1" classes={{h1:style.fallbackHeading}} gutterBottom>
          If you keep getting this error, contact <span className={style.mailLinkSpan}><a href={`mailto:${PARTNER_SUPORT_EMAIL}`}>partner support</a></span>
          </Typography>
        </Grid>
      </Grid>
    </Container>
    </Layout>
  );
};

export default FallbackPage;
