import styles from "./StandardTutorial.module.css";
import React, { FC, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import { Button } from "../../../components/UI/Form/Button/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import * as routeConstant from "../../../common/RouteConstants";

const StandardTutorial: FC<any> = (props: any) => {
  const history = useNavigate();
  const location = useLocation();
  const { showBoundary } = useErrorBoundary();
  const [param, setParam] = useState<any>(
    location?.state ? location?.state[Object.keys(location?.state)[0]] : null
  );
  const marginData = "0px 0px 100px 10px";
  useEffect(() => {
    if (location.state) {
      setParam(location?.state[Object.keys(location?.state)[0]]);
    } else {
      history(routeConstant.ASSESSMENT_TYPE);
    }
  }, []);

  const handleNext = () => {
    try{
    history(routeConstant.ASSESSMENT_INFO, { state: { param } });
    }catch(err){
      showBoundary(err)
    }
  };
  const handleBack = () => {
    try{
    history(routeConstant.ASSESSMENT_TYPE, { state: { param } });
    }catch(err){
      showBoundary(err)
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container spacing={2}>
        <Grid item xs={12} className={styles.TutorialHeaders}>
          <Typography
            style={{ fontWeight: "bold" }}
            component="h5"
            variant="h1"
          >
            {param && param.assessmentName ? param.assessmentName :""} Tutorial
          </Typography>
          <div className={styles.prevBtn}>
            <Button
              id="previous-button"
              variant="contained"
              color="primary"
              onClick={handleBack}
            >
              {"<< Previous"}
            </Button>
          </div>
          <div className={styles.prevBtn}>
            <Button
              id="next-button"
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              {"Next >>"}
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid>
        <Paper className={styles.paper}>
          <div className={styles.header}>Overview</div>
          <p className={styles.content}>
            A CSET cybersecurity assessment examines the organization's
            cybersecurity posture against a specific standard. The assessment
            tests its security controls and measures how they stack up against
            known vulnerabilities.
          </p>
          <p className={styles.content}>
            <b>Step 1</b>: Enter the assessment name, City, State and Facility Name 
            and Select gross value of the asset you are trying to protect, 
            for which you want to create the assessment.
          </p>
          <p className={styles.content}>
            Under Assessment Type, assessment type and assessment description will be displayed. 
          </p>
          <p  className={styles.content}>
            On Click of Cancel button which is marked as 2, you will be redirected to the Risk
            Assessment List.
          </p>
          <p className={styles.content}>
            A pop-up with pricing or credit details would be displayed on
            clicking the next button, which is marked as 1.On Click of Yes
            Button you will be redirected to the Security Assurance Level
            screen.
          </p>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/ad_flow_1.png`}
              alt="ad_flow_1"
            />
            <p className={styles.imageTitle}>
              Figure 1: Assessment Information Screen
            </p>
          </div>
          <div className={styles.imageTitle}>
            <img
              width={500}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/credit.png`}
              alt="Credit"
            />
            <p className={styles.imageTitle}>
              Figure 1.1: Credit/Pricing Information Screen
            </p>
          </div>
          <div className={styles.imageTitle}>
            <img
              width={500}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/pricing.png`}
              alt="Pricing"
            />
            <p className={styles.imageTitle}>
              Figure 1.2: Credit/Pricing Information Screen
            </p>
          </div>
          <p className={styles.content}>
            <b>Step 2</b>: Select Sector, Industry, Organization Type, Relative Effort and SAL and Enter the Organization, Business Unit/Agency, Facilitator and Critical Point of Contact.{" "}
          </p>
          <p className={styles.content}>
            Click on the Next button marked as 1, and you will be redirected to
            the Mode selection screen.
          </p>
          <p className={styles.content}>
            On click of Cancel button marked as 2, you will be redirected to the
            Risk Assessment List.
          </p>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/ad_flow_2.png`}
              alt="ad_flow_2"
            />
            <p className={styles.imageTitle}>Figure 2: SAL Screen</p>
          </div>
          <p className={styles.content}>
            <b>Step 3</b>: Select the Mode and accordingly Requirement and
            Questions count will get updated.
          </p>
          <p className={styles.content}>
            Click on the Next button marked as 1, and you will be redirected to
            the Questions screen.
          </p>
          <p className={styles.content}>
            On click of Cancel button marked as 2, you will be redirected to the
            Risk Assessment List.
          </p>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/ad_flow_4.png`}
              alt="ad_flow_4"
            />
            <p className={styles.imageTitle}>
              Figure 3: Mode Selection Screen
            </p>
          </div>
          <p className={styles.content}>
            <b>Step 4</b>: Select answers to the questions.
          </p>
          <p className={styles.content}>
            Here on the left side panel, you will get the Total Questions count.
          </p>
          <p className={styles.content}>
            Three checkboxes will show the questions colorwise as follows:
          </p>
          <ul>
            <li>
              <img
                width={50}
                src={`${process.env.PUBLIC_URL}/images/greenbox.png`}
                alt="green"
              />
              <span style={{ margin: marginData }}>
                Green checkbox - Completed questions
              </span>
            </li>
            <li>
              {" "}
              <img
                width={50}
                src={`${process.env.PUBLIC_URL}/images/yellowbox.png`}
                alt="yellow"
              />
              <span style={{ margin: marginData }}>
                Yellow checkbox - In progress questions
              </span>
            </li>
            <li>
              <img
                width={50}
                src={`${process.env.PUBLIC_URL}/images/greybox.png`}
                alt="grey"
              />
              <span style={{ margin: marginData }}>
                Grey checkbox - Not started questions
              </span>
            </li>
          </ul>
          <p className={styles.content}>
            You can select the checkbox accordingly to answer the questions.
          </p>
          <p className={styles.content}>
            Click on the Next button marked as 1, and you will be redirected to
            the Results screen.
          </p>
          <p className={styles.content}>
            On click of Save and Exit button marked as 2 , your assessment would
            get saved and you will be redirected back to the Risk Assessment
            Listing Screen.
          </p>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/ad_flow_5.png`}
              alt="ad_flow_5"
            />
            <p className={styles.imageTitle}>Figure 4: Questions Screen</p>
          </div>
          <h2>RA360 Assessment Components in CSET®</h2>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "50%" }}
              src={`${process.env.PUBLIC_URL}/images/assessment_components.png`}
              alt="assessment_Components"
            />
          </div>

          <h3>Supplemental Guidance</h3>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "50%" }}
              src={`${process.env.PUBLIC_URL}/images/supplementary_guidance.png`}
              alt="supplementary_guidance"
            />
          </div>
          <p className={styles.content}>
            Users can refer to the supporting authorities and additional
            guidance provided under Supplemental Guidance.
          </p>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/supplemental_guidance.png`}
              alt="supplemental_guidance"
            />
          </div>
          <p className={styles.imageTitle}>Figure 4.1: Questions Screen</p>
          <h3>Comments</h3>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "50%" }}
              src={`${process.env.PUBLIC_URL}/images/comments.png`}
              alt="comments"
            />
          </div>
          <p className={styles.content}>
            The comments function allows the assessor to record non-structured
            comments for each question during the assessment. In contrast, the
            Observations function (also useful for commenting) is more rigid and
            contains required fields.{" "}
          </p>
          <div className={styles.imageTitle}>
            <img
              width={500}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/comm.png`}
              alt="comments"
            />
          </div>
          <p className={styles.imageTitle}>Figure 4.2: Comments Screen</p>
          <h3>Artifacts/Documents</h3>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "50%" }}
              src={`${process.env.PUBLIC_URL}/images/documents.png`}
              alt="documents"
            />
          </div>
          <p className={styles.content}>
            Assessors can use the Artifacts/Documents function to attach
            relevant evidence to each practice question.{" "}
          </p>
          <div className={styles.imageTitle}>
            <img
              width={500}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/docs.png`}
              alt="docs"
            />
          </div>
          <p className={styles.imageTitle}>Figure 4.3: Documents Screen</p>
          <p className={styles.content}>
            <b>Step 5</b>: If you do not complete all the questions answers then
            you will get this popup window. Click on Yes to proceed. On click of
            No, you will be redirected back to the questions screen.
          </p>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/ad_flow_6.png`}
              alt="ad_flow_6"
            />
            <p className={styles.imageTitle}>Figure 5: Confirmation Screen</p>
          </div>
          <p className={styles.content}>
            On the basis of your selected answers, the Overall Score and
            Standard Based Score will get generated. Now, Click on the Next
            button marked as 1 and the assessment will get completed.
          </p>
          <p className={styles.content}>
            On click of Cancel button marked as 2, you will be redirected to the
            Risk Assessment List.
          </p>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/ad_flow_7.png`}
              alt="ad_flow_7"
            />
            <p className={styles.imageTitle}>Figure 6: Results Screen</p>
          </div>
          <p className={styles.content}>
            You will now get the popup window as follows. Click on the yes to
            proceed.
          </p>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/ad_flow_8.png`}
              alt="ad_flow_8"
            />
            <p className={styles.imageTitle}>Figure 7: Results Screen</p>
          </div>
          <p className={styles.content}>
            <b>Step 6</b>: You are now on the Reports Screen as below.{" "}
          </p>
          <p className={styles.content}>
            If you click on the Done, marked as 1,you will be then redirected to
            the assessments list screen.
          </p>
          <p className={styles.content}>
            If you click on the Download All Reports, marked as 2, you can
            download all the reports in zip format. Extract the zip and you will
            get all the reports generated of your assessment.
          </p>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/st_flow_8.png`}
              alt="ad_flow_10"
            />
            <p className={styles.imageTitle}>Figure 8: Reports Screen 1</p>
          </div>
          <p className={styles.content}>
            <b>Step 7</b>: You can now view/download all the reports. Click on
            the Executive Summary Report.
          </p>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/st_flow_8.png`}
              alt="ad_flow_11"
            />
            <p className={styles.imageTitle}>Figure 8.1: Reports Screen 2</p>
          </div>
          <p className={styles.content}>
            <b>Step 8</b>: You can view the complete Executive Summary Report.
            <br></br>
            You can download the report in Docx as well as in Pdf format.
          </p>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/st_flow_10.png`}
              alt="ad_flow_12"
            />
            <p className={styles.imageTitle}>
              Figure 9: Executive Summary Report Screen
            </p>
          </div>
          <div className={styles.imageTitle}>
            <img
              src={`${process.env.PUBLIC_URL}/images/ad_flow_13.png`}
              alt="ad_flow_13"
            />
            <p className={styles.imageTitle}>
              Figure 10: Download Report Format Screen
            </p>
          </div>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default StandardTutorial;
