import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './UsePagination';
import './pagination.scss';
import Tooltip from '@mui/material/Tooltip';

const Pagination = (props:any) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange && paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange?paginationRange[paginationRange.length - 1] : 0;
  const paginationItem = 'pagination-item';
  return (
    <ul
      className={classnames('pagination-container', { [className]: className })}
    >
      <Tooltip title="Previous">
        <li
          className={classnames(paginationItem, {
            disabled: currentPage === 1
          })}
          onClick={onPrevious}
        >
          <div className="arrow left" />
        </li>
      </Tooltip>
      {paginationRange?.map((pageNumber:any) => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li
            className={classnames(paginationItem, {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <Tooltip title="Next">
        <li
          className={classnames(paginationItem, {
            disabled: currentPage === lastPage
          })}
          onClick={onNext}
        >
          <div className="arrow right" />
        </li>
      </Tooltip>
    </ul>
  );
};

export default Pagination;
