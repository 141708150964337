import { gql } from "@apollo/client";

export const GET_CLIENT_SUBSCRIPTION = gql`
  query getCLientSubs($where: JSON, $sort: String) {
    clientSubscriptions(where: $where, sort: $sort) {
      id
      contact_id {
        id
        name
        email
        phone
      }
      cc_subscription
      ra_subscription
    }
  }
`
