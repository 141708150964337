import React from "react";
import {Routes,Route,Navigate} from "react-router-dom";
import * as routeConstants from "./common/RouteConstants";
import Partner from "./containers/Admin/Partner/Partner";
import User from './containers/Admin/User/User';
import AdminClient from './containers/Admin/AdminClient/Client';
import AdminAssessment from './containers/Admin/AdminAssessment/AdminAssessment';
import Layout from "./components/UI/Layout/Layout";
import AdminDashboard from "./containers/Admin/AdminDashboard/AdminDashboard";

export const AdminRoutes = (
  <Routes>
     <Route path="about" Component={() => <Navigate to="about-us" />} />
     <Route
      path={routeConstants.ADMIN_PARTNER}
      element={
        <Layout>
          <Partner/>
        </Layout>
      }
    />
    <Route
      path={routeConstants.ADMIN_USER}
      element={
        <Layout>
          <User/>
        </Layout>
      }
    />
    <Route
      path={routeConstants.ADMIN_CLIENT}
      element={
        <Layout>
          <AdminClient/>
        </Layout>
      }
    />
    <Route
      path={routeConstants.ADMIN_ASSESSMENT}
      element={
        <Layout>
          <AdminAssessment/>
        </Layout>
      }
    />
    <Route
      path={routeConstants.DELETE_ASSESSMENT}
      element={
        <Layout>
          <AdminAssessment/>
        </Layout>
      }
    />
    <Route
      path={routeConstants.ADMIN_DASHBOARD}
      element={
        <Layout>
          <AdminDashboard />
        </Layout>
      }
    />
    {/* <Route path="*">
       <Navigate to={routeConstants.ADMIN_PARTNER} />
    </Route> */}
   </Routes> 
);
