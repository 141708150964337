import React, { useState, useEffect } from "react";
import styles from "./ObservationsTearOutSheet.module.css";
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Button } from "../../../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../../../common/RouteConstants";
import { useNavigate, useLocation } from 'react-router-dom';
import { CSET_URL } from "../../../../../config/index";
import Divider from "@mui/material/Divider";

export const ObservationsTearOutSheet: React.FC = (props: any) => {
  const history = useNavigate();
  const location = useLocation();
  const [siteInformation, setSiteInformation] = useState<any>({});
  const [param, setParam] = useState<any>();
  const authToken = sessionStorage.getItem("ra_token") || "";

  useEffect(() => {
    setParam(location.state);
    getDataforExecutiveSummaryReport();
  }, []);

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const getDataforExecutiveSummaryReport = async () => {
    try {
      await fetch(`${CSET_URL}api/reports/discoveries`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setSiteInformation(data.information);
        });

      await fetch(`${CSET_URL}"api/analysis/Dashboard`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((chartDataResponse) => {});

      await fetch(`${CSET_URL}"api/analysis/StandardsSummary`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {});

      await fetch(`${CSET_URL}"api/analysis/StandardsResultsByCategory`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {});

      await fetch(`${CSET_URL}"api/reports/executive`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {});
    } catch (e) {
      console.error("error", e);
    }
  };

  const handleBack = () => {
    history(routeConstant.RESULTS, {state: {param}});
  };

  const handleCancel = () => {
    history(routeConstant.CLIENT, {state: {param}});
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container>
        <Grid item md={12}>
          <Typography component="h5" variant="h1">
            {"Observations Tear-Out Sheets"}
          </Typography>
          <Paper className={styles.paper}>
            <Grid container>
              <Grid
                item
                xs={8}
                md={"auto"}
                sm={8}
                className={styles.FooterActions}
              >
                <h3>Disclaimer</h3>
                <Grid
                  item
                  xs={8}
                  md={8}
                  sm={8}
                  className={styles.FooterActions}
                >
                  <p>
                    The analysis, data, and reports in CSET ® are provided “as
                    is” for informational purposes only. The Department of
                    Homeland Security (DHS) does not provide any warranties of
                    any kind regarding any information contained within. In no
                    event shall the United States Government or its contractors
                    or subcontractors be liable for any damages, including but
                    not limited to, direct, indirect, special, or consequential
                    damages and including damages based on any negligence of the
                    United States Government or its contractors or
                    subcontractors, arising out of, resulting from, or in any
                    way connected with this report, whether based on warranty,
                    contract, tort, or otherwise, whether injury was sustained
                    from, or arose out of the results of, or reliance upon the
                    report.{" "}
                  </p>
                  <p>
                    DHS does not endorse any commercial product or service,
                    including the subject of the assessment or evaluation in
                    this report. Any reference to specific commercial products,
                    processes, or services by trademark, manufacturer, or
                    otherwise, does not constitute or imply its endorsement,
                    recommendation, or favoring by DHS.{" "}
                  </p>
                  <p>
                    The display of the DHS official seal or other DHS visual
                    identities on this report shall not be interpreted to
                    provide the recipient organization authorization to use the
                    official seal, insignia, or other visual identities of DHS.
                    The DHS seal, insignia, or other visual identities shall not
                    be used in any manner to imply endorsement of any commercial
                    product or activity by DHS or the United States Government.
                    Use of the DHS seal without proper authorization violates
                    federal law (e.g., 18 U.S.C. §§ 506, 701, 1017), and is
                    against DHS policies governing usage of the seal.{" "}
                  </p>
                  <p>
                    The report is prepared and intended for internal use by the
                    organization that made the request. The contents of this
                    report may be subject to government or private intellectual
                    property rights. To request distribution of this report
                    outside the organization for which it was prepared, contact
                    the CSET Program Office. The contents of this report may be
                    reproduced or incorporated into other reports, but may not
                    be modified without the prior express written permission of
                    the CSET Program Office.{" "}
                  </p>
                </Grid>
                <hr></hr>
                <Divider />
                <h3>Advisory</h3>
                <Grid
                  item
                  xs={8}
                  md={8}
                  sm={8}
                  className={styles.FooterActions}
                >
                  <p>
                    {" "}
                    CSET® is only one component of the overall cyber security
                    picture and should be complemented with a robust cyber
                    security program within the organization. A self-assessment
                    with CSET® cannot reveal all types of security weaknesses,
                    and should not be the sole means of determining an
                    organization’s security posture.{" "}
                  </p>
                  <p>
                    {" "}
                    The tool will not provide a detailed architectural analysis
                    of the network or a detailed network hardware/software
                    configuration review. It is not a risk analysis tool so it
                    will not generate a complex risk assessment. CSET® is not
                    intended as a substitute for in depth analysis of control
                    system vulnerabilities as performed by trained
                    professionals. Periodic onsite reviews and inspections must
                    still be conducted using a holistic approach including
                    facility walk downs, interviews, and observation and
                    examination of facility practices. Consideration should also
                    be given to additional steps including scanning, penetration
                    testing, and exercises on surrogate, training, or
                    non-production systems, or systems where failures,
                    unexpected faults, or other unexpected results will not
                    compromise production or safety.{" "}
                  </p>
                  <p>
                    {" "}
                    CSET® assessments cannot be completed effectively by any one
                    individual. A cross-functional team consisting of
                    representatives from operational, maintenance, information
                    technology, business, and security areas is essential. The
                    representatives must be subject matter experts with
                    significant expertise in their respective areas. No one
                    individual has the span of responsibility or knowledge to
                    effectively answer all the questions.{" "}
                  </p>
                  <p>
                    {" "}
                    Data and reports generated by the tool should be managed
                    securely and marked, stored, and distributed in a manner
                    appropriate to their sensitivity.{" "}
                  </p>
                </Grid>
                <hr></hr>
                <Divider />
                <Grid
                  item
                  xs={6}
                  md={12}
                  sm={8}
                  className={styles.FooterActions}
                >
                  <h3>Site Information </h3>
                </Grid>
                <p> Assessment Name:{siteInformation.Assessment_Name}</p>
                <p> Assessment Date:{siteInformation.Assessment_Date}</p>
                <p> Facility Name:{siteInformation.Facility_Name}</p>
                <p> City or Site Name: {siteInformation.City_Or_Site_Name}</p>
                <p>
                  {" "}
                  State, Province or Region:{" "}
                  {siteInformation.State_Province_Or_Region}
                </p>
                <p>Principal Assessor Name: {siteInformation.Assessor_Name} </p>
                <p>
                  Additional Notes and Comments:
                  {siteInformation.Additional_Notes_And_Comments}{" "}
                </p>
                <p>
                  Contact(s):
                  {siteInformation.Additional_Contacts
                    ? siteInformation.Additional_Contacts[0]
                    : ""}
                </p>
                <hr></hr>
                <Divider />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={4} className={styles.FooterActions}>
          <div className={styles.prevBtn}>
            <Button  id="previous-button" variant="contained" color="primary" onClick={handleBack}>
              {"Previous"}
            </Button>
            <div className={styles.cancelButton}>
              <Button
                 id="cancel-button"
                variant="contained"
                color="primary"
                onClick={handleCancel}
              >
                {"Cancel"}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ObservationsTearOutSheet;
