import React, { useState } from 'react';
import { FormHelperText } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import IconButton from "@mui/material/IconButton";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import Input from "../../../components/UI/Form/Input/Input";
import { Button } from "../../../components/UI/Form/Button/Button";
import clsx from "clsx";
import styles from "./Login.module.css";
import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
import { USER_LOGIN } from "../../../graphql/mutations/User";
import * as validations from "../../../common/validateRegex";
import * as msgConstants from "../../../common/MessageConstants"
import { useMutation, useLazyQuery } from '@apollo/client';
import { GET_CONTACT } from '../../../graphql/queries/Contact';
                                    
const Login: React.FC = () => {
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [getContacts] = useLazyQuery(GET_CONTACT, {
    fetchPolicy: msgConstants.FETCH_POLICY,
  });
  const [login] = useMutation(USER_LOGIN);
  const handlePasswordChange = () => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassword(event.target.value);
    if (!event.target.value) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  }
  const handleEmailChange = () => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmail(event.target.value);
    if (!validations.EMAIL_REGEX.test(event.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }
  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  }
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }

  const onLogin = (event: any) => {
    let raContact: any = {}
    event.preventDefault();
    if (!emailError && !passwordError) {
      setShowBackDrop(true);
      login({
        variables: {
          identifier: email,
          password
        }
      }).then(async (data: any) => {
        const con = await getContacts({
          variables: {
            id: parseInt(data.data.login.user.id)
          },
          context :{
            headers: {
              'Authorization':  'Bearer' + " " + data.data.login.jwt 
            },
          }
        }).then((data: any) => {
          raContact = {
            id: data.data.contacts[0].id,
            last_name: data.data.contacts[0].last_name,
            name: data.data.contacts[0].name,
            email: data.data.contacts[0].email,
            contact_type: data.data.contacts[0].contact_type,
            user_id: {
              id: data.data.contacts[0].user_id.id,
            }
          }
          return raContact;
        }).catch((error: any) => {
          console.log("error", error);
        })
        const res: any = data.data.login;
        const user: any = {
          jwt: data.data.login.jwt,
          user: {
            id: res.user.id,
            username: res.user.username,
            role: {
              name: res.user.role.name,
            }
          }
        }
        sessionStorage.setItem("ra_contact", JSON.stringify(con));
        sessionStorage.setItem("ra_session", data.data.login.jwt);
        sessionStorage.setItem("ra_user", JSON.stringify(user));
        window.location.replace("/admin/dashboard");
      }).catch((err) => {
        console.log("login err", err);
        setShowBackDrop(false);
        setInvalidLogin(true);
      })
      setShowBackDrop(false);
    }
  }
  return (
    <div className={styles.Container}>
      <Card className={styles.root}>
        {showBackDrop ? <SimpleBackdrop /> : null}
        <Grid item xs={12} className={styles.siteInfo}>
          <form className={styles.Login} onSubmit={onLogin} noValidate>
            <div className={styles.Box}>
              <div className={styles.RALogoImg}>
                <img
                  src={
                    `${process.env.PUBLIC_URL}/RA360.png`
                  }
                  alt="user icon"
                />
              </div>

              <div className={styles.Margin}>
                <FormControl className={styles.TextField} variant="outlined">
                  <Input
                    type="email"
                    label="Email Address"
                    // name="email"
                    value={email}
                    id="email-id"
                    onChange={handleEmailChange()}
                    error={emailError}
                    required
                    data-testid="email-id"
                    classes={{
                      root: styles.InputField,
                      notchedOutline: styles.InputField,
                      focused: styles.InputField,
                    }}
                  >
                    E-mail
                  </Input>
                  {emailError ? (
                    <FormHelperText classes={{ root: styles.FormHelperText }}>
                      Please enter valid email address.
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </div>
              <div
                className={clsx(
                  styles.Margin,
                  styles.BottomMargin,
                  styles.PasswordField
                )}
              >
                <FormControl className={styles.TextField} variant="outlined">
                  <InputLabel classes={{ root: styles.FormLabel }}>
                    Password
                  </InputLabel>
                  <OutlinedInput
                    classes={{
                      root: styles.InputField,
                      notchedOutline: styles.InputField,
                      focused: styles.InputField,
                    }}
                    data-testid="password"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    value={password}
                    required
                    onChange={handlePasswordChange()}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {passwordError ? (
                    <FormHelperText classes={{ root: styles.FormHelperText }}>
                      Please enter a password.
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </div>
              {invalidLogin ? (
                <div className={styles.Errors}>
                  Incorrect username or password.
                </div>
              ) : null}
              <Button
                className={styles.ContinueButton}
                variant={"contained"}
                type="submit"
              >
                Login
              </Button>
            </div>
          </form>
        </Grid>
      </Card>
    </div>
  );
}

export default Login;
