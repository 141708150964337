import React, { useState, useEffect } from "react";
import { CSET_URL } from "../../../../config/index";
import { HorizontalBar } from "react-chartjs-2";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import Paper from '@mui/material/Paper';
import styles from "./RankedCategories.module.css";
import MaterialTable from "../../../../components/UI/Table/MaterialTable";
import logout from "../../../../containers/Auth/Logout/Logout";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../components/UI/Alert/Alert";
import * as msgConstant from '../../../../common/MessageConstants';
import { sentry_error_catch } from "../../../../common/sentry_error_catch";
export interface RankedProps {
  data?: any;
  props?: any;
}

export const RankedCategories: React.FC<RankedProps> = ({
  data,
  props,
}: RankedProps) => {
  const [chartData, setChartData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState([]);
  const [ param, setParam] = useState<any>();
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const column = [
    { title: "Category", key: "category" },
    { title: "Rank", key: "rank" },
    { title: "Failed", key: "failed" },
    { title: "Total", key: "total" },
    { title: "Percentage", key: "percentage" },
  ];
  useEffect(() => {
    const controller = new AbortController();
    if(data!== undefined){
    setParam(data)
    }
    getRankedCategoriesDetails();
    return () => controller.abort();
  }, []);

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: msgConstant.ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const getRankedCategoriesDetails = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/analysis/OverallRankedCategories`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          createTableDataObject(data.dataRows);
          setChartData({
            labels: data.labels,
            datasets: [
              {
                label: msgConstant.RANKED_SUBJECT_AREAS,
                data: data.data,
                fill: true,
                backgroundColor: "rgb(163, 0, 14)",
                borderColor: "rgb(163, 0, 14)",
              },
            ],
          });
        }).catch((error)=>{
          handleError(error);
        })
    } catch (err:any) {
        sentry_error_catch(err,setLoading,setFormState)
    }
  };

  const options = {
    title: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
          },
        },
      ],
    },
  };
  const createTableDataObject = (data: any) => {
    const arr: any = [];
    data.forEach((element: any) => {
      const obj: any = {};
      obj["category"] = !element.title ? "-" : element.title;
      obj["rank"] = element.rank;
      obj["failed"] = !element.failed ? "-" : element.failed;
      obj["total"] = element.total;
      obj["percentage"] = `${Math.round(element.percent)}%`;
      arr.push(obj);
    });
    setNewData(arr);
  };

  if (loading) {
    return <SimpleBackdrop />
  }

  return (
    <React.Fragment>
      {formState.isFailed ? (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {formState.errMessage}
        </Alert>
      ) : null}
      <Paper className={styles.paper}>
        <div className="App">
          <Paper className={styles.paper}>
            <HorizontalBar data={chartData} options={options} />
          </Paper>
        </div>
        <Paper className={styles.paper}>
          <div className={styles.ScrollTable}>
            <MaterialTable columns={column} data={newData} pageSize={10} />
          </div>
        </Paper>
      </Paper>
    </React.Fragment>
  );
};
export default RankedCategories;
