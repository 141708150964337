import React, { useState, useEffect, useRef } from "react";
import styles from "./CRRExecutiveSummary.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_ORGANIZATION } from "../../../../../graphql/queries/Organization";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import * as msgConstants from "../../../../../common/MessageConstants";
import AssetManagement from "../../CRRResults/AssetManagement/AssetManagement";
import ConfigurationandChangeManagement from "../../CRRResults/ConfigurationandChangeManagement/ConfigurationandChangeManagement";
import ControlsManagement from "../../CRRResults/ControlsManagement/ControlsManagement";
import VulnerabilityManagement from "../../CRRResults/VulnerabilityManagement/VulnerabilityManagement";
import IncidentManagement from "../../CRRResults/IncidentManagement/IncidentManagement";
import ServiceContinuityManagement from "../../CRRResults/ServiceContinuityManagement/ServiceContinuityManagement";
import RiskManagement from "../../CRRResults/RiskManagement/RiskManagement";
import ExternalDependenciesManagement from "../../CRRResults/ExternalDependenciesManagement/ExternalDependenciesManagement";
import TrainingandAwareness from "../../CRRResults/TrainingandAwareness/TrainingandAwareness";
import SituationalAwareness from "../../CRRResults/SituationalAwareness/SituationalAwareness";
import { CSET_URL } from "../../../../../config";
import logout from "../../../../Auth/Logout/Logout";
import CrrPerformanceSummary from "./CrrPerformanceSummary";
import Crrmil1PerformanceSummary from "./Crrmil1PerformanceSummary";
import CrrNistCsfSummary from "./CrrNistCsfSummary";
import Crrmil1Performance from "./Crrmil1Performance";
import CrrSummaryResults from "./CrrSummaryResults";
import CrrPerformanceAppendixA from "./CrrPerformanceAppendixA";
import CrrNistCsfCatSummary from "./CrrNistCsfCatSummary";
import CrrNistCsfCatPerformance from "./CrrNistCsfCatPerformance";
import CRRReportReferences from "./CRRReportReferences";
import ExecutiveReportDocGeneration from "./ExecutiveReportDocGeneration";
import { GET_DOCUMENT_BY_ASSESSMENT_ID } from "../../../../../graphql/queries/QuestionDocument";
import MaterialTable from "../../../../../components/UI/Table/MaterialTable";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../../components/UI/Alert/Alert";
import { sentry_error_catch } from "../../../../../common/sentry_error_catch";

export interface ExecutiveProps {
  from?: string;
  props?: any;
  reportBlobData?: any;
  handleChange?: any;
  location?: any;
  partnerLogo?: any;
  clientLogo?: any;
  partnerImageDimension?: any;
  clientImageDimension?: any;
  getReportData?: any;
}

export interface Goal {
  title: string;
  chart: any; // Replace 'any' with the actual type of the 'chart' property
}

export const CRRExecutiveSummary: React.FC<ExecutiveProps> = ({
  from,
  reportBlobData,
  props,
  handleChange,
  partnerLogo,
  clientLogo,
  partnerImageDimension,
  clientImageDimension,
}: ExecutiveProps) => {
  const componentRef = React.useRef<HTMLHeadingElement>(null);
  const location = useLocation();
  const showloader = false;
  const subCategory = "sub-category";
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [param, setParam] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [clientData, setClientData] = useState<any>({});
  const [partnerData, setPartnerData] = useState<any>({});
  const Crrmil1PerformanceSummaryreference = useRef(null);
  const CrrPerformanceSummaryreference = useRef(null);
  const Crrmil1Performancereference = useRef(null);
  const CrrNistCsfSummaryreference = useRef(null);
  const [crrReportData, setCRRReportData] = useState<any>(null);
  const [crrmil1PerformanceData, setCrrmil1PerformanceData] =
    useState<any>(null);
  const [crrNistCsfSummaryReportData, setCrrNistCsfSummaryReportData] =
    useState<any>(null);
  const mimlChart1 = useRef<any>(null);
  const mimlChart2 = useRef<any>(null);
  const mimlCharts = useRef<any>(null);
  const controlsManagementPdfRef = useRef<any>(null);
  const configurationandChangePdfRef = useRef<any>(null);
  const vulnerabilityManagementPdfRef = useRef<any>(null);
  const assetManagementPdfRef = useRef<any>(null);
  const incidentManagementPdfRef = useRef<any>(null);
  const serviceManagementPdfRef = useRef<any>(null);
  const riskManagementPdfRef = useRef<any>(null);
  const externalManagementPdfRef = useRef<any>(null);
  const trainingAndAwarenessRef = useRef<any>(null);
  const situationalAwarenessPdfRef = useRef<any>(null);
  const crrReportTablePdfRef = useRef<any>(null);
  const crrPerformanceAppendixPdfRef = useRef<any>(null);
  const [crrModel, setCRRModel] = useState<any>(null);
  const [assetManageData, setAssetManageData] = useState<any>(null);
  const [configurationManagementData, setConfigurationManagementData] =
    useState<any>(null);
  const [controlManageData, setControlManageData] = useState<any>(null);
  const [externalDependenciesManageData, setExternalDependenciesManageData] =
    useState<any>(null);
  const [incidentManageData, setIncidentManageData] = useState<any>(null);
  const [riskManageData, setRiskManageData] = useState<any>(null);
  const [serviceContinuityManageData, setServiceContinuityManageData] =
    useState<any>(null);
  const [situaltionalAwarenessData, setSitualtionalAwarenessData] =
    useState<any>(null);
  const [trainingAwarenessData, setTrainingAwarenessData] = useState<any>(null);
  const [vulnerabilityManagementData, setVulnerabilityManagementData] =
    useState<any>(null);
  const [getAllDocumentsByAssId] = useLazyQuery(GET_DOCUMENT_BY_ASSESSMENT_ID, {
    fetchPolicy: msgConstants.networkFetchPolicy,
  });
  const [assessmentDocument, setAssessmentDocument] = useState([]);
  const [documentListData, setDocumentListData] = useState<any>([]);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const DocumentListColumn = [
    { title: "Category", key: "category" },
    { title: "Sub-Category", key: subCategory },
    { title: "File Name", key: "File Name" },
  ];

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  useEffect(() => {
    getCRRModelData();
    if (props) {
      setParam(props);
    } else {
      setParam(location?.state[Object.keys(location?.state)[0]]);
    }
  }, []);

  useEffect(() => {
    getDocumentData();
  },[param?.ccAssessmentInfo?.id,props?.ccAssessmentInfo?.id])

  useEffect(() => {
    GetOrganizationDataOfPartner({
      variables: {
        where: {
          contact_id: param?.clientInfo.partnerId,
        },
      },
    });
    GetOrganizationDataOfClient({
      variables: {
        where: {
          id: param?.clientInfo.clientOrgId,
          subtype: "Client",
        },
      },
    });
  }, [param]);

  useEffect(() => {
    GetOrganizationDataOfClient({
      variables: {
        where: {
          id: param?.clientInfo.clientOrgId,
          subtype: "Client",
        },
      },
    });
  }, [param]);

  useEffect(() => {
    if (
      crrmil1PerformanceData !== null &&
      crrNistCsfSummaryReportData !== null &&
      assetManageData !== null &&
      configurationManagementData !== null &&
      controlManageData !== null &&
      externalDependenciesManageData !== null &&
      incidentManageData !== null &&
      riskManageData !== null &&
      serviceContinuityManageData !== null &&
      situaltionalAwarenessData !== null &&
      trainingAwarenessData !== null &&
      vulnerabilityManagementData !== null
    ) {
      setCRRReportData({
        Crrmil1PerformanceData: crrmil1PerformanceData,
        CrrNistCsfSummaryReportData: crrNistCsfSummaryReportData,
        Assetdata: assetManageData,
        ConfigData: configurationManagementData,
        ControlData: controlManageData,
        ExternalData: externalDependenciesManageData,
        IncidentData: incidentManageData,
        RiskData: riskManageData,
        ServiceData: serviceContinuityManageData,
        SituationalData: situaltionalAwarenessData,
        TrainingData: trainingAwarenessData,
        VulnData: vulnerabilityManagementData,
        partner: partnerData,
        client: clientData,
        assessmentDocument:assessmentDocument,
      });
    }
  }, [
    crrmil1PerformanceData,
    assessmentDocument,
    crrNistCsfSummaryReportData,
    assetManageData,
    configurationManagementData,
    controlManageData,
    externalDependenciesManageData,
    incidentManageData,
    riskManageData,
    serviceContinuityManageData,
    situaltionalAwarenessData,
    trainingAwarenessData,
    vulnerabilityManagementData,
    partnerData,
    clientData,
  ]);

  useEffect(() => {
    if (crrReportData !== null) {
      setLoading(false);
    }
  }, [crrReportData]);

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };
  
  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: msgConstants.ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const [GetOrganizationDataOfPartner] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.partnerId === element.contact_id.id) {
          setPartnerData(element);
        }
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err) => {
      handleError(err);
    },
  });
  const [GetOrganizationDataOfClient] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err) => {
      handleError(err);
    },
  });

  useEffect(() => {
    if (from && from === "result") {
      setTimeout(() => {
        generate();
      }, 800);
    }
  }, [crrmil1PerformanceData && crrNistCsfSummaryReportData]);

  const generate = (event?: any) => {
    handleChange(event);
  };

  const CRRMil1PerformanceSummaryObj = (data?: any) => {
    setCrrmil1PerformanceData(data);
  };

  const CrrNistCsfSummaryObj = (data?: any) => {
    setCrrNistCsfSummaryReportData(data);
  };

  const AssetManagementObj = (data?: any) => {
    setAssetManageData(data);
  };
  const ControlsManagementObj = (data?: any) => {
    setControlManageData(data);
  };
  const ConfigurationandChangeObj = (data?: any) => {
    setConfigurationManagementData(data);
  };

  const getCRRModelData = async () => {
    await fetch(`${CSET_URL}api/reportscrr/getCrrModel`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.json())
      .then((data) => {
        setCRRModel(data);
      }).catch((err:any)=>{
        sentry_error_catch(err,setLoading,setFormState)
      })
  };
  const getDocumentData = async () => {
    await getAllDocumentsByAssId({
      variables: {
        assId:
          parseInt(props?.ccAssessmentInfo?.id) ||
          parseInt(param?.ccAssessmentInfo?.id),
        featureType: "Document",
      },
    })
      .then((data: any) => {
        console.log("Documentdata",data)
        createDocumentListingTable(data.data.questionDocuments);
        setAssessmentDocument(data.data.questionDocuments);
      })
      .catch((err: any) => {
        sentry_error_catch(err,setLoading,setFormState)
      });
  };

  const createDocumentListingTable = (documentData: any) => {
    const arr: any = [];
    documentData.forEach((doc: any) => {
      const obj: any = {};
      const docArr: any = [];
      const splitArr = doc.practise ? doc.practise.split("->") : " ";
      obj["category"] = doc.practise ? splitArr[0] : "Global";
      obj[subCategory] =
        doc.question_Text === "Global"
          ? "--"
          : doc.practise
          ? `${splitArr[1]} ${splitArr[2] ? "-> Q." + splitArr[2] : ""}`
          : "";
      doc.document_id.forEach((docInfo: any) => {
        docArr.push(docInfo.name);
      });
      obj["File Name"] = docArr.join(", ");
      arr.push(obj);
    });
    console.log("arr", arr);
    setDocumentListData(arr);
  };

  const handleChildCompError = (error:any)=>{
    sentry_error_catch(error,setLoading,setFormState)
  }
  return (
      <div>
        <CssBaseline />
        <Grid container ref={componentRef}>
          <Grid item md={12}>
            <Typography component="h5" variant="h1">
              {msgConstants.EXECUTIVE_SUMMARY}
            </Typography>
            <Grid item xs={12} sm={6} className={styles.FooterActions}>
              <div className={styles.prevBtn}>
                <div className={styles.borderButton}>
                  <ExecutiveReportDocGeneration
                    from={from}
                    reportData={crrReportData}
                    handleReportGenerate={generate}
                    partnerLogo={partnerLogo}
                    clientLogo={clientLogo}
                    clientImageDimension={clientImageDimension}
                    partnerImageDimension={partnerImageDimension}
                    handleChildCompError={handleChildCompError}
                  />
                </div>
              </div>
            </Grid>
            {formState.isFailed ? (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {formState.errMessage}
              </Alert>
            ) : null}
            <Paper className={styles.paper}>
              {loading ? <SimpleBackdrop /> : null}
              <Grid container>
                <Grid>
                  <h4>Notification</h4>
                  <p>
                    This report is provided "as-is" for informational purposes
                    only. The Department of Homeland Security (DHS) does not
                    provide any warranties of any kind regarding any information
                    contained within. In no event shall the United States
                    Government or its contractors or subcontractors be liable
                    for any damages, including but not limited to, direct,
                    indirect, special or consequential damages and including
                    damages based on any negligence of the United States
                    Government or its contractors or subcontractors, arising out
                    of, resulting from, or in any way connected with this
                    report, whether or not based upon warranty, contract, tort,
                    or otherwise, whether or not injury was sustained from, or
                    arose out of the results of, or reliance upon the report
                  </p>
                  <p>
                    The DHS does not endorse any commercial product or service,
                    including the subject of the analysis in this report. Any
                    reference to specific commercial products, processes, or
                    services by service mark, trademark, manufacturer, or
                    otherwise, does not constitute or imply their endorsement,
                    recommendation, or favoring by DHS.
                  </p>
                  <p>
                    The display of the DHS official seal or other DHS visual
                    identities on this report shall not be interpreted to
                    provide the recipient organization authorization to use the
                    official seal, insignia or other visual identities of the
                    Department of Homeland Security. The DHS seal, insignia, or
                    other visual identities shall not be used in any manner to
                    imply endorsement of any commercial product or activity by
                    DHS or the United States Government. Use of the DHS seal
                    without proper authorization violates federal law (e.g., 18
                    U.S.C. Â§Â§ 506, 701, 1017), and is against DHS policies
                    governing usage of its seal.
                  </p>
                  <Grid
                    item
                    xs={6}
                    md={12}
                    sm={8}
                    className={styles.FooterActions}>
                    <hr className={styles.ruler} />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={12}
                  sm={8}
                  className={styles.FooterActions}>
                  <Typography variant="h2">
                    <b>Cyber Resilience Review Report for</b>
                  </Typography>
                  <Typography>{clientData?.contact_id?.name}</Typography>
                  <br />
                </Grid>
                <Grid>
                  <Typography variant="h3">{"Introduction"}</Typography>
                  <Typography>
                    {`On ${new Date()}, Webaccess conducted a Cyber Resilience Review (CRR) Self-Assessment. 
                      Answers to questions about cybersecurity practices were gathered from key stakeholders 
                      within Webaccess and scored.`}
                  </Typography>
                  <br></br>
                </Grid>
                <Grid>
                  <Typography variant="h3">
                    {"Overview and Scope of the CRR"}
                  </Typography>
                  <Typography>
                    {`The CRR consists of a one-day, structured facilitation and interview of key personnel. The 
                      primary goal of the CRR is to develop an understanding and qualitative measurement of 
                      essential cybersecurity capabilities. Personnel are asked to describe how these capabilities
                      are institutionalized and managed, and how these capabilities are applied to support the 
                      organization during times of stress. The assessment questions asked participants to 
                      articulate evidence regarding both performances of cybersecurity practices as well as 
                      sustainment of those practices over time. Individual organizations are examined for specific
                      capacities and capabilities in defining, managing, and measuring cybersecurity practices 
                      and behaviors, as described in categories. The categories examined are:`}
                  </Typography>
                </Grid>
                <Grid>
                  <ol>
                    <li>
                      <Typography>Asset Management</Typography>
                    </li>
                    <li>
                      <Typography>Controls Management</Typography>
                    </li>
                    <li>
                      <Typography>
                        Configuration and Change Management
                      </Typography>
                    </li>
                    <li>
                      <Typography>Vulnerability Management</Typography>
                    </li>
                    <li>
                      <Typography>Incident Management</Typography>
                    </li>
                    <li>
                      <Typography>Service Continuity Management</Typography>
                    </li>
                    <li>
                      <Typography>Risk Management</Typography>
                    </li>
                    <li>
                      <Typography>External Dependencies Management</Typography>
                    </li>
                    <li>
                      <Typography>Training and Awareness</Typography>
                    </li>
                    <li>
                      <Typography>Situational Awareness</Typography>
                    </li>
                  </ol>

                  <Typography>
                    The categories examined are derived from a larger security
                    and business continuity framework known as the CERT®
                    Resilience Management Model (CERT-RMM), which was developed
                    by the CERT Program at Carnegie Mellon University's Software
                    Engineering Institute.
                  </Typography>
                  <br />
                </Grid>
                <Grid item xs={6} md={12} sm={8} className={styles.gridSection}>
                  <Typography variant="h3">About this Report</Typography>
                  <Typography>
                    The CRR has a service orientation, meaning that one of the
                    foundational principles of its design is the idea that an
                    organization deploys its people, information, technology and
                    facilities to support specific operational missions. During
                    the CRR, this focus on services is how Webaccess improves
                    its understanding of the cybersecurity management of
                    services that support critical infrastructure. This improved
                    understanding helps the organization focus its efforts in
                    improving cybersecurity management
                  </Typography>
                  <br></br>
                  <Typography>
                    This report summarizes the assessment findings and provides
                    your organization with options for consideration in each
                    category. The options for consideration aim to provide
                    general guidelines or activities as to how your organization
                    can improve the organization's cybersecurity posture and
                    preparedness. These options are not meant to fully represent
                    all activities needed for a robust cybersecurity management
                    program, but to provide initial guidance on how to
                    incorporate various cybersecurity practices including CERT®
                    Resilience Management Model (CERT-RMM), National Institute
                    of Standard and Technology (NIST), and other cybersecurity
                    standards.
                  </Typography>
                  <br></br>
                  <Typography>
                    Please note that guidance provided in this report includes
                    National Institute of Standards and Technology (NIST)
                    Special Publications. While the primary audience for these
                    documents is United States Federal Civilian Agencies, NIST
                    encourages the adoption of these guidelines by State, local,
                    and tribal governments, as well as private sector
                    organizations. Guidance from the NIST Cybersecurity
                    Framework (NIST CSF) for Improving Critical Infrastructure
                    Cybersecurity is also included. The Framework, created
                    through collaboration between industry and government,
                    consists of standards, guidelines, and practices to promote
                    the protection of critical infrastructure. Additionally,
                    while the CRR bases its questions and options for
                    consideration on CERT-RMM, the results do not constitute a
                    formal “rating” and should not be interpreted as a formal
                    appraisal of your organization against CERT-RMM. Detailed
                    information about the RMM can be found at
                    <a href="www.cert.org/resilience">
                      {" "}
                      www.cert.org/resilience.
                    </a>
                  </Typography>
                  <br></br>
                  <Typography>
                    An additional benefit of the CRR is that it allows an
                    organization to compare its capabilities to the criteria of
                    the NIST CSF. This comparison is provided in the NIST
                    Cybersecurity Framework Summary and provides the basis for
                    understanding where improvements could be made
                  </Typography>
                  <br></br>
                  <Typography>
                    A reference crosswalk mapping the relationship of the CRR
                    goals and practices to the NIST CSF categories and
                    subcategories is included in the CRR Self-Assessment Kit.
                  </Typography>
                  <br />
                </Grid>
                <Grid item xs={6} md={12} sm={8} className={styles.gridSection}>
                  <Typography variant="h3">
                    Cyber Resilience Review Results
                  </Typography>
                  <Typography>
                    The CRR is an interview-based assessment. It is understood
                    that participants often do not have complete knowledge of an
                    organization's operations. Actual performance may vary from
                    what is indicated in this report. Organizational performance
                    is presented across several dimensions within the report.
                    Scores are provided for individual Practices, Goals, and
                    Domains
                  </Typography>
                  <br />
                  <Typography variant="h3">Basic Rules</Typography>
                  <ol>
                    <li>
                      <Typography>
                        {" "}
                        Practices are either performed (answer =”Yes”),
                        incompletely performed (answer = “Incomplete”), or not
                        performed (answer = “No”)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        A goal is achieved only if all practices are performed
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        A Domain is achieved at MIL-1 if all the Goals in the
                        Domain are achieved
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        A Domain can be achieved at higher levels if the MIL
                        questions for each level (MIL-2 through MIL-5) are
                        answered.
                      </Typography>
                    </li>
                  </ol>
                  <br />
                  <Typography variant="h3">Scoring Rubric</Typography>
                  <Typography>
                    <p>
                      <b>
                        <i>Step 1</i>
                      </b>
                    </p>
                  </Typography>
                  <Typography>
                    Each Practice in a Domain is scored as the following:
                  </Typography>
                  <ul>
                    <li>
                      <Typography>
                        performed when the question is answered with a “Yes”
                        (green)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        not performed when a question is answered with an
                        “Incomplete” (yellow) or “No” (red) or “Not Answered”
                        (grey)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        if “Not Answered” (grey) is shown, the question was left
                        blank and is scored the same as a “No”
                      </Typography>
                    </li>
                  </ul>
                  <Typography>
                    <p>
                      <b>
                        <i>Step 2</i>
                      </b>
                    </p>
                  </Typography>
                  <Typography>
                    Each Goal within the Domain is then scored as the following:
                  </Typography>
                  <ul>
                    <li>
                      <Typography>
                        achieved when all practices are performed (green)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        partially achieved when some practices are performed
                        (yellow)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        not achieved when no practices are performed (red)
                      </Typography>
                    </li>
                  </ul>
                  <Typography>
                    <p>
                      <b>
                        <i>Step 3</i>
                      </b>
                    </p>
                  </Typography>
                  <Typography>
                    Each Domain is assigned a MIL level based on the following
                  </Typography>
                  <ul>
                    <li>
                      <Typography>
                        MIL-0 if only some of the goals are achieved
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        MIL-1 if all of the goals are achieved
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        MIL-2 if MIL-1 is achieved and all of the MIL-2
                        questions are answered YES
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        MIL-3 if MIL-2 is achieved and all of the MIL-3
                        questions are answered YES
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        MIL-4 if MIL-3 is achieved and all of the MIL-4
                        questions are answered YES
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        MIL-5 if MIL-4 is achieved and all of the MIL-5
                        questions are answered YES
                      </Typography>
                    </li>
                  </ul>
                  <Typography variant="h3">
                    Maturity Indicator Levels
                  </Typography>
                  <Typography>
                    Maturity Indicator Levels (MIL) are assigned by Domain and
                    represent a consolidated view of performance. CERT-RMM MILs
                    describe attributes that would be indicative of mature
                    capabilities as represented in the model's capability
                    levels. However, they do not fully represent capability
                    levels as defined because a capability level can only be
                    assigned through a formal appraisal process, not as the
                    result of using an assessment-based instrument.
                  </Typography>
                  <br />
                  <Typography>
                    <p>
                      <i>
                        <b>MIL0 Incomplete</b>
                      </i>
                    </p>
                  </Typography>
                  <Typography>
                    Indicates that Practices in the Domain are not being
                    performed as measured by responses to the relevant CRR
                    questions. If MIL0 is assigned, no further assessment of
                    maturity indicator is performed.
                  </Typography>
                  <br />
                  <Typography>
                    <p>
                      <i>
                        <b>MIL1 Performed</b>
                      </i>
                    </p>
                  </Typography>
                  <Typography>
                    Indicates that all Practices in a Domain are being performed
                    as measured by responses to the relevant CRR questions. MIL1
                    means that there is sufficient and substantial support for
                    the existence of the practices
                  </Typography>
                  <br />
                  <Typography>
                    <p>
                      <i>
                        <b>MIL2 Planned</b>
                      </i>
                    </p>
                  </Typography>
                  <Typography>
                    Indicates that all Practices in Domain are not only
                    performed, but are supported by sufficient planning,
                    stakeholders, and relevant standards and guidelines. A
                    planned process/practice is
                  </Typography>
                  <ul>
                    <li>
                      <Typography>
                        established by the organization (Is the practice
                        documented and communicable to all who need to know?)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        planned (Is the practice performed according to a
                        documented plan?)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        supported by stakeholders (Are the stakeholders of the
                        practice known and are they aware of the practice and
                        their role in the practice?)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        supported by relevant standards and guidelines (Have the
                        standards and guidelines that support the practice been
                        identified and implemented?)
                      </Typography>
                    </li>
                  </ul>
                  <Typography>
                    <p>
                      <i>
                        <b>MIL3 Managed</b>
                      </i>
                    </p>
                  </Typography>
                  <Typography>
                    Indicates that all Practices in a Domain are performed,
                    planned, and have the basic infrastructure in place to
                    support the process. A managed process/practice
                  </Typography>
                  <ul>
                    <li>
                      <Typography>
                        is governed by the organization (Is the practice
                        supported by policy and is there appropriate oversight
                        over the performance of the practice?)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        is appropriately staffed and funded (Are the staff and
                        funds necessary to perform the practice as intended
                        available?)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        is assigned to staff who are responsible and accountable
                        for the performance of the practice (Have staff been
                        assigned to perform the practice and are they
                        responsible and accountable for the performance of the
                        practice?)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        is performed by staff who are adequately trained to
                        perform the practice (Are the staff who perform the
                        practice adequately skilled and trained to perform the
                        practice?)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        produces work products that are expected from
                        performance of the practice and are placed under
                        appropriate levels of configuration control (Does the
                        practice produce artifacts and work products that are
                        expected from performing the practice, and if so, are
                        the configurations of these artifacts/work products
                        managed?)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        is managed for risk (Are risks related to the
                        performance of the practice identified, analyzed,
                        disposed of, monitored, and controlled?)
                      </Typography>
                    </li>
                  </ul>
                  <Typography>
                    <p>
                      <i>
                        <b>MIL4 Measured</b>
                      </i>
                    </p>
                  </Typography>
                  <Typography>
                    Indicates that all Practices in a Domain are performed,
                    planned, managed, monitored, and controlled. A measured
                    process/practice is
                  </Typography>
                  <ul>
                    <li>
                      <Typography>
                        periodically evaluated for effectiveness (Is the
                        practice periodically reviewed to ensure that it is
                        effective and producing intended results?)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        monitored and controlled (Are appropriate implementation
                        and performance measures identified, applied, and
                        analyzed?)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        objectively evaluated against its practice description
                        and plan (Is the practice periodically evaluated to
                        ensure that it adheres to the practice description and
                        the plan for the practice?)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        periodically reviewed with higher-level management (Is
                        higher-level management aware of any issues related to
                        the performance of the practice?)
                      </Typography>
                    </li>
                  </ul>
                  <Typography>
                    <p>
                      <i>
                        <b>MIL5 Defined</b>
                      </i>
                    </p>
                  </Typography>
                  <Typography>
                    Indicates that all Practices in a Domain are performed,
                    planned, managed, monitored, controlled, and consistent
                    across all internal[1] constituencies who have a vested
                    interest in the performance of the practice. A defined
                    process/practice ensures that the organization reaps the
                    benefits of consistent performance of the practice across
                    organizational units and that all organizational units can
                    benefit from improvements realized in any organizational
                    unit. At MIL5, a process/practice
                  </Typography>
                  <ul>
                    <li>
                      <Typography>
                        is defined by the organization and tailored by
                        organizational units for their use (Is there an
                        organization-sponsored definition of the practice from
                        which organizational units can derive practices that fit
                        their unique operating circumstances?)
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        is supported by improvement information that is
                        collected by and shared among organizational units for
                        the overall benefit of the organization (Are practice
                        improvements documented and shared across internal
                        constituencies so that the organization as a whole reaps
                        benefits from these improvements?)
                      </Typography>
                    </li>
                  </ul>
                  <p>
                    [1] In this case, “internal” refers to constituencies over
                    which the organization has direct managerial control.
                  </p>
                </Grid>

                <Grid item xs={12} sm={12} className={styles.FooterActions}>
                  <div
                    className={styles.pageMarginTop}
                    style={{ marginTop: "40px", paddingTop: "40px" }}>
                    <Crrmil1PerformanceSummary
                      props={showloader}
                      Crrmil1PerformanceSummaryref={
                        Crrmil1PerformanceSummaryreference
                      }
                      CRRModelAPIData={crrModel}
                      CRRMil1PerformanceSummaryData={
                        CRRMil1PerformanceSummaryObj
                      }
                      handleChildCompError={handleChildCompError}
                    />
                  </div>
                  <div
                    className={styles.pageMarginTop}
                    style={{ marginTop: "40px", paddingTop: "40px" }}>
                    <CrrPerformanceSummary
                      props={showloader}
                      CRRModelAPIData={crrModel}
                      CrrPerformanceSummaryref={CrrPerformanceSummaryreference}
                      handleChildCompError={handleChildCompError}
                    />
                  </div>
                  <CrrNistCsfSummary
                    props={showloader}
                    CrrNistCsfSummaryref={CrrNistCsfSummaryreference}
                    CrrNistCsfSummaryData={CrrNistCsfSummaryObj}
                    handleChildCompError={handleChildCompError}
                  />
                  <Crrmil1Performance
                    props={showloader}
                    CRRModelAPIData={crrModel}
                    Crrmil1Performanceref={Crrmil1Performancereference}
                    ref1={mimlChart1}
                    ref2={mimlChart2}
                    ref4={mimlCharts}
                    handleChildCompError={handleChildCompError}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  md={12}
                  sm={8}
                  className={styles.FooterActions}>
                  <hr className={styles.ruler} />
                </Grid>
                <Grid>
                  <div
                    className={styles.pageMarginTop}
                    style={{ marginTop: "40px", paddingTop: "40px" }}>
                    <CrrSummaryResults handleChildCompError={handleChildCompError} />
                  </div>
                  <div
                    className={styles.pageMarginTop}
                    style={{ marginTop: "40px", paddingTop: "40px" }}>
                    <AssetManagement
                      props={showloader}
                      assetManagementRef={assetManagementPdfRef}
                      getAssetManagementData={AssetManagementObj}
                      handleChildCompError={handleChildCompError}
                    />
                  </div>
                  <div
                    className={styles.pageMarginTop}
                    style={{ marginTop: "40px", paddingTop: "40px" }}>
                    <ControlsManagement
                      props={showloader}
                      controlsManagementRef={controlsManagementPdfRef}
                      getcontrolsManagementData={ControlsManagementObj}
                      handleChildCompError={handleChildCompError}
                    />
                  </div>
                  <div
                    className={styles.pageMarginTop}
                    style={{ marginTop: "40px", paddingTop: "40px" }}>
                    <ConfigurationandChangeManagement
                      props={showloader}
                      configurationandChangeRef={configurationandChangePdfRef}
                      getConfigurationandChangeData={ConfigurationandChangeObj}
                      handleChildCompError={handleChildCompError}
                    />
                  </div>
                  <div
                    className={styles.pageMarginTop}
                    style={{ marginTop: "40px", paddingTop: "40px" }}>
                    <VulnerabilityManagement
                      props={showloader}
                      vulnerabilityManagementRef={vulnerabilityManagementPdfRef}
                      getvulnerabilityManagementData={(data: any) =>
                        setVulnerabilityManagementData(data)
                      }
                      handleChildCompError={handleChildCompError}
                    />
                  </div>
                  <div
                    className={styles.pageMarginTop}
                    style={{ marginTop: "40px", paddingTop: "40px" }}>
                    <IncidentManagement
                      props={showloader}
                      incidentManagementRef={incidentManagementPdfRef}
                      getincidentManagementData={(data: any) =>
                        setIncidentManageData(data)
                      }
                      handleChildCompError={handleChildCompError}
                    />
                  </div>
                  <div
                    className={styles.pageMarginTop}
                    style={{ marginTop: "40px", paddingTop: "40px" }}>
                    <ServiceContinuityManagement
                      props={showloader}
                      serviceManagementRef={serviceManagementPdfRef}
                      getserviceManagementData={(data: any) =>
                        setServiceContinuityManageData(data)
                      }
                      handleChildCompError={handleChildCompError}
                    />
                  </div>
                  <div
                    className={styles.pageMarginTop}
                    style={{ marginTop: "40px", paddingTop: "40px" }}>
                    <RiskManagement
                      props={showloader}
                      riskManagementRef={riskManagementPdfRef}
                      getRiskManagementData={(data: any) =>
                        setRiskManageData(data)
                      }
                      handleChildCompError={handleChildCompError}
                    />
                  </div>
                  <div
                    className={styles.pageMarginTop}
                    style={{ marginTop: "40px", paddingTop: "40px" }}>
                    <ExternalDependenciesManagement
                      props={showloader}
                      externalManagementRef={externalManagementPdfRef}
                      getExternalManagementData={(data: any) =>
                        setExternalDependenciesManageData(data)
                      }
                      handleChildCompError={handleChildCompError}
                    />
                  </div>
                  <div
                    className={styles.pageMarginTop}
                    style={{ marginTop: "40px", paddingTop: "40px" }}>
                    <TrainingandAwareness
                      props={showloader}
                      trainingandAwarenessRef={trainingAndAwarenessRef}
                      getTrainingandAwarenessData={(data: any) =>
                        setTrainingAwarenessData(data)
                      }
                      handleChildCompError={handleChildCompError}
                    />
                  </div>
                  <div
                    className={styles.pageMarginTop}
                    style={{ marginTop: "40px", paddingTop: "40px" }}>
                    <SituationalAwareness
                      props={showloader}
                      situationalAwarenessRef={situationalAwarenessPdfRef}
                      getSituationalAwarenessData={(data: any) =>
                        setSitualtionalAwarenessData(data)
                      }
                      handleChildCompError={handleChildCompError}
                    />
                  </div>
                  <br />
                </Grid>
                  <div className={styles.containerFluid}>
                    <div
                      className={`{styles.row} {styles.alignItemsCenter} {styles.textCenter}`}>
                      <div className={styles.col}>
                        <h4 style={{ color: "black" }}>
                          <b>Appendix A:</b>
                          <br />
                          Additional Data Views
                        </h4>
                      </div>
                    </div>
                  </div>
                <CRRReportReferences crrReportTableRef={crrReportTablePdfRef} />
                <CrrPerformanceAppendixA
                  crrPerformanceAppendixRef={crrPerformanceAppendixPdfRef}
                  handleChildCompError={handleChildCompError}
                />
                <CrrNistCsfCatSummary handleChildCompError={handleChildCompError}/>
                <div
                  className={styles.pageMarginTop}
                  style={{ marginTop: "40px", paddingTop: "40px" }}>
                  <CrrNistCsfCatPerformance handleChildCompError={handleChildCompError}/>
                </div>
              </Grid>
            </Paper>
            {documentListData.length > 0 ? (
              <Paper className={styles.paper}>
                <MaterialTable
                  columns={DocumentListColumn}
                  data={documentListData}
                  pageSize={10}
                />
              </Paper>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <ExecutiveReportDocGeneration
            from={from}
            reportData={crrReportData}
            handleReportGenerate={generate}
            partnerLogo={partnerLogo}
            clientLogo={clientLogo}
            clientImageDimension={clientImageDimension}
            partnerImageDimension={partnerImageDimension}
            handleChildCompError={handleChildCompError}
          />
        </Grid>
      </div>
  );
};

export default CRRExecutiveSummary;
