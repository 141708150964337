export const LOGOUT_URL = "/logout";
export const DASHBOARD = "/dashboard";
export const CLIENT = "/client";
export const HELP = "/help";

//------------RA ROUTES---------------//
export const TARGET = "/target";
export const TASK_DETAILS = "/task-details";
export const REPORT_STATUS = "/report-status";
export const RA_REPORT_LISTING = "/report-listing";
export const DEFAULT_BANNER = "/thankyou";
//------------CSET ROUTES---------------//
export const ASSESSMENT_LIST = "/assessment-list";
export const ASSESSMENT_INFO = "/assessment-info";
export const SHARED_ASSESSMENT = "/assessment-info/:token/:assessmentId"
export const SECURITY_ASSURANCE_LEVEL = "/security-assurance-level";
export const STANDARDS = "/standards-selection";
export const REPORTS = "/reports";
export const EXECUTIVE_SUMMARY = "/executive-summary";
export const SITE_SUMMARY_REPORT = "/site-summary-report";
export const SITE_CYBERSECURITY_PLAN = "/site-cybersecurity-plan";
export const SITE_DETAIL_REPORT = "/site-detail-report";
export const OBSERVATIONS_TEAR_OUT_SHEETS = "/observations-tearout-sheets";
export const QUESTIONS = "/question";
export const RESULTS = "/results";
export const STANDARDSUMMARY = "/standard-summary";
export const FIRM_TYPE = "/firm-type";

//-----------------CMMC-2 ROUTES------------------//
export const CMMC2_QUESTIONS = "/cmmc2-questions";
export const CRR_QUESTIONS = "/crr-questions";
export const CMMC2_RESULTS = "/cmmc2-results";
export const CMMC2_REPORTS = "/cmmc2-reports";
export const CMMC2_EXECUTIVE_SUMMARY = "/cmmc2-executive-summary";
export const CMMC2_DEFICIENCY_REPORT = "/cmmc2-deficiency-report";
export const CMMC2_COMMENT_AND_REVIEW_REPORT = "/cmmc2-comment-review-report";
export const CMMC2_LEVEL = "/cmmc2-level";
export const ASSESSMENT_TYPE = "/assessment-type";

//-----------------RRA ROUTES------------------//
export const RRA_QUESTIONS = "/rra-questions";
export const RRA_RESULTS = "/rra-results";
export const RRA_REPORTS = "/rra-reports";
export const RRA_REPORT = "/rra-report";
export const RRA_DEFICIENCY_REPORT = "/rra-deficiency-report";
export const RRA_COMMENT_AND_REVIEW_REPORT = "/rra-comment-review-report";

export const CRR_REPORTS = "/crr-reports";
export const CRR_RESULTS = "/crr-results";
export const CRR_DEFICIENCY_REPORT = "/crr-deficiency-report";
export const CRR_COMMENT_AND_REVIEW_REPORT = "/crr-comment-review-report";
export const CRR_EXECUTIVE_SUMMARY = "/crr-executive-summary";
export const ASSESSMENT_DEMOGRAPHICS = "/assessment-demographics";
export const CRR_TUTORIAL = "/crr-tutorial";
export const CMMC_TUTORIAL = "/cmmc-tutorial";
export const RRA_TUTORIAL = "/rra-tutorial";
export const STANDARD_TUTORIAL = "/standard-tutorial";

//-----------------Admin ROUTES------------------//
export const ADMIN_PARTNER = "/admin/delete-partner-list";
export const ADMIN_USER = "/admin/delete-user-list";
export const ADMIN_CLIENT = "/admin/delete-client-list";
export const ADMIN_ASSESSMENT = '/admin/assessment-list';
export const DELETE_ASSESSMENT = '/admin/delete-assessment-list';
export const ADMIN_DASHBOARD = '/admin/dashboard'
