import React, { useState, useEffect } from "react";
import { CSET_URL } from "../../../../../config";
import logout from "../../../../Auth/Logout/Logout";
import DOMPurify from "dompurify";
import styles from "./CRRExecutiveSummary.module.css";
import { Grid } from "@mui/material";

export interface crrmil1PerformanceSummaryProps {
  data?: any;
  props?: any;
  Crrmil1PerformanceSummaryref?: any;
  crrmilperformanceRef?: any;
  CRRMil1PerformanceSummaryData?: any;
  CRRModelAPIData?: any;
  handleChildCompError?: (err: any) => void;
}

export const Crrmil1PerformanceSummary: React.FC<
  crrmil1PerformanceSummaryProps
> = ({
  Crrmil1PerformanceSummaryref,
  props,
  CRRMil1PerformanceSummaryData,
  CRRModelAPIData,
  handleChildCompError = () =>{}
}: crrmil1PerformanceSummaryProps) => {
  const [performanceMil, setPerformanceMil] = useState<any>(null);
  const [mil1FullAnswerDistribChart, setMil1FullAnswerDistribChart] =
    useState<any>(null);
  const [stackedBarCharts, setStackedBarCharts] = useState<any>(null);
  const [legend, setLegend] = useState<any>(null);
  const [crrModel, setCRRModel] = useState<any>(null);
  const authToken = sessionStorage.getItem("ra_token") || logout();

  useEffect(() => {
    const controller = new AbortController();
    getmil1FullAnswerDistrib();
    getmil1PerformanceLegend();
    getCrrMil1PerformanceSummaryBodyCharts();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (CRRModelAPIData) {
      setCRRModel(CRRModelAPIData.structure.Model);
    }
  }, [CRRModelAPIData]);

  useEffect(() => {
    if (
      legend !== null &&
      crrModel !== null &&
      mil1FullAnswerDistribChart !== null &&
      performanceMil !== null &&
      stackedBarCharts !== null
    )
      CRRMil1PerformanceSummaryData({
        legend,
        crrModel,
        mil1FullAnswerDistribChart,
        performanceMil,
        stackedBarCharts,
      });
  }, [
    legend,
    crrModel,
    mil1FullAnswerDistribChart,
    performanceMil,
    stackedBarCharts,
  ]);

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const getCrrMil1PerformanceSummaryBodyCharts = async () => {
    await fetch(
      `${CSET_URL}api/reportscrr/getCrrMil1PerformanceSummaryBodyCharts`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response: any) => response.json())
      .then((CrrMil1PerformanceSummaryBodyCharts: any) => {
        setPerformanceMil(CrrMil1PerformanceSummaryBodyCharts.scoreBarCharts);
        setStackedBarCharts(
          CrrMil1PerformanceSummaryBodyCharts.stackedBarCharts
        );
      })
      .catch((error: any) => {
        console.log("CrrMil1PerformanceSummaryBodyCharts Error:- ", error);
        handleChildCompError(error)
      });
  };

  const getmil1FullAnswerDistrib = async () => {
    await fetch(`${CSET_URL}api/reportscrr/widget/mil1FullAnswerDistrib`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response: any) => response.text())
      .then((mil1FullAnswerDistrib: any) => {
        setMil1FullAnswerDistribChart(mil1FullAnswerDistrib);
      })
      .catch((error: any) => {
        console.log("mil1FullAnswerDistrib Error:- ", error);
        handleChildCompError(error)
      });
  };

  const getmil1PerformanceLegend = async () => {
    await fetch(
      `${CSET_URL}api/reportscrr/widget/mil1PerformanceSummaryLegend`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response: any) => response.text())
      .then((mil1PerformanceSummaryLegend: any) => {
        setLegend(mil1PerformanceSummaryLegend);
      })
      .catch((error: any) => {
        console.log("mil1PerformanceSummaryLegend Error:- ", error);
        handleChildCompError(error)
      });
  };

  // This function splits strings like
  // "Goal 6 - Post-incident lessons learned are translated into improvement strategies."
  // and
  // "Goal 3-Risks are identified."
  const stringSplitter = (str: string) => {
    return str.split(" - ")[1] ?? str.split("-")[1];
  };

  const getStackedBarChart = (goalTitle: string) => {
    return stackedBarCharts.find((c: any) => c.title === goalTitle).chart;
  };

  const filterMilDomainGoals = (goals: any) => {
    return goals.filter((g: any) => !g.title.startsWith("MIL"));
  };

  /**
   * Convert String to html Response
   * @param html
   */
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  return (
      <Grid container>
        <Grid item xs={12} className={styles.FooterActions}>
          <div
            className={styles.securityPlanTable}
            ref={Crrmil1PerformanceSummaryref}
          >
            <div className={styles.containerFluid}>
              <div className={styles.row}>
                <div
                  className={styles.col1}
                  style={{ width: "15%", flexDirection: "column" }}
                >
                  <div
                    className={styles.row}
                    style={{
                      padding: 0,
                      height: "auto",
                      minWidth: "130px",
                    }}
                  >
                    <div
                      className={styles.col3}
                      style={{
                        width: "36.67px",
                        padding: "0.1rem",
                        marginRight: "0.3rem",
                        fontSize: "6px",
                      }}
                    >
                      Total number of practices performed
                    </div>
                    <div
                      className={styles.col3}
                      style={{
                        width: "36.67px",
                        padding: "0.1rem",
                        marginRight: "0.3rem",
                        fontSize: "6px",
                      }}
                    >
                      Total number of practices incompletely performed
                    </div>
                    <div
                      className={styles.col3}
                      style={{
                        width: "36.67px",
                        padding: "0.1rem",
                        marginRight: "0.3rem",
                        fontSize: "6px",
                      }}
                    >
                      Total number of practices not performed
                    </div>
                  </div>
                  <div className={styles.row} style={{ height: "50px" }}>
                    <div
                      className={styles.col1}
                      style={{ position: "relative", padding: 0 }}
                    >
                      <div
                        dangerouslySetInnerHTML={createDangerousHTML(
                          mil1FullAnswerDistribChart
                        )}
                      ></div>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col} style={{ padding: 0 }}>
                      <h5 className={styles.appendixLabel}>
                        CRR MIL-1 Summary
                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  className={styles.col1}
                  style={{ width: "85%", padding: 0, position: "relative" }}
                >
                  <div
                    className={styles.col6}
                    style={{ padding: 0, position: "absolute", bottom: 0 }}
                  >
                    <h3
                      className={styles.depictionTitle}
                      style={{ paddingBottom: "1rem" }}
                    >
                      CRR MIL-1 Performance
                    </h3>
                  </div>
                  <div
                    className={`${styles.col6} ${styles.tinytext}`}
                    style={{
                      padding: 0,
                      position: "absolute",
                      bottom: 0,
                      left: "47%",
                    }}
                    dangerouslySetInnerHTML={createDangerousHTML(legend)}
                  ></div>
                </div>
              </div>
              <div
                className={styles.row}
                style={{ borderTop: "solid 1.5px black" }}
              >
                <div style={{ width: "15%", padding: 0, fontSize: "10px" }}>
                  DOMAIN SUMMARY
                </div>
                <div style={{ width: "85%", padding: 0, fontSize: "10px" }}>
                  MIL-1 PRACTICE LEVEL PERFORMANCE SUMMARY
                </div>
              </div>
              <div id="domain1">
                <div id="domain2">
                  {crrModel?.Domain.map((domain: any, i: any) => {
                    return (
                      <div
                        className={styles.row}
                        style={{ borderTop: "1px solid black" }}
                      >
                        <div
                          className={styles.col1}
                          style={{
                            width: "15%",
                            padding: "0 1.5rem 0 1rem",
                          }}
                        >
                          <div className={styles.row}>
                            <div
                              style={{
                                fontSize: "0.7rem",
                                fontWeight: "bold",
                              }}
                            >
                              <div
                                style={{
                                  width: "85%",
                                  padding: 0,
                                  fontSize: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "0.7rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {domain.title}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            dangerouslySetInnerHTML={createDangerousHTML(
                              performanceMil[i]
                            )}
                          ></div>
                        </div>
                        <div
                          className={styles.col1}
                          style={{ width: "85%", padding: "0 0 0 0.75rem" }}
                        >
                          {filterMilDomainGoals(domain.Goal).map(
                            (goal: any, j: any) => {
                              return (
                                <div
                                  className={styles.goalrow}
                                  style={{
                                    marginRight: "0.05rem",
                                    borderBottom: "0.5px solid black",
                                  }}
                                >
                                  <div
                                    className={styles.col1}
                                    style={{
                                      fontSize: "8px",
                                      width: "36px",
                                      padding: 0,
                                    }}
                                  >
                                    <div className={styles.goalrowText}>
                                      <b>Goal {j + 1} -</b>
                                    </div>
                                  </div>
                                  <div
                                    className={styles.col5}
                                    style={{
                                      fontSize: "8px",
                                      padding: 0,
                                    }}
                                  >
                                    <div className={styles.goalrowText}>
                                      {stringSplitter(goal.title)}
                                    </div>
                                  </div>
                                  <div
                                    className={styles.col6}
                                    style={{ padding: "0.1rem" }}
                                  >
                                    <div
                                      className={styles.goalrowText}
                                      dangerouslySetInnerHTML={createDangerousHTML(
                                        getStackedBarChart(goal.title)
                                      )}
                                    ></div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
  );
};

export default Crrmil1PerformanceSummary;
