import { gql } from "@apollo/client";

export const UPLOAD_FILE = gql`
  mutation uploadFile(
    $refId: ID
    $ref: String
    $field: String
    $source: String
    $file: Upload!
  ) {
    upload(
      refId: $refId
      ref: $ref
      field: $field
      source: $source
      file: $file
    ) {
      id
      name
    }
  }
`;

export const uploadFiles = gql`
mutation uploadFiles($files: Upload!) {
  upload(file: $files) {
    id
    name
    hash
    url
  }
}
`;

export const deleteFileById = gql`
mutation deleteFile($id:ID!){
  deleteFile(input:{where:{id:$id}}){
    file{
      id
    }
  }
}
`;
export const getFileByName = gql`
query getFileByName($name:String!){
  files(where: {name: $name}) {
    id
    url
  }
}
`