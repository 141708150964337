import { gql } from "@apollo/client";

export const CREATE_CLIENT_SUBSCRIPTION = gql`
  mutation createClientSubscription(
    $contact_id: ID!,
    $CCSubscription: Boolean!,
    $RAsubscription: Boolean!
  ) {
    createClientSubscription(
      input: {
        data: {
          contact_id: $contact_id
          cc_subscription: $CCSubscription
          ra_subscription: $RAsubscription
        }
      }
    ) {
      clientSubscription {
        id
      }
    }
  }
`;

export const UPDATE_CLIENT_SUBSCRIPTIONS = gql`
  mutation updateClientSubscription (
    $id :ID!,
    $contact_id: ID!,
    $CCSubscription: Boolean!,
    $RAsubscription: Boolean!
  ) {
    updateClientSubscription (
      input: {
        where: { id: $id }
        data: {
          contact_id: $contact_id
          cc_subscription: $CCSubscription
          ra_subscription: $RAsubscription
        }
      }
    ) {
      clientSubscription {
        id
      }
    }
  }
`;

export const DELETE_CLIENT_SUBSCRIPTIONS = gql`
mutation deleteClientSubscription (
  $id: ID!
  ) {
  deleteClientSubscription(input: {where: {  id: $id }}) {
    clientSubscription {
      contact_id {
        id
        contact_type
      }
      cc_subscription
    }
  }
}
`;

export const CREATE_GUEST_CLIENT = gql`
  mutation createGuestClient($input: JSON){
    createGuestClient(input:$input){
      message
      guest_client_data{
        contact_id
        name
        last_name 
        email
        phone
        pg_partner_id
        cc_subscription
        ra_subscription
        guest_client
        organization_id
        partner_id
        active_status
      }
    }
  }
`