import React, { useState, useEffect } from "react";
import { CSET_URL } from "../../../../../config";
import logout from "../../../../Auth/Logout/Logout";
import DOMPurify from "dompurify";
import styles from "./CRRExecutiveSummary.module.css";

export interface CrrPerformanceAppendixAProps {
  crrPerformanceAppendixRef?: any;
  CRRModelAPIData?: any;
  handleChildCompError?: (err: any) => void;
}
export const CrrPerformanceAppendixA = ({
  crrPerformanceAppendixRef,
  CRRModelAPIData,
  handleChildCompError = () => {}
}: CrrPerformanceAppendixAProps) => {
  const [mil1FullAnswerDistribChart, setMil1FullAnswerDistribChart] =
    useState<any>(null);
  const [legend, setLegend] = useState<any>(null);
  const [crrModel, setCRRModel] = useState<any>();
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [bodyData, setBodyData] = useState<any>();

  useEffect(() => {
    getMil1FullAnswerDistribWidget();
    getCrrPerformanceAppendixABodyData();
    getMil1PerformanceLegendWidget();
  }, []);

  useEffect(() => {
    if (CRRModelAPIData) {
      console.log("CRRModelAPIData", CRRModelAPIData);
      setCRRModel(CRRModelAPIData.structure.Model);
    }
  }, []);

  /**
   * Convert String to html Response
   * @param html
   */
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const getMil1FullAnswerDistribWidget = async () => {
    try{
      const response = await fetch(
      `${CSET_URL}api/reportscrr/widget/mil1FullAnswerDistrib`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data = await response.text();
    if (response.status === 200) {
      setMil1FullAnswerDistribChart(data);
    }else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  }catch(err){
    handleChildCompError(err)
  }
  };

  const getCrrPerformanceAppendixABodyData = async () => {
    try{
      const response = await fetch(
      `${CSET_URL}api/reportscrr/getCrrPerformanceAppendixABodyData`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data = await response.json();
    if (response.status === 200) {
      setBodyData(data);
    }else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  }catch(err){
      handleChildCompError(err)
    }
  };

  const getMil1PerformanceLegendWidget = async () => {
    try{
      const response = await fetch(
      `${CSET_URL}api/reportscrr/widget/mil1PerformanceLegend`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data = await response.text();
    if (response.status === 200) {
      setLegend(data);
    }else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  }catch(err){
    handleChildCompError(err)
  }
  };

  return (
    <div
      className={styles.pageMarginTop}
      ref={crrPerformanceAppendixRef}
      style={{ marginTop: "40px", paddingTop: "40px", width: "100%" }}
    >
      <div className={styles.containerFluid}>
        <div className={styles.row}>
          <div className={styles.col1} style={{ width: "15%" }}>
            <div
              className={styles.row}
              style={{ padding: 0, height: "auto", minWidth: "130px" }}
            >
              <div
                className={styles.col3}
                style={{
                  width: "36.67px",
                  padding: "0.1rem",
                  marginRight: "0.3rem",
                  fontSize: "6px",
                }}
              >
                Total number of practices performed
              </div>
              <div
                className={styles.col3}
                style={{
                  width: "36.67px",
                  padding: "0.1rem",
                  marginRight: "0.3rem",
                  fontSize: "6px",
                }}
              >
                Total number of practices incompletely performed
              </div>
              <div
                className={styles.col3}
                style={{
                  width: "36.67px",
                  padding: "0.1rem",
                  marginRight: "0.3rem",
                  fontSize: "6px",
                }}
              >
                Total number of practices not performed
              </div>
            </div>
            <div
              className={styles.row}
              style={{ position: "absolute", bottom: 0 }}
            >
              <div className={styles.col} style={{ padding: 0 }}>
                <div
                  className={styles.row}
                  style={{ padding: "0 0 0 1rem" }}
                  dangerouslySetInnerHTML={createDangerousHTML(
                    mil1FullAnswerDistribChart
                  )}
                ></div>
                <div className={styles.col} style={{ padding: 0 }}>
                  <h5 className={styles.appendixlabel}>CRR Summary</h5>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.col1} style={{ width: "85%" }}>
            <div className={styles.row}>
              <div style={{ padding: 0, width: "60%" }}>
                <h3 className={styles.depictionTitle}>CRR Performance</h3>
              </div>
              <div
                style={{ padding: 0, width: "40%" }}
                dangerouslySetInnerHTML={createDangerousHTML(legend)}
              ></div>
            </div>
            <div
              className={`${styles.row} ${styles.header}`}
              style={{ borderTop: "1px solid black", padding: 0 }}
            >
              <div style={{ width: "60%" }}>
                <b>MIL-1 Performed</b>
                <br />
                Domain practices are being performed.
              </div>
              <div style={{ width: "9%", paddingRight: "1rem" }}>
                <b>MIL-2 Planned</b>
                <br />
                Domain practices are supported by planning, policy,
                stakeholders, and standards.
              </div>
              <div style={{ width: "10%", paddingRight: "1rem" }}>
                <b>MIL-3 Managed</b>
                <br />
                Domain practices are supported by governance and adequate
                resources.
              </div>
              <div style={{ width: "10%", paddingRight: "1rem" }}>
                <b>MIL-4 Measured</b>
                <br />
                Domain practices are supported by measurement, monitoring, and
                executive oversight.
              </div>
              <div style={{ width: "10%" }}>
                <b>MIL-5 Defined</b>
                <br />
                Domain practices are supported by enterprise standardization and
                analysis of. lessons learned.
              </div>
            </div>
          </div>
        </div>
        <div className={styles.row} style={{ borderTop: "solid 1.5px black" }}>
          <div style={{ width: "15%", padding: 0, fontSize: "10px" }}>
            DOMAIN SUMMARY
          </div>
          <div style={{ width: "85%", padding: 0, fontSize: "10px" }}>
            MIL-1 PRACTICE LEVEL PERFORMANCE SUMMARY
          </div>
        </div>
        {crrModel?.Domain?.map((domain: any, i: any) => {
          return (
            <div
              className={styles.row}
              style={{ borderTop: "0.5px solid black" }}
            >
              <div
                className={styles.col1}
                style={{ width: "15%", padding: "0 2rem 0 1rem" }}
              >
                <div className={styles.row}>
                  <div style={{ fontSize: "0.7rem", fontWeight: "bold" }}>
                    {domain.title.split("(")[0].trim()}
                  </div>
                </div>
                <div
                  className={styles.row}
                  dangerouslySetInnerHTML={createDangerousHTML(
                    bodyData[i].scoreBarChart
                  )}
                ></div>
              </div>
              <div
                className="col-10"
                style={{ display: "flex", width: "85%", padding: 0 }}
              >
                {bodyData[i].heatMaps.map((heatMap: any, j: any) => {
                  return (
                    <div
                      style={{
                        padding: "0.1rem",
                        width: `${j === 0 ? "62%" : "10%"}`,
                      }}
                      dangerouslySetInnerHTML={createDangerousHTML(heatMap)}
                    ></div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CrrPerformanceAppendixA;
