import React, { useState, useEffect } from "react";
import styles from "./PerformanceByDomains.module.css";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { CSET_URL } from "../../../../../config/index";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import logout from "../../../../../containers/Auth/Logout/Logout";
import { HorizontalBar } from "react-chartjs-2";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../../components/UI/Alert/Alert";
import { sentry_error_catch } from "../../../../../common/sentry_error_catch";
import { ERROR_MESSAGE } from "../../../../../common/MessageConstants";

export interface PerformanceByDomainProps {
  propsData?: any;
}

export const PerformanceByDomains: React.FC<PerformanceByDomainProps> = ({
  propsData,
}: PerformanceByDomainProps) => {
  const [performanceDomainChart, setPerformanceDomainChart] = useState({});
  const [loading, setLoading] = useState(false);
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [param, setParam] = useState<any>();

  const headerObj = {
    "Content-Type": `application/json`,
    Authorization: authToken,
  };

  useEffect(() => {
    if (propsData !== undefined) {
      setParam(propsData);
    }
    getPerformanceByDomain();
  }, []);


  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };
  
  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const buildbarGraphData = (compliancebydomain: any) => {
    // build the object to populate the chart
    const x: any = {};
    x.labels = [];
    x.datasets = [];
    const ds: any = {
      label: "Compliant Percentages",
      backgroundColor: "#245075",
      data: [],
    };
    x.datasets.push(ds);

    compliancebydomain.forEach((element: any) => {
      x.labels.push(element.domainName);
      ds.data.push(
        sumCompliantPercentages(element.answerDistribution).toFixed(1)
      );
    });
    setPerformanceDomainChart(x);
  };

  /**
   * Returns the sum of the Y and NA percentages
   */
  const sumCompliantPercentages = (Percentages: any) => {
    let total = 0;
    Percentages.forEach((element: any) => {
      if (element.value === "Y" || element.value === "NA") {
        total += element.percent;
      }
    });
    return total;
  };

  const getPerformanceByDomain = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/results/compliancebydomain`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((res: any) => {
          buildbarGraphData(res);
          setLoading(false);
        })
        .catch((err:any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });
    } catch (e) {
      setLoading(false);
      handleError(e);
      console.error("error", e);
    }
  };

  const options = {
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18,
        fontColor: "black",
      },
    },
    title: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            fontStyle: "bold",
            fontSize: 18,
            fontColor: "black",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            fontStyle: "bold",
            fontSize: 18,
            fontColor: "black",
          },
        },
      ],
    },
  };

  if (loading) {
    return <SimpleBackdrop />;
  }

  return (
    <Grid>
      <React.Fragment>
        {formState.isFailed ? (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {formState.errMessage}
          </Alert>
        ) : null}
        <Paper className={styles.paper}>
          <HorizontalBar data={performanceDomainChart} options={options} />
        </Paper>
      </React.Fragment>
    </Grid>
  );
};
export default PerformanceByDomains;
