import { gql } from "@apollo/client";

export const HISTORY = gql`
  mutation ($auditTrailInput: AuditTrailInput) {
    createAuditTrail(input: { data: $auditTrailInput }) {
      auditTrail {
        id
      }
    }
  }
`;
