import styles from "./CMMCTutorial.module.css";
import React, { FC, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import { Button } from "../../../components/UI/Form/Button/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import * as routeConstant from "../../../common/RouteConstants";

const CMMCTutorial: FC<any> = (props: any) => {
  const history = useNavigate();
  const location = useLocation();
  const { showBoundary } = useErrorBoundary();
  const [param, setParam] = useState<any>(
    location?.state[Object.keys(location?.state)[0]]
  );
  useEffect(() => {
    if (location.state) {
      setParam(location?.state[Object.keys(location?.state)[0]]);
    } else {
      history(routeConstant.ASSESSMENT_TYPE);
    }
  }, []);

  const handleNext = () => {
    try{
    history(routeConstant.ASSESSMENT_INFO, { state: { param } });
    }catch(err){
      showBoundary(err)
    }
  };

  const handleBack = () => {
    try{
    history(routeConstant.ASSESSMENT_TYPE, { state: { param } });
    }catch(err){
      showBoundary(err)
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container spacing={2}>
        <Grid item xs={12} className={styles.TutorialHeaders}>
          <Typography
            style={{ fontWeight: "bold" }}
            component="h5"
            variant="h1"
          >
            Draft CMMC 2.0 w/SPRS Scorecard Tutorial
          </Typography>
          <div className={styles.prevBtn}>
            <Button
              id="previous-button"
              variant="contained"
              color="primary"
              onClick={handleBack}
            >
              {"<< Previous"}
            </Button>
          </div>
          <div className={styles.prevBtn}>
            <Button
              id="next-button"
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              {"Next >>"}
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid>
        <Paper className={styles.paper}>
          <div className={styles.header}>1. CMMC 2.0 Tutorial for CSET®</div>
          <div className={styles.subHeader}>Overview</div>
          <p className={styles.content}>
            The CMMC framework consists of the security requirements from NIST
            SP 800-171 Rev 2, Protecting Controlled Unclassified Information in
            Nonfederal Systems and Organizations, and a subset of the
            requirements from NIST SP 800-172, Enhanced Security Requirements
            for Protecting Controlled Unclassified Information: A Supplement to
            NIST Special Publication 800-171. The model framework organizes
            these practices into a set of domains, which map directly to the
            NIST SP 800-171 Rev 2 families.There are three levels within
            CMMC-Level 1, Level 2, and Level 3-as described in the sections
            below.
          </p>
          <div className={styles.subHeader}>CMMC Levels </div>
          <p className={styles.content}>
            The CMMC model measures the implementation of cybersecurity
            requirements at three levels. Each level consists of a set of CMMC
            practices:
          </p>
          <ul>
            <li>
              Level 1: Encompasses the basic safeguarding requirements for FCI
              specified in FAR Clause 52.204-21.
            </li>
            <li>
              Level 2: Encompasses the security requirements for CUI specified
              in NIST SP 800-171 Rev 2 per DFARS Clause 252.204-7012 [3, 4, 5].
            </li>
            <li>
              Level 3: Information on Level 3 will be released at a later date
              and will contain a subset of the security requirements specified
              in NIST SP 800-172 [6].
            </li>
          </ul>
          <p className={styles.content}>
            The CMMC levels and associated sets of practices across domains are
            cumulative. More specifically, for an organization to achieve a
            specific CMMC level, it must also demonstrate achievement of the
            preceding lower levels. For the case in which an organization does
            not meet its targeted level, it will be certified at the highest
            level for which it has achieved all applicable practices.
          </p>
          <div className={styles.subHeader}>CMMC 2.0 Overview</div>
          <p className={styles.content}>
            Below Figure provides an overview of the CMMC 2.0 Levels.
          </p>
          <div className={styles.TextCenter}>
            <img
              className={styles.imageWidth}
              src={`${process.env.PUBLIC_URL}/images/cmmc_2_0_tutorial.png`}
              alt="cmmc_2_0_tutorial"
            />
            <p className={styles.imageWidth}>
              Figure 1: CMMC Levels and Associated Focus Areas
            </p>
          </div>
          <div className={styles.subHeader}>CMMC Level 1</div>
          <p className={styles.content}>
            Practices: Foundational<br></br> Level 1 focuses on the protection
            of FCI and consists of only practices that correspond to the basic
            safeguarding requirements specified in 48 CFR 52.204-21, commonly
            referred to as the FAR Clause [3].
          </p>
          <div className={styles.subHeader}>CMMC Level 2</div>
          <div className={styles.content}>
            Practices: Advanced <br></br>Level 2 focuses on the protection of
            CUI and encompasses the 110 security requirements specified in NIST
            SP 800-171 Rev 2 [4].
          </div>
          <div className={styles.subHeader}>CMMC Level 3</div>
          <div className={styles.content}>
            Practices: Expert<br></br> Level 3 will be based on a subset of NIST
            SP 800-172 requirements [6]. Details will be released at a later
            date.
          </div>
          <div className={styles.subHeader}>CMMC Domains </div>
          <p className={styles.content}>
            The CMMC model consists of 14 domains that align with the families
            specified in NIST SP 800-171. These domains and their abbreviations
            are as follows:
          </p>
          <ul>
            <li>Access Control (AC)</li>
            <li>Awareness & Training (AT)</li>
            <li>Audit & Accountability (AU)</li>
            <li>Configuration Management (CM)</li>
            <li>Identification & Authentication (IA)</li>
            <li>Incident Response (IR)</li>
            <li>Maintenance (MA)</li>
            <li>Media Protection (MP)</li>
            <li> Personnel Security (PS)</li>
            <li>Physical Protection (PE)</li>
            <li> Risk Assessment (RA)</li>
            <li> Security Assessment (CA)</li>
            <li> System and Communications Protection (SC)</li>
            <li>System and Information Integrity (SI)</li>
          </ul>
          <div className={styles.subHeader}>CMMC Practices</div>
          <div className={styles.subHeader}>Overview</div>
          <p className={styles.content}>
            The CMMC model measures the implementation of the NIST SP 800-171
            Rev 2 [4] security requirements. The practices originate from the
            safeguarding requirements and security requirements specified in FAR
            Clause 52.204-21 [3] and DFARS Clause 252.204-7012 [5],
            respectively.
          </p>
          <ul>
            <li>
              Level 1 is equivalent to all of the safeguarding requirements from
              FAR Clause 52.204-21.
            </li>
            <li>
              Level 2 is equivalentto all of the security requirements in NIST
              SP 800-171 Revision 2.
            </li>
            <li>
              Level 3 will be based on a subset of NIST SP 800-172 and more
              detailed information will be released at a later date.
            </li>
          </ul>
          <div className={styles.subHeader}>List of Practices</div>
          <p className={styles.content}>
            This subsection itemizes the practices for each domain and at each
            level. Each practice has a practice identification number in the
            format - DD.L#-REQ- where:
          </p>
          <ul>
            <li>DD is the two-letter domain abbreviation;</li>
            <li>L# is the level number ; and</li>
            <li>
              REQ is the NIST SP 800-171 Rev 2 or NIST SP 800-172 security
              requirement number.
            </li>
          </ul>
          <p className={styles.content}>
            Below the identification number, a short name identifier is provided
            for each practice, meant to be used for quick reference only.
            Finally, each practice has a complete practice statement.
          </p>
          <div className={styles.header}>2. Scoping</div>
          <div className={styles.subHeader}>Level 1 Self-Assessment Scope</div>
          <p className={styles.content}>
            Prior to a Level 1 Cybersecurity Maturity Model Certification (CMMC)
            Self-Assessment, the contractor must specify the CMMC
            Self-Assessment Scope. The CMMC Self-Assessment Scope informs which
            assets within the contractor's environment will be assessed and the
            details of the self-assessment.
          </p>
          <p className={styles.content}>
            FCI Assets<br></br>
            Federal Contract Information (FCI) Assets process, store, or
            transmit FCI as follows:
          </p>
          <ul>
            <li>
              Process - FCI can be used by an asset (e.g., accessed, entered,
              edited, generated, manipulated, or printed).
            </li>
            <li>
              Store - FCI is inactive or at rest on an asset (e.g., located on
              electronic media, in system component memory, or in physical
              format such as paper documents).
            </li>
            <li>
              Transmit - FCI is being transferred from one asset to another
              asset (e.g., data in transit using physical or digital transport
              methods).
            </li>
          </ul>
          <p className={styles.content}>
            {" "}
            FCI Assets are part of the CMMC Self-Assessment Scope and are
            assessed against applicable CMMC practices.
          </p>
          <p className={styles.content}>
            Additional information on CMMC 2.0 Level 1 scope can be found in
            CMMC Self-Assessment Scope Level 1 Version 2.0 December 2021.
          </p>
          <p className={styles.content}>
            Additional Guidance on Level 1 Scoping Activities <br></br>To
            appropriately scope a CMMCLevel 1 self-assessment, the contractor
            should consider the people, technology, facilities, and external
            service providers within their environment that process, store, or
            transmit FCI.
          </p>
          <div className={styles.subHeader}>Level 2 Scope</div>
          <p className={styles.content}>
            <b>Identifying the CMMC Assessment Scope</b> <br></br>For CMMC 2.0
            scope guidance, the CMMC Self-Assessment Scope Level 2 Version 2.0
            December 2021 provides information on the{" "}
            <b>categorization of assets</b>
            that, in turn, inform the specification of assessment scope for a
            Cybersecurity Maturity Model Certification (CMMC) assessment. The
            scoping guide discusses CMMC asset categories as well as the
            associated requirements for Defense Industrial Base (DIB)
            contractors and CMMC assessments.
          </p>
          <p className={styles.content}>
            <b>CMMC Asset Categories</b> <br></br>The CMMC Assessment Guide
            Level 2 Version 2.0 December 2021 maps contractor assets into one of
            five categories. <b>Table 1 in the Assessment Guide</b> describes
            each asset category, contractor requirements, and assessment
            requirements. Additional information about each asset category is
            provided in the ensuing sections.
          </p>
          <p className={styles.content}>
            <b>Defining the CMMC Assessment Scope</b>
            <br></br> After categorizing their assets, the contractor then
            specifies the CMMC Assessment Scope. See the CMMC Self-Assessment
            Scope Level 2 Version 2.0 December 2021 for next steps in the
            scoping process.
          </p>
          <div className={styles.header}>3. CMMC Assessment Steps</div>
          <div>
            <ol>
              <li className={styles.subHeader}>Beginning the assessment:</li>
              <p className={styles.content}>
                The first step to completing the CMMC practice questions is to
                set your desired asset. Fill up the basic details like
                assessment name, city or site name, state/province/region and facility name.
                Select gross value of the asset you are trying to protect.
              </p>
              <p className={styles.content}>
                In Assessment Type section, assessment type and assessment description will be displayed. 
              </p>
              <p className={styles.content}>
                A pop-up with pricing or credit details would be displayed on
                clicking the Next button, which is marked as 1.
              </p>
              <p className={styles.content}>
                On Click of Cancel button which is marked as 2, you will be redirected to the Risk
                Assessment List.
              </p>
              <div className={styles.TextCenter}>
                <img
                  className={styles.imageWidth}
                  src={`${process.env.PUBLIC_URL}/images/cmmc_1.png`}
                  alt="cmmc_1"
                />
                <p className={styles.imageTitle}>
                  Figure 2: Assessment Information Screen
                </p>
              </div>
              <div className={styles.imageTitle}>
                <img
                  width={500}
                  style={{ maxWidth: "100%" }}
                  src={`${process.env.PUBLIC_URL}/images/credit.png`}
                  alt="Credit"
                />
                <p className={styles.imageTitle}>
                  Figure 2.1: Credit/Pricing Information Screen
                </p>
              </div>
              <div className={styles.imageTitle}>
                <img
                  width={500}
                  style={{ maxWidth: "100%" }}
                  src={`${process.env.PUBLIC_URL}/images/pricing.png`}
                  alt="Pricing"
                />
                <p className={styles.imageTitle}>
                  Figure 2.2: Credit/Pricing Information Screen
                </p>
              </div>
              <li className={styles.subHeader}>
                <b>Selecting maturity level:</b>
                <p className={styles.content}>
                  The second step to completing the CMMC practice questions is
                  to set your desired target level. If you are unsure of your
                  target level, see the CMMC Levels and Focus Areas section
                  above, or check your RFI or RFP documents for the required
                  CMMC compliance level for your contract.
                </p>
                <div className={styles.TextCenter}>
                  <img
                    className={styles.imageWidth}
                    src={`${process.env.PUBLIC_URL}/images/cmmc_2.png`}
                    alt="cmmc_2"
                  />
                </div>
                <p className={styles.imageTitle}>
                  Figure 3: Maturity level Screen
                </p>
              </li>
              <li className={styles.subHeader}>
                <div>
                  <b>Answering the Practice Questions:</b>
                </div>
                <br></br>
                <div>CMMC Practice Question Layout</div>
                <br></br>
                <div className={styles.TextCenter}>
                  <img
                    className={styles.imageWidth}
                    src={`${process.env.PUBLIC_URL}/images/cmmc_4.png`}
                    alt="cmmc_4"
                  />
                </div>
                <p className={styles.imageTitle}>Figure 4: Questions Screen</p>
                <br></br>
                <br></br>
                <ol type="I">
                  <li>
                    Domain and Domain Acronym: <br></br>
                    <div>
                      <img
                        className={styles.imageWidth}
                        style={{ maxWidth: "50%" }}
                        src={`${process.env.PUBLIC_URL}/images/domain_acronym.png`}
                        alt="domain_acronym"
                      />
                    </div>
                    {/* <p>
                      The CMMC model consists of 17 domains. The majority of
                      these domains originate from the security-related areas in
                      Federal Information Processing Standards (FIPS)
                      Publication 200 and the related security requirement
                      families from NIST SP 800-171. The CMMC model also
                      includes the three domains of Asset Management (AM),
                      Recovery (RE), and Situational Awareness (SA).
                    </p> */}
                  </li>
                  <li>
                    Progress Indicator: <br></br>
                    <div className={styles.image}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/progress_indicator.png`}
                        alt="progress_indicator"
                      />
                    </div>
                    <p>
                      The Progress Indicator will turn purple and complete the
                      circle as the questions in each Capability area are
                      completed. When all the questions are complete, the
                      Progress Indicator will show a purple circle.
                    </p>
                  </li>
                  <li>
                    Collapse | Expand Button: <br></br>
                    <div className={styles.image}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/expand.png`}
                        alt="expand"
                      />
                    </div>
                    <p>
                      While in the Assessment Section of CMMC, users can click
                      the the Collapse/Expand buttons at the left of the screen
                      to adjust the amount of practice questions content on the
                      page.
                    </p>
                  </li>
                  <li>
                    Practice Identifier: <br></br>
                    <div className={styles.image}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/practice_identifier.png`}
                        alt="practice_identifier"
                      />
                    </div>
                    <p>
                      Each CMMC practice is specified using the convention of
                      [DOMAIN].[LEVEL].[PRACTICE NUMBER]
                    </p>
                  </li>
                  <li>
                    Level Indicator : <br></br>
                    <p>
                      Indicates the corresponding level associated with the
                      practice statement. CMMC provides a benchmark against
                      which an organization can evaluate the current level of
                      capability of its processes, practices, and methods and
                      set goals and priorities for improvement. The CMMC
                      assessment process provides consistent guidance for
                      assessing a contractor against specific CMMC Levels 1-5.
                    </p>
                  </li>
                  <li>
                    Practice Statement: <br></br>
                    <div className={styles.image}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/practice_statement.png`}
                        alt="practice_statement"
                      />
                    </div>
                    <p>
                      The CMMC model consists of 171 practices that are mapped
                      across the five levels for all capabilities and domains.
                      This mapping and the cumulative nature of the model is
                      shown in Figure.
                    </p>
                  </li>
                  <li>
                    Assessment Findings (CSET Answer Key) : <br></br>
                    <div className={styles.image}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/crr_questions_answer.png`}
                        alt="crr_questions_answer"
                      />
                    </div>
                    <p>
                      A full breakdown of the CMMC Assessment Findings (CSET
                      Answer Key) is below.
                    </p>
                  </li>
                </ol>
              </li>
              <li className={styles.subHeader}>
                Answering the Practice Questions:
                <div className={styles.TextCenter}>
                  <img
                    className={styles.imageWidth}
                    src={`${process.env.PUBLIC_URL}/images/crr_questions_answer.png`}
                    alt="crr_questions_answer"
                  />
                </div>
                <ol type="I">
                  <li>
                    Yes :<br></br>
                    <div className={styles.image}>
                      <img
                        src={process.env.PUBLIC_URL + "/images/yes.png"}
                        alt="yes"
                      />
                    </div>
                    <p>
                      MET is presented as Yes in the CSET Assessment Tool. The
                      contractor successfully meets the practice. For each
                      practice marked MET, the assessor includes statements that
                      indicate the response conforms to the objectives and
                      documents the appropriate evidence to support the
                      response. For MET (Yes), the assessor can use the
                      Observations field to record applicable observations.
                    </p>
                  </li>
                  <li>
                    No :<br></br>
                    <div className={styles.image}>
                      <img
                        src={process.env.PUBLIC_URL + "/images/no.png"}
                        alt="no"
                      />
                    </div>
                    <p>
                      NOT MET is presented as NO in the CSET Assessment tool.
                      The contractor has not met the practice. For each practice
                      marked NOT MET, the assessor includes statements that
                      explain why and documents the appropriate evidence that
                      the contractor does not conform to the objectives. For NOT
                      MET(No), the assessor can use the Observations field to
                      record applicable observations.
                    </p>
                  </li>
                  <li>
                    NA :<br></br>
                    <div className={styles.image}>
                      <img
                        src={process.env.PUBLIC_URL + "/images/na.png"}
                        alt="na"
                      />
                    </div>
                    <p>
                      NOT APPLICABLE is presented as NA n the CSET Assessment
                      Tool. NA indicates the practice does not apply for the
                      assessment. Assessor comments can be recorded in the
                      pop-up alternate justification text box beneath the
                      question.
                    </p>
                  </li>
                  <li>
                    Reference :<br></br>
                    <div className={styles.image}>
                      <img
                        src={process.env.PUBLIC_URL + "/images/reference.png"}
                        alt="reference"
                      />
                    </div>
                    <p>
                      Users can click on the References icon to open links to
                      CMMC .pdf documentation and to access CMMC assessment help
                      documents, Errata, and the Glossary/Acronyms document.
                      Documents will open in a separate window.
                    </p>
                  </li>
                  <li>
                    Mark for Review :<br></br>
                    <div className={styles.image}>
                      <img
                        src={process.env.PUBLIC_URL + "/images/reviewFlag.png"}
                        alt="marked for review"
                      />
                    </div>
                    <p>
                      The Mark for Review function allows a user to mark a
                      practice question for further review.
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
            <div className={styles.header}>
              3.1 CMMC 2.0 Assessment Components in RA360®{" "}
            </div>
            <div className={styles.image}>
              <img
                style={{ maxWidth: "50%" }}
                src={`${process.env.PUBLIC_URL}/images/assessment_components.png`}
                alt="assessment_Components"
              />
            </div>
            <h3>Supplemental Guidance</h3>
            <p className={styles.content}>
              Users can refer to the supporting authorities and additional
              guidance provided under Supplemental Guidance.
            </p>
            <p>
              <b>
                NOTE: The "FURTHER GUIDANCE" section below NIST SP 800-171A
                guidance is derived from previous CMMC 1.02 guidance for
                informational purposes only. It will be updated when the CMMC
                2.0 publications become available.
              </b>
            </p>
            <div className={styles.image}>
              <img
                className={styles.imageWidth}
                style={{ maxWidth: "100%" }}
                src={process.env.PUBLIC_URL + "/images/supp_guidance.png"}
                alt="supp_guidance"
              />
            </div>
            <p className={styles.imageTitle}>Figure 4.1: Questions Screen</p>
            <h3>Comments</h3>
            <div className={styles.imageTitle}>
              <img
                width={1000}
                style={{ maxWidth: "50%" }}
                src={`${process.env.PUBLIC_URL}/images/comments.png`}
                alt="comments"
              />
            </div>
            <p className={styles.content}>
              The comments function allows the assessor to record non-structured
              comments for each question during the assessment. In contrast, the
              Observations function (also useful for commenting) is more rigid
              and contains required fields.{" "}
            </p>
            <div className={styles.imageTitle}>
              <img
                width={500}
                style={{ maxWidth: "100%" }}
                src={`${process.env.PUBLIC_URL}/images/comm.png`}
                alt="comments"
              />
            </div>
            <p className={styles.imageTitle}>Figure 4.2: Comments Screen</p>
            <h3>Artifacts/Documents</h3>
            <div className={styles.imageTitle}>
              <img
                width={1000}
                style={{ maxWidth: "50%" }}
                src={`${process.env.PUBLIC_URL}/images/documents.png`}
                alt="documents"
              />
            </div>
            <p className={styles.content}>
              Assessors can use the Artifacts/Documents function to attach
              relevant evidence to each practice question.{" "}
            </p>
            <div className={styles.imageTitle}>
              <img
                width={500}
                style={{ maxWidth: "100%" }}
                src={`${process.env.PUBLIC_URL}/images/docs.png`}
                alt="docs"
              />
            </div>
            <p className={styles.imageTitle}>Figure 4.3: Documents Screen</p>
            <div className={styles.header}>4. CMMC Results </div>
            <p className={styles.content}>
              The primary deliverables of the CMMC 2.0 assessment are the
              Performance by Level, Performance by Domain, and the NIST 800-171A
              SPRS interpretation based on the 110 requirements in NIST 800-171.
              To learn more about the CMMC Results and Report functionality,
              visit the Results and Reports section of the CMMC User Guide. To
              learn more about the NIST SPRS scoring methodology presented in
              the Results section, please review NIST SP 800-171 DoD Assessment
              Methodology, Version 1.2.1.
            </p>
            {/* <div>
              <img
                src={process.env.PUBLIC_URL + "/images/results.png"}
                alt="results"
                className={styles.bigImg}
              />
            </div> */}
            <div className={styles.TextCenter}>
              <img
                className={styles.imageWidth}
                src={process.env.PUBLIC_URL + "/images/cmmc_graph_1.png"}
                alt="cmmc_graph_1"
              />
              <p className={styles.imageTitle}>
                Figure 5: Performance by Level
              </p>
            </div>
            <div className={styles.TextCenter}>
              <img
                className={styles.imageWidth}
                src={process.env.PUBLIC_URL + "/images/cmmc_graph_2.png"}
                alt="cmmc_graph_2"
              />
              <p className={styles.imageTitle}>
                Figure 6: Performance by Domain
              </p>
            </div>
            <div className={styles.header}>5. CMMC Reports </div>

            <p className={styles.content}>
              This section includes the Executive Summary Report and Deficiency
              Report of CMMC 2.0. The CMMC Site Reports, which contain the
              findings of the assessment, are the predominant document of a CMMC
              assessment. In order to download all reports, Download all option
              is available.
            </p>
            <div className={styles.TextCenter}>
              <img
                className={styles.imageWidth}
                src={process.env.PUBLIC_URL + "/images/reports.png"}
                alt="reports"
              />
              <p className={styles.imageTitle}>
                Figure 7: Report Screen
              </p>
            </div>
          </div>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default CMMCTutorial;
