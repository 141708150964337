import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser (
    $username: String!,
    $email: String!,
    $password: String!,
    $role: ID!
    $confirmed: Boolean!
    ) {
      createUser (input: {
        data: {
          username: $username
          email: $email
          password: $password
          role: $role
          confirmed: $confirmed
        }
      }) {
        user {
          id
          username
          email
        }
      }
    }
`;

export const USER_LOGIN = gql`
  mutation login(
    $identifier: String!,
    $password: String!
  ){
    login(
      input:{
        identifier: $identifier,
        password: $password
      }
    ){
      jwt
      user{
        id
        role {
          name
        }
        username
      }
    }
  }
`;
export const UPDATE_USER = gql`
mutation (
  $id :ID!,
  $username: String!,
  $email: String!,
  $password: String,
  $role: ID!
  $confirmed: Boolean!
){
  updateUser
  (input: {where: {id: $id},
   data:
    {
    username: $username,
    email:  $email,
    password: $password,
    confirmed: $confirmed,
    role: $role
    }
  }) {
    user {
      id
      username
      email
    }
  }
}
`
export const PG_DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(input: { where: { id: $id } }) {
      user {
        id
      }
    }
  }
`;

export const CREATE_UPDATE_PARTNER_USER = gql`
mutation createUpdatePartnerUser(
  $pg_partner_id: Int!
  $pg_user_id: Int!
  $first_name: String!
  $last_name: String
  $phone: String
  $email: String!
  $password: String
  $roleName: String
  $pg_user_role: String!
) {
  createUpdatePartnerUser(
    input: {
      first_name: $first_name
      email: $email
      phone: $phone
      pg_partner_id: $pg_partner_id
      pg_user_id: $pg_user_id
      last_name: $last_name
      password: $password
      roleName: $roleName
      pg_user_role: $pg_user_role
    }
  ) {
    partner_user_data {
      pg_partner_id
      pg_user_id
      first_name
      email
      contact_id
      pg_user_role
    }
    message
  }
}
`
