import React, { useState, useEffect } from "react";
import styles from "./SPRSScore.module.css";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import Paper from "@mui/material/Paper";
import { CSET_URL } from "../../../../../config/index";
import Grid from "@mui/material/Grid";
import logout from "../../../../Auth/Logout/Logout";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import DOMPurify from "dompurify";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../../components/UI/Alert/Alert";
import * as msgConstants from "../../../../../common/MessageConstants";
import { sentry_error_catch } from "../../../../../common/sentry_error_catch";

export interface SPRSScoreProps {
  propsData?: any;
}

export const SPRSScore: React.FC<SPRSScoreProps> = ({
  propsData,
}: SPRSScoreProps) => {
  const [newData, setNewData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [param, setParam] = useState<any>({});
  const [gaugeSvg, setGaugeSvg] = useState<any>(null);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const authToken = sessionStorage.getItem("ra_token") || logout();

  useEffect(() => {
    const controller = new AbortController();
    if (propsData !== undefined) {
      setParam(propsData);
    }
    getSPRSScore();
    return () => controller.abort();
  }, []);

  const headerObj = {
    "Content-Type": msgConstants.CONTENT_TYPE,
    Authorization: authToken,
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };
  
  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: msgConstants.ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const getSPRSScore = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/SPRSScore`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setGaugeSvg(data.gaugeSvg);
          setNewData(data.domains);
          setLoading(false);
        })
        .catch((err:any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });
    } catch (e) {
      setLoading(false);
      handleError(e);
      console.error("error", e);
    }
  };

  const answerCellClass = (answer: string) => {
    switch (answer) {
      case "Y":
        return "green-score";
      case "I":
        return "yellow-score";
      case "N":
        return "red-score";
      case "U":
        return "default-score";
    }
    return "";
  };

  /**
   * Convert String to html Response
   * @param html
   */
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  if (loading) {
    return <SimpleBackdrop />;
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <>
        {formState.isFailed ? (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {formState.errMessage}
          </Alert>
        ) : null}
        <Paper className={styles.paper}>
          <Grid container>
            <Grid item xs={12} className={styles.RRAModelImg}>
              <div className={styles.RRAModel}>
                <span dangerouslySetInnerHTML={createDangerousHTML(gaugeSvg)} />
                <Typography variant="h3" component="h3">
                  SPRS Scorecard
                </Typography>
                <br />
              </div>
            </Grid>
            <Typography component="h5" variant="h3">
              The following is an accounting of CMMC Level 2 interpreted as your
              NIST 171 SPRS score. For more information on SPRS scoring
              methodology and preliminary requirements for its use, please refer
              to the tutorial.
            </Typography>
            <Grid item xs={8} md={12} sm={8} className={styles.FooterActions}>
              <div className={styles.securityPlanTable}>
                {newData.map((element: any, index: any) => {
                  return (
                    <table
                      id="secondTable"
                      key={index}
                      className={styles.tableStyle}>
                      <tr>
                        <td colSpan={1}>
                          <Typography component="h3" variant="h2">
                            {element.domainName}
                          </Typography>{" "}
                        </td>
                      </tr>
                      <tr key={index}>
                        <td colSpan={3}>
                          {" "}
                          <Typography component="h3" variant="h3">
                            Practice
                          </Typography>
                        </td>
                        <td>
                          {" "}
                          <Typography component="h3" variant="h3">
                            Response
                          </Typography>
                        </td>
                        <td>
                          {" "}
                          <Typography component="h3" variant="h3">
                            Value
                          </Typography>
                        </td>
                      </tr>

                      {element.questions.map(
                        (questionData: any, index: number) => {
                          return (
                              <tr key={index}>
                                <td colSpan={1}>{questionData.id} </td>
                                <td colSpan={2}>
                                  <span
                                    dangerouslySetInnerHTML={createDangerousHTML(
                                      questionData.questionText
                                    )}></span>
                                </td>
                                <td
                                  colSpan={1}
                                  className={
                                    styles[
                                      answerCellClass(questionData.answerText)
                                    ]
                                  }>
                                  {questionData.answerText === "Y"
                                    ? "Yes"
                                    : questionData.answerText === "N"
                                    ? "No"
                                    : questionData.answerText === "NA"
                                    ? "NA"
                                    : "Unanswered"}{" "}
                                </td>
                                <td colSpan={1}>{questionData.score}</td>
                              </tr>
                          );
                        }
                      )}
                    </table>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </>
    </React.Fragment>
  );
};
export default SPRSScore;
