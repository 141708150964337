import React, { useState, useEffect } from "react";
import styles from "./SecurityAssuranceLevel.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Button } from "../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router-dom";
import Simple from "../SecurityAssuranceLevel/Simple/Simple";
import TopStepper from "../../../components/UI/Layout/Navigation/TopStepper/TopStepper";
import stepper from "../../../common/csetStepperMenu.json";
import { setStepper } from "../../../common/SetStepper";
import { setActiveFormStep } from "../../../services/Data";
import AutoCompleteDropDown from "../../../components/UI/Form/Autocomplete/Autocomplete";
import Input from "../../../components/UI/Form/Input/Input";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import standardStepper from "../../../common/csetStandardStepperMenu.json";
import logout from "../../../containers/Auth/Logout/Logout";
import {
  CSET_URL,
  REACT_APP_PG_URL,
  PG_DEDUCT_SCAN_COUNT_API_KEY,
} from "../../../config/index";
import { HISTORY } from "../../../graphql/mutations/AuditTrail";
import { useMutation, useApolloClient, useLazyQuery } from "@apollo/client";
import { UPDATE_CSET_ASSESSMENT } from "../../../graphql/mutations/CSETAssessment";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../components/UI/Alert/Alert";
import { GET_CSET_ASSESSMENT } from "../../../graphql/queries/CSETAssessment";
import { sentry_error_catch } from "../../../common/sentry_error_catch";
import { useErrorBoundary } from "react-error-boundary";
import { CANCEL_MSG, ERROR_MESSAGE } from "../../../common/MessageConstants";
import { DialogBox } from "../../../components/UI/DialogBox/DialogBox";
interface SectorValueObj {
  sectorId: number;
  sectorName: string;
  name: string;
}
interface RelativeEffortObj {
  demographicId: number;
  size: string;
  description: string;
}
interface OrganizationValue {
  organizationTypeId: number;
  organizationType: string;
  demographics: any;
}
interface IndustryValueObj {
  sectorId: number;
  sectorName: string;
  name: string;
}

export const SecurityAssuranceLevel: React.FC = (props: any) => {
  const history = useNavigate();
  const location = useLocation();
  const contentType = "application/json";
  const { showBoundary } = useErrorBoundary();
  const [active, setActive] = useState("Simple");
  const [salObj, setSalObj] = useState<any>({});
  const authToken = sessionStorage.getItem("ra_token") || "";
  const is_link_assessment =  Boolean(sessionStorage.getItem("is_link_authenticated")) || false;
  const [param, setParam] = useState<any>(
    location.state[Object.keys(location.state)[0]]
  );
  const [stepperObj, setStepperObj] = useState<any>({ path: "" });
  const [sectorList, setSectorList] = useState<any>("");
  const [industryList, setIndustryList] = useState<any>([]);
  const [sector, setSector] = useState<any>("");
  const [industry, setIndustry] = useState<any>(null);
  const [relativeEffort, setRelativeEffort] = useState<any>("");
  const [organizations, setOrganizations] = useState<any>("");
  const [organization, setOrganization] = useState<any>("");
  const [business, setBusiness] = useState<any>("");
  const [organizationList, setOrganizationList] = useState<any>([]);
  const [facilitator, setFacilitator] = useState<any>("");
  const [contact, setContact] = useState<any>("");
  const [relativeEffortList, setRelativeEffortList] = useState<any>([]);
  const [demographicsObj, setDemographicsObj] = useState<any>({});
  const [dataLoading, setDataLoading] = useState(false);
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const [updateAssessment] = useMutation(UPDATE_CSET_ASSESSMENT);
  const [getFacilitatorAndContactOfAssessment] =
    useLazyQuery(GET_CSET_ASSESSMENT);

  const onMenuClick = (value: any) => {
    setActive(value);
  };
  const stepperObject = stepper;
  const standardStepperObject = standardStepper;

  const client = useApolloClient();

  const getAllSectors = {
    options: sectorList,
    getOptionLabel: (option: SectorValueObj) => option.name,
  };

  const getAllIndustries = {
    options: industryList,
    getOptionLabel: (option: IndustryValueObj) => option.name,
  };

  const getAllRelativeEffort = {
    options: relativeEffortList,
    getOptionLabel: (option: RelativeEffortObj) => option.description,
  };

  const getAllOrganization = {
    options: organizationList,
    getOptionLabel: (option: OrganizationValue) => option.organizationType,
  };

  const enableBackdrop = () => {
    if (dataLoading) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    setParam(location.state[Object.keys(location.state)[0]]);
    getSecurityAssuranceLevel();
    getAssessmentDetails(authToken);
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    getSectors();
    getRelativeEffort();
    getOrganizationType();
    getFacilitatorAndContact();
    if (
      demographicsObj !== null &&
      demographicsObj?.hasOwnProperty("sectorId")
    ) {
      getIndustries(demographicsObj.sectorId);
    }
    setBusiness(demographicsObj.agency);
    setOrganizations(demographicsObj.organizationName);
    return () => controller.abort();
  }, [demographicsObj]);

  useEffect(() => {
    const controller = new AbortController();
    setDataLoading(true);
    setStepper(
      client,
      stepperObject.securityAssuranceLevel.name,
      stepperObject.securityAssuranceLevel.value
    );
    setActiveFormStep(1);
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    //for deducting 1 credit from standard flow
    if (param?.assessmentCreate && !param?.assessmentUpdate) {
      handleDeduction();
    }
    return () => controller.abort();
  }, []);

  const [auditTrail] = useMutation(HISTORY);

  const handleDeduction = async () => {
    const deductionObj = {
      partner_id: param.pgPartnerId,
      scan_type: param.groupTitle,
      uid: param.pgPartnerUserId,
      main_type:
        param.flowType.type === "Custom" || param.flowType.type === "Standard"
          ? "RAA"
          : param.flowType.type === "CMMC2"
          ? "CMMC"
          : param.flowType.type,
      timestamp: new Date().getTime() / 1000,
      target_id: param.ccAssessmentInfo.id,
    };
    await fetch(
      `${REACT_APP_PG_URL}/ob-ra-scans/api/v1/deduct-scan-count/post`,
      {
        method: "POST",
        headers: {
          "api-key": PG_DEDUCT_SCAN_COUNT_API_KEY,
          "Content-type": "application/json",
        },
        body: JSON.stringify(deductionObj),
      }
    )
      .then((response) => {
        if(response.status === 200){ 
          return response.json()
        }else{
          throw new Error(`Deduction API failed`);
        } 
      })
      .then((response) => {
        auditTrail({
          variables: {
            auditTrailInput: {
              individual: param.individualId,
              organization: param.partnerOrganizationId,
              cset_assessment: param.ccAssessmentInfo.id,
              action: {
                message: `Deduction API Successfully deducted one scan.`,
                data: response,
              },
              flowtype: param.flowType.type
              ? param.flowType.type
              : param.flowType,
              page: "Security Assurance Level Page Deduction API.",
              client: param.clientOrgId,
            },
          },
        }).then((res: any) => {
          console.log("audit trail added")
        }).catch((err:any)=> {
          Sentry.captureException(err);
        })
      })
      .catch((error) => {
        Sentry.captureException(error)
        auditTrail({
          variables: {
            auditTrailInput: {
              individual: param.individualId,
              organization: param.partnerOrganizationId,
              cset_assessment: param.ccAssessmentInfo.id,
              action: {
                message: `${error}.`,
                data: deductionObj,
              },
              flowtype: param.flowType.type
              ? param.flowType.type
              : param.flowType,
              page: "Security Assurance Level Page Deduction API.",
              client: param.clientOrgId,
            },
          },
        }).then((res: any) => {
          console.log("audit trail added")
        }).catch((err) => {
          Sentry.captureException(err)
        });
      });
  };

  const headerObj = {
    "Content-Type": contentType,
    Authorization: authToken,
  };

  const getAssessmentDetails = async (val: any) => {
    await fetch(`${CSET_URL}api/assessmentdetail`, {
      method: "GET",
      headers: {
        "Content-Type": contentType,
        Authorization: val,
      },
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        getDemographicsInfo(data.id, val);
      })
      .catch((error: any) => {
        handleError(error);
      });
  };

  const getDemographicsInfo = async (id: any, token: any) => {
    await fetch(`${CSET_URL}api/Demographics/?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": contentType,
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDemographicsObj(data);
      })
      .catch((error: any) => {
        handleError(error);
      });
  };
  const getSecurityAssuranceLevel = async () => {
    try {
      await fetch(`${CSET_URL}api/SAL`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setSalObj(data);
        })
        .catch((error: any) => {
          handleError(error);
        });
    } catch (err:any) {
        sentry_error_catch(err,setDataLoading,setFormState)
    }
  };

  const handleSubmit = async () => {
    const temp = {
      industryId: demographicsObj.industryId,
      sectorId: demographicsObj.sectorId,
      size: demographicsObj.size,
      AssetValue: param.AssetValue,
      organizationName: organizations ? organizations : null,
      agency: business ? business : null,
      organizationType: demographicsObj?.organizationType
        ? demographicsObj.organizationType
        : null,
    };
    auditTrail({
      variables: {
        auditTrailInput: {
          individual: param.individualId,
          organization: param.partnerOrganizationId,
          action: {
            message: "Clicked on Next Button to Redirect to Standards Page.",
            data: null,
          },
          flowtype: param.flowType.type ? param.flowType.type : param.flowType,
          page: "Security Assurance Level Page",
          client: param.clientOrgId,
          cset_assessment: param.ccAssessmentInfo.id,
        },
      },
    })
      .then(async (res: any) => {
        const obj = {
          "Content-Type": contentType,
          Authorization: authToken,
        };
        await fetch(`${CSET_URL}api/Demographics/`, {
          method: "POST",
          headers: obj,
          body: JSON.stringify(temp),
        })
          .then((response) => response.json())
          .then((data) => {
            submitContactAndFacilitatorData();
            handleNext();
          })
          .catch((error: any) => {
            handleError(error);
          });
      })
      .catch((error: any) => {
        handleError(error);
      });
  };

  const handleNext = () => {
    param.assessmentCreate = false;
    param.assessmentUpdate = true;
    if (authToken) {
      setStepper(
        client,
        stepperObject.standards.name,
        stepperObject.standards.value
      );
      history(routeConstant.STANDARDS, { state: { param } });
    } else {
      logout();
    }
  };

  const handleBack = () => {
    try{
    if (
      param.hasOwnProperty("flowType") &&
      param.flowType.type === "Standard"
    ) {
      setStepper(
        client,
        standardStepperObject.firmType.name,
        standardStepperObject.firmType.value
      );
      history(routeConstant.FIRM_TYPE, { state: { param } });
    }
    if (param.hasOwnProperty("flowType") && param.flowType.type === "Custom") {
      history(routeConstant.ASSESSMENT_INFO, { state: { param } });
    }
   }catch(err){
    showBoundary(err);
   }
  };

  const simpleLevelHandleChange = (val: any) => {
    const obj = { ...val };
    setSalObj(obj);
  };

  const handleCancel = () => {
    try{
    const val: any = {
      clientInfo: param.clientInfo,
      csetUserInfo: param.csetUserInfo,
      pgPartnerId: param.pgPartnerId,
      pgPartnerUserId: param.pgPartnerUserId,
    };
    history(routeConstant.ASSESSMENT_LIST, { state: { val } });
   }catch(err){
    showBoundary(err);
   }
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleError = (err:any) => {
    setDataLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const callingFromTopStepper = (event: any) => {
    setStepperObj(event);
  };

  const handleSectorChange = (event: any, selectedValue: any) => {
    setSector(selectedValue);
    if (selectedValue) {
      getIndustries(selectedValue.sectorId);
    }
    if (selectedValue) {
      setDemographicsObj((demographicObj: any) => {
        return {
          ...demographicObj,
          sectorId: selectedValue.sectorId,
          industryId: null,
        };
      });
    } else {
      setIndustry(null);
      setIndustryList([]);
      setDemographicsObj((demographicObj: any) => {
        return {
          ...demographicObj,
          sectorId: null,
          industryId: null,
        };
      });
    }
  };

  const handleValueChange = (event: any, selectedValue: any) => {
    setOrganization(selectedValue);
    setDemographicsObj((demographicsObj: any) => {
      return {
        ...demographicsObj,
        organizationType: selectedValue
          ? selectedValue.organizationTypeId
          : null,
      };
    });
  };
  const handleFacilitatorValueChange = (event: any) => {
    setFacilitator(event.target.value);
    setDemographicsObj((demographicsObj: any) => {
      return {
        ...demographicsObj,
        facilitator: event.target.value ? event.target.value : null,
      };
    });
  };
  const handleContactValueChange = (event: any) => {
    setContact(event.target.value);
    setDemographicsObj((demographicsObj: any) => {
      return {
        ...demographicsObj,
        pointOfContact: event.target.value ? event.target.value : null,
      };
    });
  };
  const handleIndustryValueChange = (event: any, selectedValue: any) => {
    setIndustry(selectedValue);
    setDemographicsObj((demographicObj: any) => {
      return {
        ...demographicObj,
        sectorId: demographicsObj ? demographicsObj.sectorId : null,
        industryId: selectedValue ? selectedValue.industryId : null,
      };
    });
  };

  const handleRelativeEffortChange = (event: any, selectedValue: any) => {
    setRelativeEffort(selectedValue);
    setDemographicsObj((demographicObj: any) => {
      return {
        ...demographicObj,
        size: selectedValue ? selectedValue.demographicId : null,
        sectorId: demographicsObj ? demographicsObj.sectorId : null,
        industryId: demographicsObj ? demographicsObj.industryId : null,
      };
    });
  };

  const getSectors = async () => {
    try {
      await fetch(`${CSET_URL}api/Demographics/Sectors`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setDataLoading(false);
          setSectorList(data);
          const result = data.filter((obj: any) => {
            return obj.sectorId === demographicsObj.sectorId;
          });
          setSector(result[0]);
        })
        .catch((error: any) => {
          handleError(error);
        });
    } catch (err:any) {
      sentry_error_catch(err,setDataLoading,setFormState)
    }
  };

  const getIndustries = async (id: any) => {
    try {
      setDataLoading(false);
      if (id) {
        await fetch(`${CSET_URL}api/Demographics/Sectors_Industry/${id}`, {
          method: "GET",
          headers: headerObj,
        })
          .then((response) => response.json())
          .then((data) => {
            setDataLoading(false);
            if (data && data[0]) {
              setIndustryList(data);
            }
            const result = data.filter((obj: any) => {
              return obj.industryId === demographicsObj.industryId;
            });
            setIndustry(result[0]);
          })
          .catch((err:any)=>{
            sentry_error_catch(err,setDataLoading,setFormState)
          })
      }
    } catch (err:any) {
      sentry_error_catch(err,setDataLoading,setFormState)
    }
  };

  const getRelativeEffort = async () => {
    try {
      await fetch(`${CSET_URL}api/Demographics/Size`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setRelativeEffortList(data);
          const result = data.filter((obj: any) => {
            return obj.demographicId === demographicsObj.size;
          });
          setRelativeEffort(result[0]);
        })
        .catch((error: any) => {
          handleError(error);
        });
    } catch (err:any) {
      sentry_error_catch(err,setDataLoading,setFormState)
    }
  };

  const getOrganizationType = async () => {
    try {
      fetch(`${CSET_URL}api/getOrganizationTypes`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setDataLoading(false);
          setOrganizationList(data);
          const result = data.filter((obj: any) => {
            return obj.organizationTypeId === demographicsObj.organizationType;
          });
          setOrganization(result[0]);
        })
        .catch((error: any) => {
          console.log("error", error);
          handleError(error);
        });
    } catch (err:any) {
      sentry_error_catch(err,setDataLoading,setFormState)
    }
  };

  const getFacilitatorAndContact = () => {
    try {
      getFacilitatorAndContactOfAssessment({
        variables: {
          where: {
            id: param.ccAssessmentInfo.id,
          },
        },
      })
        .then((data) => {
          if (data.data.csetAssessments && data.data.csetAssessments[0]) {
            if (data.data.csetAssessments[0].facilitator) {
              setFacilitator(data.data.csetAssessments[0].facilitator);
            }
            if (data.data.csetAssessments[0].critical_point_of_contact) {
              setContact(
                data.data.csetAssessments[0].critical_point_of_contact
              );
            }
          }
        })
        .catch((err: any) => {
          sentry_error_catch(err,setDataLoading,setFormState)
        });
    } catch (err: any) {
        sentry_error_catch(err,setDataLoading,setFormState)
    }
  };

  const submitContactAndFacilitatorData = () => {
    try {
      updateAssessment({
        variables: {
          id: param.ccAssessmentInfo.id,
          assessment_type: param.flowType.type,
          facilitator: demographicsObj.facilitator
            ? demographicsObj.facilitator
            : facilitator,
          criticalPointOfContact: demographicsObj.pointOfContact
            ? demographicsObj.pointOfContact
            : contact,
        },
      })
        .then((data) => {
          console.log("data", data);
        })
        .catch((err: any) => {
          sentry_error_catch(err,setDataLoading,setFormState)
        });
    } catch (err:any) {
        sentry_error_catch(err,setDataLoading,setFormState)
    }
  };

  const inputChangeHandler = (event: any) => {
    if (event.target.name === "Organization") {
      setOrganizations(event.target.value);
      setDemographicsObj((demographicsObj: any) => {
        return {
          ...demographicsObj,
          organizationName: event.target.value ? event.target.value : null,
        };
      });
    } else if (event.target.name === "Business Unit/Agency") {
      setBusiness(event.target.value);
      setDemographicsObj((demographicsObj: any) => {
        return {
          ...demographicsObj,
          agency: event.target.value ? event.target.value : null,
        };
      });
    }
  };

  const handleErrorOfSimple = (err:any)=>{
    sentry_error_catch(err,setDataLoading,setFormState)
  }

  const closeDialogBox = () => {
    try{
      setOpenDialogBox(false);
    }catch(err: any){
      showBoundary(err)
    }
  };

  const cancelHandler = () => {
    try{
      setOpenDialogBox(true);
    }catch(err: any){
      showBoundary(err)
    }
  }
  return (
    <React.Fragment>
      <CssBaseline />
      {enableBackdrop() ? <SimpleBackdrop /> : null}
      <Typography component="h5" variant="h1">
        Risk Assessment for {param ? param.clientInfo.name : null}
      </Typography>
      <DialogBox
        open={openDialogBox}
        handleOk={handleCancel}
        handleCancel={closeDialogBox}
        buttonOk={"Yes"}
        buttonCancel={"No"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.MainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}>
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            Please Confirm
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          <p>
          {CANCEL_MSG}{" "}
            {/* {param.assessmentInfo?.assessmentName}? */}
          </p>
        </div>
      </DialogBox>
      <Grid container spacing={0}>
        <Grid item xs={12} className={styles.FooterGridWrap}>
          <div className={styles.topstepperWrap}>
            <div className={styles.prevBtn}>
              <Button
                id="previous-button"
                variant="contained"
                color="primary"
                onClick={handleBack}
              >
                {"<< Previous"}
              </Button>
            </div>
            <div className={styles.topstepperButonsWrap}>
              <TopStepper
                param={param}
                handleSave={callingFromTopStepper}
                obj={stepperObj}
                submitDisabled={false}
              />
            </div>
            <div className={styles.nextBtn}>
              <Button
                id="next-button"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                {"Next >>"}
              </Button>
            </div>
          </div>
          {!is_link_assessment && (<div className={styles.crossBtn}>
            <Button
              id="cancel-button"
              variant="contained"
              color="primary"
              onClick={cancelHandler}
            >
              {"X"}
            </Button>
            <span className={styles.cancleTooltip}>Cancel</span>
          </div>)}
        </Grid>
      </Grid>
      {formState.isFailed ? (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {formState.errMessage}
        </Alert>
      ) : null}
      <Grid container>
        <Grid item xs={12}>
          <Paper className={styles.paper}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <AutoCompleteDropDown
                  {...getAllSectors}
                  id="sector"
                  value={sector}
                  name="sector"
                  onChange={handleSectorChange}
                  renderInput={(
                    params:
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").StandardTextFieldProps)
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").FilledTextFieldProps)
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").OutlinedTextFieldProps)
                  ) => <Input {...params} id="sector" label="Sector" />}
                />
              </Grid>
              <Grid item xs={6}>
                <AutoCompleteDropDown
                  {...getAllIndustries}
                  id="industry"
                  value={industry}
                  disabled={sector ? false : true}
                  name="industry"
                  onChange={handleIndustryValueChange}
                  renderInput={(
                    params:
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").StandardTextFieldProps)
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").FilledTextFieldProps)
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").OutlinedTextFieldProps)
                  ) => <Input {...params} id="industry" label="Industry" />}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Input
                  id="Organization"
                  name="Organization"
                  label="Organization"
                  variant="outlined"
                  value={organizations || ""}
                  onChange={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Input
                  id="Business Unit/Agency"
                  name="Business Unit/Agency"
                  label="Business Unit/Agency"
                  variant="outlined"
                  value={business || ""}
                  onChange={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AutoCompleteDropDown
                  {...getAllOrganization}
                  id="organization"
                  value={organization}
                  name="organization"
                  onChange={handleValueChange}
                  renderInput={(
                    params:
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").StandardTextFieldProps)
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").FilledTextFieldProps)
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").OutlinedTextFieldProps)
                  ) => (
                    <Input
                      {...params}
                      id="organization"
                      label="Organization Type"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  id="Facilitator"
                  name="Facilitator"
                  label="Facilitator"
                  Autocomplete="off"
                  variant="outlined"
                  value={facilitator || ""}
                  onChange={handleFacilitatorValueChange}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  id="criticalPointOfContact"
                  name="criticalPointOfContact"
                  label="Critical Point Of Contact"
                  Autocomplete="off"
                  variant="outlined"
                  value={contact || ""}
                  onChange={handleContactValueChange}
                />
              </Grid>
              <Grid item xs={12}>
                <span className={styles.CompanyFormHead}>
                  {"What is the relative expected effort for this assessment?"}
                </span>
                <div className={styles.RelativeAssessment}>
                  <AutoCompleteDropDown
                    {...getAllRelativeEffort}
                    id="relativeEffort"
                    value={relativeEffort}
                    name="Relative Effort"
                    onChange={handleRelativeEffortChange}
                    renderInput={(
                      params:
                        | (JSX.IntrinsicAttributes &
                            import("@mui/material").StandardTextFieldProps)
                        | (JSX.IntrinsicAttributes &
                            import("@mui/material").FilledTextFieldProps)
                        | (JSX.IntrinsicAttributes &
                            import("@mui/material").OutlinedTextFieldProps)
                    ) => (
                      <Input
                        {...params}
                        id="relativeEffort"
                        label="Relative Effort"
                      />
                    )}
                  />
                </div>
              </Grid>
            </Grid>
            <span className={styles.CompanyFormHead}>
              {"Security Assurance Level (SAL)"}
            </span>
            <Grid container>
              <Grid item xs={12} md={12} lg={12} className={styles.secure_para}>
                The Security Assurance Level or SAL determines the number of
                questions you will need to answer and level of rigor of the
                assessment. For example, a typical high SAL will contain
                350-1000 questions where a low SAL will typically contain 30-350
                questions, depending on the selected standard.
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <span className={styles.CompanyFormHead}>
                  Current Security Assurance Level
                </span>
              </Grid>
              <Grid
                container
                spacing={2}
                className={styles.Security_Assurance_Grid}
              >
                <Grid item xs={12} md={3}>
                  <div className={styles.sal_levels}>
                    Overall
                    <Grid className={styles.selected_level}>
                      {salObj.selected_Sal_Level}
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className={styles.sal_levels}>
                    Confidentiality
                    <Grid className={styles.selected_level}>
                      {salObj.cLevel}
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className={styles.sal_levels}>
                    Integrity
                    <Grid className={styles.selected_level}>
                      {salObj.iLevel}
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className={styles.sal_levels}>
                    Availability
                    <Grid className={styles.selected_level}>
                      {salObj.aLevel}
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              <div className={styles.TabOptions}>
                <Button
                  id="simple"
                  className={
                    active === "Simple"
                      ? styles.TabOptionsActive
                      : styles.TabOptionsInactive
                  }
                  onClick={() => onMenuClick("Simple")}
                >
                  Simple
                </Button>
                <div className={styles.Tabinner}>
                    {active === "Simple" ? (
                      <Simple
                        name="Simple"
                        data={salObj}
                        handleChange={simpleLevelHandleChange}
                        handleErrorOfSimple={handleErrorOfSimple}
                      />
                    ) : null}
                </div>
              </div>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SecurityAssuranceLevel;
