import React, { useState, useEffect } from "react";
import styles from "./CRRResults.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import AssetManagement from "./AssetManagement/AssetManagement";
import ConfigurationandChangeManagement from "./ConfigurationandChangeManagement/ConfigurationandChangeManagement";
import ControlsManagement from "./ControlsManagement/ControlsManagement";
import ExternalDependenciesManagement from "./ExternalDependenciesManagement/ExternalDependenciesManagement";
import IncidentManagement from "./IncidentManagement/IncidentManagement";
import RiskManagement from "./RiskManagement/RiskManagement";
import ServiceContinuityManagement from "./ServiceContinuityManagement/ServiceContinuityManagement";
import SituationalAwareness from "./SituationalAwareness/SituationalAwareness";
import VulnerabilityManagement from "./VulnerabilityManagement/VulnerabilityManagement";
import TrainingandAwareness from "./TrainingandAwareness/TrainingandAwareness";
import { Button } from "../../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../../common/RouteConstants";
import TopStepper from "../../../../components/UI/Layout/Navigation/TopStepper/TopStepper";
import crrStepper from "../../../../common/csetCRRStepperMenu.json";
import { setStepper } from "../../../../common/SetStepper";
import { uploadFiles, getFileByName } from "../../../../graphql/mutations/Upload";
import { UPDATE_CSET_ASSESSMENT } from "../../../../graphql/mutations/CSETAssessment";
import { GET_ORGANIZATION } from "../../../../graphql/queries/Organization";
import { DialogBox } from "../../../../components/UI/DialogBox/DialogBox";
import * as msgConstants from "../../../../common/MessageConstants";
import { setActiveFormStep } from "../../../../services/Data";
import { HISTORY } from "../../../../graphql/mutations/AuditTrail";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { CSET_URL } from "../../../../config/index";
import Logout from "../../../../containers/Auth/Logout/Logout";
import CRRExecutiveSummary from "../CRRReports/ExecutiveSummary/CRRExecutiveSummary";
import CrrSummaryResults from "../CRRReports/ExecutiveSummary/CrrSummaryResults";
import CommentAndReview from "../CRRReports/CRRCommentAndReview/CommentAndReview";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../components/UI/Alert/Alert";
import CRRDeficiencyReport from "../CRRReports/DeficiencyReport/CRRDeficiencyReport";
import { useErrorBoundary } from "react-error-boundary";
import { sentry_error_catch } from "../../../../common/sentry_error_catch";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={styles.tabBoxPanel} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}


export const CRRResults: React.FC = (props: any) => {
  const JSZip = require("jszip");
  const testArr: any = [];
  const stepperObject = crrStepper;
  const { showBoundary } = useErrorBoundary();
  const authToken = sessionStorage.getItem("ra_token") || Logout();
  const is_link_assessment =  Boolean(sessionStorage.getItem("is_link_authenticated")) || false;
  const [genReport, setGenReport] = useState<any>(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const client = useApolloClient();
  const location = useLocation();
  const history = useNavigate();
  const [value, setValue] = React.useState(0);
  const [param, setParam] = useState<any>(
    location?.state[Object.keys(location?.state)[0]]
  );
  const [stepperObj, setStepperObj] = useState<any>({ path: "" });
  const [updateAssessment] = useMutation(UPDATE_CSET_ASSESSMENT);
  const [partnerData, setPartnerData] = useState<any>({});
  const [clientData, setClientData] = useState<any>({});
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
  const dialogBoxMsg = msgConstants.REPORT_GENERATION;
  const [uploadFile] = useMutation(uploadFiles);
  const envVariables = process.env;
  const serverUrl = envVariables.REACT_APP_SERVER_URL;
  const [getBlankImage] = useLazyQuery(getFileByName);
  const [defaultBlankURL,setDefaultBlankURL] = useState<any>("");
  const [cpaImage, setCPAImage] = useState<any>();
  const [partnerImage, setPartnerImage] = useState<any>();
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [cpaImageDimensions, setCPAImageDimensions] = useState<any>("");
  const [partnerImageDimensions, setPartnerImageDimensions] = useState<any>("");

  useEffect(() => {
    setParam(location?.state[Object.keys(location?.state)[0]]);
    getBlankImage({
      variables:{
        name:"blank_img.png",
      }
    }).then((data:any) => {
      console.log("data----",data)
      if(data.data && data.data.files && data.data.files[0]){
        setDefaultBlankURL(data.data.files[0]?.url.slice(1));
      }
    }).catch((err:any) => {
      sentry_error_catch(err,setShowBackdrop,setFormState)
    });
  }, []);

  const headerObj = {
    "Content-Type": msgConstants.CONTENT_TYPE,
    Authorization: authToken,
  };

  useEffect(() => {
    if (partnerData && clientData) {
      getImagesWhileBuildingReports(partnerData, clientData);
    }
  }, [partnerData, clientData]);

  useEffect(() => {
    setStepper(client, stepperObject.results.name, stepperObject.results.value);
    setActiveFormStep(3);
  }, []);

  const [auditTrail] = useMutation(HISTORY);

  const getImagesWhileBuildingReports = async (partner: any, client: any) => {
    if (client && client.logo && client.logo[0]) {
      const clientLogoUrl = client.logo[0].url.slice(1);
      await fetch(`${serverUrl}${clientLogoUrl}`, {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((data) => {
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = function (theFile: any) {
            const base64data = reader.result;
            setCPAImage(base64data);
            const image = new Image();
            image.src = theFile.target.result;
            image.onload = () => {
              setCPAImageDimensions({
                height: image.height,
                width: image.width,
              });
            };
          };
        }).catch((error)=>{
          Sentry.captureException(error)
        })
    }
    // else{
    //   console.log("logo not found",defaultBlankURL);
    //   fetch(`${serverUrl}${defaultBlankURL}`, {
    //     method: "GET",
    //     headers: headerObj,
    //   })
    //     .then((response:any) => response.blob())
    //     .then((data:any) => {
    //       const reader = new FileReader();
    //       reader.readAsDataURL(data);
    //       reader.onloadend = function (theFile: any) {
    //         const base64data = reader.result;
    //         setCPAImage(base64data);
    //         const image = new Image();
    //         image.src = theFile.target.result;
    //         image.onload = () => {
    //           setCPAImageDimensions({
    //             height: image.height,
    //             width: image.width,
    //           });
    //         };
    //       };
    //     })
    //     .catch((error) => {
    //       console.log("Error", error);
    //       Sentry.captureException(error)
    //       // return response = ""
    //     });
    // }
    if (partner && partner.logo && partner.logo[0]) {
      const partnerLogoUrl = partner.logo[0].url.slice(1);
      await fetch(`${serverUrl}${partnerLogoUrl}`, {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((data) => {
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = function (theFile: any) {
            const base64data = reader.result;
            setPartnerImage(base64data);
            const image = new Image();
            image.src = theFile.target.result;
            image.onload = () => {
              setPartnerImageDimensions({
                height: image.height,
                width: image.width,
              });
            };
          };
        }).catch((error)=>{
          Sentry.captureException(error)
        })
    }
    // else{
    //   fetch(`${serverUrl}${defaultBlankURL}`, {
    //     method: "GET",
    //     headers: headerObj,
    //   })
    //   .then((response) => response.blob())
    //   .then((data) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(data);
    //     reader.onloadend = function (theFile: any) {
    //       const base64data = reader.result;
    //       setPartnerImage(base64data);
    //       const image = new Image();
    //       image.src = theFile.target.result;
    //       image.onload = () => {
    //         setPartnerImageDimensions({
    //           height: image.height,
    //           width: image.width,
    //         });
    //       };
    //     };
    //   }).catch((error)=>{
    //     Sentry.captureException(error)
    //   })
    // }
  };

  const getAssessmentDetails = async (val: any) => {
    await fetch(`${CSET_URL}api/assessmentdetail`, {
      method: "GET",
      headers: {
        "Content-Type": msgConstants.CONTENT_TYPE,
        Authorization: val,
      },
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        param.assessmentInfo = data;
        setGenReport(true);
      })
      .catch((error: any) => {
        handleError(error);
        setShowBackdrop(false);
        console.log("Error :-", error);
      });
  };

  const handleGenerate = () => {
    setShowBackdrop(true);
    auditTrail({
      variables: {
        auditTrailInput: {
          individual: param.individualId,
          organization: param.partnerOrganizationId,
          action: {
            message: "Clicked on Okay Button to Generate Report.",
            data: null,
          },
          flowtype: param.flowType.type ? param.flowType.type : param.flowType,
          page: "CRR Results Page",
          client: param.clientOrgId,
          cset_assessment: param.ccAssessmentInfo.id,
        },
      },
    }).then((res: any) => {
      getAssessmentDetails(authToken);
    }).catch((err:any) => {
      sentry_error_catch(err,setShowBackdrop,setFormState)
    });
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleBack = () => {
    try{
    setStepper(
      client,
      stepperObject.questions.name,
      stepperObject.questions.value
    );
    history(routeConstant.CRR_QUESTIONS, { state: { param } });
    }catch(err){
      showBoundary(err);
    }
  };

  const handleCancel = () => {
    try{
    const val: any = {
      clientInfo: param.clientInfo,
      csetUserInfo: param.csetUserInfo,
      pgPartnerId: param.pgPartnerId,
      pgPartnerUserId: param.pgPartnerUserId,
    };
    history(routeConstant.ASSESSMENT_LIST, { state: { val } });
    }catch(err){
      showBoundary(err);
    }
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };
  
  const handleError = (err:any) => {
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: msgConstants.ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const callingFromTopStepper = (event: any) => {
    setStepperObj(event);
  };

  useEffect(() => {
    GetOrganizationDataOfPartner({
      variables: {
        where: {
          contact_id: param?.clientInfo.partnerId,
        },
      },
    });
    GetOrganizationDataOfClient({
      variables: {
        where: {
          id: param?.clientInfo.clientOrgId,
          subtype: "Client",
        },
      },
    });
  }, [param]);

  const [GetOrganizationDataOfPartner] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      if (param?.hasOwnProperty("clientInfo")) {
        OrgDataResponse.organizations.forEach((element: any) => {
          if (param.clientInfo.partnerId === element.contact_id.id) {
            setPartnerData(element);
          }
          if (param.clientInfo.clientOrgId === element.id) {
            setClientData(element);
          }
        });
      }
    },
    onError: (err) => {
      handleError(err);
    },
  });

  const [GetOrganizationDataOfClient] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackdrop,setFormState)
    },
  });

  const handleClose = () => {
    setShowDialogBox(false);
  };

  const handleOkay = () => {
    setShowDialogBox(false);
    handleGenerate();
  };

  const handleShowDialog = () => {
    setShowDialogBox(true);
  };

  const handleNext = () => {
    setStepper(client, stepperObject.reports.name, stepperObject.reports.value);
    history(routeConstant.CRR_REPORTS, { state: { param } });
  };

  let generatedReportCount = 0;

  const handleExecutiveSummaryReport = async (event: any) => {
    if (event) {
      testArr.push(event);
      generatedReportCount++;
      await addToZipAndUploadIfReady();
    }
  };

  const handleDeficiencyReport = async (event: any) => {
    if (event) {
      testArr.push(event);
      generatedReportCount++;
      await addToZipAndUploadIfReady();
    }
  };

  const handleCommentReport = async (event: any) => {
    if (event) {
      testArr.push(event);
      generatedReportCount++;
      await addToZipAndUploadIfReady();
    }
  };

  const addToZipAndUploadIfReady = async () => {
    // All three reports have been generated
    const hasAllReports =
      testArr.some(
        (element: any) => element.name === "CRR_Executive_Summary_Report.docx"
      ) &&
      testArr.some(
        (element: any) => element.name === "Deficiency_Report.docx"
      ) &&
      testArr.some(
        (element: any) => element.name === "Comment_And_Review_Report.docx"
      );

    if (hasAllReports) {
      // Create a ZIP folder
      const zip = new JSZip();

      // Add each report to the ZIP folder
      testArr.forEach((element: any) => {
        zip.folder("Report").file(element.name, element, { base64: true });
      });

      // Generate the ZIP blob
      const content = await zip.generateAsync({ type: "blob" });

      // Set the ZIP file name (you may need to adjust this)
      content.name = "Reports.zip";

      // Upload the ZIP file or perform further actions with it
      await uploadDocument(content);
    } else {
      // Handle the case where not all reports are present in testArr
      console.error("Not all reports are available to generate the ZIP.");
    }
  };

  const uploadDocument = async (ComplianceDocumentData: any) => {
    const handleUploadPromise = new Promise(function (Resolve, Reject) {
      if (ComplianceDocumentData) {
        // when successfull
        Resolve(ComplianceDocumentData);
      }else{
      Reject("error"); // when error
      }
    });

    handleUploadPromise.then(
      async function (value) {
        if (ComplianceDocumentData.size && ComplianceDocumentData.type) {
          const BlobDocData: any = ComplianceDocumentData;
          if (BlobDocData !== undefined) {
            await uploadFile({
              variables: {
                files: value,
              },
            })
              .then((uploadRes: any) => {
                updateAssessment({
                  variables: {
                    id: param.ccAssessmentInfo.id,
                    assessment_type: param.ccAssessmentInfo.assessment_type,
                    allow_download: true,
                    assessment_document: uploadRes.data.upload.id,
                  },
                })
                  .then((updateCompRes: any) => {
                    param.ccAssessmentInfo["assessment_Document"] =
                      updateCompRes.data.updateCsetAssessment.csetAssessment.assessment_document;
                    setShowBackdrop(false);
                    handleNext();
                  })
                  .catch((err:any) => {
                    sentry_error_catch(err,setShowBackdrop,setFormState)
                  });
              })
              .catch((err:any) => {
                sentry_error_catch(err,setShowBackdrop,setFormState)
              });
          }
        }
      },
      function (error) {
        console.log("Error :-", error);
      }
    );
  };

  const handleErrorFromChild = (error:any)=>{
    sentry_error_catch(error,setShowBackdrop,setFormState)
  }

  const closeDialogBox = () => {
    try{
      setOpenDialogBox(false);
    }catch(err: any){
      showBoundary(err)
    }
  };

  const cancelHandler = () => {
    try{
      setOpenDialogBox(true);
    }catch(err: any){
      showBoundary(err)
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        Risk Assessment for {param ? param.clientInfo.name : null}
      </Typography>
      <DialogBox
        open={openDialogBox}
        handleOk={handleCancel}
        handleCancel={closeDialogBox}
        buttonOk={"Yes"}
        buttonCancel={"No"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.MainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}>
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            Please Confirm
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          <p>
          {msgConstants.CANCEL_MSG}{" "}
            {/* {param.assessmentInfo?.assessmentName}? */}
          </p>
        </div>
      </DialogBox>
      {showBackdrop ? <SimpleBackdrop /> : null}
      <Grid container spacing={0}>
        <Grid item xs={12} className={styles.FooterActions}>
          <div className={styles.topstepperButons}>
            <div className={styles.prevBtn}>
              <Button
                id="previous-button"
                variant="contained"
                color="primary"
                onClick={handleBack}
              >
                {"<< Previous"}
              </Button>
            </div>
            <div className={styles.topstepperButons}>
              <TopStepper
                param={param}
                handleSave={callingFromTopStepper}
                obj={stepperObj}
                submitDisabled={false}
              />
            </div>
            <div className={styles.nextBtn}>
              <Button
                id="next-button"
                variant="contained"
                color="primary"
                onClick={handleShowDialog}
              >
                {"Next >>"}
              </Button>
            </div>
          </div>
          {!is_link_assessment && (<div className={styles.crossBtn}>
            <Button
              id="cancel-button"
              variant="contained"
              color="primary"
              onClick={cancelHandler}
            >
              {"X"}
            </Button>
            <span className={styles.cancleTooltip}>Cancel</span>
          </div>)}
        </Grid>
      </Grid>
      {formState.isFailed ? (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {formState.errMessage}
        </Alert>
      ) : null}
      <div>
        <DialogBox
          open={showDialogBox}
          handleOk={handleOkay}
          handleCancel={handleClose}
          buttonOk={"Okay"}
          buttonCancel={"Close"}
          classes={{
            root: styles.MainOfficeDialogRoot,
            container: styles.MainOfficeDialogboxContainer,
            paper: styles.MainOfficeDialogboxPaper,
            scrollPaper: styles.MainOfficeScrollPaper,
          }}
        >
          <div className={styles.DialogBoxTitle}>
            <Typography component="h1" variant="h1">
              Warning
            </Typography>
          </div>
          <div className={styles.DialogBoxContext}>
            <p>{dialogBoxMsg}</p>
          </div>
        </DialogBox>
      </div>

      <AppBar position="static" color="default" className={styles.cset_band}>
        <Tabs
          classes={{ indicator: styles.PrivateTabIndicator }}
          value={value}
          selectionFollowsFocus
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons={true}
          aria-label="scrollable auto tabs example"
        >
          <Tab
            classes={{ selected: styles.selectedTab }}
            label="Asset Management"
            {...a11yProps(0)}
          />
          <Tab
            classes={{ selected: styles.selectedTab }}
            label="Controls Management"
            {...a11yProps(2)}
          />
          <Tab
            classes={{ selected: styles.selectedTab }}
            label="Configuration and Change Management"
            {...a11yProps(3)}
          />
          <Tab
            classes={{ selected: styles.selectedTab }}
            label="Vulnerability Management"
            {...a11yProps(4)}
          />
          <Tab
            classes={{ selected: styles.selectedTab }}
            label="Incident Management"
            {...a11yProps(5)}
          />
          <Tab
            classes={{ selected: styles.selectedTab }}
            label="Service and Continuity Management"
            {...a11yProps(6)}
          />
          <Tab
            classes={{ selected: styles.selectedTab }}
            label="Risk Management"
            {...a11yProps(7)}
          />
          <Tab
            classes={{ selected: styles.selectedTab }}
            label="External Dependencies Management"
            {...a11yProps(8)}
          />
          <Tab
            classes={{ selected: styles.selectedTab }}
            label="Training and Awareness"
            {...a11yProps(9)}
          />
          <Tab
            classes={{ selected: styles.selectedTab }}
            label="Situational Awareness"
            {...a11yProps(10)}
          />
          <Tab
            classes={{ selected: styles.selectedTab }}
            label="Summary Results"
            {...a11yProps(11)}
          />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <AssetManagement data={param} handleError={handleErrorFromChild} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ControlsManagement data={param} handleError={handleErrorFromChild} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ConfigurationandChangeManagement data={param} handleError={handleErrorFromChild} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <VulnerabilityManagement data={param} handleError={handleErrorFromChild} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <IncidentManagement data={param} handleError={handleErrorFromChild} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <ServiceContinuityManagement data={param} handleError={handleErrorFromChild} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <RiskManagement data={param} handleError={handleErrorFromChild} />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <ExternalDependenciesManagement data={param} handleError={handleErrorFromChild} />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <TrainingandAwareness data={param} />
      </TabPanel>
      <TabPanel value={value} index={9}>
        <SituationalAwareness data={param} handleError={handleErrorFromChild} />
      </TabPanel>
      <TabPanel value={value} index={10}>
        <CrrSummaryResults data={param} handleError={handleErrorFromChild}/>
      </TabPanel>

      {genReport ? (
        <>
          <div className={styles.docstyle}>
            {
              <CRRExecutiveSummary
                from={"result"}
                handleChange={handleExecutiveSummaryReport}
                props={param}
                partnerLogo={partnerImage}
                partnerImageDimension={partnerImageDimensions}
                clientLogo={cpaImage}
                clientImageDimension={cpaImageDimensions}
              />
            }
          </div>
          <div className={styles.docstyle}>
            {
              <CRRDeficiencyReport
                from={"result"}
                handleChange={handleDeficiencyReport}
                props={param}
                partnerLogo={partnerImage}
                partnerImageDimension={partnerImageDimensions}
                clientLogo={cpaImage}
                clientImageDimension={cpaImageDimensions}
              />
            }
          </div>
          <div className={styles.docstyle}>
            {
              <CommentAndReview
                from={"result"}
                handleChange={handleCommentReport}
                props={param}
                partnerLogo={partnerImage}
                partnerImageDimension={partnerImageDimensions}
                clientLogo={cpaImage}
                clientImageDimension={cpaImageDimensions}
              />
            }
          </div>
        </>
      ) : null}
    </React.Fragment>
  );
};
export default CRRResults;
