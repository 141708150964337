import styles from "./CRRTutorial.module.css";
import React, { FC, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import { Button } from "../../../components/UI/Form/Button/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import * as routeConstant from "../../../common/RouteConstants";

const CRRTutorial: FC<any> = (props: any) => {
  const history = useNavigate();
  const location = useLocation();
  const { showBoundary } = useErrorBoundary();
  const [param, setParam] = useState<any>(location?.state[Object.keys(location?.state)[0]]);
  
  useEffect(() => {
    if (location.state) {
      setParam(location?.state[Object.keys(location?.state)[0]]);
    } else {
      history(routeConstant.ASSESSMENT_TYPE);
    }
  }, []);

  const handleNext = () => {
    try{
    history(routeConstant.ASSESSMENT_INFO, {state: {param}});
    }catch(err){
      showBoundary(err)
    }
  };
  const handleBack = () => {
    try{
    history(routeConstant.ASSESSMENT_TYPE, {state: {param}});
    }catch(err){
      showBoundary(err)
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container spacing={2}>
        <Grid item xs={12} className={styles.TutorialHeaders}>
          <Typography
            style={{ fontWeight: "bold" }}
            component="h5"
            variant="h1"
          >
            CRR Tutorial
          </Typography>
          <div className={styles.prevBtn}>
            <Button
              id="previous-button"
              variant="contained"
              color="primary"
              onClick={handleBack}
            >
              {"<< Previous"}
            </Button>
          </div>
          <div className={styles.prevBtn}>
            <Button
              id="next-button"
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              {"Next >>"}
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid>
        <Paper className={styles.paper}>
          <div className={styles.header}>Overview</div>
          <p className={styles.content}>
            The Cyber Resilience Review (CRR) Assessment provides a measure of
            an organization’s cyber resilience capabilities. The CRR Assessment
            also enables an organization to assess its capabilities relative to
            v1.1 of the National Institute of Standards and Technology (NIST)
            Cybersecurity Framework (CSF) with CRR results depicted in terms of
            the NIST CSF. A facilitator should review the CRR Method Description
            and Assessment User Guide as a starting point for preparing and
            executing the CRR Assessment.
          </p>
          <div className={styles.header}>The CRR Assessment</div>
          <p className={styles.content}>
            The CRR is a lightweight, interview-based assessment method that was
            created by the Department of Homeland Security (DHS) for the purpose
            of evaluating the cybersecurity and service continuity practices of
            critical infrastructure owners and operators. The CRR Assessment,
            consisting of 10 domains of practice with 299 questions, is
            typically delivered in a six-hour interactive workshop. Each domain
            represents important capabilities that contribute to the cyber
            resilience of an organization. The number of goals and practice
            questions varies by domain, but the set of Maturity Indicator Level
            (MIL) questions and the concepts they encompass are the same for all
            domains. The MIL questions examine the institutionalization of
            practices within an organization.
          </p>
          <p className={styles.content}>
            The CRR reflects an organization’s capabilities at a single point in
            time—at the time of the assessment. It seeks to understand the
            cybersecurity management of business services, and their associated
            assets, that are critical for an organization’s mission success. The
            CRR measures essential cybersecurity capabilities and behaviors to
            provide meaningful indicators of an organization’s operational
            resilience during normal operations and during times of operational
            stress.
          </p>
          <p className={styles.content}>The assessment does not consider:</p>
          <div className={styles.content}>
            <ul>
              <li>
                activities that are planned or are in the process of
                implementation
              </li>
              <li>
                practices that have not been performed for extended periods of
                time. For example, if the organization has a disaster recovery
                plan that, in the opinion of the participants, is out of date to
                the point of being unusable, the plan should not be considered.
              </li>
            </ul>
          </div>
          <div className={styles.header}>Critical Service Scope</div>
          <p className={styles.content}>
            The CRR has a service-oriented approach, meaning that it is intended
            to assess the organization's management of a specific mission
            critical service. Scoping the assessment is critical because answers
            to the CRR questions must be provided in relation to a specific
            service and the assets that underpin that service.
          </p>
          <p className={styles.content}>
            A critical service must be selected, and is defined as:
          </p>
          <p className={styles.content}>
            A set of activities an organization carries out in the performance
            of a duty or in the production of a product that is so critical to
            the organization's success that its disruption would severely impact
            continued operations or success in meeting the organization's
            mission.
          </p>
          <p className={styles.content}>
            Organizations typically have a set of critical services that define
            their mission. The selection of a critical service for
            assessment—rather than assessing the organization as a whole—helps
            to scope the assessment and tie the results to the organization's
            mission.
          </p>
          <p className={styles.content}>
            Below are some examples of organizations and their typical critical
            services that might be selected as part of a CRR Assessment:
          </p>
          <div className={styles.content}>
            <ul>
              <li>
                banks and other financial institutions: clearing and settlement,
                mortgage application processing
              </li>
              <li>
                emergency services providers: processing 911 calls, dispatch
              </li>
              <li>
                electrical power plants: electricity generation, electricity
                distribution
              </li>
              <li>hospitals: clinical services, prescription management</li>
              <li>
                government agencies: court case management, benefit management
              </li>
              <li>
                manufacturing companies: machining operations, order processing
              </li>
              <li>airports: air traffic control, fuel management</li>
            </ul>
          </div>
          <div className={styles.header}>Practice Questions Structure</div>
          <p className={styles.content}>
            Each practice question contains a 3-point response scale to evaluate
            the degree to which the organization has implemented each practice.
            Each question has three possible answers: “Yes”, "No", and “NA”:
          </p>
          {/* screenshot here  */}
          <div className={styles.TextCenter}>
            <img
              className={styles.imageWidth}
              src={`${process.env.PUBLIC_URL}/images/crr_questions_answer.png`}
              alt="crr_ansere_img"
            />
          </div>
          <div className={styles.content}>
            <ul>
              <li>
                <b>Yes </b>– the organization fully performs the activity
                specified in the question.
              </li>

              <li>
                <b>No </b>– the organization does not perform the activity at
                all.
              </li>
              <li>
                <b>NA </b>– the activity is not applicable for the organization
              </li>
              <li>
                <b>References: </b> Selecting the References Icon will display
                the Options for Consideration associated with the particular
                question. The Options for Consideration are normally found in
                the report but are also made available to the facilitator while
                performing the assessment. These options outline general
                guidelines or activities and the associated sources that can be
                used to improve the organization’s cyber security practices and
                the resilience of the critical service assessed.
              </li>
            </ul>
          </div>
          <div className={styles.header}> Asset Types</div>
          <p className={styles.content}>
            The CRR divides assets into four categories: People, Information,
            Technology, and Facilities. Many questions require a separate answer
            for each of the four assets while other questions refer to all
            assets.
          </p>
          <p className={styles.content}>The four asset types are:</p>
          <div className={styles.content}>
            <ul>
              <li>
                <b>People </b>- the staff (both internal and external to the
                organization) such as people that support data centers or
                otherwise use information and communications technology to
                operate and monitor the service.
              </li>

              <li>
                <b>Information </b>- account information, technology asset
                configuration files, operational data, customer information and
                other information necessary for the delivery of the service.
              </li>
              <li>
                <b>Technology </b>– computers (hardware), software, control
                systems, or other technology including external information
                systems used by the organization to deliver the service.
              </li>
              <li>
                <b>Facilities </b>– offices buildings, data centers and other
                physical structures supporting the delivery of the service.
              </li>
            </ul>
          </div>
          <p className={styles.content}>See the example directly below.</p>
          <div className={styles.TextCenter}>
            <img
              className={styles.imageWidth}
              src={`${process.env.PUBLIC_URL}/images/crr_people.png`}
              alt="crr_people"
            />
            <p className={styles.imageTitle}>
                Figure 1: Questions Screen
            </p>
          </div>
          <p className={styles.content}>
            The CRR Assessment utilizes one standard set of Maturity Indicator
            Level (MIL) questions. The MIL questions examine the
            institutionalization of CRR practices within an organization.
            Institutionalization means that cybersecurity practices become a
            deeper, more lasting part of the organization because they are
            managed and supported in meaningful ways. When cybersecurity
            practices become more institutionalized—or “embedded”—managers can
            have more confidence in the practices’ predictability and
            reliability. The practices also become more likely to be sustained
            during times of disruption or stress to the organization.
          </p>
          <p className={styles.content}>
            A Maturity Indicator Level (MIL) is assigned to the each CRR domain.
            It represents a consolidated view of performance. The MIL scale
            itself uses six maturity levels, each with rigorous, defined
            components:
          </p>
          <div className={styles.header}>
            MIL0 Incomplete - MIL1 Performed - MIL2 Planned - MIL3 Managed -
            MIL4 Measured - MIL5 Defined
          </div>
          <div className={styles.header}>MIL0 Incomplete</div>
          <p className={styles.content}>
            All practices in the domain are not being fully performed as
            measured by responses to the relevant CRR questions in the domain.
          </p>

          <div className={styles.header}>MIL1 Performed</div>
          <p className={styles.content}>
            All practices that support the goals in a domain are being performed
            as measured by responses to the relevant CRR questions.
          </p>

          <div className={styles.header}>MIL2 Planned</div>
          <p className={styles.content}>
            All specific practices in the CRR domain are not only performed but
            are also supported by planning, policy, stakeholders, and relevant
            standards and guidelines.
          </p>

          <div className={styles.header}>MIL3 Managed</div>
          <p className={styles.content}>
            All practices in a domain are performed, planned, and have the basic
            governance infrastructure in place, are appropriately staffed,
            adequately funded and managed for risk.
          </p>

          <div className={styles.header}>MIL4 Measured</div>
          <p className={styles.content}>
            All practices in a domain are performed, planned, managed,
            monitored, and controlled. A measured process or practice is
            periodically evaluated for effectiveness, evaluated against its
            practice description and plan, and periodically reviewed with higher
            level management.
          </p>

          <div className={styles.header}>MIL5 Defined</div>
          <p className={styles.content}>
            All practices in a domain are performed, planned, managed, measured,
            and consistent across all constituencies within an organization who
            have a vested interest in the performance of the practice. At MIL5,
            a process or practice is defined by the organization and tailored by
            individual operating units and supported by improvement information
            that is collected by and shared among operating units for the
            overall benefit of the organization.
          </p>

          <div className={styles.header}>MIL Progression</div>
          <p className={styles.content}>
            In the above progression, an organization can only attain a given
            MIL if it has attained all lower MILs. In other words, an
            organization that fails to perform all of the cybersecurity
            practices at MIL1 in a domain would also fail to reach MIL2 in that
            domain, even if it would have satisfied all the requirements at
            MIL2.
          </p>
          <p className={styles.content}>
            The CRR scoring methodology is further described in section 4.1 of
            the CRR Assessment User Guide.
          </p>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default CRRTutorial;
