import { gql } from "@apollo/client";

export const GET_DELETE_DATA = gql`
  query {
    partners: organizations(where: { subtype: "Partner" }) {
      contact_id {
        name
        email
      }
      pg_id
      active_status
    }
    clients: organizations(where: { subtype: "Client" }) {
      contact_id {
        name
        email
      }
      partner_id {
        name
        email
      }
      pg_id
      active_status
    }
    users: individuals {
      pg_user_id
      active_status
      disable_transactional_mail_status
      pg_user_role
      partner_id {
        name
      }
      contact_id {
        email
      }
    }
  }
`;
export const ADMIN_GET_PARTNER_DELETE_DATA = gql`
 query getDeletePartner($where: JSON, $limit: Int, $sort: String){
    organizations(
      where: $where,
      limit: $limit,
      sort: $sort,
    ) {
      id
      pg_id
      contact_id {
        id
        name
        email
        created_at
      }
      partner_id {
        id
      }
      subtype
      active_status
    }
  }
`
export const ADMIN_GET_CLIENT_DELETE_DATA = gql`
 query getDeleteClient($where: JSON, $limit: Int, $sort: String){
    organizations(
      where: $where,
      limit: $limit,
      sort: $sort,
    ) {
      id
      pg_id
      contact_id{
        id
        name
        email
        created_at
      }
      partner_id{
        id
        name
      }
      active_status
    }
  }
`
export const ADMIN_GET_USER_DELETE_DATA = gql`
 query getDeleteUsers($where: JSON, $limit: Int, $sort: String){
    individuals(
      where: $where,
      limit: $limit,
      sort: $sort,
    ) {
    id
    first_name
    last_name
    pg_user_id
    created_at
    contact_id{
      id
      name
      email
      created_at
    }
    active_status
    partner_id{
      id
      name
    }
  }
 }
`