import { gql } from "@apollo/client";

export const CREATE_QUESTION_DOCUMENT = gql`
mutation createQuestionDocument(
    $flow_type:String!,
    $question_id:Int,
    $question_Text:String!,
    $practise:String!,
    $assessment_id:ID!,
    $document_id:[ID],
    $comment:String,
    $markedForReview:Boolean,
    $featureType:ENUM_QUESTIONDOCUMENTS_FEATURE_TYPE!
) {
createQuestionDocument(
 input: {
   data:{
    flow_type: $flow_type,
    question_id:$question_id
    question_Text:$question_Text
    practise:$practise
    assessment_id:$assessment_id
    document_id:$document_id
    comment:$comment
    marked_for_review:$markedForReview
    feature_type:$featureType
  }
 }) {
questionDocument{
 id
 comment
 marked_for_review
 document_id{
  id
  name
  url
 }
} 
}
}
`
export const CREATE_QUESTION_DOCUMENT_BY_ASS_ID = gql`
mutation createQuestionDocumentByAssId($assId:ID!,$document_id:[ID],$flow_type:String!,$featureType:ENUM_QUESTIONDOCUMENTS_FEATURE_TYPE!){
  createQuestionDocument(input:{data:{
    assessment_id:$assId
    document_id:$document_id
    question_Text:"Global"
    flow_type:$flow_type
    feature_type:$featureType
  }
  }){
    questionDocument{
      id
      question_id
      practise
      document_id{
        id
        name
        url
      }
      question_Text      
    }
  }
}
`
export const UPDATE_QUESTION_DOCUMENT = gql`
  mutation UpdateQuestionDocument($id:ID!,$document_id:[ID]!){
    updateQuestionDocument(input:{where:{id:$id}
     data:{
      document_id:$document_id
     }
    }){
      questionDocument{
        id
        document_id{
          id
          name
          url
        }
      }
    }
  }
`
export const UPDATE_QUESTION_DOCUMENT_COMMENT = gql`
mutation updateQuestionDocumentComment($id:ID!,$newcomment:String!){
  updateQuestionDocument(input:{where:{id:$id}
  data:{
   comment:$newcomment
  }
 }){
   questionDocument{
     id
     comment
   }
 }
}
`
export const UPDATE_QUESTION_DOCUMENT_MARKEDREVIEW = gql`
mutation updateQuestionDocumentComment($id:ID!,$newmarkedForReview:Boolean!){
  updateQuestionDocument(input:{where:{id:$id}
  data:{
    marked_for_review:$newmarkedForReview
  }
 }){
   questionDocument{
     id
     marked_for_review
   }
 }
}
`

export const DELETE_QUESTION_DOCUMENT = gql`
mutation deletedata($id:ID!){
  deleteQuestionDocument(input:{
    where:{id:$id}
   }){
    questionDocument{
      id
      flow_type 
      question_id
      question_Text
      document_id{
        name
        id
        url
      }
    }
  }
}
`
