import React, { useState, useEffect } from "react";
import styles from "./AssessmentForm.module.css";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Input from "../../../components/UI/Form/Input/Input";
import AutoCompleteDropDown from "../../../components/UI/Form/Autocomplete/Autocomplete";
import { CSET_URL } from "../../../config/index";
import * as routeConstant from "../../../common/RouteConstants";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import {
  SUCCESS,
  UPDATE,
  ASSESSMENT_TYPE_WARNING,
} from "../../../common/MessageConstants";
import Alert from "../../../components/UI/Alert/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DialogBox } from "../../../components/UI/DialogBox/DialogBox";
import { Typography, FormHelperText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { sentry_error_catch } from "../../../common/sentry_error_catch";

export interface FormProps {
  authToken: string;
  classname?: any;
  assessmentObj?: any;
  demographicsObj?: any;
  propsData?: any;
  currentAssessmentData?: any;
  handleChange?: any;
  propsFlowType?: any;
  formState?: any;
  assessmentTypeObj?: any;
}

interface AssetValueObj {
  DemographicsAssetId: number;
  assetValue: string;
}

export const AssessmentForm: React.FC<FormProps> = ({
  authToken,
  assessmentObj,
  propsData,
  handleChange,
  demographicsObj,
  propsFlowType,
  formState,
}: FormProps) => {
  const CSETAuthToken = authToken;
  const history = useNavigate();
  const [assetValue, setAssetValue] = useState<any>("");
  const [assetValueList, setAssetValueList] = useState<any>([]);
  const [assessmentName, setAssessmentName] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [facilityName, setFacilityName] = useState<string>("");
  const [formStates, setFormStates] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [isError, setIsError] = useState<boolean>(false);
  const getAllAssetValue = {
    options: assetValueList,
    getOptionLabel: (option: AssetValueObj) =>
      option.assetValue ? option.assetValue : "",
  };
  const [showBackdrop, setShowBackdrop] = useState(true);
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
  const dialogBoxMsg = ASSESSMENT_TYPE_WARNING;
  const headerObj = {
    "Content-Type": "application/json",
    Authorization: CSETAuthToken,
  };
  const is_link_assessment =  Boolean(sessionStorage.getItem("is_link_authenticated")) || false;
  useEffect(() => {
    getAssetValues();
  }, [demographicsObj, assessmentObj]);

  useEffect(() => {
    setFormStates(formState);
  }, [formState]);

  useEffect(() => {
    if (assessmentObj) {
      setAssessmentName(assessmentObj.assessmentName);
      setCity(assessmentObj.cityOrSiteName);
      setState(assessmentObj.stateProvRegion);
      setFacilityName(assessmentObj.facilityName);
    }
  }, [assessmentObj]);

  let counter = 0;
  const MAX_RETRIES = 2;

  const getAssetValues = async () => {
    try {
      const response = await fetch(`${CSET_URL}api/Demographics/AssetValues`, {
        method: "GET",
        headers: headerObj,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.length > 0) {
        setAssetValueList(data);
        setShowBackdrop(false);
        if (propsData?.flowType?.type !== undefined) {
          handleType(propsData?.flowType.type);
        }
        if (demographicsObj.assetValue) {
          const result = data.filter((obj: any) => {
            return obj.demographicsAssetId === demographicsObj.assetValue;
          });
          setAssetValue(result[0]);
        }
        if (assessmentObj.assetValue) {
          const result = data.filter((obj: any) => {
            return (
              obj.demographicsAssetId === parseInt(assessmentObj.assetValue)
            );
          });
          setAssetValue(result[0]);
        }
      } else {
        throw new Error("No data returned from API");
      }
    } catch (e) {
      const error: any = e;
      console.log("errrr", error.message);

      if (error.message.includes("NetworkError") && counter < MAX_RETRIES) {
        counter++;
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
        await getAssetValues();
      } else {
        const serverErrorMessage =
          "Something Went Wrong Please try Again Later !.";
        if(!is_link_assessment){
          history(routeConstant.CLIENT, { state: { serverErrorMessage } });
        }else{
          sentry_error_catch(error,setShowBackdrop,setFormStates)
        }
      }
    }
  };

  const formHandleChange = (event: any) => {
    handleAlertClose();
    if (event.target.name === "assessmentName") {
      setAssessmentName(event.target.value);
      assessmentObj["assessmentName"] = event.target.value;
      if (!event.target.value) {
        setIsError(true);
      } else {
        setIsError(false);
      }
    }

    if (event.target.name === "cityOrSiteName") {
      setCity(event.target.value);
      assessmentObj["cityOrSiteName"] = event.target.value;
    }

    if (event.target.name === "stateProvRegion") {
      setState(event.target.value);
      assessmentObj["stateProvRegion"] = event.target.value;
    }

    if (event.target.name === "FacilityName") {
      setFacilityName(event.target.value);
      assessmentObj["FacilityName"] = event.target.value;
    }
    handleChange(assessmentObj);
  };

  const handleAssetValueChange = (event: any, selectedValue: any) => {
    handleAlertClose();
    demographicsObj["assetValue"] = selectedValue
      ? selectedValue.demographicsAssetId
      : null;
    setAssetValue(selectedValue);
    handleChange(demographicsObj);
  };

  const handleType = async (event: any) => {
    handleAlertClose();
    propsFlowType = {
      type: event,
    };
    handleChange(propsFlowType);
  };
  const handleAlertClose = () => {
    setFormStates((formStateObj) => ({
      ...formStateObj,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleClose = () => {
    setShowDialogBox(false);
  };

  const handleOkay = () => {
    setShowDialogBox(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container className={styles.CompanyProfileForm}>
        <React.Fragment>
          {showBackdrop ? <SimpleBackdrop /> : null}
          <Grid container spacing={2}>
            <Grid item xs={12} className={styles.OtherOfficeWrap}>
              {formStates.isSuccess ? (
                <Alert
                  id="success"
                  severity="success"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleAlertClose}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {SUCCESS}
                </Alert>
              ) : null}
              {formStates.isUpdate ? (
                <Alert
                  id="update"
                  severity="success"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleAlertClose}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {UPDATE}
                </Alert>
              ) : null}
              {formStates.isFailed ? (
                <Alert
                  id="error"
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleAlertClose}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {formStates.errMessage}
                </Alert>
              ) : null}
            </Grid>
            <div>
              <DialogBox
                open={showDialogBox}
                handleOk={handleOkay}
                handleCancel={handleClose}
                buttonOk={"Yes"}
                buttonCancel={"No"}
                classes={{
                  root: styles.MainOfficeDialogRoot,
                  container: styles.MainOfficeDialogboxContainer,
                  paper: styles.MainOfficeDialogboxPaper,
                  scrollPaper: styles.MainOfficeScrollPaper,
                }}
              >
                <div className={styles.DialogBoxTitle}>
                  <Typography component="h1" variant="h1">
                    Warning
                  </Typography>
                </div>
                <div className={styles.DialogBoxContext}>
                  <p>{dialogBoxMsg}</p>
                </div>
              </DialogBox>
            </div>
            <Grid item xs={12}>
              <span className={styles.DetailsFormHead}>{"Details"}</span>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Input
                    required={true}
                    id="assessmentid"
                    name="assessmentName"
                    label="Assessment Name"
                    Autocomplete="off"
                    variant="outlined"
                    value={
                      assessmentName.indexOf("%") > -1
                        ? assessmentName.slice(0, assessmentName.indexOf("%"))
                        : assessmentName
                    }
                    onChange={formHandleChange}
                    error
                  />
                  {isError ? (
                    <FormHelperText
                      classes={{ root: styles.ErrorFormHelperText }}
                    >
                      Assessment name is required.
                    </FormHelperText>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Input
                    id="cityname"
                    name="cityOrSiteName"
                    label="City Or Site Name"
                    Autocomplete="off"
                    variant="outlined"
                    OutlinedTextFieldProps
                    value={city || ""}
                    onChange={formHandleChange}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Input
                    id="stateProvRegion"
                    name="stateProvRegion"
                    label="State/Province/Region"
                    variant="outlined"
                    value={state || ""}
                    onChange={formHandleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Input
                id="FacilityName"
                name="FacilityName"
                label="Facility Name"
                Autocomplete="off"
                variant="outlined"
                value={facilityName || ""}
                onChange={formHandleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <span className={styles.CompanyFormHead}>
                  {
                    "What is the gross value of the asset you are trying to protect?"
                  }
                </span>
                <AutoCompleteDropDown
                  {...getAllAssetValue}
                  id="assetValue"
                  value={assetValue}
                  name="Asset Value"
                  onChange={handleAssetValueChange}
                  label="Asset Value"
                  renderInput={(
                    params:
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").StandardTextFieldProps)
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").FilledTextFieldProps)
                      | (JSX.IntrinsicAttributes &
                          import("@mui/material").OutlinedTextFieldProps)
                  ) => (
                    <Input {...params} id="assetValue" label="Asset Value" />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography component="h2" variant="h2">
                {"Assessment Type"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component="h3" variant="h3">
                {propsData.assessmentName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {propsData.assessmentDescription}
            </Grid>
          </Grid>
        </React.Fragment>
      </Grid>
    </React.Fragment>
  );
};
export default AssessmentForm;
