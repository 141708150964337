import { gql } from "@apollo/client";

export const CreateCSETUser = gql`
mutation CreateCSETUserLoginDetails(
  $username: String
  $password: String
  $contact_id: ID
  $cset_user_id : String
) {
  createCsetUserDetail(
    input: {
      data: {
        cset_username: $username
        cset_password: $password
        contact: $contact_id
        cset_user_id : $cset_user_id
      }
    }
  ) {
    csetUserDetail {
      cset_username
      cset_password
      cset_user_id
      contact {
        id
      }
    }
  }
}
`;  

export const UpdateCSETUser = gql`
mutation($id: ID!, $cset_username: String, $cset_password: String) {
  updateCsetUserDetail(
    input: {
      where: { id: $id }
      data: { cset_username: $cset_username, cset_password: $cset_password }
    }
  ) {
    csetUserDetail {
      id
    }
  }
}

`; 
