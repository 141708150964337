import styles from "./RRATutorial.module.css";
import React, { FC, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import { Button } from "../../../components/UI/Form/Button/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import * as routeConstant from "../../../common/RouteConstants";

const RRATutorial: FC<any> = (props: any) => {
  const location = useLocation();
  const history = useNavigate();
  const { showBoundary } = useErrorBoundary();
  const [param, setParam] = useState<any>(
    location?.state[Object.keys(location?.state)[0]]
  );

  useEffect(() => {
    if (location.state) {
      setParam(location?.state[Object.keys(location?.state)[0]]);
    } else {
      history(routeConstant.ASSESSMENT_TYPE);
    }
  }, []);

  const handleNext = () => {
    try{
    history(routeConstant.ASSESSMENT_INFO, { state: { param } });
    }catch(err){
      showBoundary(err)
    }
  };
  const handleBack = () => {
    try{
    history(routeConstant.ASSESSMENT_TYPE, { state: { param } });
    }catch(err){
      showBoundary(err)
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container spacing={2}>
        <Grid item xs={12} className={styles.TutorialHeaders}>
          <Typography
            style={{ fontWeight: "bold" }}
            component="h5"
            variant="h1"
          >
            Ransomware Readiness Tutorial
          </Typography>
          <div className={styles.prevBtn}>
            <Button
              id="previous-button"
              variant="contained"
              color="primary"
              onClick={handleBack}
            >
              {"<< Previous"}
            </Button>
          </div>
          <div className={styles.prevBtn}>
            <Button
              id="next-button"
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              {"Next >>"}
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid>
        <Paper className={styles.paper}>
          <p
            className={styles.content}
            style={{ marginTop: "0px", paddingTop: "0px" }}
          >
            Ransomware is an ever-evolving form of malware designed to encrypt
            files on a device, rendering any files and the systems that rely on
            them unusable. Malicious actors then demand ransom in exchange for
            decryption. Ransomware actors often target and threaten to sell or
            leak exfiltrated data or authentication information if the ransom is
            not paid. In recent years, ransomware incidents have become
            increasingly prevalent among the Nation’s state, local, tribal, and
            territorial (SLTT) government entities and critical infrastructure
            organizations.
          </p>
          <p className={styles.content}>
            Malicious actors continue to adjust and evolve their ransomware
            tactics over time, and CISA analysts remain vigilant in maintaining
            awareness of ransomware attacks and associated tactics, techniques,
            and procedures across the country and around the world.
          </p>
          <p className={styles.content}>
            This Ransomware Readiness Assessment (RRA) will help you understand
            your cybersecurity posture with respect to the ever-evolving threat
            of ransomware. The RRA also provides a clear path for improvement,
            and contains an evolving progression of questions tiered by the
            categories of basic, intermediate, and advanced. This is intended to
            help an organization improve by focusing on the basics first, and
            then progressing by implementing practices through the intermediate
            and advanced categories.{" "}
          </p>
          <div className={styles.colorHeading}>
            Looking to learn more about this growing cyber threat?
          </div>
          <p className={styles.content}>
            The NEW Ransomware Guide is a great place to start. The Guide,
            released in September 2020, represents a joint effort between CISA
            and the Multi-State Information Sharing and Analysis Center
            (MS-ISAC). The joint Ransomware Guide includes industry best
            practices and a response checklist that can serve as a
            ransomware-specific addendum to organization cyber incident response
            plans.
          </p>
          <p className={styles.content}>
            In January 2021, CISA unveiled the Reduce the Risk of Ransomware
            Campaign to raise awareness and instigate actions to combat this
            ongoing and evolving threat. The campaign is a focused, coordinated
            and sustained effort to encourage public and private sector
            organizations to implement best practices, tools and resources that
            can help them mitigate ransomware risk.
          </p>
          <p className={styles.content}>
            For critical infrastructure owners and operators of industrial
            control systems, CISA published a Fact Sheet, Rising Ransomware
            Threat to Operational Technology Assets, that provides information
            on the rising risk of ransomware to ICS and recommended actions to
            reduce the risk of becoming a victim as well as severe business or
            functional degradation should they fall victim.
          </p>
          <p className={styles.content}>
            CISA also continues to publish cybersecurity alerts and tips, to
            include those specific to ransomware. Visit Ransomware Alerts and
            Tips | CISA and click subscribe.
          </p>
          <div className={styles.colorHeading}>
            Ransomware Readiness Assessment Composition: Goals and Practice
            Questions{" "}
          </div>
          <p className={styles.content}>
            This assessment represents one Domain that addresses Ransomware and
            is constructed with ten Goals and Practice Questions that address
            each Goal.
          </p>
          <p className={styles.content}>
            The Practice Questions are tiered as Basic, Intermediate, and
            Advanced. Each question contains an identifier structured as
            follows:
          </p>
          <p className={styles.content}>
            <b>Goal</b> [GG] : <b>Level</b> [B,I,A] . <b>Question Number</b>{" "}
            [Q##]
          </p>
          <p className={styles.content}>
            An example of the above structure is <b>DB:B.Q01</b>, which
            represents{" "}
            <b>Asset Management, Level “Intermediate,” Question Number 1.</b>
          </p>
          <p className={styles.content}>
            The blue oval beneath each identifier indicates the Basic,
            Intermediate, or Advanced tier for the question.
          </p>
          <div className={styles.TextCenter}>
            <img
              src={`${process.env.PUBLIC_URL}/images/rra_question.png`}
              alt="rra_question"
              className={styles.bigImg}
            />
          </div>
          <p className={styles.content}>
            The RRA practices and tiers (basic, intermediate, and advanced) are
            designed to:
          </p>
          <ul>
            <li>
              assist an organization to understand its current state of
              readiness to address the threat of ransomware
            </li>
            <li>
              to assist the organization with implementing a focused path for
              improvement.
            </li>
          </ul>
          <p className={styles.content}>
            <b>Implementation approach:</b>
          </p>
          <ul>
            <li>
              Organizations that are unfamiliar with ransomware and need to
              begin basic cyber hygiene safeguards against ransomware threats
              should focus on implementing the basic tier of practices.
            </li>
            <li>
              After completing the basic tier of the improvement effort,
              organizations looking to further improve their risk posture
              against ransomware should implement the intermediate tier of
              practices.
            </li>
            <li>
              Organizations that have already adopted basic and intermediate
              level RRA practices and are looking to improve their response to
              ransomware threats should focus on adopting the practices in the
              Advanced Tier.
            </li>
          </ul>
          <p className={styles.content}>
            This suggested path to improvement can always be adapted by the
            organization to suit its own needs.
          </p>

          <div className={styles.TextCenter}>
            <img
              src={`${process.env.PUBLIC_URL}/images/yes_no.png`}
              alt="yes_no"
              className={styles.image}
            />
          </div>
          <p className={styles.content}>
            <b>Yes</b> – the organization fully performs the activity specified
            in the question.
          </p>
          <div className={styles.TextCenter}>
            <img
              src={`${process.env.PUBLIC_URL}/images/yes.png`}
              alt="yes"
              className={styles.image}
            />
          </div>
          <p className={styles.content}>
            <b>No</b> – the organization does not perform the activity
            completely.
          </p>
          <div className={styles.TextCenter}>
            <img
              src={`${process.env.PUBLIC_URL}/images/no.png`}
              alt="no"
              className={styles.image}
            />
          </div>
          <h2>RA360 Assessment Components in CSET®</h2>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "50%" }}
              src={`${process.env.PUBLIC_URL}/images/rra_assessment_component.png`}
              alt="assessment_Components"
            />
          </div>

          <h3>Supplemental Guidance</h3>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "3%" }}
              src={`${process.env.PUBLIC_URL}/images/rra_supplementary_guidances.png`}
              alt="supplementary_guidance"
            />
          </div>
          <p className={styles.content}>
            Users can refer to the supporting authorities and additional
            guidance provided under Supplemental Guidance.
          </p>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/rra_supplemental_guidances.png`}
              alt="supplemental_guidance"
            />
            <p className={styles.imageTitle}>Figure 1: Questions Screen</p>
          </div>

          <h3>Comments</h3>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "50%" }}
              src={`${process.env.PUBLIC_URL}/images/comments.png`}
              alt="comments"
            />
          </div>
          <p className={styles.content}>
            The comments function allows the assessor to record non-structured
            comments for each question during the assessment. In contrast, the
            Observations function (also useful for commenting) is more rigid and
            contains required fields.{" "}
          </p>
          <div className={styles.imageTitle}>
            <img
              width={500}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/comm.png`}
              alt="comments"
            />
            <p className={styles.imageTitle}>Figure 1.1: Comments Screen</p>
          </div>
          <h3>Artifacts/Documents</h3>
          <div className={styles.imageTitle}>
            <img
              width={1000}
              style={{ maxWidth: "50%" }}
              src={`${process.env.PUBLIC_URL}/images/documents.png`}
              alt="documents"
            />
          </div>
          <p className={styles.content}>
            Assessors can use the Artifacts/Documents function to attach
            relevant evidence to each practice question.{" "}
          </p>
          <div className={styles.imageTitle}>
            <img
              width={500}
              style={{ maxWidth: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/docs.png`}
              alt="docs"
            />
            <p className={styles.imageTitle}>Figure 1.2: Documents Screen</p>
          </div>
          <p className={styles.content}>
            Practice Questions are presented in the following format:
          </p>
          <div className={styles.TextCenter}>
            <img
              src={`${process.env.PUBLIC_URL}/images/rra_questions.png`}
              alt="rra_questions"
              className={styles.image}
            />
            <p className={styles.imageTitle}>Figure 1.3: Questions Screen</p>
          </div>
          <p className={styles.content}>
            Each practice question contains a yes/no response scale to evaluate
            whether the organization has implemented each practice.
          </p>
          <div className={styles.header}>RRA Reports </div>

          <p className={styles.content}>
            This section includes the Executive Summary Report, Deficiency
            Report and Comment and Review Report of RRA. The RRA Site Reports,
            which contain the findings of the assessment, are the predominant
            document of a RRA assessment. In order to download all reports,
            Download all option is available.
          </p>
          <div className={styles.TextCenter}>
            <img
              className={styles.imageWidth}
              src={process.env.PUBLIC_URL + "/images/rra_reports.png"}
              alt="reports"
            />
            <p className={styles.imageTitle}>Figure 2: Reports Screen</p>
          </div>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default RRATutorial;
