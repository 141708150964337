import React, { useState, useEffect } from "react";
import styles from "./ExecutiveSummary.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Button } from "../../../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router-dom";
import { CSET_URL } from "../../../../../config/index";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLazyQuery } from "@apollo/client";
import { GET_ORGANIZATION } from "../../../../../graphql/queries/Organization";
import { HorizontalBar, Doughnut } from "react-chartjs-2";
import { saveAs } from "file-saver";
import * as docx from "docx";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import { jsPDF } from "jspdf";
import GetAppIcon from "@mui/icons-material/GetApp";
import moment from "moment";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../../components/UI/Alert/Alert";
import * as msgConstants from "../../../../../common/MessageConstants";
import { sentry_error_catch } from "../../../../../common/sentry_error_catch";

export interface ExecutiveProps {
  from?: string;
  props?: any;
  data?: any;
  handleChange?: any;
  partnerLogo?: any;
  clientLogo?: any;
  clientImageDimension?: any;
  partnerImageDimension?: any;
}

export const ExecutiveSummary: React.FC<ExecutiveProps> = ({
  from,
  data,
  props,
  handleChange,
  partnerLogo,
  clientLogo,
  clientImageDimension,
  partnerImageDimension,
}: ExecutiveProps) => {
  const componentRef = React.useRef<HTMLHeadingElement>(null);
  const chartRef1 = React.useRef<any>(null);
  const chartRef2 = React.useRef<any>(null);
  const chartRef3 = React.useRef<any>(null);
  const preparedForBy = "preparedFor/By";
  const envVariables = process.env;
  const serverUrl = envVariables.REACT_APP_SERVER_URL;
  const history = useNavigate();
  const location = useLocation();
  const [topCategories, setTopCategories] = useState<any>([]);
  const [topQuestions, setTopQuestions] = useState<any>([]);
  const [partnerData, setPartnerData] = useState<any>({});
  const [clientData, setClientData] = useState<any>({});
  const [
    summaryPercentComplianceChartData,
    setSummaryPercentComplianceChartData,
  ] = useState<any>({});
  const [standardSummaryPieData, setStandardSummaryPieData] = useState<any>({});
  const [standardBarChartData, setStandardBarChartData] = useState<any>({});
  const [standardsResultsByCategory, setStandardsResultsByCategory] =
    useState<any>({});
  const authToken = sessionStorage.getItem("ra_token") || "";
  const [param, setParam] = useState<any>(
    location.state[Object.keys(location.state)[0]]
  );
  const [loading, setLoading] = useState(false);
  const [bannerImage, setBannerImage] = useState<any>("");
  const [cpaImage, setCPAImage] = useState<any>("");
  const [topQuestionsList, setTopQuestionsList] = useState<any>([]);
  const [partnerImage, setPartnerImage] = useState<any>("");
  const [cpaImageDimensions, setCPAImageDimensions] = useState<any>("");
  const [partnerImageDimensions, setPartnerImageDimensions] = useState<any>("");
  const [checkQueryStatus, setCheckQueryStatus] = useState<any>(false);
  const [defaultCPAImage, setDefaultCPAImage] = useState<any>("");
  const [waitforTopQuestions, setWaitforTopQuestions] =
    useState<boolean>(false);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  useEffect(() => {
    const controller = new AbortController();
    if (props) {
      setParam(props);
    } else {
      setParam(location.state[Object.keys(location.state)[0]]);
    }
    getDataforExecutiveSummaryReport();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (topQuestions.length > 0) {
      const temp5quest = topQuestions.map((q: any) => {
        q.question = q.question.replace(/<\s*\/?br\s*[\/]?>/gi, "\n");
        return q;
      });
      setTopQuestionsList(temp5quest);
      setWaitforTopQuestions(true);
    }
    return () => controller.abort();
  }, [topQuestions.length]);

  useEffect(() => {
    const controller = new AbortController();
    GetOrganizationDataOfPartner({
      variables: {
        where: {
          contact_id: param?.clientInfo.partnerId,
        },
      },
    });
    GetOrganizationDataOfClient({
      variables: {
        where: {
          id: param?.clientInfo.clientOrgId,
          subtype: "Client",
        },
      },
    });
    return () => controller.abort();
  }, [param]);

  const Doughnutoptions = {
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18,
        fontColor: "black",
      },
    },
  };

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const options = {
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18,
        fontColor: "black",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            fontStyle: "bold",
            fontSize: 18,
            fontColor: "black",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontStyle: "bold",
            fontSize: 18,
            fontColor: "black",
          },
        },
      ],
    },
  };

  const getDataforExecutiveSummaryReport = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/analysis/ComponentTypes`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {})
        .catch((err:any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });

      await fetch(`${CSET_URL}api/analysis/Dashboard`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((chartDataResponse) => {
          setSummaryPercentComplianceChartData({
            labels: chartDataResponse.overallBars.labels,
            datasets: [
              {
                label: "SUMMARY PERCENT COMPLIANCE",
                data: chartDataResponse.overallBars.data,
                fill: true,
                backgroundColor: "rgb(11,71,133)",
                borderColor: "rgb(75,192,192)",
              },
            ],
          });
        })
        .catch((err:any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });
      await fetch(`${CSET_URL}api/analysis/StandardsSummary`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setStandardBarChartData({
            labels: data.labels,
            datasets: data.dataSets,
            colors: data.colors,
          });
          if (data.data.length > 0) {
            setStandardSummaryPieData({
              labels: data.labels,
              datasets: [
                {
                  data: data.data,
                  backgroundColor: data.colors,
                  borderColor: data.colors,
                  borderWidth: 1,
                },
              ],
            });
          }
        })
        .catch((err:any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });

      await fetch(`${CSET_URL}api/analysis/StandardsResultsByCategory`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setStandardsResultsByCategory({
            labels: data.labels,
            datasets: data.dataSets,
            colors: data.colors,
          });
        })
        .catch((err:any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });

      await fetch(`${CSET_URL}api/reports/executive`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          setTopCategories(data.top5Categories);
          setTopQuestions(data.top5Questions);
          setCheckQueryStatus(true);
        })
        .catch((err:any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });
    } catch (err:any) {
      sentry_error_catch(err,setLoading,setFormState)
    }
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleCancel = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: msgConstants.ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const [GetOrganizationDataOfPartner] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.partnerId === element.contact_id.id) {
          setPartnerData(element);
        }
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err) => {
      handleCancel(err);
    },
  });
  const [GetOrganizationDataOfClient] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err) => {
      handleCancel(err);
    },
  });
  const getBannerImages = () => {
    fetch(`${process.env.PUBLIC_URL}/images/ExecutiveSummary.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setBannerImage(base64data);
        };
      })
      .catch((error) => {
        console.log("__Error", error);
        Sentry.captureException(error)
      });
    fetch(`${process.env.PUBLIC_URL}/images/blank_img.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setDefaultCPAImage(base64data);
        };
      })
      .catch((error) => {
        console.log("__Error", error);
        Sentry.captureException(error)
      });
    if (from === "result") {
      setPartnerImage(partnerLogo);
      setCPAImage(clientLogo);
      setCPAImageDimensions(clientImageDimension);
      setPartnerImageDimensions(partnerImageDimension);
    } else {
      if (clientData.logo && clientData.logo[0]) {
        const clientLogoUrl = clientData.logo[0].url.slice(1);
        fetch(`${serverUrl}${clientLogoUrl}`, {
          method: "GET",
          headers: headerObj,
        })
          .then((response) => response.blob())
          .then((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function (theFile: any) {
              const base64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setCPAImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };
              setCPAImage(base64data);
            };
          })
          .catch((error) => {
            console.log("__Error", error);
            Sentry.captureException(error)
          });
      }

      if (partnerData.logo && partnerData.logo[0]) {
        const partnerLogoUrl = partnerData.logo[0].url.slice(1);
        fetch(`${serverUrl}${partnerLogoUrl}`, {
          method: "GET",
        })
          .then((response) => response.blob())
          .then((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function (theFile: any) {
              const ClientLogobase64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setPartnerImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };
              setPartnerImage(ClientLogobase64data);
            };
          })
          .catch((error) => {
            console.log("__Error", error);
            Sentry.captureException(error)
          });
      }
    }
  };

  useEffect(() => {
    if (from && from === "result" && checkQueryStatus && ((topQuestions.length <= 0) || waitforTopQuestions)) {
        setTimeout(() => {
          generate();
        }, 200);
      } 
    
  }, [checkQueryStatus, waitforTopQuestions]);
  useEffect(() => {
    getBannerImages();
  }, [partnerData, clientData]);

  const handleBack = () => {
    history(routeConstant.REPORTS, { state: { param } });
  };

  let count = 0;
  const incrCounter = () => {
    count = count + 1;
    return count;
  };

  const createRulesList1 = (
    contents: any[],
    key: string,
    ref: string
  ): docx.Paragraph[] => {
    return contents.map(
      (contents) =>
        new docx.Paragraph({
          text: `${incrCounter()}  ${contents[key]}`,
          style: "customFontCng",
          spacing: {
            after: 200,
          },
        })
    );
  };
  const createRulesList2 = (
    contents: any[],
    key: string,
    ref: string
  ): docx.Paragraph[] => {
    return contents.map(
      (contents) =>
        new docx.Paragraph({
          text: `•   ${contents[key]}`,
          style: "customFontCng",
          spacing: {
            after: 200,
          },
        })
    );
  };

  const fitImg = (targetHeight: any, originalSize: any) => {
    const originalAspectRatio = originalSize.width / originalSize.height;
    let width: number;
    let height: number;
    if (!originalSize) {
      width = targetHeight;
    } else {
      height = targetHeight;
      width = height * originalAspectRatio;
    }
    return width;
  };

  const generate = async () => {
    console.log("Generating Executive Summary");
    setLoading(true);
    const doc = new docx.Document({
      numbering: {
        config: [
          {
            reference: "top_categories",
            levels: [
              {
                level: 0,
                format: docx.LevelFormat.DECIMAL,
                text: "%1",
                alignment: docx.AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: 720, hanging: 260 },
                  },
                },
              },
            ],
          },
          {
            reference: "questions",
            levels: [
              {
                level: 0,
                format: docx.LevelFormat.DECIMAL,
                text: "%1",
                alignment: docx.AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: 720, hanging: 260 },
                  },
                },
              },
            ],
          },
        ],
      },
      styles: {
        default: {
          heading1: {
            run: {
              font: "Helvetica",
              size: 36,
              bold: true,
              color: "#4b0079",
            },
            paragraph: {
              alignment: docx.AlignmentType.LEFT,
              spacing: { line: 340 },
            },
          },
          heading2: {
            run: {
              font: "Helvetica",
              size: 30,
              bold: true,
              color: "#0b4785",
            },
            paragraph: {
              spacing: { line: 340 },
            },
          },
          heading3: {
            run: {
              font: "Helvetica",
              size: 20,
            },
            paragraph: {
              spacing: { line: 276 },
            },
          },
          heading4: {
            run: {
              font: "Helvetica",
              size: 20,
            },
            paragraph: {
              alignment: docx.AlignmentType.JUSTIFIED,
            },
          },
        },
        paragraphStyles: [
          {
            id: "normalPara",
            name: "Normal Para",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              font: "Helvetica",
              size: 20,
            },
            paragraph: {
              spacing: {
                line: 276,
                before: 20 * 72 * 0.1,
                after: 20 * 72 * 0.05,
              },
              rightTabStop: docx.TabStopPosition.MAX,
              leftTabStop: 453.543307087,
            },
          },
          {
            id: "mainHeading",
            name: "Normal Para2",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              font: "Helvetica",
              size: 80,
              color: "#4B0079",
              bold: true,
            },
          },
          {
            id: "date",
            name: "Aside",
            basedOn: "Normal",
            next: "Normal",
            run: {
              font: "Helvetica",
              color: "#4B0079",
              size: 40,
              bold: true,
            },
          },
          {
            id: "bulletText",
            name: "Normal para",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              font: "Helvetica",
              size: 20,
            },
          },
          {
            id: "customFontCng",
            quickFormat: true,
            run: {
              font: "Helvetica",
              size: 20,
            },
          },
          {
            id: "miniHeader",
            name: "Normal para",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,

            run: {
              font: "Helvetica",
              size: 24,
              bold: true,
            },
          },
          {
            id: "TableHeading",
            name: "Normal Para2",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,

            run: {
              font: "Helvetica",
              size: 20,
              color: "#4B0079",
              bold: true,
            },
          },
          {
            id: "BoldPara",
            name: "Normal Para3",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,

            run: {
              font: "Helvetica",
              size: 20,
              bold: true,
            },
          },
          {
            id: preparedForBy,
            name: "Aside",
            basedOn: "Normal",
            next: "Normal",
            run: {
              font: "Helvetica",
              color: "#4B0079",
              size: 20,
              bold: true,
            },
          },
          {
            id: "clientPartner",
            name: "Aside",
            basedOn: "Normal",
            next: "Normal",
            run: {
              font: "Helvetica",
              size: 20,
            },
          },
        ],
      },
    });

    const imageBase64Data1 = chartRef1.current.chartInstance.toBase64Image();
    const imageBase64Data2 = chartRef2.current.chartInstance.toBase64Image();
    const imageBase64Data3 = chartRef3.current.chartInstance.toBase64Image();
    const firstchart = imageBase64Data1.split(",")[1];
    const secondchart = imageBase64Data2.split(",")[1];
    const thirdchart = imageBase64Data3.split(",")[1];
    const exeSummaryLogo = bannerImage ? bannerImage.split(",")[1] : "";
    const defaultcpaLogo = defaultCPAImage ? defaultCPAImage.split(",")[1] : "";
    const cpaLogo = cpaImage ? cpaImage.split(",")[1] : defaultcpaLogo;
    const msplogo = partnerImage ? partnerImage.split(",")[1] : defaultcpaLogo;

    const image1 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(firstchart), (c) => c.charCodeAt(0)),
      600,
      300
    );
    const image2 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(secondchart), (c) => c.charCodeAt(0)),
      500,
      250
    );
    const image3 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(thirdchart), (c) => c.charCodeAt(0)),
      600,
      350
    );

    const image5 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(exeSummaryLogo), (c) => c.charCodeAt(0)),
      600,
      350
    );

    const image6 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(cpaLogo), (c) => c.charCodeAt(0)),
      fitImg(50, cpaImageDimensions),
      50
    );

    const image7 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
      fitImg(50, partnerImageDimensions),
      50
    );

    const footerLogo = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
      fitImg(50, partnerImageDimensions),
      50,
      {
        floating: {
          horizontalPosition: {
            align: docx.HorizontalPositionAlign.RIGHT,
          },
          verticalPosition: {
            align: docx.VerticalPositionAlign.BOTTOM,
          },
        },
      }
    );
    const bannertable = new docx.Table({
      rows: [
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  alignment: docx.AlignmentType.LEFT,
                  heading: docx.HeadingLevel.HEADING_1,
                  style: preparedForBy,
                  children: [
                    new docx.TextRun({
                      text: "Prepared for",
                      bold: true,
                    }),
                  ],
                }),
                new docx.Paragraph({
                  text: "                                               ",
                }),

                new docx.Paragraph(image6),
                new docx.Paragraph({
                  text: "                                               ",
                }),
                new docx.Paragraph({
                  text: "                                               ",
                }),
                new docx.Paragraph({
                  text: param?.clientInfo.name,
                  style: "clientPartner",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.2),
                right: docx.convertInchesToTwip(1.8),
              },
              columnSpan: 5,
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },

                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  alignment: docx.AlignmentType.LEFT,
                  heading: docx.HeadingLevel.HEADING_1,
                  style: preparedForBy,
                  children: [
                    new docx.TextRun({
                      text: "Prepared By",
                      bold: true,
                    }),
                  ],
                }),
                new docx.Paragraph({
                  text: "                                               ",
                }),
                new docx.Paragraph(image7),
                new docx.Paragraph({
                  text: "",
                }),
                new docx.Paragraph({
                  text: partnerData?.contact_id?.name,
                  style: "clientPartner",
                }),
                new docx.Paragraph({
                  text: "",
                  style: "clientPartner",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.5),
                right: docx.convertInchesToTwip(2),
              },
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              columnSpan: 5,
              shading: {
                fill: "ffffff",
                color: "auto",
              },
            }),
          ],
        }),
      ],
    });

    doc.addSection({
      children: [
        new docx.Paragraph(image5),
        new docx.Paragraph({
          text: "Executive Summary Report",
          style: "mainHeading",
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,

          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: moment().format("DD MMMM YYYY"),
          heading: docx.HeadingLevel.HEADING_1,
          style: "date",
          alignment: docx.AlignmentType.LEFT,

          spacing: {
            after: 400,
          },
        }),

        bannertable,
      ],
    });

    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: msgConstants.EXECUTIVE_SUMMARY,
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,

          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: `This report was prepared as an account of work sponsored by an agency of the U.S. Government. 
            Neither the U.S. Government nor any agency thereof, nor any employee, makes any warranty, 
            expressed or implied, or assumes any legal liability or responsibility for any third-party's use, or
            the results of such use, or any information, apparatus, product, or process disclosed in this 
            publication, or represents that its use by such third party would not infringe privately owned rights. `,
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph(image1),
        new docx.Paragraph({
          text: " ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 400,
          },
        }),
      ],
    });
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Evaluation Against Selected Standards and Question Sets",
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 300,
          },
        }),
        new docx.Paragraph({
          text: "STANDARDS SUMMARY",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 300,
          },
        }),
        new docx.Paragraph(image2),
        new docx.Paragraph({
          text: "",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 300,
          },
        }),
        new docx.Paragraph({
          text: "STANDARD OR QUESTION SET",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 300,
          },
        }),
        new docx.Paragraph(image3),
      ],
    });

    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Areas of Concern Top Subjects and Questions",
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          contextualSpacing: true,
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: `The top subject areas and questions identify those areas where attention will either provide 
            the most immediate impact or protect against the greatest vulnerabilities. `,
          heading: docx.HeadingLevel.HEADING_3,
          alignment: docx.AlignmentType.LEFT,
          contextualSpacing: true,
          spacing: {
            after: 200,
          },
          tabStops: [
            {
              type: docx.TabStopType.LEFT,
              position: docx.TabStopPosition.MAX,
            },
          ],
        }),
        new docx.Paragraph({
          text: "Top Categories of Concern",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),

        ...createRulesList1(
          topCategories,
          "question_Group_Heading",
          "top_categories"
        ),

        new docx.Paragraph({
          text: topQuestionsList.length > 0 ? "Top Questions of Concern" : "",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),

        ...createRulesList2(topQuestionsList, "question", "questions"),
      ],
    });

    docx.Packer.toBlob(doc)
      .then(async (blob: any) => {
        if (from && from === "result") {
          await Promise.resolve()
            .then(() => {
              setLoading(false);
              data = blob;
              data["name"] = "Executive_Summary_Report.docx";
              handleChange(data);
            })
            .catch((err) => {
              console.log(err);
              Sentry.captureException(err)
            });
        } else {
          await Promise.resolve(saveAs(blob, "Executive_Summary_Report.docx"))
            .then(() => {
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              Sentry.captureException(err)
            });
        }
      })
      .catch((err: any) => {
        console.log(err);
        Sentry.captureException(err)
      });
  };

  const startLoader = async () => {
    setLoading(true);
    setTimeout(function () {
      generatePdf();
    }, 1000);
  };

  const generatePdf = async () => {
    try {
      setLoading(true);
      const pdf: any = new jsPDF();
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(12);
      const imageBase64Data1 = chartRef1.current.chartInstance.toBase64Image();
      const imageBase64Data2 = chartRef2.current.chartInstance.toBase64Image();
      const imageBase64Data3 = chartRef3.current.chartInstance.toBase64Image();
      //cover page
      pdf.addImage(bannerImage, "JPEG", 10, 10, 190, 125);
      pdf.setDrawColor(0);
      pdf.setTextColor(75, 0, 121);
      pdf.setFontSize(40);
      pdf.setFont("helvetica", "bold");
      pdf.text(msgConstants.EXECUTIVE_SUMMARY, 20, 150);
      pdf.text("Report", 20, 165);
      pdf.setFontSize(20);
      const date = moment().toDate();
      const formatedDate = moment(date).format("DD MMMM YYYY");
      pdf.text(formatedDate, 20, 180);
      pdf.setFontSize(10);
      pdf.text("Prepared For", 20, 200);
      pdf.text("Prepared By", 110, 200);
      pdf.setDrawColor(75, 0, 121);
      pdf.setLineWidth(0.75);
      pdf.line(100, 200, 100, 240);
      if (cpaImage !== undefined) {
        pdf.addImage(
          cpaImage,
          "JPEG",
          20,
          205,
          fitImg(18, cpaImageDimensions),
          18
        );
      }
      if (partnerImage !== undefined) {
        pdf.addImage(
          partnerImage,
          "JPEG",
          107,
          205,
          fitImg(18, partnerImageDimensions),
          18
        );
      }
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      pdf.text(param.clientInfo.name, 20, 235);
      pdf.text(partnerData.contact_id.name, 110, 235);
      pdf.text("", 110, 240);

      // first page
      pdf.addPage("a4", "p");
      //header
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.setFontSize(18);
      pdf.text(msgConstants.EXECUTIVE_SUMMARY, 10, 30);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0); //paragraph black color
      pdf.setFontSize(10);
      const description = pdf.splitTextToSize(
        `This report was prepared as an account of work sponsored by an agency of the U.S. Government. Neither 
        the U.S. Government nor any agency thereof, nor any employee, makes any warranty, expressed or implied, 
        or assumes any legal liability or responsibility for any third-party's use, or the results of such use, 
        or any information, apparatus, product, or process disclosed in this publication, or represents that its 
        use by such third party would not infringe privately owned rights. `,
        190
      );
      pdf.text(description, 10, 40);
      const imgData1 = imageBase64Data1;
      pdf.addImage(imgData1, "JPEG", 15, 70, 190, 0);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121);
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133); //subheading orange color
      //footer
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      // second page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121);
      pdf.text(
        "Evaluation Against Selected Standards and Question Sets",
        10,
        30
      );
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133); //subheading orange color
      pdf.text("STANDARDS SUMMARY", 10, 40);
      const imgData2 = imageBase64Data2;
      pdf.addImage(imgData2, "JPEG", 15, 50, 180, 0);
      pdf.text("STANDARD OR QUESTION SET", 10, 160);
      const imgData3 = imageBase64Data3;
      pdf.addImage(imgData3, "JPEG", 15, 170, 180, 0);
      //footer
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);

      // third page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121);
      pdf.text("Areas of Concern Top Subjects and Questions", 10, 30);
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0); //paragraph black color
      const topSubjects = pdf.splitTextToSize(
        `The top subject areas and questions identify those areas where attention will either provide the most 
        immediate impact or protect against the greatest vulnerabilities. `,
        180
      );
      pdf.text(topSubjects, 10, 40);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133); //subheading orange color
      pdf.text("Top Categories of Concern", 10, 60);
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0); //paragraph black color
      let updateY = 70;
      let count = 1;
      topCategories.forEach((content: any) => {
        const key = content["question_Group_Heading"];
        pdf.text(`${count}       ${key}`, 10, updateY);
        updateY = updateY + 10;
        count = count + 1;
      });
      if(topQuestionsList.length > 0){
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133); //subheading orange color
      pdf.text("Top Questions of Concern", 10, 130);
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0); //paragraph black color
      let updateQuesY = 140;

      topQuestionsList.forEach((content: any) => {
        const key = content["question"];
        const sp1 = pdf.splitTextToSize("•       " + key, 180);
        let countq = 0;
        sp1.forEach((quest: any) => {
          if (updateQuesY >= 270) {
            pdf.addPage("a4", "p");
            updateQuesY = 30;
          }
          const x = countq ? 18 : 10;
          pdf.text(quest, x, updateQuesY);
          updateQuesY = updateQuesY + 10;
          countq = countq + 1;
        });
      });
     }
      //header and footer
      const pageCount = pdf.internal.getNumberOfPages();
      for (let i = 0; i <= pageCount; i++) {
        if (i > 1) {
          pdf.setPage(i);
          pdf.setFont("helvetica", "normal");
          pdf.setFontSize(10);
          pdf.setTextColor(0, 0, 0); //paragraph black color
          pdf.text(
            `Page | ${pdf.internal.getCurrentPageInfo().pageNumber - 1}`,
            10,
            290
          );
          const width = pdf.internal.pageSize.getWidth();
          const height = pdf.internal.pageSize.getHeight();
          if (partnerImage !== undefined) {
            pdf.addImage(
              partnerImage,
              "JPEG",
              180,
              270,
              width / 10,
              height / 14
            );
          }
        }
      }

      await Promise.resolve(pdf.save("Executive_Summary_Report.pdf"))
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
        });
    } catch (err){
      setLoading(false);
      console.log("ERR");
      Sentry.captureException(err)
    }
  };

  return (

      <div>
        <CssBaseline />
        <Grid container ref={componentRef}>
          <Grid item md={12}>
            <Typography component="h5" variant="h1">
              {msgConstants.EXECUTIVE_SUMMARY}
            </Typography>
            <Grid item xs={12} sm={6} className={styles.FooterActions}>
              <div className={styles.prevBtn}>
                <Button
                  id="previous-button1"
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                >
                  {"Previous"}
                </Button>
                <div className={styles.borderButton}>
                  <Button
                    id="doc-generate-button1"
                    variant="contained"
                    color="primary"
                    onClick={() => generate()}
                  >
                    <GetAppIcon className={styles.EditIcon} />
                    &nbsp; Doc
                  </Button>
                  <Button
                    id="pdf-generate-button1"
                    variant="contained"
                    color="primary"
                    onClick={() => startLoader()}
                  >
                    <GetAppIcon className={styles.EditIcon} />
                    &nbsp; PDF
                  </Button>
                </div>
              </div>
            </Grid>
            {formState.isFailed ? (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {formState.errMessage}
              </Alert>
            ) : null}
            <Paper className={styles.paper}>
              {loading ? <SimpleBackdrop /> : null}
              <Grid container>
                <Grid>
                  <p>
                    This report was prepared as an account of work sponsored by
                    an agency of the U.S. Government. Neither the U.S.
                    Government nor any agency thereof, nor any employee, makes
                    any warranty, expressed or implied, or assumes any legal
                    liability or responsibility for any third-party's use, or
                    the results of such use, or any information, apparatus,
                    product, or process disclosed in this publication, or
                    represents that its use by such third party would not
                    infringe privately owned rights.
                  </p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={12}
                  sm={12}
                  className={styles.FooterActions}
                >
                  <div>
                    <HorizontalBar
                      data={summaryPercentComplianceChartData}
                      ref={chartRef1}
                      options={options}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={12}
                  sm={12}
                  className={styles.FooterActions}
                >
                  <hr className={styles.ruler} />
                </Grid>
                <Grid item xs={6} md={12} sm={12} className={styles.gridSection}>
                  <h3>
                    Evaluation Against Selected Standards and Question Sets
                  </h3>

                  <h4>STANDARDS SUMMARY</h4>
                  {standardSummaryPieData?.datasets?.length > 0 ? (
                    <Paper className={styles.paper}>
                      <Doughnut
                        data={standardSummaryPieData}
                        ref={chartRef2}
                        options={Doughnutoptions}
                      />
                    </Paper>
                  ) : (
                    <Paper className={styles.paper}>
                      <HorizontalBar
                        data={standardBarChartData}
                        ref={chartRef2}
                        options={options}
                      />
                    </Paper>
                  )}
                </Grid>
                <Grid item xs={6} md={12} sm={12} className={styles.gridSection}>
                  <br />
                  <h4>STANDARD OR QUESTION SET</h4>
                  <HorizontalBar
                    data={standardsResultsByCategory}
                    ref={chartRef3}
                    options={options}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={12}
                  sm={12}
                  className={styles.FooterActions}
                >
                  <hr className={styles.ruler} />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={12}
                  sm={12}
                  className={styles.FooterActions}
                >
                  <h3> Areas of Concern Top Subjects and Questions </h3>
                  <p>
                    The top subject areas and questions identify those areas
                    where attention will either provide the most immediate
                    impact or protect against the greatest vulnerabilities.
                  </p>
                  <h3>Top Categories of Concern</h3>
                  <Grid
                    item
                    xs={6}
                    md={12}
                    sm={12}
                    className={styles.FooterActions}
                  >
                    {topCategories.map((element: any, index: any) => {
                      return (
                        <div key={index}>
                          <List
                            className={styles.elementListStyle}
                            component="nav"
                            aria-label="main mailbox folders"
                          >
                            <ListItem>
                              <ListItemIcon>{index + 1}</ListItemIcon>
                              <ListItemText
                                secondary={element.question_Group_Heading}
                              />
                            </ListItem>
                          </List>
                        </div>
                      );
                    })}
                  </Grid>
                  {topQuestionsList && topQuestionsList.length > 0 ? (
                  <>
                  <h3>Top Questions of Concern </h3>
                  <Grid item xs={6} md={12} sm={12}>
                    {topQuestionsList.map((element: any, index: any) => {
                      return (
                        <div key={index}>
                          <List
                            className={styles.elementListStyle}
                            component="nav"
                            aria-label="main mailbox folders"
                          >
                            <ListItem>
                              <ListItemIcon className={styles.bulletStyle}>
                                {"\u25CF"}
                              </ListItemIcon>
                              <ListItemText secondary={element.question} />
                            </ListItem>
                          </List>
                        </div>
                      );
                    })}
                  </Grid>
                  </>)
                  :null}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} className={styles.FooterActions}>
            <div className={styles.prevBtn}>
              <Button
                id="previous-button2"
                variant="contained"
                color="primary"
                onClick={handleBack}
              >
                {"Previous"}
              </Button>
              <div className={styles.borderButton}>
                <Button
                  id="doc-generate-button2"
                  variant="contained"
                  color="primary"
                  onClick={() => generate()}
                >
                  <GetAppIcon className={styles.EditIcon} />
                  &nbsp; Doc
                </Button>
                <Button
                  id="pdf-generate-button2"
                  variant="contained"
                  color="primary"
                  onClick={() => startLoader()}
                >
                  <GetAppIcon className={styles.EditIcon} />
                  &nbsp; PDF
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
  );
};

export default ExecutiveSummary;
