import React from "react";
import Cookies from "js-cookie";

export const Logout: any = () => {
  localStorage.clear();
  Cookies.remove('ra_user', { path: '', domain: process.env.REACT_APP_DOMAIN });
  Cookies.remove('ra_session', { path: '', domain: process.env.REACT_APP_DOMAIN });
  Cookies.remove('ra_contact', { path: '', domain: process.env.REACT_APP_DOMAIN });
  Cookies.remove('ra_app', { path: '', domain: process.env.REACT_APP_DOMAIN });
  Cookies.remove('ra_user', { path: '' });
  Cookies.remove('ra_contact', { path: '' });
  sessionStorage.removeItem('ra_user');
  sessionStorage.removeItem('ra_session');
  sessionStorage.removeItem('ra_contact');
  sessionStorage.removeItem('ra_app');
  sessionStorage.removeItem('ra_user');
  sessionStorage.removeItem('ra_contact');
  sessionStorage.removeItem('param');
  sessionStorage.removeItem('is_link_authenticated');
  return window.location.replace("/logout");
};
export default Logout;
