let compliance: never[] = [];
let doYouHaveOtherOffice = true
let id: any = 0;

export const setCompliance = (value: any) => {
  compliance = value;
}

export const getCompliance = () => {
  return compliance;
}

// function  will return arrray of ids
export const returnArray = (val: any) => {
  const valArray: any[] = [];
  val.forEach((item: any) => {
    valArray.push(parseInt(item.id));
  });
  return valArray;
}

export const setOtherOffice = (value: boolean) => {
  doYouHaveOtherOffice = value;
}

export const getOtherOffice = () => {
  return doYouHaveOtherOffice;
}

export const setActiveFormStep = (value: any) => {
  id = value;
}

export const getActiveFormStep = () => {
  return id;
}
