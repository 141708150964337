import { gql } from "@apollo/client";

export const CREATE_CSET_ASSESSMENT_LINK = gql`
mutation createUpdateSharedLink($input: JSON){
  createUpdateSharedLink(input:$input){
    message
    shared_link_data{
      partner_id
      assessment_id
      token
      shared_link
      email
      email_send
      partnerOrgId
      clientOrgId
      user_id
      client_id
    }
  }
}
`
export const UPDATE_CSET_ASSESSMENT_LINK = gql`
mutation UpDateCsetAssessmentLink($id:ID!,$is_link_expired:Boolean!){
  updateCsetAssessmentLink(input: {
    where: { id: $id }
    data: {
      is_link_expired: $is_link_expired
    }
  }) {
    csetAssessmentLink {
      id
      shared_link
      is_link_expired
    }
  }
}
`

export const DELETE_CSET_ASSESSMENT_LINK = gql`
mutation DeleteCsetAssessmentLink($id:ID!){
  deleteCsetAssessmentLink(input: {
    where: { id: $id }
  }) {
    csetAssessmentLink {
      id
      shared_link
    }
  }
}
`