import { Grid, Paper, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import styles from "./AssetManagement.module.css";
import { CSET_URL } from "../../../../../config";
import logout from "../../../../Auth/Logout/Logout";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import CssBaseline from "@mui/material/CssBaseline";
import DOMPurify from "dompurify";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../../../../../components/UI/Alert/Alert";

export interface AssetManagementProps {
  data?: any;
  props?: any;
  assetManagementRef?: any;
  generateDataURL?: any;
  getAssetManagementData?: (data: any) => void;
  handleError?: (err :any) => void;
  handleChildCompError?: (err: any) => void;
}

export const AssetManagement: React.FC<AssetManagementProps> = ({
  data,
  props,
  assetManagementRef,
  generateDataURL,
  getAssetManagementData = () => {},
  handleError = () =>{},
  handleChildCompError = () => {}
}: AssetManagementProps) => {
  const assetManagementSVGRef = useRef<any>(null);
  const [showBackDrop, setShowBackDrop] = useState(true);
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [AssetManagement, setAssetManagement] = useState<any>({});
  const [milData, setMilData] = useState<any>(null);
  const [milData2, setMilData2] = useState<any>(null);
  const [milData3, setMilData3] = useState<any>(null);
  const [milData4, setMilData4] = useState<any>(null);
  const [milData5, setMilData5] = useState<any>(null);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  useEffect(() => {
    const controller = new AbortController();
    getAssetManagement();
    getMilHeatMapData();
    if (props === false) {
      setShowBackDrop(false);
    }
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (
      Object.keys(AssetManagement).length > 0 &&
      milData &&
      milData2 &&
      milData3 &&
      milData4 &&
      milData5
    ) {
      setShowBackDrop(false);
    }
  }, [milData && milData2 && milData3 && milData4 && milData5]);

  useEffect(() => {
    if (AssetManagement) {
      passDatatoReportFunc();
    }
  }, [AssetManagement]);

  const passDatatoReportFunc = async () => {
    await getAssetManagementData({
      AssetManagement,
    });
  };

  const getAssetManagement = async () => {
    await fetch(
      `${CSET_URL}api/MaturityStructure?domainAbbrev=AM`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response: any) => response.json())
      .then((domainAbbrev: any) => {
        setAssetManagement(domainAbbrev.Domain);
      })
      .catch((err: any) => {
        setShowBackDrop(false)
        console.log((handleChildCompError))
        handleChildCompError(err)
        handleError(err)
      });
  };

  const getMilHeatMapData = async () => {
    await fetch(
      `${CSET_URL}api/reportscrr/widget/milheatmap?domain=AM&mil=MIL-1&scale=1.5`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response: any) => response.text())
      .then((domain: any) => {
        setMilData(domain);
      })
      .catch((err: any) => {
        setShowBackDrop(false)
        handleError(err)
        handleChildCompError(err)
      });

    await fetch(
      `${CSET_URL}api/reportscrr/widget/milheatmap?domain=AM&mil=MIL-2&scale=1.5`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response2: any) => response2.text())
      .then((domain: any) => {
        setMilData2(domain);
      })
      .catch((err: any) => {
        setShowBackDrop(false)
        handleError(err)
        handleChildCompError(err)
      });

    await fetch(
      `${CSET_URL}api/reportscrr/widget/milheatmap?domain=AM&mil=MIL-3&scale=1.5`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response3) => response3.text())
      .then((domain: any) => {
        setMilData3(domain);
      })
      .catch((err: any) => {
        setShowBackDrop(false)
        handleError(err)
        handleChildCompError(err)
      });

    await fetch(
      `${CSET_URL}api/reportscrr/widget/milheatmap?domain=AM&mil=MIL-4&scale=1.5`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response4) => response4.text())
      .then((domain: any) => {
        setMilData4(domain);
      })
      .catch((err: any) => {
        setShowBackDrop(false)
        handleError(err)
        handleChildCompError(err)
      });

    await fetch(
      `${CSET_URL}api/reportscrr/widget/milheatmap?domain=AM&mil=MIL-5&scale=1.5`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response5: any) => response5.text())
      .then((domain: any) => {
        setMilData5(domain);
      })
      .catch((err: any) => {
        setShowBackDrop(false)
        handleError(err)
        handleChildCompError(err)
      });
  };

  /**
   * Convert String to html Response
   * @param html
   */
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  /**
   * Actually, "non-child questions"
   * @param q
   */
  const parentQuestions = (q: any): any[] => {
    // q might be a single question or might be an array of questions
    let questions: any = [];

    if (q instanceof Array) {
      questions = q;
    } else {
      questions = [].concat(q);
    }

    return questions.filter((x: any) => !x.parentquestionid);
  };

  /**
   * Until question numbers are broken out into their own data element,
   * we'll parse them from the question text.
   * @param q
   */

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };
  const getQuestionNumber = (q: any): string => {
    const dot = q.questiontext.trim().indexOf(".");
    if (dot < 0) {
      return "Q";
    }
    return "Q" + q.questiontext.trim().substring(0, dot);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      {showBackDrop ? <SimpleBackdrop /> : null}
      <Typography
        component="h5"
        variant="h1"
        style={{ padding: "0px", fontWeight: 600 }}
      >
        Asset Management (AM)
      </Typography>
      {formState.isFailed ? (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {formState.errMessage}
        </Alert>
      ) : null}
      <Paper className={styles.paper}>
        <Grid container>
          {milData !== null &&
          milData2 !== null &&
          milData3 !== null &&
          milData4 !== null &&
          milData5 !== null ? (
            <div ref={assetManagementSVGRef}>
              <Grid item xs={12} className={styles.QuestionViewMIL}>
                <div dangerouslySetInnerHTML={createDangerousHTML(milData)} />
                <div dangerouslySetInnerHTML={createDangerousHTML(milData2)} />
                <div dangerouslySetInnerHTML={createDangerousHTML(milData3)} />
                <div dangerouslySetInnerHTML={createDangerousHTML(milData4)} />
                <div dangerouslySetInnerHTML={createDangerousHTML(milData5)} />
              </Grid>
            </div>
          ) : (
            <></>
          )}
          <Grid item xs={12} className={styles.FooterActions}>
            <h4
              style={{ padding: "12px 8px", margin: "0px", textAlign: "left" }}
            >
              The purpose of Asset Management is to identify, document, and
              manage assets during their life cycle to ensure sustained
              productivity to support critical services.{" "}
            </h4>
            <div className={styles.securityPlanTable}>
              {AssetManagement.Goal
                ? AssetManagement?.Goal.map((data: any, index: number) => {
                    return (
                      <table
                        id="secondTable"
                        key={index}
                        className={styles.tableStyle}
                      >
                        <tr>
                          <td colSpan={3}> {data.title} </td>
                        </tr>

                        {data.Question.map(
                          (Quesdata: any, QuesIndex: number) => {
                            return (
                                <tr key={QuesIndex}>
                                  <td colSpan={2}> {Quesdata.questiontext} </td>
                                  <td colSpan={1}>
                                    {Quesdata.isparentquestion === "true"
                                      ? " "
                                      : Quesdata.answer === "Y"
                                      ? "Yes"
                                      : Quesdata.answer === "N"
                                      ? "No"
                                      : Quesdata.answer === "NA"
                                      ? "NA"
                                      : "Unanswered"}{" "}
                                  </td>
                                </tr>
                            );
                          }
                        )}
                        <tr>
                          <td colSpan={3}> Option(s) for Consideration </td>
                        </tr>
                        {parentQuestions(data.Question).map(
                          (refData: any, refIndex: number) => {
                            return (
                              <tr key={refIndex}>
                                  <td colSpan={1}>
                                    {getQuestionNumber(refData)}
                                  </td>
                                  <td
                                    colSpan={1}
                                    dangerouslySetInnerHTML={createDangerousHTML(
                                      refData.referencetext
                                    )}
                                  ></td>
                                  <td colSpan={1}> </td>
                              </tr>
                            );
                          }
                        )}
                      </table>
                    );
                  })
                : null}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

function areEqual(
  prevProps: AssetManagementProps,
  nextProps: AssetManagementProps
) {
  // Implement your custom comparison logic here.
  // You can compare specific props or skip memoization entirely based on your requirements.
  // For a basic implementation, we'll compare the data prop.
  return prevProps.data === nextProps.data;
}

export default React.memo(AssetManagement, areEqual);
