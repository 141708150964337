import { gql } from "@apollo/client";

export const GET_CSET_ASSESSMENT_LINK = gql` 
query getCsetAssesmentLink($where: JSON , $sort:String, $limit:Int){
    csetAssessmentLinks(where: $where , sort:$sort, limit:$limit){
        id
        password
        partner_id{
          id
          name
        }
        created_at
        client_id{
          id
          name
        }
        shared_link
        token
        cset_assessment_id
        users_id{
            id
            username
            email
        }
    }
  }`

export const VALIDATE_ASSESSMENT_LINK =gql `
query SharedLinkValidation($input: JSON){
  SharedLinkValidation(input:$input){
    message
    ra_validation_data
 }
}
`