import { gql } from "@apollo/client";

export const GET_CSET_FIRM = gql`
  query getcsetFirm($where: JSON, $sort: String) {
    csetFirms(where: $where, sort: $sort) {
      id
      name
      sal
      firm_size
      sector {
        name
        sector_id
      }
      industry {
        name
        industry_id
      }
      relative_effort {
        demographics_id
        size
        description
      }
      cset_standards {
        id
        name
        code
      }
    }
  }
`
