import React, { useState, useEffect } from "react";
import { CSET_URL } from "../../../../../config";
import logout from "../../../../Auth/Logout/Logout";
import DOMPurify from "dompurify";
import styles from "./CRRExecutiveSummary.module.css";

export interface CrrNistCsfCatSummaryProps {
  data?: any;
  props?: any;
  CrrNistCsfCatSummaryref?: any;
  ref1?: any;
  ref2?: any;
  ref4?: any;
  CRRModelAPIData?: any;
  handleChildCompError?: (err: any) => void;
}

export const CrrNistCsfCatSummary: React.FC<CrrNistCsfCatSummaryProps> = ({
  CRRModelAPIData,handleChildCompError=()=>{}
}: CrrNistCsfCatSummaryProps) => {
  const [legend, setLegend] = useState<any>(null);
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [bodyData, setBodyData] = useState<any>([]);
  const [crrModel, setCRRModel] = useState<any>({});

  useEffect(() => {
    getNistCsfCatSummaryBodyData();
    getMil1PerformanceSummaryLegendWidget();
  }, []);

  useEffect(() => {
    if (CRRModelAPIData) {
      setCRRModel(CRRModelAPIData.structure.Model);
    }
  }, [CRRModelAPIData]);

  /**
   * Convert String to html Response
   * @param html
   */
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const getNistCsfCatSummaryBodyData = async () => {
    await fetch(
      `${CSET_URL}api/reportscrr/getNistCsfCatSummaryBodyData`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response: any) => response.json())
      .then((NistCsfCatSummaryBodyData: any) => {
        setBodyData(NistCsfCatSummaryBodyData);
      })
      .catch((error: any) => {
        console.log("NistCsfCatSummaryBodyData Error:- ", error);
        handleChildCompError(error)
      });
  };

  const getMil1PerformanceSummaryLegendWidget = async () => {
    await fetch(
      `${CSET_URL}api/reportscrr/widget/mil1PerformanceSummaryLegend`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response: any) => response.text())
      .then((mil1PerformanceSummaryLegend: any) => {
        setLegend(mil1PerformanceSummaryLegend);
      })
      .catch((error: any) => {
        console.log("mil1PerformanceSummaryLegend Error:- ", error);
        handleChildCompError(error)
      });
  };
  return (
    <div
      className={styles.pageMarginTop}
      style={{ marginTop: "40px", paddingTop: "40px" }}
    >
      <div className={`${styles.containerFluid} ${styles.crrNistCatSumWrap}`}>
        <div className={styles.row}>
          <div className={styles.col6}>
            <h3 className={styles.depictionTitle}>
              NIST Cybersecurity Framework
              <br />
              Category Summary
            </h3>
          </div>
          <div
            className={`${styles.col6} ${styles.tinytext}`}
            dangerouslySetInnerHTML={createDangerousHTML(legend)}
          ></div>
        </div>
        {bodyData.map((e: any) => {
          return (
            <div>
              <table
                cellPadding={0}
                cellSpacing={0}
                className={styles.domainFunction}
                style={{ height: "90%", border: "none" }}
              >
                <tbody>
                  <tr style={{ width: "100%", border: "none" }}>
                    <th style={{ width: "75px" }}>
                      <div style={{ textAlign: "center" }}>Function</div>
                    </th>
                    <th className={styles.category}>Category</th>
                    <th className={styles.subcategory}>Subcategory</th>
                    <th className={styles.references}>CRR References</th>
                  </tr>
                  <tr
                    className={styles.bb2}
                    style={{ border: "none", pageBreakInside: "auto" }}
                  >
                    <td
                      rowSpan={e.subCatsCount}
                      style={{
                        height: "100%",
                        padding: "0 !important",
                        border: "none",
                      }}
                    >
                      <div
                        className={styles.function}
                        style={{
                          backgroundColor: `${
                            crrModel?.crrScores?.csfFunctionColors[
                              e.function.Function.code
                            ]
                          }`,
                        }}
                      >
                        <div className={styles.verticalText}>
                          {e.function.Function.name.toUpperCase()}{" "}
                          {e.function.Function.code.toUpperCase()}
                        </div>
                      </div>
                    </td>
                    <td
                      className={styles.summaryCellPadding}
                      style={{ padding: "0 1rem !important", border: "none" }}
                    >
                      <b>
                        {e.function.Function.name.toUpperCase() + " Summary"}
                      </b>
                      <br />
                      <p
                        dangerouslySetInnerHTML={createDangerousHTML(e.chart)}
                      ></p>
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        fontSize: "12px",
                        padding: "0.25rem",
                        border: "none",
                      }}
                    >
                      <b>{e.function.Function.desc}</b>
                    </td>
                  </tr>
                  {e.cats.map((cat: any) => {
                    return (
                      <tr className={styles.bb2} style={{ border: "none" }}>
                        <td
                          className={styles.summaryCellPadding}
                          style={{
                            padding: "0 1rem 1rem 1rem",
                            border: "none",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "10px",
                              marginBottom: "1rem",
                              width: "70%",
                            }}
                          >
                            <b>
                              {cat.name} {cat.code}:
                            </b>{" "}
                            {cat.desc}
                          </div>
                          {cat.catChart && (
                            <>
                              <b>
                                {e.function.Function.code}.{cat.code}:
                              </b>
                              <div
                                dangerouslySetInnerHTML={createDangerousHTML(
                                  cat.catChart
                                )}
                              ></div>
                            </>
                          )}

                          <br />
                        </td>
                        <td colSpan={2} style={{ padding: 0, border: "none" }}>
                          <table style={{ margin: 0, border: "none" }}>
                            {cat.subCats.map((subCat: any, i: any) => {
                              return (
                                <tr style={{ padding: "1rem", border: "none" }}>
                                  <td
                                    style={{
                                      width: "210px",
                                      padding: "0.25rem",
                                      border: "none",
                                      fontSize: "10px",
                                    }}
                                  >
                                    <b>{subCat.subCat.Subcategory.title}:</b>{" "}
                                    {subCat.subCat.Subcategory.desc}
                                  </td>
                                  <td
                                    style={{ width: "280px", border: "none" }}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={createDangerousHTML(
                                        subCat.chartSubCat
                                      )}
                                      className={styles.ml1}
                                    ></div>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </td>
                      </tr>
                    );
                  })}
                  {e.function.Function.code != "RC" ? (
                    <div className={styles.break}></div>
                  ) : (
                    <></>
                  )}
                  <div className={styles.break}></div>
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CrrNistCsfCatSummary;
