import React from "react";
import styles from "./Header.module.css";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Logout from "../../../../containers/Auth/Logout/Logout";
import * as routeConstant from "../../../../common/RouteConstants";
import { useNavigate } from "react-router-dom";
import { useErrorBoundary } from 'react-error-boundary';

export const Header: React.FC = () => {
  const history = useNavigate();
  const { resetBoundary } = useErrorBoundary();
  const RAcontact = sessionStorage.getItem("ra_contact") || Logout();
  const userdata =  sessionStorage.getItem("ra_user") || Logout();
  const ra_user = userdata ? JSON.parse(userdata) : "";
  const is_link_assessment =  Boolean(sessionStorage.getItem("is_link_authenticated")) || false; 
  const user = JSON.parse(RAcontact) ? JSON.parse(RAcontact) || "" : Logout();
  const getHelpManual = () => {
    resetBoundary();
    let ra_manual = process.env.REACT_APP_HELP_URL;
    window.open(ra_manual, "_blank");
  };
  const getHome = () => {
    resetBoundary();
    if(!is_link_assessment){
      if(ra_user?.user?.role?.name === "Administrator"){
        history(routeConstant.ADMIN_DASHBOARD);
      }else{
        history(routeConstant.CLIENT);
        localStorage.clear();
      }
    }
  };
  return (
    <div className={styles.Header} data-testid="Header">
      <Card className={styles.root}>
        <Grid container>
          <Grid item xs={12} className={styles.RALogoImg}>
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/RA360.png`}
                alt="user icon"
                onClick={getHome}
              />
            </div>
          </Grid>
          <Grid item xs={12} className={styles.userInfo}>
            {!is_link_assessment && (<span className={styles.userNameLabel}>
              {user.name}&nbsp;{user.last_name} &nbsp;&nbsp;|&nbsp;&nbsp;
            </span>)}
            {!is_link_assessment && (<span className={styles.userNameLabel}>
              <a className={styles.logout} onClick={getHelpManual}>
                Help
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
            </span>)}
            <span className={styles.userNameLabel}>
              <a className={styles.logout} onClick={Logout}>
                Logout
              </a>
            </span>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default Header;
