import React, { useEffect, useState } from 'react';
import { Button } from "../../../components/UI/Form/Button/Button";
import classes from "./Client.module.css";
import * as routeConstants from '../../../common/RouteConstants';
import * as msgConstants from "../../../common/MessageConstants";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import { useNavigate, useLocation } from "react-router-dom";
import Input from "../../../components/UI/Form/Input/Input";
import ClearIcon from "@mui/icons-material//Clear";
import SearchIcon from "@mui/icons-material/Search";
import { DialogBox } from "../../../components/UI/DialogBox/DialogBox";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import { ADMIN_GET_CLIENT_DELETE_DATA } from "../../../graphql/queries/AdminUI";
import { useLazyQuery,useMutation } from '@apollo/client';
import Alert from "../../../components/UI/Alert/Alert";
import { DELETE_DATA } from '../../../graphql/mutations/Delete';
import { sentry_error_catch } from '../../../common/sentry_error_catch';

const Client: React.FC = (props:any) => {
const history = useNavigate();
  const timeFormat = "MM/DD/YYYY hh:mm a";
  const moment = require("moment-timezone");
  const deleteIcon = "/icons/svg-icon/delete.svg";
  const location: any = useLocation();
  const [showBackDrop, setShowBackDrop] = useState(true);
  const [newData, setNewData] = useState<any>([]);
  const [clientFilter,setClientFilter] = useState<any>("");
  const [searchData,setSearchData] = useState<any>([]);
  const [error,setError] = useState<any>(false);
  // array of column name to be show in table.
  const [openDeleteClientDialogBox,setOpenDeleteClientDialogBox] = useState<any>(false);
  const [rowData,setRowData] = useState<any>({});
  const [formState, setFormState] = useState({
    isDelete: false,
    isSuccess: false,
    isFailed: false,
    errMessage: "",
  })

  const [clientDelete] = useMutation(DELETE_DATA);
  const column: any = [
    { title: "Partner Name", key: "client_partnerName" },
    { title: "Client Name", key: "client_name" },
    { title: "Email", key: "email" },
    { title: "Created on", key: "created_at" },
    { title: "Pg Client Id",key :"client_pg_id"},
    {
      icon: ( <img
          className={classes.EditIcon}
          src={process.env.PUBLIC_URL + deleteIcon}
          alt="delete icon"
      />
      ),
      tooltip: "Delete Client",
      key: "deleteclient",
      title: "",
      displayIcon: (rowData: any) => true,
    }
  ];

  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    } else {
      return false;
    }
  }
  // Query to get clients by partner id
  const [getDeletedClient] = useLazyQuery(ADMIN_GET_CLIENT_DELETE_DATA, {
    fetchPolicy: msgConstants.FETCH_POLICY,
    onCompleted: (OrgClientdata) => {
      setShowBackDrop(false);
      createTableDataObject(OrgClientdata.organizations);
    },
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  })
  // query get call after every initial render.
  useEffect(() => {
    console.log("Client",location.state)
    getDeletedClient({
      variables: {
        where: { subtype: "Client", active_status: 3 },
        limit: 1000,
        sort: "id:desc"
      }
    })
  }, [])

  const onRowClick:any = (rowData: any,val: string) => {
    if (val === "deleteclient") {
      setOpenDeleteClientDialogBox(true);
      setRowData(rowData);
    }
  }
  // data object for displaying in table.
  const createTableDataObject = (data: any) => {
    const arr: any = [];
    if (data !== 0) {
      data.forEach((element: any) => {
        const obj: any = {}
        obj['client_orgId'] = element.id ? element.id : '';
        obj['client_name'] = element.contact_id.name ? element.contact_id.name : '';
        obj["email"] = element.contact_id.email ? element.contact_id.email : " ";
        obj['created_at'] = element.contact_id.created_at ? moment(element.contact_id.created_at).tz("US/Eastern").format(
          timeFormat
        ) : " ";
        obj['client_contact_id'] = element.contact_id.id ? element.contact_id.id : ' ';
        obj['client_partnerId'] = element.partner_id.id ? element.partner_id.id : ' ';
        obj['client_partnerName'] = element.partner_id.name ? element.partner_id.name : " ";
        obj['client_pg_id'] = element.pg_id ? element.pg_id : ' ';
        arr.push(obj);
      })
    }
    setNewData(arr);
    setSearchData(arr)
  }

  const backToList = () => {
    history(routeConstants.ADMIN_DASHBOARD);
  }
  const handlePartnerFilterChange = (event:any) => {
    setClientFilter(event.target.value)
   }
   const searchResetHandler = (event:any) => {
     setClientFilter("")
     setSearchData(newData)
   }
   const searchHandler = (event:any) => {
     // console.log("event",event)
    if(clientFilter.trim().length > 0){
     console.log("event",event)
       const search:any = []
       newData.forEach((element:any) => {
         console.log("element",element)
         if(element["client_name"].includes(clientFilter)){
           search.push(element)
         }
       });
       setSearchData(search)
    }else{
     console.log("event",event)
     setError(true)
    }
   }

   const confirmDelete = () => {
    console.log("rowData",rowData)
    setOpenDeleteClientDialogBox(false);
    setShowBackDrop(true);
    if(rowData && rowData.client_pg_id){
       console.log("rowData",rowData.client_pg_id)
       clientDelete({
         variables: {
           id:parseInt(rowData.client_pg_id),
           type: "Client",
         }
       }).then((data:any) => {
        setShowBackDrop(false);
        if(data.data.DeleteContactData.message === "Deleted Successfully!"){
           setFormState((formState) => ({
             ...formState,
             isDelete: false,
             isSuccess: true,
             isFailed: false,
             errMessage: "Client has been Deleted Successfully!"
           }))
           getDeletedClient({
            variables: {
              where: { subtype: "Client", active_status: 3 },
              limit: 1000,
              sort: "id:desc"
            }
          })
         }else{
          setFormState((formState) => ({
            ...formState,
            isDelete: false,
            isSuccess: false,
            isFailed: true,
            errMessage: msgConstants.ERROR_MESSAGE
          }))
        }
       }).catch((err:any) => {
        sentry_error_catch(err,setShowBackDrop,setFormState)
       })
    }
   }
   const closeDialogBox = () => {
    setOpenDeleteClientDialogBox(false)
    setRowData({});
   }
   const handleAlertClose = () => {
     setFormState((formState) => ({
       ...formState,
       isDelete: false,
       isSuccess: false,
       isFailed: false,
       errMessage: ""
     }))
    }

  return <React.Fragment>
    <CssBaseline />
    {showBackDrop ? <SimpleBackdrop /> : null}
    <DialogBox
      open={openDeleteClientDialogBox}
      handleOk={confirmDelete}
      handleCancel={closeDialogBox}
      buttonOk={"Yes"}
      buttonCancel={"No"}
      classes={{
        root: classes.MainOfficeDialogRoot,
        container: classes.MainOfficeDialogboxContainer,
        paper: classes.MainOfficeDialogboxPaper,
        scrollPaper: classes.MainOfficeScrollPaper,
      }}
    >
      <div className={classes.DialogBoxTitle}>
        <Typography component="h1" variant="h1">
          Please Confirm
        </Typography>
      </div>
      <div className={classes.DialogBoxContext}>
        <p>Are you sure you want to Delete the <b>{rowData.client_name}</b> Client ?</p>
      </div>
    </DialogBox>
    <Typography component="h5" variant="h1">
      Delete Client List
    </Typography>
    <Grid container>
      <Grid item xs={12} sm={12}>
        {formState.isSuccess ? (
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <strong>{formState.errMessage}</strong>
          </Alert>
        ) : null}
        {formState.isFailed ? (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <strong>{formState.errMessage}</strong>
          </Alert>
        ) : null}
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={12} sm={8} className={classes.FilterWrap}>
        <span style={{ width: "250px" }}>
          <Grid item md={12} xs={12}>
            <Input
              required={true}
              id="client-id"
              type="search"
              name="filterClient"
              label="Search Client Name"
              variant="outlined"
              value={clientFilter}
              onChange={handlePartnerFilterChange}
              error
            />
          </Grid>
        </span>
        <div className={classes.FilterWrap1}>
          <span>
            <Button id="search" onClick={searchHandler}>
              Search <SearchIcon />
            </Button>
          </span>
        </div>
        <div className={classes.FilterWrap2}>
          <span>
            <Button id="reset" color="secondary" onClick={searchResetHandler}>
              RESET <ClearIcon />
            </Button>
          </span>
        </div>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.FilterAddWrap}>
        <div className={classes.ButtonGroup2}>
          <Button
            id="cancel-button"
            className={classes.BackToButton}
            variant={"contained"}
            onClick={backToList}
            color="secondary"
          >
            <img
              src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
              alt="user icon"
            />
            &nbsp; Back to List
          </Button>
        </div>
      </Grid>
    </Grid>
    <Grid>
      <Paper className={classes.paper}>
        <div className={classes.ScrollTable}>
          {searchData.length !== 0 ? (
            <MaterialTable
              data={searchData}
              columns={column}
              pageSize={10}
              onButtonClick={onRowClick}
            />
          ) : !enableBackdrop() ? (
            <Typography component="h5" variant="h3">
              You don't have any Client subscribed for RiskAssessment360
            </Typography>
          ) : null}
        </div>
      </Paper>
    </Grid>
  </React.Fragment>
}

export default Client;
