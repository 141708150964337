import { gql } from "@apollo/client";

export const GET_CSET_ASSESSMENT = gql`
query csetAssessments($where: JSON , $sort:String, $limit:Int) {
    csetAssessments(where: $where, sort:$sort, limit:$limit) {
      assessment_name
      assessment_id
      assessment_type
      mail_send
      report_generation
      stateProvRegion
      cityOrSiteName
      facility_name
      cset_assessment_type{
        GUID
        id
        group_Title
        name
        description
      }
      cset_firm_id {
        id
        name
        sal
        firm_size
        industry {
          name
          industry_id
        }
        relative_effort {
          demographics_id
          description
          size
        }
        sector {
          sector_id
          name
        }
        cset_standards {
          id
          name
          code
        }
      }
      facilitator
      critical_point_of_contact
      maturityLevel
      assetValue
      id
      allow_download
      updated_at
      created_at
      assessment_document{
        url
      }
      deleteData
      contact_id {
        id
        name
      }
    }
  }
  `;
  export const GET_ASSESSMENT_BY_CONTACT_ID = gql`
  query csetAssessments($where: JSON) {
    csetAssessments(where:$where){
      assessment_name
      assessment_id
      assessment_type
      report_generation
      id
      allow_download
      updated_at
      created_at
      assessment_document{
        id
        url
      }
      deleteData
      contact_id {
        id
        name
      }
    }
  }
  `;
  export const GET_CSET_ASSESSMENT_TYPE = gql`
  query getAssessmentType{
    csetAssessmentTypes{
      id
      name
      GUID
      group_Title
      assessment_type
      description
      icon{
        id
        url
      }
    }
  }
  `
  export const GET_SOFT_DELETE_ASSESSMENT = gql`
  query getDeleteAssessment {
    getDeleteAssessment{
      delete_assessment_data{
        id
        client_name 
        assessment_name
        partner_name 
        assessment_id
        assessment_type 
        created_at 
        updated_at 
        client_id 
        deleteData
        allow_download
        assessment_document
        cset_assessment_type
      }
    }
  }
  `

  export const GET_LATEST_ASSESSMENT = gql`
  query getLatestAssessment($queryType: String!,$todayDate: String,$endDate: String,$startDate: String){
    getLatestAssessment(queryType:$queryType,todayDate:$todayDate,startDate:$startDate,endDate:$endDate){
      latest_assessment_data{
        id
          client_name
          partner_name
          assessment_name 
          assessment_id
          assessment_type
          created_at 
          updated_at 
          client_id 
          deleteData
          allow_download
          assessment_document
          cset_assessment_type
      }
    }
  }
  `