import * as routeConstants from "../common/RouteConstants";

const AssessmentInfo = "Assessment Information";

export const sideDrawerMenus = [
  {
    title: "Home",
    path: routeConstants.CLIENT,
    icon: "users",
    pathList: [],
  },
  {
    title: "Help",
    path: "",
    icon: "Help",
    pathList: [],
  },
];

export const csetTopStepperMenu = [
  {
    title: AssessmentInfo,
    path: routeConstants.ASSESSMENT_INFO,
    icon: "tag",
  },
  {
    title: "Security Assurance Level (SAL)",
    path: routeConstants.SECURITY_ASSURANCE_LEVEL,
    icon: "speedSend",
  },
  {
    title: "Mode Selection",
    path: routeConstants.STANDARDS,
    icon: "speedSend1",
  },
  {
    title: "Questions",
    path: routeConstants.QUESTIONS,
    icon: "automation",
  },
  {
    title: "Results",
    path: routeConstants.RESULTS,
    icon: "automation",
  },
  {
    title: "Reports",
    path: routeConstants.REPORTS,
    icon: "speedSend",
  }
];

export const csetStandardTopStepperMenu = [
  {
    title: AssessmentInfo,
    path: routeConstants.ASSESSMENT_INFO,
    icon: "tag",
  },
  {
    title: "Firm Type",
    path: routeConstants.FIRM_TYPE,
    icon: "tag",
  },
  {
    title: "Questions",
    path: routeConstants.QUESTIONS,
    icon: "automation",
  },
  {
    title: "Results",
    path: routeConstants.RESULTS,
    icon: "automation",
  },
  {
    title: "Reports",
    path: routeConstants.REPORTS,
    icon: "speedSend",
  }
];

export const csetCMMCTopStepperMenu = [
  {
    title: AssessmentInfo,
    path: routeConstants.ASSESSMENT_INFO,
    icon: "tag",
  },
  {
    title: "Maturity Level",
    path: routeConstants.CMMC2_LEVEL,
    icon: "tag",
  },
  {
    title: "Questions",
    path: routeConstants.CMMC2_QUESTIONS,
    icon: "automation",
  },
  {
    title: "Results",
    path: routeConstants.CMMC2_RESULTS,
    icon: "automation",
  },
  {
    title: "Reports",
    path: routeConstants.CMMC2_REPORTS,
    icon: "speedSend",
  }
];

export const csetRRATopStepperMenu = [
  {
    title: AssessmentInfo,
    path: routeConstants.ASSESSMENT_INFO,
    icon: "tag",
  },
  {
    title: "Assessment Demographics",
    path: routeConstants.ASSESSMENT_DEMOGRAPHICS,
    icon: "tag",
  },
  {
    title: "Questions",
    path: routeConstants.RRA_QUESTIONS,
    icon: "automation",
  },
  {
    title: "Results",
    path: routeConstants.RRA_RESULTS,
    icon: "automation",
  },
  {
    title: "Reports",
    path: routeConstants.RRA_REPORTS,
    icon: "speedSend",
  }
];

export const csetCRRTopStepperMenu = [
  {
    title: AssessmentInfo,
    path: routeConstants.ASSESSMENT_INFO,
    icon: "tag",
  },
  {
    title: "Assessment Demographics",
    path: routeConstants.ASSESSMENT_DEMOGRAPHICS,
    icon: "tag",
  },
  {
    title: "Questions",
    path: routeConstants.CRR_QUESTIONS,
    icon: "automation",
  },
  {
    title: "Results",
    path: routeConstants.CRR_RESULTS,
    icon: "automation",
  },
  {
    title: "Reports",
    path: routeConstants.CRR_REPORTS,
    icon: "speedSend",
  }
];
