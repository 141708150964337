import React, { useState, useEffect } from "react";
import styles from "./SiteCyberSecurityPlan.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Button } from "../../../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router-dom";
import { CSET_URL } from "../../../../../config/index";
import { saveAs } from "file-saver";
import { useLazyQuery } from "@apollo/client";
import { GET_ORGANIZATION } from "../../../../../graphql/queries/Organization";
import logout from "../../../../Auth/Logout/Logout";
import * as docx from "docx";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import PaginationList from "react-pagination-list";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import GetAppIcon from "@mui/icons-material/GetApp";
import moment from "moment";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../../components/UI/Alert/Alert";
import * as msgConstants from "../../../../../common/MessageConstants";
import { sentry_error_catch } from "../../../../../common/sentry_error_catch";

export interface SiteCyberSecurityPlanProps {
  from?: string;
  props?: any;
  data?: any;
  handleChange?: any;
  partnerLogo?: any;
  clientLogo?: any;
  clientImageDimension?: any;
  partnerImageDimension?: any;
}

export const SiteCyberSecurityPlan: React.FC<SiteCyberSecurityPlanProps> = ({
  from,
  data,
  handleChange,
  props,
  partnerLogo,
  clientLogo,
  clientImageDimension,
  partnerImageDimension,
}: SiteCyberSecurityPlanProps) => {
  const history = useNavigate();
  const location = useLocation();
  const preparedForBy = "preparedFor/By";
  const [salTableData, setSalTableData] = useState<any>({});
  const [partnerData, setPartnerData] = useState<any>({});
  const [clientData, setClientData] = useState<any>({});
  const envVariables = process.env;
  const serverUrl = envVariables.REACT_APP_SERVER_URL;
  const [controlListData, setControlListData] = useState<any>([]);
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [param, setParam] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [bannerImage, setBannerImage] = useState<any>("");
  const [cpaImage, setCPAImage] = useState<any>("");
  const [cpaImageDimensions, setCPAImageDimensions] = useState<any>("");
  const [partnerImageDimensions, setPartnerImageDimensions] = useState<any>("");
  const [partnerImage, setPartnerImage] = useState<any>("");
  const [defaultCPAImage, setDefaultCPAImage] = useState<any>("");
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [checkQueryStatus, setCheckQueryStatus] = useState<any>(false);

  useEffect(() => {
    const controller = new AbortController();
    if (props) {
      setParam(props);
    } else {
      setParam(location.state[Object.keys(location.state)[0]]);
    }
    getDataforSiteCyberSecurityReport();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    GetOrganizationDataOfPartner({
      variables: {
        where: {
          contact_id: param?.clientInfo.partnerId,
        },
      },
    });
    GetOrganizationDataOfClient({
      variables: {
        where: {
          id: param?.clientInfo.clientOrgId,
          subtype: "Client",
        },
      },
    });
    return () => controller.abort();
  }, [param]);

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: msgConstants.ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const [GetOrganizationDataOfPartner] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.partnerId === element.contact_id.id) {
          setPartnerData(element);
        }
      });
    },
    onError: (err) => {
      handleError(err);
    },
  });

  const [GetOrganizationDataOfClient] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err) => {
      handleError(err);
    },
  });

  const getBannerImages = () => {
    fetch(`${process.env.PUBLIC_URL}/images/SiteCybersecurity.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setBannerImage(base64data);
        };
      })
      .catch((error) => {Sentry.captureException(error)});
    fetch(`${process.env.PUBLIC_URL}/images/blank_img.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setDefaultCPAImage(base64data);
        };
      })
      .catch((error) => {
        console.log("__Error", error);
        Sentry.captureException(error)
      });
    if (from === "result") {
      setPartnerImage(partnerLogo);
      setCPAImage(clientLogo);
      setCPAImageDimensions(clientImageDimension);
      setPartnerImageDimensions(partnerImageDimension);
    } else {
      if (clientData.logo && clientData.logo[0]) {
        const clientLogoUrl = clientData.logo[0].url.slice(1);
        fetch(`${serverUrl}${clientLogoUrl}`, {
          method: "GET",
          headers: headerObj,
        })
          .then((response) => response.blob())
          .then((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function (theFile: any) {
              const base64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setCPAImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };
              setCPAImage(base64data);
            };
          })
          .catch((error) => {Sentry.captureException(error)});
      }
      if (partnerData.logo && partnerData.logo[0]) {
        const partnerLogoUrl = partnerData.logo[0].url.slice(1);
        fetch(`${serverUrl}${partnerLogoUrl}`, {
          method: "GET",
        })
          .then((response) => response.blob())
          .then((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function (theFile: any) {
              const ClientLogobase64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setPartnerImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };
              setPartnerImage(ClientLogobase64data);
            };
          })
          .catch((error) => {Sentry.captureException(error)});
      }
    }
  };

  useEffect(() => {
    getBannerImages();
  }, [partnerData, clientData]);

  const getDataforSiteCyberSecurityReport = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/analysis/ComponentTypes`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {})
        .catch((error) => {
          handleError(error);
        });

      await fetch(`${CSET_URL}api/analysis/Dashboard`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((chartDataResponse) => {})
        .catch((err:any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });
      await fetch(`${CSET_URL}api/reports/securityplan`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data.controlList, "----", data);
          setLoading(false);
          setSalTableData(data.salTable);
          setControlListData(data.controlList);
          setCheckQueryStatus(true);
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (err:any) {
        sentry_error_catch(err,setLoading,setFormState)
    }
  };
  useEffect(() => {
    if (
      from &&
      from === "result" &&
      salTableData?.osv &&
      salTableData &&
      controlListData
    ) {
      generate();
    }
  }, [
    checkQueryStatus &&
      salTableData?.osv &&
      salTableData &&
      bannerImage &&
      controlListData &&
      partnerData.contact_id,
  ]);

  const handleBack = () => {
    history(routeConstant.REPORTS, { state: { param } });
  };

  const generateQuestTable = (data: any[]): any[] => {
    return data.map((d) => {
      return new docx.TableRow({
        children: [
          new docx.TableCell({
            width: {
              size: 10510,
              type: docx.WidthType.DXA,
            },
            children: [
              new docx.Paragraph({
                text: d.simple_Question,
                heading: docx.HeadingLevel.HEADING_3,
              }),
            ],
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
            },
            shading: {
              fill: "ffffff",
              color: "auto",
            },
            columnSpan: 2,
          }),
          new docx.TableCell({
            width: {
              size: 3505,
              type: docx.WidthType.DXA,
            },
            children: [
              new docx.Paragraph({
                text:
                  d.answer === "Y"
                    ? "Yes"
                    : d.answer === "N"
                    ? "No"
                    : d.answer === "U"
                    ? "Unanswered"
                    : "Not Applicable",
                heading: docx.HeadingLevel.HEADING_3,
              }),
            ],
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
            },
            shading: {
              fill: "ffffff",
              color: "auto",
            },
          }),
        ],
      });
    });
  };

  const createControlListData = (): any => {
    let getControlList: any[] = [];
    getControlList = controlListData.map((contents: any) => {
      return [
        new docx.Table({
          columnWidths: [3505, 3505, 3505],
          rows: [
            new docx.TableRow({
              children: [
                new docx.TableCell({
                  width: {
                    size: 3505,
                    type: docx.WidthType.DXA,
                  },
                  children: [
                    new docx.Paragraph({
                      text: contents.requirementTitle,
                      heading: docx.HeadingLevel.HEADING_3,
                    }),
                  ],
                  margins: {
                    top: docx.convertInchesToTwip(0.1),
                    bottom: docx.convertInchesToTwip(0.1),
                    left: docx.convertInchesToTwip(0.1),
                  },
                  shading: {
                    fill: "ffffff",
                    color: "auto",
                  },
                  columnSpan: 2,
                }),
                new docx.TableCell({
                  width: {
                    size: 3505,
                    type: docx.WidthType.DXA,
                  },
                  children: [
                    new docx.Paragraph({
                      text: `${contents.standard_Category} / ${contents.subCategory}`,
                      heading: docx.HeadingLevel.HEADING_3,
                    }),
                  ],
                  margins: {
                    top: docx.convertInchesToTwip(0.1),
                    bottom: docx.convertInchesToTwip(0.1),
                    left: docx.convertInchesToTwip(0.1),
                  },
                  shading: {
                    fill: "ffffff",
                    color: "auto",
                  },
                  columnSpan: 2,
                }),
              ],
            }),
            new docx.TableRow({
              children: [
                new docx.TableCell({
                  width: {
                    size: 3505,
                    type: docx.WidthType.DXA,
                  },
                  children: [
                    new docx.Paragraph({
                      text:
                        contents.level === "H"
                          ? "High"
                          : contents.level === "L"
                          ? "Low"
                          : contents.level,
                      heading: docx.HeadingLevel.HEADING_3,
                    }),
                  ],
                  margins: {
                    top: docx.convertInchesToTwip(0.1),
                    bottom: docx.convertInchesToTwip(0.1),
                    left: docx.convertInchesToTwip(0.1),
                  },
                  shading: {
                    fill: "ffffff",
                    color: "auto",
                  },
                }),
                new docx.TableCell({
                  width: {
                    size: 3505,
                    type: docx.WidthType.DXA,
                  },
                  children: [
                    new docx.Paragraph({
                      text: contents.implementationStatus,
                      heading: docx.HeadingLevel.HEADING_3,
                    }),
                  ],
                  margins: {
                    top: docx.convertInchesToTwip(0.1),
                    bottom: docx.convertInchesToTwip(0.1),
                    left: docx.convertInchesToTwip(0.1),
                  },
                  shading: {
                    fill: "ffffff",
                    color: "auto",
                  },
                }),
                new docx.TableCell({
                  width: {
                    size: 1500,
                    type: docx.WidthType.DXA,
                  },
                  children: [
                    new docx.Paragraph({
                      text: contents.standardShortName,
                      heading: docx.HeadingLevel.HEADING_3,
                    }),
                  ],
                  margins: {
                    top: docx.convertInchesToTwip(0.1),
                    bottom: docx.convertInchesToTwip(0.1),
                    left: docx.convertInchesToTwip(0.1),
                  },
                  shading: {
                    fill: "ffffff",
                    color: "auto",
                  },
                  columnSpan: 2,
                }),
              ],
            }),
            new docx.TableRow({
              children: [
                new docx.TableCell({
                  width: {
                    size: 3505,
                    type: docx.WidthType.DXA,
                  },
                  children: [
                    new docx.Paragraph({
                      text: contents.controlDescription,
                      heading: docx.HeadingLevel.HEADING_3,
                    }),
                  ],
                  margins: {
                    top: docx.convertInchesToTwip(0.1),
                    bottom: docx.convertInchesToTwip(0.1),
                    left: docx.convertInchesToTwip(0.1),
                  },
                  shading: {
                    fill: "ffffff",
                    color: "auto",
                  },
                  columnSpan: 4,
                }),
              ],
            }),
            ...generateQuestTable(contents.control_Questions),
          ],
        }),
        new docx.Paragraph({
          text: "",
          spacing: {
            after: 200,
          },
        }),
      ];
    });
    const dataToReturn: any[] = [];
    getControlList.forEach((f) => {
      dataToReturn.push(...f);
    });
    return dataToReturn;
  };

  const fitImg = (targetHeight: any, originalSize: any) => {
    const originalAspectRatio = originalSize.width / originalSize.height;
    let width: number;
    let height: number;
    if (!originalSize) {
      width = targetHeight;
    } else {
      height = targetHeight;
      width = height * originalAspectRatio;
    }
    return width;
  };
  const generate = () => {
    if (
      salTableData?.osv &&
      checkQueryStatus &&
      salTableData &&
      bannerImage &&
      controlListData &&
      partnerData.contact_id
    ) {
      setLoading(true);
      const doc = new docx.Document({
        styles: {
          default: {
            heading1: {
              run: {
                font: "Helvetica",
                size: 36,
                bold: true,
                color: "#4B0079",
              },
              paragraph: {
                alignment: docx.AlignmentType.LEFT,
                spacing: { line: 340 },
              },
            },
            heading2: {
              run: {
                font: "Helvetica",
                size: 30,
                bold: true,
                color: "#0b4785",
              },
              paragraph: {
                spacing: { line: 340 },
              },
            },
            heading3: {
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                spacing: { line: 276 },
              },
            },
            heading4: {
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                alignment: docx.AlignmentType.JUSTIFIED,
              },
            },
          },
          paragraphStyles: [
            {
              id: "normalPara",
              name: "Normal Para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                bold: true,
                font: "Helvetica",
                size: 20,
                color: "#245B8D",
              },
            },
            {
              id: "mainHeading",
              name: "Normal Para2",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 80,
                color: "#4B0079",
                bold: true,
              },
            },
            {
              id: "date",
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                color: "#4B0079",
                size: 40,
                bold: true,
              },
            },
            {
              id: "bulletText",
              name: "Normal para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 20,
              },
            },
            {
              id: "miniHeader",
              name: "Normal para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 24,
                bold: true,
              },
            },
            {
              id: "TableHeading",
              name: "Normal Para2",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 20,
                color: "#4B0079",
                bold: true,
              },
            },
            {
              id: "BoldPara",
              name: "Normal Para3",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 20,
                bold: true,
              },
            },
            {
              id: preparedForBy,
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                color: "#4B0079",
                size: 20,
                bold: true,
              },
            },
            {
              id: "clientPartner",
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                size: 20,
              },
            },
          ],
        },
      });
      const table1 = new docx.Table({
        columnWidths: [3000, 3000],
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({ text: "", style: "TableHeading" }),
                ],
                shading: {
                  color: "auto",
                },
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                width: {
                  size: 1500,
                  type: docx.WidthType.DXA,
                },
                children: [new docx.Paragraph({ text: "" })],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "d9d2e9",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                width: {
                  size: 1500,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: "Confidentiality",
                    style: "TableHeading",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#d9d2e9",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                width: {
                  size: 1500,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: "Integrity",
                    style: "TableHeading",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#d9d2e9",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                width: {
                  size: 1500,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: "Availability",
                    style: "TableHeading",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#d9d2e9",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
            ],
          }),
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({ text: "", style: "TableHeading" }),
                ],
                shading: {
                  color: "auto",
                },
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                width: {
                  size: 1500,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: "Overall Values",
                    heading: docx.HeadingLevel.HEADING_3,
                    style: "TableHeading",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#d9d2e9",
                  color: "auto",
                },
              }),
              new docx.TableCell({
                width: {
                  size: 1500,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: salTableData.q_CV,
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
              new docx.TableCell({
                width: {
                  size: 1500,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: salTableData.q_IV,
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
              new docx.TableCell({
                width: {
                  size: 1500,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: salTableData.q_AV,
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
            ],
          }),
        ],
      });
      const table2 = new docx.Table({
        columnWidths: [3505, 3505, 3505],
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: 7010,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: "Requirement Title ",
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
                columnSpan: 2,
              }),
              new docx.TableCell({
                width: {
                  size: 3505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: "CSET Question/Requirement Category ",
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
            ],
          }),
          new docx.TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: 3505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: "Control Level ",
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
              new docx.TableCell({
                width: {
                  size: 3505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: "Implementation Status ",
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
              new docx.TableCell({
                width: {
                  size: 3505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: "Short Standard Name ",
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
            ],
          }),
          new docx.TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: 3505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: "Requirement Description ",
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
                columnSpan: 3,
              }),
            ],
          }),
          new docx.TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: 10510,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: "Affected Zones  (Optional, may not be included in the table) ",
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
                columnSpan: 3,
              }),
            ],
          }),
          new docx.TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: 10510,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: "Affected Components  (Optional, may not be included in the table) ",
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
                columnSpan: 3,
              }),
            ],
          }),
          new docx.TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: 10510,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: "Related Questions and Answers ",
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
                columnSpan: 3,
              }),
            ],
          }),
        ],
      });
      const calLevelTable = new docx.Table({
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({ text: "", style: "TableHeading" }),
                ],
                shading: {
                  color: "auto",
                },
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                width: {
                  size: 100,
                  type: docx.WidthType.AUTO,
                },
                children: [
                  new docx.Paragraph({
                    text: "CALCULATED LEVEL",
                    style: "TableHeading",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#d9d2e9",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
            ],
          }),
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({ text: "", style: "TableHeading" }),
                ],
                shading: {
                  color: "auto",
                },
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                width: {
                  size: 100,
                  type: docx.WidthType.AUTO,
                },
                children: [
                  new docx.Paragraph({
                    text: salTableData.osv,
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                },
              }),
            ],
          }),
        ],
      });

      const siteCyberSecurityLogo = bannerImage
        ? bannerImage.split(",")[1]
        : "";
      const defaultcpaLogo = defaultCPAImage
        ? defaultCPAImage.split(",")[1]
        : "";
      const cpaLogo = cpaImage ? cpaImage.split(",")[1] : defaultcpaLogo;
      const msplogo = partnerImage
        ? partnerImage.split(",")[1]
        : defaultcpaLogo;
      const image5 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(siteCyberSecurityLogo), (c) => c.charCodeAt(0)),
        600,
        350
      );

      const image6 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(cpaLogo), (c) => c.charCodeAt(0)),
        fitImg(50, cpaImageDimensions),
        50
      );

      const image7 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
        fitImg(50, partnerImageDimensions),
        50
      );
      const footerLogo = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
        fitImg(50, partnerImageDimensions),
        50,
        {
          floating: {
            horizontalPosition: {
              align: docx.HorizontalPositionAlign.RIGHT,
            },
            verticalPosition: {
              align: docx.VerticalPositionAlign.BOTTOM,
            },
          },
        }
      );
      const bannertable = new docx.Table({
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    alignment: docx.AlignmentType.LEFT,
                    heading: docx.HeadingLevel.HEADING_1,
                    style: preparedForBy,
                    children: [
                      new docx.TextRun({
                        text: "Prepared for",
                        bold: true,
                      }),
                    ],
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph(image6),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph({
                    text: param.clientInfo.name,
                    style: "clientPartner",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.2),
                  right: docx.convertInchesToTwip(1.8),
                },
                columnSpan: 5,
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },

                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    alignment: docx.AlignmentType.LEFT,
                    heading: docx.HeadingLevel.HEADING_1,
                    style: preparedForBy,
                    children: [
                      new docx.TextRun({
                        text: "Prepared By",
                        bold: true,
                      }),
                    ],
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph(image7),
                  new docx.Paragraph({
                    text: "",
                  }),
                  new docx.Paragraph({
                    text: partnerData.contact_id.name,
                    style: "clientPartner",
                  }),
                  new docx.Paragraph({
                    text: "",
                    style: "clientPartner",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.5),
                  right: docx.convertInchesToTwip(2),
                },
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                columnSpan: 5,
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
            ],
          }),
        ],
      });

      doc.addSection({
        children: [
          new docx.Paragraph(image5),
          new docx.Paragraph({
            text: "Site Cybersecurity Report",
            style: "mainHeading",
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,

            spacing: {
              after: 400,
            },
          }),
          new docx.Paragraph({
            text: moment().format("DD MMMM YYYY"),
            heading: docx.HeadingLevel.HEADING_1,
            style: "date",
            alignment: docx.AlignmentType.LEFT,

            spacing: {
              after: 400,
            },
          }),
          bannertable,
        ],
      });

      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: "Introduction",
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "Template instructions and directives are given in italicized 10 point font and should be replaced appropriately. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "This security plan template is intended to be used as a tool for the development of a security plan. This template will assist you in identifying the controls in place and those needing further implementation based upon the answers provided in the accompanying Cybersecurity Evaluation Tool (CSET) assessment. The basic process for this plan development would be to first determine risk, second select the countermeasures necessary to mitigate the risk to an acceptable level, and finally follow through to ensure that the countermeasures are implemented to the expected level.",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "The purpose of the security plan is to provide an overview of the security requirements of the system and describe the controls in place or planned, for meeting those requirements. The site cyber security plan also delineates responsibilities and expected behavior of all individuals who access the system. The security plan should be viewed as documentation of the structured process of planning adequate, cost effective security protection for a system. It should reflect input from various managers with responsibilities concerning the system, including information owners, the system operator, and the system security manager. Additional information may be included in the basic plan and the structure and format organized according to agency needs, so long as the major sections described in this document are adequately covered and readily identifiable.  ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "The System Owner is responsible for ensuring that the security plan is prepared and for  implementing the plan and monitoring its effectiveness. Security plans should reflect input  from various individuals with responsibilities concerning the system, including functional “end users,” Information Owners, the System Administrator, and the System Security Manager ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "This template may also include: ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "•   Recommended templates for policies or procedures that have been identified as needed  but not currently available based on the assessment answers.",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            style: "bulletText",
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   The basic network diagram ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   An Inventory List of the components included in the diagram that will be associated with specific controls. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   The List of recommended security controls along with a status as can be determined from the assessment questions. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   A recommended implementation priorities list. This priority is based on incident occurrences on the Industrial Control System Cyber Emergency Response Team (ICS-CERT) watch floor and cybersecurity expert opinion. These recommendations do not take into account any cost benefit analysis with respect to implementing a control. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Basic security assurance level determinations carried over from the assessment. In developing a security plan it is recommended that a deeper risk analysis is conducted to ensure that the selection of controls is not overly conservative (incurring undo costs) or optimistic (leaving excessive risk exposure). ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
        ],
      });

      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: "1. System Identification ",
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "Provide a brief (1-2 paragraphs) description of the main system assests and the necessary  protection levels for confidentiality, integrity, and availability.   See section 3.1 for a more detailed description of confidentiality, integrity, and availability. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "1.1 System Environment ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "Provide a brief (1-3 paragraphs) general description of the technical system. Include any environmental or technical factors that raise special security concerns, such as: ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "•   The system is connected to the Internet;",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   It is located in a harsh or overseas environment;",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Software is rapidly implemented;",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   The software resides on an open network used by the general public or with overseas access;",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   The application is processed at a facility outside of the organization's control; or",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   The general support mainframe has dial-up lines.",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "Describe the primary computing platform(s) used (e.g., mainframe, desk top, LAN or Wide Area Network (WAN). Include a general description of the principal system components, including hardware, software, and communications resources. Discuss the type of communications included (e.g., dedicated circuits, dial circuits, public data/voice networks, Internet). Describe controls used to protect communication lines in the appropriate sections of the security plan. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "Include any security software protecting the system and information. Describe in general terms the type of security protection provided (e.g., access control to the computing platform and stored files at the operating system level or access to data records within an application). Include only controls that have been implemented or planned, rather than listing the controls that are available in the software. Controls that are available, but not implemented, provide no protection. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
        ],
      });
      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: "2. Roles and Responsibilities ",
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "This section defines the roles and responsibilities for cybersecurity within the company. Use this section to define the roles and responsibilities with respect to this plan for your company.",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "2.1 Executive Management ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "Often this role is comprised of the Board of Directors and CEO. Executive management is ultimately responsible for the security of the organization but will most likely delegate tasks and actual implementation.",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "2.2 Chief Security Officer or Chief Information Security Officer (CISO) ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "CSO or CISO is the senior level executive within an organization responsible for establishing  and maintaining the enterprise vision, strategy and program to ensure information assets are adequately  protected. The CISO directs staff in identifying, developing, implementing and maintaining processes  across the organization to reduce information and information technology (IT) risks, respond to  incidents, establish appropriate standards and controls, and direct the establishment and implementation of policies and procedures. The CISO is also usually responsible for information related compliance.",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "Typically, the CISO's influence reaches the whole organization. Responsibilities include: ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "•   Information security and information assurance ",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,

            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Information regulatory compliance (e.g., US PCI DSS, FISMA, GLBA, HIPAA; UK Data Protection Act 1998; Canada PIPEDA)",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,

            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Information risk management",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            // style: "normalPara",
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Supply chain risk management",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            // style: "normalPara",
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Cybersecurity",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            // style: "normalPara",
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Information technology controls for financial and other systems ",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            // style: "normalPara",
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Information privacy",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            // style: "normalPara",
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Computer Emergency Response Team / Computer Security Incident Response Team",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            // style: "normalPara",
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Identity and access management",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            // style: "normalPara",
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Security Architecture (e.g. Sherwood Applied Business Security Architecture)",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            // bullet: {
            //     level: 0,
            //   },
          }),
          new docx.Paragraph({
            text: "•   IT investigations, digital forensics, eDiscovery",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            // style: "normalPara",
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Disaster recovery and business continuity management",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            // style: "normalPara",
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Information Security Operations Center (ISOC)",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            // style: "normalPara",
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "2.3 Security Steering Committee ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "The security steering committee is composed of a representative of all the key stakeholders in  IT security. These stake holders are often representatives of the executive council, CISO or CSO, IT  management, physical security personnel, help desk, and key application and digitial asset owners. This committee meets regularly often quarterly to review policies and procedures, security controls implementation progress, and determine future direction for security within a company.",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "The security steering committee is responsible for making decisions on tactical and strategic  security issues within the enterprise as a whole and should not be tied to one or more business units.  The group should be made up of people from all over the organization so they can view risks and the  effects of security decisions on individual departments and the organization as a whole. The CEO should  head this committee, and the CFO, CIO, department managers, and chief internal auditor should all be on  it. This committee should meet at least quarterly and have a well defined agenda. Some of the group’s  responsibilities are listed next: ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "•   Define the acceptable risk level for the organization.",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              before: 400,
            },
          }),
          new docx.Paragraph({
            text: "•   Develop security objectives and strategies. ",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Determine priorities of security initiatives based on business needs. ",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Review risk assessment and auditing reports.",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Monitor the business impact of security risks. ",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Review major security breaches and incidents. ",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Approve any major change to the security policy and program. ",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "They should also have a clearly defined vision statement in place that is set up to work with  and support the organizational intent of the business. The statement shouldbe structured in a manner  that provides support for the goals of confidentiality, integrity, and availability as they pertain to  the business objectives of the organization. This in turn should be followed, or supported, by a  mission statement that provides support and definition to the processes that will apply to the  organization and allow it to reach its business goals.",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "2.4 System Owners ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "The system owner is responsible for one or more systems, each of which may hold and process data  owned by different data owners. A system owner is responsible for integrating security considerations  into application and system purchasing decisions and development projects. The system owner is  responsible for ensuring that adequate security is being provided by the necessary controls, password  management, remote access controls, operating system configurations, and so on. This role must ensure  the systems are properly assessed for vulnerabilities and must report any to the incident response team  and data owner. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "2.5 Data Owners ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "The data owner (information owner) is usually a member of management who is incharge of a  specific business unit, and who is ultimately responsible for the protection and use of a specific  subset of information. The data owner has due care responsibilities and thus will be held responsible  for any negligent act that results in the corruption or disclosure of the data. The data owner decides  upon the classification of the data he is responsible for and alters that classification if the business need arises. This person is also responsible for ensuring that the necessary security controls are in place, defining security requirements per classification and backup requirements, approving any  disclosure activities, ensuring that proper access rights are being used, and defining user access  criteria. The data owner approves access requests or may choose to delegate this function to business unit managers. And it is the data owner who will deal with security violations pertaining to the data he is responsible for protecting. The data owner, who obviously has enough on his plate, delegates responsibility of the day-to-day maintenance of the data protection mechanisms to the data custodian. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "2.6 Security Administrators ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "Anyone who has a root account on Unix or Linux systems or an administrator account on Windows or Macintosh systems actually has security administrator rights. (Unfortunately, too many people have these accounts in most environments.) This means they can give and take away permissions and set security configurations. However, just because a person has a root or administrator account does not mean they are fulfilling the security administrator role. A security administrator’s tasks are many, and include creating new system user accounts, implementing new security software, testing security patches and components, and issuing new passwords. The security administrator should not actually approve new system user accounts. This is the responsibility of the supervisor. The security administrator must make sure access rights given to users support the policies and data owner directives. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "2.7 Supervisors/Managers ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "The supervisor role, also called user manager, is ultimately responsible for all user activity and any assets created and owned by these users. The supervisor responsibilities would include ensuring that employees understand their responsibilities with respect to security, distributing initial passwords, making sure the employees’ account information is up-to-date, and informing the security administrator when an employee is fired, suspended, or transferred. Any change that pertains to an employee’s role within the company usually affects what access rights they should and should not have, so the user manager must inform the security administrator of these changes immediately. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "2.8 Users ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "The user is any individual who routinely uses the data for work related tasks. The user must have the necessary level of access to the data to perform the duties within their position and is responsible for following operational security procedures to ensure the data’s confidentiality, integrity, and availability to others. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
        ],
      });

      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: "3. Risk Analysis ",
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "A good security plan will require that a risk evaluation is performed to determine the level of necessary rigor and cost benefit analysis for the level of controls selected. It is recommended that a general risk analysis be performed. A good risk assessment should include an evaluation of the value of the protected assets and information, an examination of the consequences to the organization in the event of a successful attack, an examination of the threat if possible, and the cost of implementing mitigating controls. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "threats × vulnerability × asset value = total risk ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "total risk – countermeasures = residual risk ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "Consequence ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "The examination of the consequences of an attack should include: ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "•   How many people could sustain injuries requiring a hospital stay? ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   How many people could be killed? ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Determine priorities of security initiatives based on business needs. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Estimate the potential cost of losing capital assets or the overall economic impact. (Consider the cost of site buildings, facilities, equipment, etc.) ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Estimate the potential cost in terms of economic impact to both the site and surrounding communities. (Consider any losses to community structures and any costs associated with displacement.)",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "•   Estimate the potential cost of environmental cleanup to the site and surrounding communities.(Consider the cost for cleanup, fines, litigation, long term monitoring, etc.) ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "Threat ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "The threat portion of the equation can be deduced from the recommended implementation priorities list. The priorities are based on incident data collected by the ICS-CERT watch floor and subject matter experts as of the time of publication of CSET. Top priorities are controls that mitigate the most actively exploited vulnerabilities with the most significant consequences. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "Cost Benefit Analysis ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "The cost of implementing controls with respect to the additional security provided is the final step in selecting the controls to implement. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
        ],
      });
      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page |  ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: "3.1 Basic Model ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "Traditional security models define three areas of consideration Confidentiality, Integrity, and Availability. The security plan should address each of these areas with respect to data and systems. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "3.1.1 Confidentiality ",
            heading: docx.HeadingLevel.HEADING_3,
            style: "miniHeader",
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "Confidentiality refers to preventing the disclosure of information to unauthorized individuals or systems. For example, a credit card transaction on the Internet requires the credit card number to be transmitted from the buyer to the merchant and from the merchant to a transaction processing network. The system attempts to enforce confidentiality by encrypting the card number during transmission, by limiting the places where it might appear (in databases, log files, backups, printed receipts, and so on), and by restricting access to the places where it is stored. If an unauthorized party obtains the card number in any way, a breach of confidentiality has occurred. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "Confidentiality is necessary for maintaining the privacy of the people whose personal information a system holds. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "3.1.2 Integrity ",
            heading: docx.HeadingLevel.HEADING_3,
            style: "miniHeader",
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "In information security, data integrity means maintaining and assuring the accuracy and  consistency of data over its entire life cycle. This means that data cannot be modified in an unauthorized or undetected manner. This is not the same thing as referential integrity in databases, although it can be viewed as a special case of Consistency as understood in the classic ACID (Atomicity, Consistency, Isolation, Durability) model of transaction processing. Integrity is violated when a message is actively modified in transit. Information security systems typically provide message integrity in addition to data confidentiality. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "3.1.3 Availability ",
            style: "miniHeader",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "For any information system to serve its purpose, the information must be available when it is needed. This means that the computing systems used to store and process the information, the security controls used to protect it, and the communication channels used to access it must be functioning correctly. High availability systems aim to remain available at all times, preventing service disruptions due to power outages, hardware failures, and system upgrades. Ensuring availability also involves preventing denial-of-service attacks. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
        ],
      });
      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: "3.2 Security Assurance Level (SAL) ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
          calLevelTable,
          new docx.Paragraph({
            text: " ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
          table1,
          new docx.Paragraph({
            text: " ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
        ],
      });
      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: "4. Security Plan Controls and Status List ",
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "This section lists all the controls which constitute the security plan and implementation status.To enable easier reading of the controls list a table key is included at the start of this section. In this section the terms Control and Requirement are used interchangeably to indicate the mitigationeffort as defined in the given standard. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "Table Key and Field Descriptions: ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            style: "BoldPara",
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          table2,
          new docx.Paragraph({
            text: " ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "Requirement Title: Is the control title as it is generally defined in the standard document from which this control is derived ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "CSET Question Category: Shows the CSET question category from the global questions list. Questions from multiple standards have been consolidated together in the CSET tool and assigned a common category. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "Control Level: Mapped to one of Low, Moderate, High, or Very High. A value of none indicates that the level was not defined for the given information type in the standard. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "Control Level: Mapped to one of Low, Moderate, High, or Very High. A value of none indicates that the level was not defined for the given information type in the standard. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "Implementation Status: Shows the percentage complete as the number of yes and alternate answers / total related questions for this control. The percentage implemented will not necessarily be reflective of the amount of work required to implement the control but is merely an indicator of how many of the questions related to the control have been addressed so far. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "Short Standard Name: An indicator of which standard this control is derived from. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "Control Description: The full control text as defined in the standard from which the control is derived. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "Affected Zones: Only applicable to controls derived from a diagram. If you have included a diagram in your original assessment this field will contain a list of zone in which at least one component was found to require this control. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "Affected Components:This field contains a list of the components that are directly applicable to this control. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "Related Questions and Answers: A list of the questions and answers from which the implementation status of this control was determined. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
        ],
      });
      if (controlListData.length > 0) {
        doc.addSection({
          footers: {
            default: new docx.Footer({
              children: [
                new docx.Paragraph({
                  alignment: docx.AlignmentType.LEFT,
                  children: [
                    new docx.TextRun({
                      children: ["Page | ", docx.PageNumber.CURRENT],
                    }),
                  ],
                }),
                new docx.Paragraph(footerLogo),
              ],
            }),
          },
          children: [...createControlListData()],
        });
      }
      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: "Disclaimer",
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "The analysis, data, and reports in CSET ® are provided “as is” for informational purposes only. The Department of Homeland Security (DHS) does not provide any warranties of any kind regarding any information contained within. In no event shall the United States Government or its contractors or subcontractors be liable for any damages, including but not limited to, direct, indirect, special, or consequential damages and including damages based on any negligence of the United States Government or its contractors or subcontractors, arising out of, resulting from, or in any way connected with this report, whether based on warranty, contract, tort, or otherwise, whether injury was sustained from, or arose out of the results of, or reliance upon the report. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "DHS does not endorse any commercial product or service, including the subject of the assessment or evaluation in this report. Any reference to specific commercial products, processes, or services by trademark, manufacturer, or otherwise, does not constitute or imply its endorsement, recommendation, or favoring by DHS. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "The display of the DHS official seal or other DHS visual identities on this report shall not be interpreted to provide the recipient organization authorization to use the official seal, insignia, or other visual identities of DHS. The DHS seal, insignia, or other visual identities shall not be used in any manner to imply endorsement of any commercial product or activity by DHS or the United States Government. Use of the DHS seal without proper authorization violates federal law (e.g., 18 U.S.C. §§ 506, 701, 1017), and is against DHS policies governing usage of the seal. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
          new docx.Paragraph({
            text: "The report is prepared and intended for internal use by the organization that made the request. The contents of this report may be subject to government or private intellectual property rights. To request distribution of this report outside the organization for which it was prepared, contact the CSET Program Office. The contents of this report may be reproduced or incorporated into other reports, but may not be modified without the prior express written permission of the CSET Program Office. ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
            tabStops: [
              {
                type: docx.TabStopType.LEFT,
                position: docx.TabStopPosition.MAX,
              },
            ],
          }),
        ],
      });
      docx.Packer.toBlob(doc).then(async (blob) => {
        if (from && from == "result") {
          await Promise.resolve().then(() => {
            data = blob;
            data["name"] = "Site_Cybersecurity_Plan.docx";

            handleChange(data);
            setLoading(false);
          }).catch((error)=>{
            Sentry.captureException(error)
          })
        } else {
          await Promise.resolve(
            saveAs(blob, "Site_Cybersecurity_Plan.docx")
          ).then(() => {
            setLoading(false);
          }).catch((error)=>{
            Sentry.captureException(error)
          })
        }
      });
    }
  };

  const startLoader = async () => {
    setLoading(true);
    setTimeout(function () {
      generatePdf();
    }, 1000);
  };

  const generatePdf = async () => {
    try {
      setLoading(true);
      const pdf: any = new jsPDF();
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(12);
      // cover page
      pdf.addImage(bannerImage, "JPEG", 10, 10, 190, 125);
      pdf.setDrawColor(0);
      pdf.setTextColor(75, 0, 121);
      pdf.setFontSize(40);
      pdf.setFont("helvetica", "bold");
      pdf.text("Site Cybersecurity", 20, 150);
      pdf.text("Plan Report", 20, 165);
      pdf.setFontSize(20);
      const date = moment().toDate();
      const formatedDate = moment(date).format("DD MMMM YYYY");
      pdf.text(formatedDate, 20, 180);
      pdf.setFontSize(10);
      pdf.text("Prepared For", 20, 200);
      pdf.text("Prepared By", 110, 200);
      pdf.setDrawColor(75, 0, 121);
      pdf.setLineWidth(0.75);
      pdf.line(100, 200, 100, 240);
      if (cpaImage !== undefined) {
        pdf.addImage(
          cpaImage,
          "JPEG",
          20,
          205,
          fitImg(18, cpaImageDimensions),
          18
        );
      }
      if (partnerImage !== undefined) {
        pdf.addImage(
          partnerImage,
          "JPEG",
          107,
          205,
          fitImg(18, partnerImageDimensions),
          18
        );
      }
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      pdf.text(param.clientInfo.name, 20, 235);
      pdf.text(partnerData.contact_id.name, 110, 235);
      pdf.text("", 110, 240);

      // third page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121);
      pdf.text("Introduction", 10, 30);
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      const introduction = pdf.splitTextToSize(
        "Template instructions and directives are given in italicized 10 point font and should be replaced appropriately. ",
        180
      );
      pdf.text(introduction, 10, 40);
      const sp2 = pdf.splitTextToSize(
        "This security plan template is intended to be used as a tool for the development of a security plan. This template will assist you in identifying the controls in place and those needing further implementation based upon the answers provided in the accompanying Cybersecurity Evaluation Tool (CSET) assessment. The basic process for this plan development would be to first determine risk, second select the countermeasures necessary to mitigate the risk to an acceptable level, and finally follow through to ensure that the countermeasures are implemented to the expected level. ",
        180
      );
      pdf.text(sp2, 10, 50);
      const sp3 = pdf.splitTextToSize(
        "The purpose of the security plan is to provide an overview of the security requirements of the system and describe the controls in place or planned, for meeting those requirements. The site cyber security plan also delineates responsibilities and expected behavior of all individuals who access the system. The security plan should be viewed as documentation of the structured process of planning adequate, cost effective security protection for a system. It should reflect input from various managers with responsibilities concerning the system, including information owners, the system operator, and the system security manager. Additional information may be included in the basic plan and the structure and format organized according to agency needs, so long as the major sections described in this document are adequately covered and readily identifiable.  ",
        180
      );
      pdf.text(sp3, 10, 80);
      const intoPara4 = pdf.splitTextToSize(
        "The System Owner is responsible for ensuring that the security plan is prepared and for implementing the plan and monitoring its effectiveness. Security plans should reflect input from various individuals with responsibilities concerning the system, including functional “end users,” Information Owners, the System Administrator, and the System Security Manager  ",
        180
      );
      pdf.text(intoPara4, 10, 118);
      pdf.text("This template may also include:  ", 10, 142);
      const intoSubParas1 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "Recommended templates for policies or procedures that have been identified as needed but not currently available based on the assessment answers. ",
        180
      );
      pdf.text(intoSubParas1, 10, 152);
      pdf.text("\u2022" + "  " + "The basic network diagram  ", 10, 165);
      const intoSubParas2 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "An Inventory List of the components included in the diagram that will be associated with specific controls.  ",
        180
      );
      pdf.text(intoSubParas2, 10, 173);
      const intoSubParas3 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "The List of recommended security controls along with a status as can be determined from the assessment questions. ",
        180
      );
      pdf.text(intoSubParas3, 10, 182);
      const intoSubParas4 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "A recommended implementation priorities list. This priority is based on incident occurrences on the Industrial Control System Cyber Emergency Response Team (ICS-CERT) watch floor and cybersecurity expert opinion. These recommendations do not take into account any cost benefit analysis with respect to implementing a control. ",
        180
      );
      pdf.text(intoSubParas4, 10, 195);
      const intoSubParas5 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "Basic security assurance level determinations carried over from the assessment. In developing a security plan it is recommended that a deeper risk analysis is conducted to ensure that the selection of controls is not overly conservative (incurring undo costs) or optimistic (leaving excessive risk exposure). ",
        180
      );
      pdf.text(intoSubParas5, 10, 213);

      //forth page
      pdf.addPage("a4", "p");
      pdf.text("", 10, 30);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121);
      const systemIdentification = pdf.splitTextToSize(
        "1. System Identification ",
        180
      );
      pdf.text(systemIdentification, 10, 30);
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      const systemIdentification1 = pdf.splitTextToSize(
        "Provide a brief (1-2 paragraphs) description of the main system assests and the necessary protection levels for confidentiality, integrity, and availability.   See section 3.1 for a more detailed description of confidentiality, integrity, and availability.  ",
        180
      );
      pdf.text(systemIdentification1, 10, 40);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133);
      const systemIdentification2 = pdf.splitTextToSize(
        "1.1 System Environment  ",
        180
      );
      pdf.text(systemIdentification2, 10, 60);
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      const systemIdentification3 = pdf.splitTextToSize(
        "Provide a brief (1-3 paragraphs) general description of the technical system. Include any environmental or technical factors that raise special security concerns, such as:   ",
        180
      );
      pdf.text(systemIdentification3, 10, 70);
      const systemIdentification4 = pdf.splitTextToSize(
        "\u2022" + "  " + "The system is connected to the Internet; ",
        180
      );
      pdf.text(systemIdentification4, 10, 84);
      const systemIdentification5 = pdf.splitTextToSize(
        "\u2022" + "  " + "It is located in a harsh or overseas environment; ",
        180
      );
      pdf.text(systemIdentification5, 10, 94);
      const systemIdentification6 = pdf.splitTextToSize(
        "\u2022" + "  " + "Software is rapidly implemented; ",
        180
      );
      pdf.text(systemIdentification6, 10, 104);
      const systemIdentification7 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "The software resides on an open network used by the general public or with overseas access; ",
        180
      );
      pdf.text(systemIdentification7, 10, 114);
      const systemIdentification8 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "The application is processed at a facility outside of the organization's control; or ",
        180
      );
      pdf.text(systemIdentification8, 10, 124);
      const systemIdentification9 = pdf.splitTextToSize(
        "\u2022" + "  " + "The general support mainframe has dial-up lines. ",
        180
      );
      pdf.text(systemIdentification9, 10, 134);
      const systemIdentification10 = pdf.splitTextToSize(
        "Describe the primary computing platform(s) used (e.g., mainframe, desk top, LAN or Wide Area Network (WAN). Include a general description of the principal system components, including hardware, software, and communications resources. Discuss the type of communications included (e.g., dedicated circuits, dial circuits, public data/voice networks, Internet). Describe controls used to protect communication lines in the appropriate sections of the security plan.  ",
        180
      );
      pdf.text(systemIdentification10, 10, 144);
      const systemIdentification11 = pdf.splitTextToSize(
        "Include any security software protecting the system and information. Describe in general terms the type of security protection provided (e.g., access control to the computing platform and stored files at the operating system level or access to data records within an application). Include only controls that have been implemented or planned, rather than listing the controls that are available in the software. Controls that are available, but not implemented, provide no protection. ",
        180
      );
      pdf.text(systemIdentification11, 10, 170);

      // fifth page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121);
      const rolesResponsibilities = pdf.splitTextToSize(
        "2. Roles and Responsibilities ",
        180
      );
      pdf.text(rolesResponsibilities, 10, 30);
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      const rolesResponsibilities1 = pdf.splitTextToSize(
        "This section defines the roles and responsibilities for cybersecurity within the company. Use this section to define the roles and responsibilities with respect to this plan for your company. ",
        180
      );
      pdf.text(rolesResponsibilities1, 10, 40);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133);
      const rolesResponsibilities2 = pdf.splitTextToSize(
        "2.1 Executive Management ",
        180
      );
      pdf.text(rolesResponsibilities2, 10, 57);
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      const rolesResponsibilities3 = pdf.splitTextToSize(
        "Often this role is comprised of the Board of Directors and CEO. Executive management is ultimately responsible for the security of the organization but will most likely delegate tasks and actual implementation. ",
        180
      );
      pdf.text(rolesResponsibilities3, 10, 65);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133);
      const rolesResponsibilities4 = pdf.splitTextToSize(
        "2.2 Chief Security Officer or Chief Information Security Officer (CISO) ",
        180
      );
      pdf.text(rolesResponsibilities4, 10, 85);
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      const rolesResponsibilities5 = pdf.splitTextToSize(
        "CSO or CISO is the senior level executive within an organization responsible for establishing and maintaining the enterprise vision, strategy and program to ensure information assets are adequately protected. The CISO directs staff in identifying, developing, implementing and maintaining processes across the organization to reduce information and information technology (IT) risks, respond to incidents, establish appropriate standards and controls, and direct the establishment and implementation of policies and procedures. The CISO is also usually responsible for information related compliance. ",
        180
      );
      pdf.text(rolesResponsibilities5, 10, 95);
      const rolesResponsibilities6 = pdf.splitTextToSize(
        "Typically, the CISO's influence reaches the whole organization. Responsibilities include: ",
        180
      );
      pdf.text(rolesResponsibilities6, 10, 125);
      const rolesResponsibilities7 = pdf.splitTextToSize(
        "\u2022" + "  " + "Information security and information assurance ",
        180
      );
      pdf.text(rolesResponsibilities7, 10, 135);
      const rolesResponsibilities8 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "Information regulatory compliance (e.g., US PCI DSS, FISMA, GLBA, HIPAA; UK Data Protection Act 1998; Canada PIPEDA) ",
        180
      );
      pdf.text(rolesResponsibilities8, 10, 145);
      const rolesResponsibilities9 = pdf.splitTextToSize(
        "\u2022" + "  " + "Information risk management ",
        180
      );
      pdf.text(rolesResponsibilities9, 10, 160);
      const rolesResponsibilities10 = pdf.splitTextToSize(
        "\u2022" + "  " + "Supply chain risk management ",
        180
      );
      pdf.text(rolesResponsibilities10, 10, 170);
      const rolesResponsibilities11 = pdf.splitTextToSize(
        "\u2022" + "  " + "Cybersecurity ",
        180
      );
      pdf.text(rolesResponsibilities11, 10, 180);
      const rolesResponsibilities12 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "Information technology controls for financial and other systems  ",
        180
      );
      pdf.text(rolesResponsibilities12, 10, 190);
      const rolesResponsibilities13 = pdf.splitTextToSize(
        "\u2022" + "  " + "Information privacy ",
        180
      );
      pdf.text(rolesResponsibilities13, 10, 200);
      const rolesResponsibilities14 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "Computer Emergency Response Team / Computer Security Incident Response Team ",
        180
      );
      pdf.text(rolesResponsibilities14, 10, 210);
      const rolesResponsibilities15 = pdf.splitTextToSize(
        "\u2022" + "  " + "Identity and access management ",
        180
      );
      pdf.text(rolesResponsibilities15, 10, 220);
      const rolesResponsibilities16 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "Security Architecture (e.g. Sherwood Applied Business Security Architecture) ",
        180
      );
      pdf.text(rolesResponsibilities16, 10, 230);
      const rolesResponsibilities17 = pdf.splitTextToSize(
        "\u2022" + "  " + "IT investigations, digital forensics, eDiscovery ",
        180
      );
      pdf.text(rolesResponsibilities1, 10, 240);
      const rolesResponsibilities18 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "Disaster recovery and business continuity management ",
        180
      );
      pdf.text(rolesResponsibilities18, 10, 250);
      const rolesResponsibilities19 = pdf.splitTextToSize(
        "\u2022" + "  " + "Information Security Operations Center (ISOC) ",
        180
      );
      pdf.text(rolesResponsibilities19, 10, 260);

      // sixth page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133);
      const securitySteeringCommitee = pdf.splitTextToSize(
        "2.3 Security Steering Committee ",
        180
      );
      pdf.text(securitySteeringCommitee, 10, 30);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(10);
      const securitySteeringCommitee1 = pdf.splitTextToSize(
        "The security steering committee is composed of a representative of all the key stakeholders in IT security.These stake holders are often representatives of the executive council, CISO or CSO, IT management, physical security personnel, help desk, and key application and digitial asset owners. This committee meets regularly often quarterly to review policies and procedures, security controls implementation progress, and determine future direction for security within a company. ",
        180
      );
      pdf.text(securitySteeringCommitee1, 10, 40);
      const securitySteeringCommitee2 = pdf.splitTextToSize(
        "The security steering committee is responsible for making decisions on tactical and strategic security issues within the enterprise as a whole and should not be tied to one or more business units. The group should be made up of people from all over the organization so they can view risks and the effects of security decisions on individual departments and the organization as a whole. The CEO should head this committee, and the CFO, CIO, department managers, and chief internal auditor should all be on it. This committee should meet at least quarterly and have a well defined agenda. Some of the group’s responsibilities are listed next: ",
        180
      );
      pdf.text(securitySteeringCommitee2, 10, 67);
      const securitySteeringCommitee3 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "Define the acceptable risk level for the organization. ",
        180
      );
      pdf.text(securitySteeringCommitee3, 10, 100);
      const securitySteeringCommitee4 = pdf.splitTextToSize(
        "\u2022" + "  " + "Develop security objectives and strategies. ",
        180
      );
      pdf.text(securitySteeringCommitee4, 10, 110);
      const securitySteeringCommitee5 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "Determine priorities of security initiatives based on business needs. ",
        180
      );
      pdf.text(securitySteeringCommitee5, 10, 120);
      const securitySteeringCommitee6 = pdf.splitTextToSize(
        "\u2022" + "  " + "Review risk assessment and auditing reports. ",
        180
      );
      pdf.text(securitySteeringCommitee6, 10, 130);
      const securitySteeringCommitee7 = pdf.splitTextToSize(
        "\u2022" + "  " + "Monitor the business impact of security risks. ",
        180
      );
      pdf.text(securitySteeringCommitee7, 10, 140);
      const securitySteeringCommitee8 = pdf.splitTextToSize(
        "\u2022" + "  " + "Review major security breaches and incidents.  ",
        180
      );
      pdf.text(securitySteeringCommitee8, 10, 150);
      const securitySteeringCommitee9 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "Approve any major change to the security policy and program. ",
        180
      );
      pdf.text(securitySteeringCommitee9, 10, 160);
      const securitySteeringCommitee10 = pdf.splitTextToSize(
        "They should also have a clearly defined vision statement in place that is set up to work with and support the organizational intent of the business. The statement shouldbe structured in a manner that providessupport for the goals of confidentiality, integrity, and availability as they pertain to the businessobjectives of the organization. This in turn should be followed, or supported, by a mission statement that provides support and definition to the processes that will apply to the organization and allow it to reach its business goals. ",
        180
      );
      pdf.text(securitySteeringCommitee10, 10, 170);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133);
      const systemOwners = pdf.splitTextToSize("2.4 System Owners ", 180);
      pdf.text(systemOwners, 10, 200);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(10);
      const systemOwners1 = pdf.splitTextToSize(
        "The system owner is responsible for one or more systems, each of which may hold and process data owned by different data owners. A system owner is responsible for integrating security considerations into application and system purchasing decisions and development projects. The system owner is responsible for ensuring that adequate security is being provided by the necessary controls, password management, remote access controls, operating system configurations, and so on. This role must ensure the systems are properly assessed for vulnerabilities and must report any to the incident response team and data owner. ",
        180
      );
      pdf.text(systemOwners1, 10, 210);

      // seventh page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133);
      const dataOwners = pdf.splitTextToSize("2.5 Data Owners ", 180);
      pdf.text(dataOwners, 10, 30);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(10);
      const dataOwners1 = pdf.splitTextToSize(
        "The data owner (information owner) is usually a member of management who is incharge of a specific business unit, and who is ultimately responsible for the protection and use of a specific subset ofinformation. The data owner has due care responsibilities and thus will be held responsible for any negligent act that results in the corruption or disclosure of the data. The data owner decides upon the classification of the data he is responsible for and alters that classification if the business needarises. This person is also responsible for ensuring that the necessary security controls are in place, defining security requirements per classification and backup requirements, approving any disclosure activities, ensuring that proper access rights are being used, and defining user access criteria. The data owner approves access requests or may choose to delegate this function to business unit managers. And it is the data owner who will deal with security violations pertaining to the data he is responsible for protecting. The data owner, who obviously has enough on his plate, delegates responsibility of the day-to-day maintenance of the data protection mechanisms to the data custodian. ",
        180
      );
      pdf.text(dataOwners1, 10, 40);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133);
      const securityAdmin = pdf.splitTextToSize(
        "2.6 Security Administrators  ",
        180
      );
      pdf.text(securityAdmin, 10, 100);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(10);
      const securityAdmin1 = pdf.splitTextToSize(
        "Anyone who has a root account on Unix or Linux systems or an administrator account on Windows or Macintosh systems actually has security administrator rights. (Unfortunately, too many people have these accounts in most environments.) This means they can give and take away permissions and set security configurations.However, just because a person has a root or administrator account does not mean they are fulfilling the security administrator role. A security administrator’s tasks are many, and include creating new system user accounts, implementing new security software, testing security patches and components, and issuing new passwords. The security administrator should not actually approve new system user accounts. This is the responsibility of the supervisor. The security administrator must make sure access rights given to users support the policies and data owner directives. ",
        180
      );
      pdf.text(securityAdmin1, 10, 110);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133);
      const supervisorManagers = pdf.splitTextToSize(
        "2.7 Supervisors/Managers ",
        180
      );
      pdf.text(supervisorManagers, 10, 160);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(10);
      const supervisorManagers1 = pdf.splitTextToSize(
        "The supervisor role, also called user manager, is ultimately responsible for all user activity and any assets created and owned by these users. The supervisor responsibilities would include ensuring that employees understand their responsibilities with respect to security, distributing initial passwords, making sure the employees’ account information is up-to-date, and informing the security administrator when an employee is fired, suspended, or transferred. Any change that pertains to an employee’s role within the company usually affects what access rights they should and should not have, so the user manager must inform the security administrator of these changes immediately. ",
        180
      );
      pdf.text(supervisorManagers1, 10, 170);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133);
      const Usersp = pdf.splitTextToSize("2.8 Users ", 180);
      pdf.text(Usersp, 10, 215);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(10);
      const Usersp1 = pdf.splitTextToSize(
        "The user is any individual who routinely uses the data for work related tasks. The user must have the necessary level of access to the data to perform the duties within their position and is responsible for following operational security procedures to ensure the data’s confidentiality, integrity, and availability to others. ",
        180
      );
      pdf.text(Usersp1, 10, 225);

      // eighth page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121);
      const riskAnalysis = pdf.splitTextToSize("3. Risk Analysis ", 180);
      pdf.text(riskAnalysis, 10, 30);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(10);
      const riskAnalysis1 = pdf.splitTextToSize(
        "A good security plan will require that a risk evaluation is performed to determine the level of necessary rigor and cost benefit analysis for the level of controls selected. It is recommended that a general risk analysis be performed. A good risk assessment should include an evaluation of the value of the protected assets and information, an examination of the consequences to the organization in the event of a successful attack, an examination of the threat if possible, and the cost of implementing mitigating controls. ",
        180
      );
      pdf.text(riskAnalysis1, 10, 40);
      const riskAnalysis2 = pdf.splitTextToSize(
        "threats × vulnerability × asset value = total risk ",
        180
      );
      pdf.text(riskAnalysis2, 10, 65);
      const riskAnalysis3 = pdf.splitTextToSize(
        "total risk – countermeasures = residual risk ",
        180
      );
      pdf.text(riskAnalysis3, 10, 75);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133);
      const consequences = pdf.splitTextToSize("Consequence ", 180);
      pdf.text(consequences, 10, 90);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(10);
      const consequencesp1 = pdf.splitTextToSize(
        "The examination of the consequences of an attack should include: ",
        180
      );
      pdf.text(consequencesp1, 10, 100);
      const consequencesSubp2 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "How many people could sustain injuries requiring a hospital stay? ",
        180
      );
      pdf.text(consequencesSubp2, 10, 110);
      const consequencesSubp3 = pdf.splitTextToSize(
        "\u2022" + "  " + "How many people could be killed? ",
        180
      );
      pdf.text(consequencesSubp3, 10, 120);
      const consequencesSubp4 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "Determine priorities of security initiatives based on business needs. ",
        180
      );
      pdf.text(consequencesSubp4, 10, 130);
      const consequencesSubp5 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "Estimate the potential cost of losing capital assets or the overall economic impact. (Consider the cost of site buildings, facilities, equipment, etc.) ",
        180
      );
      pdf.text(consequencesSubp5, 10, 140);
      const consequencesSubp6 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "Estimate the potential cost in terms of economic impact to both the site and surrounding communities. (Consider any losses to community structures and any costs associated with displacement.) ",
        180
      );
      pdf.text(consequencesSubp6, 10, 155);
      const consequencesSubp7 = pdf.splitTextToSize(
        "\u2022" +
          "  " +
          "Estimate the potential cost of environmental cleanup to the site and surrounding communities. (Consider the cost for cleanup, fines, litigation, long term monitoring, etc.) ",
        180
      );
      pdf.text(consequencesSubp7, 10, 170);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133);
      const threatP = pdf.splitTextToSize("Threat ", 180);
      pdf.text(threatP, 10, 190);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(10);
      const threatP1 = pdf.splitTextToSize(
        "The threat portion of the equation can be deduced from the recommended implementation priorities list. The priorities are based on incident data collected by the ICS-CERT watch floor and subject matter experts as of the time of publication of CSET. Top priorities are controls that mitigate the most actively exploited vulnerabilities with the most significant consequences. ",
        180
      );
      pdf.text(threatP1, 10, 200);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133);
      const costBeneifitAnalysis = pdf.splitTextToSize(
        "Cost Benefit Analysis  ",
        180
      );
      pdf.text(costBeneifitAnalysis, 10, 230);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(10);
      const costBeneifitAnalysis1 = pdf.splitTextToSize(
        "The cost of implementing controls with respect to the additional security provided is the final step in selecting the controls to implement. ",
        180
      );
      pdf.text(costBeneifitAnalysis1, 10, 240);

      // nineth page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133);
      const basicModel = pdf.splitTextToSize("3.1 Basic Model ", 180);
      pdf.text(basicModel, 10, 30);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(10);
      const basicModel1 = pdf.splitTextToSize(
        "Traditional security models define three areas of consideration Confidentiality, Integrity, and Availability. The security plan should address each of these areas with respect to data and systems. ",
        180
      );
      pdf.text(basicModel1, 10, 40);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 0);
      const confidentiality = pdf.splitTextToSize(
        "3.1.1 Confidentiality ",
        180
      );
      pdf.text(confidentiality, 10, 55);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(10);
      const confidentiality1 = pdf.splitTextToSize(
        "Confidentiality refers to preventing the disclosure of information to unauthorized individuals or systems. For example, a credit card transaction on the Internet requires the credit card number to be transmitted from the buyer to the merchant and from the merchant to a transaction processing network. The system attempts to enforce confidentiality by encrypting the card number during transmission, by limiting the places where it might appear (in databases, log files, backups, printed receipts, and so on), and by restricting access to the places where it is stored. If an unauthorized party obtains the card number in any way, a breach of confidentiality has occurred. ",
        180
      );
      pdf.text(confidentiality1, 10, 65);
      const confidentiality2 = pdf.splitTextToSize(
        "Confidentiality is necessary for maintaining the privacy of the people whose personal information a system holds.  ",
        180
      );
      pdf.text(confidentiality2, 10, 95);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 0);
      const integrity = pdf.splitTextToSize("3.1.2 Integrity ", 180);
      pdf.text(integrity, 10, 110);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(10);
      const integrity1 = pdf.splitTextToSize(
        "In information security, data integrity means maintaining and assuring the accuracy and consistency of data over its entire life cycle. This means that data cannot be modified in an unauthorized or undetected manner. This is not the same thing as referential integrity in databases, although it can be viewed as a special case of Consistency as understood in the classic ACID (Atomicity, Consistency, Isolation, Durability) model of transaction processing. Integrity is violated when a message is actively modified in transit. Information security systems typically provide message integrity in addition to data confidentiality. ",
        180
      );
      pdf.text(integrity1, 10, 117);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 0);
      const availability = pdf.splitTextToSize("3.1.3 Availability ", 180);
      pdf.text(availability, 10, 152);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(10);
      const availability1 = pdf.splitTextToSize(
        "For any information system to serve its purpose, the information must be available when it is needed. This means that the computing systems used to store and process the information, the security controls used to protect it, and the communication channels used to access it must be functioning correctly. High availability systems aim to remain available at all times, preventing service disruptions due to power outages, hardware failures, and system upgrades. Ensuring availability also involves preventing denial-of-service attacks. ",
        180
      );
      pdf.text(availability1, 10, 160);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133);
      const securityAssuranceLevel = pdf.splitTextToSize(
        "3.2 Security Assurance Level (SAL) ",
        180
      );
      pdf.text(securityAssuranceLevel, 10, 195);
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      autoTable(pdf, {
        head: [["CALCULATED LEVEL"]],
        body: [[salTableData.osv]],
        tableLineColor: [217, 210, 233],
        theme: "grid",
        margin: { top: 200, left: 10, right: 160 },
        headStyles: {
          fillColor: [217, 210, 233],
          textColor: [75, 0, 121],
          lineWidth: 0.1,
          lineColor: "#000",
        },
        columnStyles: {
          0: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          1: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
        },
      });
      autoTable(pdf, {
        head: [[" ", "Confidentiality", "Integrity", "Availability"]],
        body: [
          [
            "Overall Values",
            salTableData.q_CV,
            salTableData.q_IV,
            salTableData.q_AV,
          ],
        ],
        margin: { top: 245, left: 10, right: 50 },
        theme: "grid",
        headStyles: {
          fillColor: [217, 210, 233],
          textColor: [75, 0, 121],
          lineWidth: 0.1,
          lineColor: "#000",
        },
        columnStyles: {
          0: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          1: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          2: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          3: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
        },
      });
      //tenth page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121);
      const securityplanControls = pdf.splitTextToSize(
        "4. Security Plan Controls and Status List ",
        180
      );
      pdf.text(securityplanControls, 10, 30);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(10);
      const securityplanControls1 = pdf.splitTextToSize(
        "This section lists all the controls which constitute the security plan and implementation status. To enable easier reading of the controls list a table key is included at the start of this section. In this section the terms Control and Requirement are used interchangeably to indicate the mitigation effort as defined in the given standard. ",
        180
      );
      pdf.text(securityplanControls1, 10, 40);
      pdf.setFont("helvetica", "bold");
      const securityplanControlsTable = pdf.splitTextToSize(
        "Table Key and Field Descriptions: ",
        180
      );
      pdf.text(securityplanControlsTable, 10, 56);
      pdf.setFont("helvetica", "normal  ");
      autoTable(pdf, {
        html: "#secondTable",
        margin: { top: 63, left: 10 },
        theme: "grid",
        headStyles: {
          fillColor: [243, 242, 250],
          lineWidth: 0.1,
          lineColor: "#000",
        },
        columnStyles: {
          0: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          1: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          2: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          3: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          4: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          5: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          6: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
        },
      });
      pdf.setFont("helvetica", "bold");
      pdf.text("Requirement Title:", 10, 120);
      const requirementTitle = pdf.splitTextToSize("is derived ", 180);
      pdf.setFont("helvetica", "normal");
      pdf.text(
        "Is the control title as it is generally defined in the standard document from which this control",
        45,
        120
      );
      pdf.text(requirementTitle, 10, 125);
      pdf.setFont("helvetica", "bold");
      pdf.text("CSET Question Category: ", 10, 135);
      const csetQuestionsCategory1 = pdf.splitTextToSize(
        "Questions from multiple standards have been consolidated together in the CSET tool and assigned a common category. ",
        210
      );
      pdf.setFont("helvetica", "normal");
      pdf.text(
        "Shows the CSET question category from the global questions list. ",
        55,
        135
      );
      pdf.text(csetQuestionsCategory1, 10, 140);
      pdf.setFont("helvetica", "bold");
      pdf.text("Control Level:  ", 10, 150);
      const controlLevel = pdf.splitTextToSize(
        "not defined for the given information type in the standard. ",
        180
      );
      pdf.setFont("helvetica", "normal");
      pdf.text(
        "Mapped to one of Low, Moderate, High, or Very High. A value of none indicates that the level was ",
        36,
        150
      );
      pdf.text(controlLevel, 10, 155);
      pdf.setFont("helvetica", "bold");
      pdf.text("Implementation Status:  ", 10, 165);
      const implementationStatus = pdf.splitTextToSize(
        "related questions for this control. The percentage implemented will not necessarily be reflective of the amount of work required to implement the control but is merely an indicator of how many of the questions related to the control have been addressed so far. ",
        200
      );
      pdf.setFont("helvetica", "normal");
      pdf.text(
        "Shows the percentage complete as the number of yes and alternate answers / total",
        52,
        165
      );
      pdf.text(implementationStatus, 10, 170);
      pdf.setFont("helvetica", "bold");
      pdf.text("Short Standard Name: ", 10, 188);
      const shortStandardName = pdf.splitTextToSize(
        "An indicator of which standard this control is derived from. ",
        160
      );
      pdf.setFont("helvetica", "normal");
      pdf.text(shortStandardName, 50, 188);
      pdf.setFont("helvetica", "bold");
      pdf.text("Control Description: ", 10, 198);
      const controlDescription = pdf.splitTextToSize(
        "The full control text as defined in the standard from which the control is derived.",
        160
      );
      pdf.setFont("helvetica", "normal");
      pdf.text(controlDescription, 45, 198);
      // pdf.text("derived. ",10,215)
      pdf.setFont("helvetica", "bold");
      pdf.text("Affected Zones: ", 10, 210);
      const affectedZones = pdf.splitTextToSize(
        "assessment this field will contain a list of zone in which atleast one component was found to require this control.  ",
        190
      );
      pdf.setFont("helvetica", "normal");
      pdf.text(
        "Only applicable to controls derived from a diagram. If you have included a diagram in your original",
        38,
        210
      );
      pdf.text(affectedZones, 10, 215);
      pdf.setFont("helvetica", "bold");
      pdf.text("Affected Components: ", 10, 228);
      const affectedComponents = pdf.splitTextToSize(
        " This field contains a list of the components that are directly applicable to this control.",
        160
      );
      pdf.setFont("helvetica", "normal");
      pdf.text(affectedComponents, 48, 228);
      // pdf.text("control. ",10,250)
      pdf.setFont("helvetica", "bold");
      pdf.text("Related Questions and Answers: ", 10, 238);
      const relatedQuestionAnswers = pdf.splitTextToSize(
        "this control was determined. ",
        140
      );
      pdf.setFont("helvetica", "normal");
      pdf.text(
        "A list of the questions and answers from which the implementation status of",
        68,
        238
      );
      pdf.text(relatedQuestionAnswers, 10, 243);

      // eleventh page
      if (controlListData && controlListData.length) {
        pdf.addPage("a4", "p");
        const startY = 30;
        controlListData.map((element: any, i: any) => {
          autoTable(pdf, {
            html: "#thirdTable" + i,
            margin: { top: startY },
            pageBreak: "avoid",
            theme: "grid",
            headStyles: {
              fillColor: [243, 242, 250],
              lineWidth: 0.1,
              lineColor: "#000",
            },
            columnStyles: {
              0: {
                lineWidth: 0.1,
                lineColor: "#000",
              },
              1: {
                lineWidth: 0.1,
                lineColor: "#000",
              },
              2: {
                lineWidth: 0.1,
                lineColor: "#000",
              },
              3: {
                lineWidth: 0.1,
                lineColor: "#000",
              },
              4: {
                lineWidth: 0.1,
                lineColor: "#000",
              },
              5: {
                lineWidth: 0.1,
                lineColor: "#000",
              },
              6: {
                lineWidth: 0.1,
                lineColor: "#000",
              },
            },
          });
        });
      }
      pdf.addPage("a4", "p");
      pdf.setFontSize(24);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121);
      pdf.text("Disclaimer", 10, 30);
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      const disclaimer = pdf.splitTextToSize(
        "The analysis, data, and reports in CSET ® are provided “as is” for informational purposes only.The Department of Homeland Security (DHS) does not provide any warranties of any kind regarding any information contained within. In no event shall the United States Government or its contractors or subcontractors be liable for any damages, including but not limited to, direct, indirect, special, or consequential damages and including damages based on any negligence of the United States Government or its contractors or subcontractors, arising out of, resulting from, or in any way connected with this report, whether based on warranty, contract, tort, or otherwise, whether injury was sustained from, or arose out of the results of, or reliance upon the report. ",
        180
      );
      pdf.text(disclaimer, 10, 40);
      const disclaimer1 = pdf.splitTextToSize(
        "DHS does not endorse any commercial product or service, including the subject of the assessment or evaluation in this report. Any reference to specific commercial products, processes, or servicesby trademark, manufacturer, or otherwise, does not constitute or imply its endorsement, recommendation, or favoring by DHS. ",
        180
      );
      pdf.text(disclaimer1, 10, 75);
      const disclaimer2 = pdf.splitTextToSize(
        "The display of the DHS official seal or other DHS visual identities on this report shall not be interpreted to provide the recipient organization authorization to use the official seal, insignia, or other visual identities of DHS. The DHS seal, insignia, or other visual identities shall not be used in any manner to imply endorsement of any commercial product or activity by DHS or the United States Government. Use of the DHS seal without proper authorization violates federal law (e.g., 18 U.S.C. §§ 506, 701, 1017), and is against DHS policies governing usage of the seal. ",
        180
      );
      pdf.text(disclaimer2, 10, 93);
      const disclaimer3 = pdf.splitTextToSize(
        "The report is prepared and intended for internal use by the organization that made the request. The contents of this report may be subject to government or private intellectual property rights. To request distribution of this report outside the organization for which it was prepared, contact the CSET Program Office. The contents of this report may be reproduced or incorporated into other reports, but may not be modified without the prior express written permission of the CSET Program Office. ",
        180
      );
      pdf.text(disclaimer3, 10, 120);

      //header and footer
      const pageCount = pdf.internal.getNumberOfPages();
      for (let i = 0; i <= pageCount; i++) {
        if (i > 1) {
          pdf.setPage(i);
          pdf.setFont("helvetica", "normal");
          pdf.setFontSize(10);
          pdf.setTextColor(0, 0, 0); //paragraph black color
          pdf.text(
            `Page | ${pdf.internal.getCurrentPageInfo().pageNumber - 1}`,
            10,
            290
          );
          const width = pdf.internal.pageSize.getWidth();
          const height = pdf.internal.pageSize.getHeight();
          if (partnerImage !== undefined) {
            pdf.addImage(
              partnerImage,
              "JPEG",
              180,
              270,
              width / 10,
              height / 14
            );
          }
        }
      }

      await Promise.resolve(pdf.save("Site_Cybersecurity_Plan_Report.pdf"))
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          Sentry.captureException(error)
        });
    } catch(error) {
      setLoading(false);
      Sentry.captureException(error)
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container>
        <Grid item md={12}>
          <Typography component="h5" variant="h1">
            {"Site Cybersecurity Plan"}
          </Typography>
          <Grid item xs={12} sm={6} className={styles.FooterActions}>
            <div className={styles.prevBtn}>
              <Button
                id="previous-button1"
                variant="contained"
                color="primary"
                onClick={handleBack}
              >
                {"Previous"}
              </Button>
            </div>
            <div className={styles.borderButton}>
              <Button
                id="doc-generate-button1"
                variant="contained"
                color="primary"
                onClick={() => generate()}
              >
                <GetAppIcon className={styles.EditIcon} />
                &nbsp; Doc
              </Button>
              <Button
                id="pdf-generate-button1"
                variant="contained"
                color="primary"
                onClick={() => startLoader()}
              >
                <GetAppIcon className={styles.EditIcon} />
                &nbsp; PDF
              </Button>
            </div>
          </Grid>
          {formState.isFailed ? (
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {formState.errMessage}
            </Alert>
          ) : null}
          <Paper className={styles.paper}>
            {loading ? <SimpleBackdrop /> : null}

            <Grid container>
              <Grid item xs={8} md={12} sm={8} className={styles.FooterActions}>
                <h3>Introduction</h3>
                <Grid
                  item
                  xs={8}
                  md={12}
                  sm={8}
                  className={styles.FooterActions}
                >
                  <p>
                    {" "}
                    Template instructions and directives are given in italicized
                    10 point font and should be replaced appropriately.{" "}
                  </p>
                  <p>
                    {" "}
                    This security plan template is intended to be used as a tool
                    for the development of a security plan. This template will
                    assist you in identifying the controls in place and those
                    needing further implementation based upon the answers
                    provided in the accompanying Cybersecurity Evaluation Tool
                    (CSET) assessment. The basic process for this plan
                    development would be to first determine risk, second select
                    the countermeasures necessary to mitigate the risk to an
                    acceptable level, and finally follow through to ensure that
                    the countermeasures are implemented to the expected level.
                  </p>
                  <p>
                    {" "}
                    The purpose of the security plan is to provide an overview
                    of the security requirements of the system and describe the
                    controls in place or planned, for meeting those
                    requirements. The site cyber security plan also delineates
                    responsibilities and expected behavior of all individuals
                    who access the system. The security plan should be viewed as
                    documentation of the structured process of planning
                    adequate, cost effective security protection for a system.
                    It should reflect input from various managers with
                    responsibilities concerning the system, including
                    information owners, the system operator, and the system
                    security manager. Additional information may be included in
                    the basic plan and the structure and format organized
                    according to agency needs, so long as the major sections
                    described in this document are adequately covered and
                    readily identifiable.{" "}
                  </p>
                  <p>
                    The System Owner is responsible for ensuring that the
                    security plan is prepared and for implementing the plan and
                    monitoring its effectiveness. Security plans should reflect
                    input from various individuals with responsibilities
                    concerning the system, including functional “end users,”
                    Information Owners, the System Administrator, and the System
                    Security Manager{" "}
                  </p>
                  <p>This template may also include: </p>
                  <ul>
                    <li>
                      Recommended templates for policies or procedures that have
                      been identified as needed but not currently available
                      based on the assessment answers.
                    </li>
                    <li> The basic network diagram </li>
                    <li>
                      {" "}
                      An Inventory List of the components included in the
                      diagram that will be associated with specific controls.{" "}
                    </li>
                    <li>
                      {" "}
                      The List of recommended security controls along with a
                      status as can be determined from the assessment questions.{" "}
                    </li>
                    <li>
                      {" "}
                      A recommended implementation priorities list. This
                      priority is based on incident occurrences on the
                      Industrial Control System Cyber Emergency Response Team
                      (ICS-CERT) watch floor and cybersecurity expert opinion.
                      These recommendations do not take into account any cost
                      benefit analysis with respect to implementing a control.{" "}
                    </li>
                    <li>
                      {" "}
                      Basic security assurance level determinations carried over
                      from the assessment. In developing a security plan it is
                      recommended that a deeper risk analysis is conducted to
                      ensure that the selection of controls is not overly
                      conservative (incurring undo costs) or optimistic (leaving
                      excessive risk exposure).{" "}
                    </li>
                  </ul>
                  <hr className={styles.ruler} />
                </Grid>
                <h3>1. System Identification </h3>
                <Grid
                  item
                  xs={8}
                  md={12}
                  sm={8}
                  className={styles.FooterActions}
                >
                  <p>
                    Provide a brief (1-2 paragraphs) description of the main
                    system assests and the necessary protection levels for
                    confidentiality, integrity, and availability. See section
                    3.1 for a more detailed description of confidentiality,
                    integrity, and availability.{" "}
                  </p>
                  <h3>1.1 System Environment </h3>
                  <p>
                    {" "}
                    Provide a brief (1-3 paragraphs) general description of the
                    technical system. Include any environmental or technical
                    factors that raise special security concerns, such as:{" "}
                  </p>
                  <ul>
                    <li>The system is connected to the Internet;</li>
                    <li> It is located in a harsh or overseas environment; </li>
                    <li> Software is rapidly implemented; </li>
                    <li>
                      {" "}
                      The software resides on an open network used by the
                      general public or with overseas access;{" "}
                    </li>
                    <li>
                      {" "}
                      The application is processed at a facility outside of the
                      organization's control; or{" "}
                    </li>
                    <li> The general support mainframe has dial-up lines. </li>
                  </ul>
                  <p>
                    {" "}
                    Describe the primary computing platform(s) used (e.g.,
                    mainframe, desk top, LAN or Wide Area Network (WAN). Include
                    a general description of the principal system components,
                    including hardware, software, and communications resources.
                    Discuss the type of communications included (e.g., dedicated
                    circuits, dial circuits, public data/voice networks,
                    Internet). Describe controls used to protect communication
                    lines in the appropriate sections of the security plan.{" "}
                  </p>
                  <p>
                    {" "}
                    Include any security software protecting the system and
                    information. Describe in general terms the type of security
                    protection provided (e.g., access control to the computing
                    platform and stored files at the operating system level or
                    access to data records within an application). Include only
                    controls that have been implemented or planned, rather than
                    listing the controls that are available in the software.
                    Controls that are available, but not implemented, provide no
                    protection.{" "}
                  </p>
                  <hr className={styles.ruler} />
                </Grid>
                <h3>2. Roles and Responsibilities </h3>
                <Grid
                  item
                  xs={8}
                  md={12}
                  sm={8}
                  className={styles.FooterActions}
                >
                  <p>
                    This section defines the roles and responsibilities for
                    cybersecurity within the company. Use this section to define
                    the roles and responsibilities with respect to this plan for
                    your company.{" "}
                  </p>
                  <h3> 2.1 Executive Management </h3>
                  <p>
                    {" "}
                    Often this role is comprised of the Board of Directors and
                    CEO. Executive management is ultimately responsible for the
                    security of the organization but will most likely delegate
                    tasks and actual implementation.{" "}
                  </p>
                  <h3>
                    2.2 Chief Security Officer or Chief Information Security
                    Officer (CISO)
                  </h3>
                  <p>
                    {" "}
                    CSO or CISO is the senior level executive within an
                    organization responsible for establishing and maintaining
                    the enterprise vision, strategy and program to ensure
                    information assets are adequately protected. The CISO
                    directs staff in identifying, developing, implementing and
                    maintaining processes across the organization to reduce
                    information and information technology (IT) risks, respond
                    to incidents, establish appropriate standards and controls,
                    and direct the establishment and implementation of policies
                    and procedures. The CISO is also usually responsible for
                    information related compliance.{" "}
                  </p>
                  <p>
                    Typically, the CISO's influence reaches the whole
                    organization. Responsibilities include:{" "}
                  </p>
                  <ul>
                    <li> Information security and information assurance</li>
                    <li>
                      {" "}
                      Information regulatory compliance (e.g., US PCI DSS,
                      FISMA, GLBA, HIPAA; UK Data Protection Act 1998; Canada
                      PIPEDA){" "}
                    </li>
                    <li> Information risk management </li>
                    <li> Supply chain risk management</li>
                    <li> Cybersecurity </li>
                    <li>
                      {" "}
                      Information technology controls for financial and other
                      systems{" "}
                    </li>
                    <li> Information privacy </li>
                    <li>
                      {" "}
                      Computer Emergency Response Team / Computer Security
                      Incident Response Team{" "}
                    </li>
                    <li> Identity and access management </li>
                    <li>
                      {" "}
                      Security Architecture (e.g. Sherwood Applied Business
                      Security Architecture){" "}
                    </li>
                    <li> IT investigations, digital forensics, eDiscovery </li>
                    <li>
                      {" "}
                      Disaster recovery and business continuity management{" "}
                    </li>
                    <li> Information Security Operations Center (ISOC) </li>
                  </ul>
                  <h3>2.3 Security Steering Committee </h3>
                  <p>
                    {" "}
                    The security steering committee is composed of a
                    representative of all the key stakeholders in IT security.
                    These stake holders are often representatives of the
                    executive council, CISO or CSO, IT management, physical
                    security personnel, help desk, and key application and
                    digitial asset owners. This committee meets regularly often
                    quarterly to review policies and procedures, security
                    controls implementation progress, and determine future
                    direction for security within a company.{" "}
                  </p>
                  <p>
                    {" "}
                    The security steering committee is responsible for making
                    decisions on tactical and strategic security issues within
                    the enterprise as a whole and should not be tied to one or
                    more business units. The group should be made up of people
                    from all over the organization so they can view risks and
                    the effects of security decisions on individual departments
                    and the organization as a whole. The CEO should head this
                    committee, and the CFO, CIO, department managers, and chief
                    internal auditor should all be on it. This committee should
                    meet at least quarterly and have a well defined agenda. Some
                    of the group’s responsibilities are listed next:{" "}
                  </p>
                  <ul>
                    <li>
                      {" "}
                      Define the acceptable risk level for the organization.
                    </li>
                    <li>Develop security objectives and strategies. </li>
                    <li>
                      {" "}
                      Determine priorities of security initiatives based on
                      business needs.{" "}
                    </li>
                    <li> Review risk assessment and auditing reports.</li>
                    <li> Monitor the business impact of security risks. </li>
                    <li> Review major security breaches and incidents. </li>
                    <li>
                      {" "}
                      Approve any major change to the security policy and
                      program.{" "}
                    </li>
                  </ul>
                  <p>
                    {" "}
                    They should also have a clearly defined vision statement in
                    place that is set up to work with and support the
                    organizational intent of the business. The statement
                    shouldbe structured in a manner that provides support for
                    the goals of confidentiality, integrity, and availability as
                    they pertain to the business objectives of the organization.
                    This in turn should be followed, or supported, by a mission
                    statement that provides support and definition to the
                    processes that will apply to the organization and allow it
                    to reach its business goals.{" "}
                  </p>
                  <h3>2.4 System Owners </h3>
                  <p>
                    {" "}
                    The system owner is responsible for one or more systems,
                    each of which may hold and process data owned by different
                    data owners. A system owner is responsible for integrating
                    security considerations into application and system
                    purchasing decisions and development projects. The system
                    owner is responsible for ensuring that adequate security is
                    being provided by the necessary controls, password
                    management, remote access controls, operating system
                    configurations, and so on. This role must ensure the systems
                    are properly assessed for vulnerabilities and must report
                    any to the incident response team and data owner.{" "}
                  </p>
                  <h3> 2.5 Data Owners </h3>
                  <p>
                    {" "}
                    The data owner (information owner) is usually a member of
                    management who is incharge of a specific business unit, and
                    who is ultimately responsible for the protection and use of
                    a specific subset of information. The data owner has due
                    care responsibilities and thus will be held responsible for
                    any negligent act that results in the corruption or
                    disclosure of the data. The data owner decides upon the
                    classification of the data he is responsible for and alters
                    that classification if the business need arises. This person
                    is also responsible for ensuring that the necessary security
                    controls are in place, defining security requirements per
                    classification and backup requirements, approving any
                    disclosure activities, ensuring that proper access rights
                    are being used, and defining user access criteria. The data
                    owner approves access requests or may choose to delegate
                    this function to business unit managers. And it is the data
                    owner who will deal with security violations pertaining to
                    the data he is responsible for protecting. The data owner,
                    who obviously has enough on his plate, delegates
                    responsibility of the day-to-day maintenance of the data
                    protection mechanisms to the data custodian.{" "}
                  </p>
                  <h3> 2.6 Security Administrators </h3>
                  <p>
                    {" "}
                    Anyone who has a root account on Unix or Linux systems or an
                    administrator account on Windows or Macintosh systems
                    actually has security administrator rights. (Unfortunately,
                    too many people have these accounts in most environments.)
                    This means they can give and take away permissions and set
                    security configurations. However, just because a person has
                    a root or administrator account does not mean they are
                    fulfilling the security administrator role. A security
                    administrator’s tasks are many, and include creating new
                    system user accounts, implementing new security software,
                    testing security patches and components, and issuing new
                    passwords. The security administrator should not actually
                    approve new system user accounts. This is the responsibility
                    of the supervisor. The security administrator must make sure
                    access rights given to users support the policies and data
                    owner directives.{" "}
                  </p>
                  <h3> 2.7 Supervisors/Managers </h3>
                  <p>
                    {" "}
                    The supervisor role, also called user manager, is ultimately
                    responsible for all user activity and any assets created and
                    owned by these users. The supervisor responsibilities would
                    include ensuring that employees understand their
                    responsibilities with respect to security, distributing
                    initial passwords, making sure the employees’ account
                    information is up-to-date, and informing the security
                    administrator when an employee is fired, suspended, or
                    transferred. Any change that pertains to an employee’s role
                    within the company usually affects what access rights they
                    should and should not have, so the user manager must inform
                    the security administrator of these changes immediately.{" "}
                  </p>
                  <h3> 2.8 Users </h3>
                  <p>
                    {" "}
                    The user is any individual who routinely uses the data for
                    work related tasks. The user must have the necessary level
                    of access to the data to perform the duties within their
                    position and is responsible for following operational
                    security procedures to ensure the data’s confidentiality,
                    integrity, and availability to others.{" "}
                  </p>
                  <hr className={styles.ruler} />
                  <h3> 3. Risk Analysis </h3>
                  <p>
                    A good security plan will require that a risk evaluation is
                    performed to determine the level of necessary rigor and cost
                    benefit analysis for the level of controls selected. It is
                    recommended that a general risk analysis be performed. A
                    good risk assessment should include an evaluation of the
                    value of the protected assets and information, an
                    examination of the consequences to the organization in the
                    event of a successful attack, an examination of the threat
                    if possible, and the cost of implementing mitigating
                    controls.{" "}
                  </p>
                  <p>threats × vulnerability × asset value = total risk </p>
                  <p>total risk – countermeasures = residual risk </p>
                  <b>
                    <h3>Consequence</h3>
                  </b>
                  <p>
                    The examination of the consequences of an attack should
                    include:{" "}
                  </p>
                  <ul className={styles.CosequenceList}>
                    <li>
                      {" "}
                      How many people could sustain injuries requiring a
                      hospital stay?
                    </li>
                    <li> How many people could be killed? </li>
                    <li>
                      {" "}
                      Determine priorities of security initiatives based on
                      business needs.{" "}
                    </li>
                    <li>
                      {" "}
                      Estimate the potential cost of losing capital assets or
                      the overall economic impact. (Consider the cost of site
                      buildings, facilities, equipment, etc.)
                    </li>
                    <li>
                      {" "}
                      Estimate the potential cost in terms of economic impact to
                      both the site and surrounding communities. (Consider any
                      losses to community structures and any costs associated
                      with displacement.){" "}
                    </li>
                    <li>
                      {" "}
                      Estimate the potential cost of environmental cleanup to
                      the site and surrounding communities. (Consider the cost
                      for cleanup, fines, litigation, long term monitoring,
                      etc.){" "}
                    </li>
                  </ul>
                  <b>
                    <h3>Threat</h3>
                  </b>
                  <p>
                    The threat portion of the equation can be deduced from the
                    recommended implementation priorities list. The priorities
                    are based on incident data collected by the ICS-CERT watch
                    floor and subject matter experts as of the time of
                    publication of CSET. Top priorities are controls that
                    mitigate the most actively exploited vulnerabilities with
                    the most significant consequences.{" "}
                  </p>
                  <b>
                    <h3>Cost Benefit Analysis</h3>
                  </b>
                  <p>
                    The cost of implementing controls with respect to the
                    additional security provided is the final step in selecting
                    the controls to implement.{" "}
                  </p>
                  <h3> 3.1 Basic Model </h3>
                  <p>
                    {" "}
                    Traditional security models define three areas of
                    consideration Confidentiality, Integrity, and Availability.
                    The security plan should address each of these areas with
                    respect to data and systems.{" "}
                  </p>
                  <h3> 3.1.1 Confidentiality </h3>
                  <p>
                    Confidentiality refers to preventing the disclosure of
                    information to unauthorized individuals or systems. For
                    example, a credit card transaction on the Internet requires
                    the credit card number to be transmitted from the buyer to
                    the merchant and from the merchant to a transaction
                    processing network. The system attempts to enforce
                    confidentiality by encrypting the card number during
                    transmission, by limiting the places where it might appear
                    (in databases, log files, backups, printed receipts, and so
                    on), and by restricting access to the places where it is
                    stored. If an unauthorized party obtains the card number in
                    any way, a breach of confidentiality has occurred.{" "}
                  </p>
                  <p>
                    Confidentiality is necessary for maintaining the privacy of
                    the people whose personal information a system holds.{" "}
                  </p>
                  <h3> 3.1.2 Integrity </h3>
                  <p>
                    In information security, data integrity means maintaining
                    and assuring the accuracy and consistency of data over its
                    entire life cycle. This means that data cannot be modified
                    in an unauthorized or undetected manner. This is not the
                    same thing as referential integrity in databases, although
                    it can be viewed as a special case of Consistency as
                    understood in the classic ACID (Atomicity, Consistency,
                    Isolation, Durability) model of transaction processing.
                    Integrity is violated when a message is actively modified in
                    transit. Information security systems typically provide
                    message integrity in addition to data confidentiality.{" "}
                  </p>
                  <h3> 3.1.3 Availability </h3>
                  <p>
                    For any information system to serve its purpose, the
                    information must be available when it is needed. This means
                    that the computing systems used to store and process the
                    information, the security controls used to protect it, and
                    the communication channels used to access it must be
                    functioning correctly. High availability systems aim to
                    remain available at all times, preventing service
                    disruptions due to power outages, hardware failures, and
                    system upgrades. Ensuring availability also involves
                    preventing denial-of-service attacks.{" "}
                  </p>
                  <h3>3.2 Security Assurance Level (SAL) </h3>
                  <Grid item xs={6} md={4} sm={4}>
                    <div className={styles.cal_block}>
                      <div className={styles.cal_head}>
                        <Typography>CALCULATED LEVEL</Typography>
                        <div className={styles.cal_level}>
                          <Typography>{salTableData.osv}</Typography>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={8}
                    sm={8}
                    className={styles.caclulated_table}
                  >
                    <table>
                      <tr>
                        <th> </th>
                        <th>Confidentiality</th>
                        <th>Integrity</th>
                        <th>Availability</th>
                      </tr>
                      <tr>
                        <td align="center">Overall Values</td>
                        <td align="center">{salTableData.q_CV}</td>
                        <td align="center">{salTableData.q_IV}</td>
                        <td align="center">{salTableData.q_AV}</td>
                      </tr>
                    </table>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={8}
                  md={12}
                  sm={8}
                  className={styles.FooterActions}
                ></Grid>
                <hr className={styles.ruler} />
                <h3> 4. Security Plan Controls and Status List </h3>
                <Grid
                  item
                  xs={8}
                  md={12}
                  sm={8}
                  className={styles.FooterActions}
                >
                  <p>
                    {" "}
                    This section lists all the controls which constitute the
                    security plan and implementation status. To enable easier
                    reading of the controls list a table key is included at the
                    start of this section. In this section the terms Control and
                    Requirement are used interchangeably to indicate the
                    mitigation effort as defined in the given standard.{" "}
                  </p>
                  <p> Table Key and Field Descriptions: </p>
                  <div className={styles.securityPlanTable}>
                    <table id="secondTable" className={styles.tableStyle}>
                      <tr>
                        <td colSpan={1}> Requirement Title </td>
                        <td colSpan={2}>
                          {" "}
                          CSET Question/Requirement Category{" "}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1}> Control Level </td>
                        <td colSpan={1}> Implementation Status </td>
                        <td colSpan={1}> Short Standard Name </td>
                      </tr>
                      <tr>
                        <td colSpan={3}> Requirement Description </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          {" "}
                          Affected Zones (Optional, may not be included in the
                          table){" "}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          {" "}
                          Affected Components (Optional, may not be included in
                          the table){" "}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}> Related Questions and Answers </td>
                      </tr>
                    </table>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={8}
                  md={12}
                  sm={8}
                  className={styles.FooterActions}
                >
                  <p>
                    {" "}
                    <span>
                      <b>Requirement Title:</b>
                    </span>
                    <span>
                      {" "}
                      Is the control title as it is generally defined in the
                      standard document from which this control is derived{" "}
                    </span>
                  </p>
                  <p>
                    {" "}
                    <span>
                      <b>CSET Question Category:</b>
                    </span>
                    <span>
                      {" "}
                      Shows the CSET question category from the global questions
                      list. Questions from multiple standards have been
                      consolidated together in the CSET tool and assigned a
                      common category.{" "}
                    </span>
                  </p>
                  <p>
                    {" "}
                    <span>
                      <b>Control Level:</b>
                    </span>
                    <span>
                      {" "}
                      Mapped to one of Low, Moderate, High, or Very High. A
                      value of none indicates that the level was not defined for
                      the given information type in the standard.{" "}
                    </span>
                  </p>
                  <p>
                    {" "}
                    <span>
                      <b>Implementation Status:</b>
                    </span>
                    <span>
                      {" "}
                      Shows the percentage complete as the number of yes and
                      alternate answers / total related questions for this
                      control. The percentage implemented will not necessarily
                      be reflective of the amount of work required to implement
                      the control but is merely an indicator of how many of the
                      questions related to the control have been addressed so
                      far.{" "}
                    </span>
                  </p>
                  <p>
                    {" "}
                    <span>
                      <b>Short Standard Name:</b>
                    </span>
                    <span>
                      {" "}
                      An indicator of which standard this control is derived
                      from.{" "}
                    </span>
                  </p>
                  <p>
                    {" "}
                    <span>
                      <b>Control Description: </b>
                    </span>
                    <span>
                      The full control text as defined in the standard from
                      which the control is derived.{" "}
                    </span>
                  </p>
                  <p>
                    {" "}
                    <span>
                      <b>Affected Zones: </b>
                    </span>
                    <span>
                      {" "}
                      Only applicable to controls derived from a diagram. If you
                      have included a diagram in your original assessment this
                      field will contain a list of zone in which at least one
                      component was found to require this control.{" "}
                    </span>
                  </p>
                  <p>
                    {" "}
                    <span>
                      <b>Affected Components:</b>
                    </span>
                    <span>
                      This field contains a list of the components that are
                      directly applicable to this control.{" "}
                    </span>
                  </p>
                  <p>
                    {" "}
                    <span>
                      <b>Related Questions and Answers:</b>
                    </span>
                    <span>
                      {" "}
                      A list of the questions and answers from which the
                      implementation status of this control was determined.{" "}
                    </span>
                  </p>
                </Grid>
                <Grid
                  item
                  xs={8}
                  md={12}
                  sm={8}
                  className={styles.FooterActions}
                >
                  <PaginationList
                    data={controlListData}
                    pageSize={10}
                    renderItem={(element: any, i: any) => (
                      <>
                        <table className={styles.sPTableStyle}>
                          <tr>
                            <td colSpan={2}>{element.requirementTitle}</td>
                            <td colSpan={2}>
                              {`${element.standard_Category} / ${element.subCategory}`}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {element.level === "H"
                                ? "High"
                                : element.level === "L"
                                ? "Low"
                                : element.level}
                            </td>
                            <td>{element.implementationStatus}</td>
                            <td colSpan={2}>{element.standardShortName}</td>
                          </tr>
                          <tr>
                            <td colSpan={4}>{element.controlDescription}</td>
                          </tr>
                          {element.control_Questions.map(
                            (q: any, index: any) => {
                              return (
                                <tr key={index}>
                                  <td colSpan={3}>{q.simple_Question}</td>
                                  <td>
                                    {" "}
                                    {q.answer === "Y"
                                      ? "Yes"
                                      : q.answer === "N"
                                      ? "No"
                                      : q.answer === "U"
                                      ? "Unanswered"
                                      : "Not Applicable"}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </table>
                        <br />
                      </>
                    )}
                  ></PaginationList>
                </Grid>
                <div className={styles.QuestionDiv}>
                  <Grid
                    item
                    xs={8}
                    md={12}
                    sm={8}
                    className={styles.FooterActions}
                  >
                    {controlListData.map((element: any, i: any) => {
                      return (
                        <>
                          <table id={"thirdTable" + i}>
                            <tr>
                              <td colSpan={2}>{element.requirementTitle}</td>
                              <td colSpan={2}>
                                {`${element.standard_Category} / ${element.subCategory}`}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {element.level === "H"
                                  ? "High"
                                  : element.level === "L"
                                  ? "Low"
                                  : element.level}
                              </td>
                              <td>{element.implementationStatus}</td>
                              <td colSpan={2}>{element.standardShortName}</td>
                            </tr>
                            <tr>
                              <td colSpan={4}>{element.controlDescription}</td>
                            </tr>
                            {element.control_Questions.map(
                              (q: any, index: any) => {
                                return (
                                  <tr key={index}>
                                    <td colSpan={3}>{q.simple_Question}</td>
                                    <td>
                                      {q.answer === "Y"
                                        ? "Yes"
                                        : q.answer === "N"
                                        ? "No"
                                        : q.answer === "U"
                                        ? "Unanswered"
                                        : "Not Applicable"}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </table>
                          <br />
                        </>
                      );
                    })}
                  </Grid>
                </div>
                <Grid
                  item
                  xs={8}
                  md={"auto"}
                  sm={8}
                  className={styles.FooterActions}
                >
                  <hr className={styles.ruler} />
                  <h3>Disclaimer</h3>
                  <Grid
                    item
                    xs={8}
                    md={12}
                    sm={8}
                    className={styles.FooterActions}
                  >
                    <p>
                      The analysis, data, and reports in CSET ® are provided “as
                      is” for informational purposes only. The Department of
                      Homeland Security (DHS) does not provide any warranties of
                      any kind regarding any information contained within. In no
                      event shall the United States Government or its
                      contractors or subcontractors be liable for any damages,
                      including but not limited to, direct, indirect, special,
                      or consequential damages and including damages based on
                      any negligence of the United States Government or its
                      contractors or subcontractors, arising out of, resulting
                      from, or in any way connected with this report, whether
                      based on warranty, contract, tort, or otherwise, whether
                      injury was sustained from, or arose out of the results of,
                      or reliance upon the report.{" "}
                    </p>
                    <p>
                      DHS does not endorse any commercial product or service,
                      including the subject of the assessment or evaluation in
                      this report. Any reference to specific commercial
                      products, processes, or services by trademark,
                      manufacturer, or otherwise, does not constitute or imply
                      its endorsement, recommendation, or favoring by DHS.{" "}
                    </p>
                    <p>
                      The display of the DHS official seal or other DHS visual
                      identities on this report shall not be interpreted to
                      provide the recipient organization authorization to use
                      the official seal, insignia, or other visual identities of
                      DHS. The DHS seal, insignia, or other visual identities
                      shall not be used in any manner to imply endorsement of
                      any commercial product or activity by DHS or the United
                      States Government. Use of the DHS seal without proper
                      authorization violates federal law (e.g., 18 U.S.C. §§
                      506, 701, 1017), and is against DHS policies governing
                      usage of the seal.{" "}
                    </p>
                    <p>
                      The report is prepared and intended for internal use by
                      the organization that made the request. The contents of
                      this report may be subject to government or private
                      intellectual property rights. To request distribution of
                      this report outside the organization for which it was
                      prepared, contact the CSET Program Office. The contents of
                      this report may be reproduced or incorporated into other
                      reports, but may not be modified without the prior express
                      written permission of the CSET Program Office.{" "}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={styles.FooterActions}>
          <div className={styles.prevBtn}>
            <Button
              id="previous-button2"
              variant="contained"
              color="primary"
              onClick={handleBack}
            >
              {"Previous"}
            </Button>
          </div>
          <div className={styles.borderButton}>
            <Button
              id="doc-generate-button2"
              variant="contained"
              color="primary"
              onClick={() => generate()}
            >
              <GetAppIcon className={styles.EditIcon} />
              &nbsp; Doc
            </Button>
            <Button
              id="pdf-generate-button2"
              variant="contained"
              color="primary"
              onClick={() => startLoader()}
            >
              <GetAppIcon className={styles.EditIcon} />
              &nbsp; PDF
            </Button>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SiteCyberSecurityPlan; 
