import React, { useMemo, useState, useEffect } from "react";
import { ListItem, List, Button } from "@mui/material";
import clsx from "clsx";
import styles from "./InPageSideMenus.module.css";
import { green, yellow, grey } from "@mui/material/colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { withStyles } from "@mui/styles";

export interface SideMenusProps {
  opened: boolean;
  menuListData: any;
  paginationData: any;
  handleOnclick: any;
  documentClicked: any;
}

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props: any) => <Checkbox color="default" {...props} />);

const GreyCheckbox = withStyles({
  root: {
    color: grey[400],
    "&$checked": {
      color: grey[600],
    },
  },
  checked: {},
})((props: any) => <Checkbox color="default" {...props} />);

const YellowCheckbox = withStyles({
  root: {
    color: yellow[400],
    "&$checked": {
      color: yellow[600],
    },
  },
  checked: {},
})((props: any) => <Checkbox color="default" {...props} />);

const InPageSideMenus: React.FC<SideMenusProps> = (props: any) => {
  const [sideDrawerMenus, setSideDrawerMenus] = useState<any>([]);
  const [filterState, setFilterState] = React.useState({
    checkedCompleted: true,
    checkedInProgress: true,
    checkedNotStarted: true,
  });
  const propsData = props?.menuListData;

  useEffect(() => {
    if (
      props.menuListData &&
      // props.menuListData.domains &&
      // props.menuListData.domains[0]?.categories
      props.menuListData?.categories
    ) {
      const categoriesArray: any = [];
      // props.menuListData.domains.forEach((element: any) => {
      props.menuListData.categories.forEach((category: any) => {
        categoriesArray.push(category);
      });
      // });
      setSideDrawerMenus(categoriesArray);
    }
  }, [propsData]);

  const PageSize = 1;

  const handleCategory = (menu: any, index: any) => {
    props.handleOnclick(menu, index);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (props?.paginationData - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return sideDrawerMenus?.slice(firstPageIndex, lastPageIndex);
  }, [sideDrawerMenus, props.paginationData]);

  const getTotalQuestions = (data: any) => {
    let TotalCount: any = 0;
    if (currentTableData !== undefined) {
      const tempArray: any = [];
      data.forEach((element: any) => {
        tempArray.push(element.questions.length);
      });
      for (const question of tempArray) {
        TotalCount += question;
      }
    }
    return TotalCount;
  };

  const getTotalQuestionsAns = (data: any) => {
    let TotalAnswerd: any = 0;
    if (currentTableData !== undefined) {
      const tempArray: any = [];
      data.forEach((element: any) => {
        element.questions.forEach((questionElement: any) => {
          tempArray.push(questionElement.answer);
        });
      });
      for (const question of tempArray) {
        if (question !== "U" && question !== null) {
          TotalAnswerd++;
        }
      }
    }
    return TotalAnswerd;
  };

  const handleChange = (event: any) => {
    setFilterState({
      ...filterState,
      [event.target.name]: event.target.checked,
    });
  };

  const menuList = sideDrawerMenus?.map((menu: any, i: any) => {
    return (
      <>
        {filterState.checkedCompleted === true &&
        getTotalQuestionsAns(menu.subCategories) ===
          getTotalQuestions(menu.subCategories) ? (
          <ListItem
            button
            className={clsx({
              [styles.Completed]:
                getTotalQuestionsAns(menu.subCategories) ===
                  getTotalQuestions(menu.subCategories) &&
                filterState.checkedCompleted === true,
              [styles.InProgress]:
                getTotalQuestionsAns(menu.subCategories) > 0 &&
                getTotalQuestionsAns(menu.subCategories) !==
                  getTotalQuestions(menu.subCategories) &&
                filterState.checkedInProgress === true,
            })}
            classes={{
              root: styles.IconItem,
              selected:
                i === props.paginationData - 1
                  ? styles.TabOptionsActive
                  : styles.TabOptionsInactive,
            }}
            key={menu.groupHeadingText}>
            {filterState.checkedCompleted === true &&
            getTotalQuestionsAns(menu.subCategories) ===
              getTotalQuestions(menu.subCategories) ? (
              <Button
                className={
                  i === props.paginationData - 1
                    ? styles.TabOptionsActive
                    : styles.TabOptionsInactive
                }
                onClick={() => handleCategory(menu, i)}>
                {filterState.checkedCompleted === true &&
                getTotalQuestionsAns(menu.subCategories) ===
                  getTotalQuestions(menu.subCategories) ? (
                  <p
                    className={
                      i === props.paginationData - 1
                        ? styles.para
                        : styles.TabOptionsInactive
                    }>
                    {menu.groupHeadingText} [
                    {`${getTotalQuestionsAns(
                      menu.subCategories
                    )} / ${getTotalQuestions(menu.subCategories)}`}
                    ]
                  </p>
                ) : null}
              </Button>
            ) : null}
          </ListItem>
        ) : (
          <></>
        )}

        {filterState.checkedNotStarted === true &&
        getTotalQuestionsAns(menu.subCategories) === 0 ? (
          <ListItem
            button
            className={clsx({
              [styles.Completed]:
                getTotalQuestionsAns(menu.subCategories) ===
                  getTotalQuestions(menu.subCategories) &&
                filterState.checkedCompleted === true,
              [styles.InProgress]:
                getTotalQuestionsAns(menu.subCategories) > 0 &&
                getTotalQuestionsAns(menu.subCategories) !==
                  getTotalQuestions(menu.subCategories) &&
                filterState.checkedInProgress === true,
            })}
            classes={{
              root: styles.IconItem,
              selected:
                i === props.paginationData - 1
                  ? styles.TabOptionsActive
                  : styles.TabOptionsInactive,
            }}
            key={i}>
            {filterState.checkedNotStarted === true &&
            getTotalQuestionsAns(menu.subCategories) === 0 ? (
              <Button
                className={
                  i === props.paginationData - 1
                    ? styles.TabOptionsActive
                    : styles.TabOptionsInactive
                }
                onClick={() => handleCategory(menu, i)}>
                {filterState.checkedNotStarted === true &&
                getTotalQuestionsAns(menu.subCategories) === 0 ? (
                  <p
                    className={
                      i === props.paginationData - 1
                        ? styles.para
                        : styles.TabOptionsInactive
                    }>
                    {menu.groupHeadingText} [
                    {`${getTotalQuestionsAns(
                      menu.subCategories
                    )} / ${getTotalQuestions(menu.subCategories)}`}
                    ]
                  </p>
                ) : null}
              </Button>
            ) : null}
          </ListItem>
        ) : null}

        {filterState.checkedInProgress === true &&
        getTotalQuestionsAns(menu.subCategories) !==
          getTotalQuestions(menu.subCategories) &&
        getTotalQuestionsAns(menu.subCategories) > 0 ? (
          <ListItem
            button
            className={clsx({
              [styles.Completed]:
                getTotalQuestionsAns(menu.subCategories) ===
                  getTotalQuestions(menu.subCategories) &&
                filterState.checkedCompleted,
              [styles.InProgress]:
                getTotalQuestionsAns(menu.subCategories) > 0 &&
                getTotalQuestionsAns(menu.subCategories) !==
                  getTotalQuestions(menu.subCategories) &&
                filterState.checkedInProgress === true,
            })}
            classes={{
              root: styles.IconItem,
              selected:
                i === props.paginationData - 1
                  ? styles.TabOptionsActive
                  : styles.TabOptionsInactive,
            }}
            key={i}>
            {filterState.checkedInProgress === true &&
            getTotalQuestionsAns(menu.subCategories) !==
              getTotalQuestions(menu.subCategories) &&
            getTotalQuestionsAns(menu.subCategories) > 0 ? (
              <Button
                className={
                  i === props.paginationData - 1
                    ? styles.TabOptionsActive
                    : styles.TabOptionsInactive
                }
                onClick={() => handleCategory(menu, i)}>
                {filterState.checkedInProgress === true &&
                getTotalQuestionsAns(menu.subCategories) !==
                  getTotalQuestions(menu.subCategories) &&
                getTotalQuestionsAns(menu.subCategories) > 0 ? (
                  <p
                    className={
                      i === props.paginationData - 1
                        ? styles.para
                        : styles.TabOptionsInactive
                    }>
                    {menu.groupHeadingText} [
                    {`${getTotalQuestionsAns(
                      menu.subCategories
                    )} / ${getTotalQuestions(menu.subCategories)}`}
                    ]
                  </p>
                ) : null}
              </Button>
            ) : null}
          </ListItem>
        ) : null}
      </>
    );
  });
  const showDocumentHandler = () => {
    props.documentClicked(true);
  };

  return (
    <>
      <div className={styles.tooltippanel}>
        <Grid>
          <FormGroup row>
            <div className={styles.tooltip}>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    id="checkedCompleted"
                    checked={filterState.checkedCompleted}
                    onChange={handleChange}
                    name="checkedCompleted"
                  />
                }
                label=""
              />
              <span className={styles.tooltiptext}>Completed</span>
            </div>
            <div className={styles.tooltip}>
              <FormControlLabel
                control={
                  <YellowCheckbox
                    id="checkedInProgress"
                    checked={filterState.checkedInProgress}
                    onChange={handleChange}
                    name="checkedInProgress"
                  />
                }
                label=""
              />
              <span className={styles.tooltiptext}>In Progress</span>
            </div>
            <div className={styles.tooltip}>
              <FormControlLabel
                control={
                  <GreyCheckbox
                    id="checkedNotStarted"
                    checked={filterState.checkedNotStarted}
                    onChange={handleChange}
                    name="checkedNotStarted"
                  />
                }
                label=""
              />
              <span className={styles.tooltiptext}>Not Started</span>
            </div>
          </FormGroup>
        </Grid>
      </div>
      <div className={styles.allDocumentsWrap}>
        <Button
          id="showDocument"
          variant={"contained"}
          color="primary"
          type="button"
          onClick={showDocumentHandler}>
          {"All Documents"}
        </Button>
      </div>
      <List className={styles.List}>{menuList}</List>
    </>
  );
};

export default InPageSideMenus;
