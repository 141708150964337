export const MODE_REQUEST = {
  "headers": {
    "normalizedNames": [["content-type","Content-Type"]],
    "lazyUpdate": null,
    "headers": [["content-type",["application/json"]]],
    "lazyInit": null
  },
  "params": {
    "updates": null,
    "cloneFrom": null,
    "encoder": {},
    "map": []
  }
}

export const EXPSECONDS = '36000';
