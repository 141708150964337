import { gql } from "@apollo/client";

export const GET_INDIVIDUAL = gql `
  query getIndividual($where: JSON, $sort: String) {
    individuals(where: $where, sort: $sort) {
      id
      first_name
      last_name
      contact_id {
        id
        email
        name
        phone
        created_at
        contact_type
      }
      partner_id {
        id
        name
        contact_type
      }
      pg_user_id
    }
  }
`
export const GET_INDIVIDUAL_COUNT = gql `
query individualCount {
  individualsConnection {
    aggregate {
      totalCount
    }
  }
}
`
export const GET_USER = gql`
query GetUsers($where:JSON){
  individuals(where:$where){
    id
    first_name
    last_name
    pg_user_id
    created_at
    contact_id{
      id
      name
      email
      created_at
    }
    partner_id{
      id
      name
    }
  }
}
`
