import React from "react";
import styles from "./CRRExecutiveSummary.module.css";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export interface CRRReportReferencesProps {
  crrReportTableRef?:any
}
export const CRRReportReferences = ({crrReportTableRef}:CRRReportReferencesProps) => {
  return (
    <>
      <Typography variant="h3">
        List of Resources Referenced in this Report
      </Typography>
      <br />
      <Grid item xs={8} md={12} sm={8} className={styles.FooterActions}>
        <div className={styles.securityPlanTable}>
          <table className={styles.tableStyle} ref={crrReportTableRef}>
            <tr>
              <td>
                <div>Resource Name</div>
              </td>
              <td>
                <div>URL</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  A Complete Guide to the Common Vulnerability Scoring System
                  Version 2.0
                </div>
              </td>
              <td>
                <a target="_blank" href="http://www.first.org/cvss/v2/guide">
                  http://www.first.org/cvss/v2/guide
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  "Introducing OCTAVE Allegro: Improving the Information
                  Security Risk Assessment Process"
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="https://resources.sei.cmu.edu/library/asset-view.cfm?assetid=8419"
                >
                  https://resources.sei.cmu.edu/library/asset-view.cfm?assetid=8419
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>CERT Resilience Management Model (CERT©-RMM)</div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="https://resources.sei.cmu.edu/library/asset-view.cfm?assetid=508084"
                >
                  https://resources.sei.cmu.edu/library/asset-view.cfm?assetid=508084
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  Special Publication 800-16 Revision 1 (DRAFT) "A Role-Based
                  Model for Federal Information Technology/Cybersecurity
                  Training (3rd Draft)"
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="https://csrc.nist.gov/publications/detail/sp/800-16/rev-1/archive/2014-03-14"
                >
                  https://csrc.nist.gov/publications/detail/sp/800-16/rev-1/archive/2014-03-14
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  FIPS Publication 199 "Standards for Security Categorization
                  and Federal Information and Information Systems"
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="http://csrc.nist.gov/publications/fips/fips199/FIPSPUB-199-final.pdf"
                >
                  http://csrc.nist.gov/publications/fips/fips199/FIPSPUB-199-final.pdf
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  FIPS Publication 200 "Minimum Security Requirements for
                  Federal Information and Information Systems"
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="http://csrc.nist.gov/publications/fips/fips200/FIPS-200-final-march.pdf"
                >
                  http://csrc.nist.gov/publications/fips/fips200/FIPS-200-final-march.pdf
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  Framework for Improving Critical Infrastructure Cybersecurity
                </div>
              </td>
              <td>
                <a target="_blank" href="http://www.nist.gov/cyberframework/">
                  http://www.nist.gov/cyberframework/
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  Handbook for Computer Security Incident Response Teams
                  (CSIRTs)
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="https://resources.sei.cmu.edu/asset_files/Handbook/2003_002_001_14102.pdf"
                >
                  https://resources.sei.cmu.edu/asset_files/Handbook/2003_002_001_14102.pdf
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>Managing for Enterprise Security</div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="http://resources.sei.cmu.edu/library/asset-view.cfm?assetID=7019"
                >
                  http://resources.sei.cmu.edu/library/asset-view.cfm?assetID=7019
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  Special Publication 800-18 Revision 1 "Guide for Developing
                  Security Plans for Federal Information Systems"
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="http://csrc.nist.gov/publications/nistpubs/800-18-Rev1/sp800-18-Rev1-final.pdf"
                >
                  http://csrc.nist.gov/publications/nistpubs/800-18-Rev1/sp800-18-Rev1-final.pdf
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  Special Publication 800-30 "Guide for Conducting Risk
                  Assessments"
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="http://nvlpubs.nist.gov/nistpubs/Legacy/SP/nistspecialpublication800-30r1.pdf"
                >
                  http://nvlpubs.nist.gov/nistpubs/Legacy/SP/nistspecialpublication800-30r1.pdf
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  Special Publication 800-34 "Contingency Planning for Federal
                  Information Systems"
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="http://csrc.nist.gov/publications/nistpubs/800-34-rev1/sp800-34-rev1_errata-Nov11-2010.pdf"
                >
                  http://csrc.nist.gov/publications/nistpubs/800-34-rev1/sp800-34-rev1_errata-Nov11-2010.pdf
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  Special Publication 800-37 "Risk Management Framework for
                  Information Systems and Organizations: A System Life Cycle
                  Approach for Security and Privacy"
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="https://csrc.nist.gov/publications/detail/sp/800-37/rev-2/final"
                >
                  https://csrc.nist.gov/publications/detail/sp/800-37/rev-2/final
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  Special Publication 800-39 "Managing Information Security
                  Risk: Organization, Mission, and Information System View"
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="http://csrc.nist.gov/publications/nistpubs/800-39/SP800-39-final.pdf"
                >
                  http://csrc.nist.gov/publications/nistpubs/800-39/SP800-39-final.pdf
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  Special Publication 800-40 Version 3.0 "Guide to Enterprise
                  Patch Management Technologies"
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="http://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-40r3.pdf"
                >
                  http://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-40r3.pdf
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  Special Publication 800-53 Revision 4 "Security and Privacy
                  Controls for Federal Information Systems and Organizations"
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="http://dx.doi.org/10.6028/NIST.SP.800-53r4"
                >
                  http://dx.doi.org/10.6028/NIST.SP.800-53r4
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  Special Publications 800-61 "Computer Security Incident
                  Handling guide"
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="http://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-61r2.pdf"
                >
                  http://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-61r2.pdf
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  Special Publication 800-70 "National Checklist Program for IT
                  Products: Guidelines for Checklist Users and Developers"
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="https://csrc.nist.gov/publications/detail/sp/800-70/rev-4/final"
                >
                  https://csrc.nist.gov/publications/detail/sp/800-70/rev-4/final
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  Special Publication 800-84 "Guide to Test, Training, and
                  Exercise Programs for IT Plans and Capabilities"
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="http://csrc.nist.gov/publications/nistpubs/800-84/SP800-84.pdf"
                >
                  http://csrc.nist.gov/publications/nistpubs/800-84/SP800-84.pdf
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  Special Publication 800-128 "Guide for Security-Focused
                  Configuration Management of Information Systems"
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="http://csrc.nist.gov/publications/nistpubs/800-128/sp800-128.pdf"
                >
                  http://csrc.nist.gov/publications/nistpubs/800-128/sp800-128.pdf
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  Special Publication 800-137 "Information Security Continuous
                  Monitoring (ICSM) for Federal Information Systems and
                  Organizations"
                </div>
              </td>
              <td>
                <a
                  target="_blank"
                  href="http://csrc.nist.gov/publications/nistpubs/800-137/SP800-137-Final.pdf"
                >
                  http://csrc.nist.gov/publications/nistpubs/800-137/SP800-137-Final.pdf
                </a>
              </td>
            </tr>
          </table>
        </div>
      </Grid>
    </>
  );
};

export default CRRReportReferences;
