import React, { useState, useEffect } from "react";
import { CSET_URL } from "../../../../../config";
import logout from "../../../../Auth/Logout/Logout";
import DOMPurify from "dompurify";
import styles from "./CRRExecutiveSummary.module.css";

export interface Crrmil1PerformanceProps {
  data?: any;
  props?: any;
  Crrmil1Performanceref?: any;
  ref1?: any;
  ref2?: any;
  ref4?: any;
  CRRModelAPIData?: any;
  handleChildCompError?: (err: any) => void;
}

export const Crrmil1Performance: React.FC<Crrmil1PerformanceProps> = ({
  data,
  props,
  Crrmil1Performanceref,
  ref1,
  ref2,
  ref4,
  CRRModelAPIData,
  handleChildCompError = () =>{}
}: Crrmil1PerformanceProps) => {
  const [mil1FullAnswerDistribChart, setMil1FullAnswerDistribChart] =
    useState<any>(null);
  const [crrModel, setCRRModel] = useState<any>();
  const [heatMaps, setHeatMaps] = useState<any>(null);
  const [legend, setLegend] = useState<any>(null);
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [scoreBarCharts, setScoreBarCharts] = useState<any>([]);
  /**
   * Convert String to html Response
   * @param html
   */
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  useEffect(() => {
    getMil1FullAnswerDistribWidget();
    getMil1PerformanceBodyCharts();
    getMil1PerformanceLegendWidget();
  }, []);

  useEffect(() => {
    if (CRRModelAPIData) {
      setCRRModel(CRRModelAPIData.structure.Model);
    }
  }, [CRRModelAPIData]);

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const getMil1FullAnswerDistribWidget = async () => {
    await fetch(`${CSET_URL}api/reportscrr/widget/mil1FullAnswerDistrib`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response: any) => response.text())
      .then((mil1FullAnswerDistrib: any) => {
        setMil1FullAnswerDistribChart(mil1FullAnswerDistrib);
      })
      .catch((error: any) => {
        console.log("mil1FullAnswerDistrib Error:- ", error);
        handleChildCompError(error)
      });
  };

  const getMil1PerformanceBodyCharts = async () => {
    await fetch(`${CSET_URL}api/reportscrr/getCrrMil1PerformanceBodyCharts`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response: any) => response.json())
      .then((CrrMil1PerformanceBodyCharts: any) => {
        setScoreBarCharts(CrrMil1PerformanceBodyCharts.scoreBarCharts);
        setHeatMaps(CrrMil1PerformanceBodyCharts.heatMaps);
      })
      .catch((error: any) => {
        console.log("CrrMil1PerformanceBodyCharts Error:- ", error);
        handleChildCompError(error)
      });
  };

  const getMil1PerformanceLegendWidget = async () => {
    await fetch(`${CSET_URL}api/reportscrr/widget/mil1PerformanceLegend`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response: any) => response.text())
      .then((mil1PerformanceLegend: any) => {
        setLegend(mil1PerformanceLegend);
      })
      .catch((error: any) => {
        console.log("mil1PerformanceLegend Error:- ", error);
        handleChildCompError(error)
      });
  };

  const filterMilDomainGoals = (goals: any) => {
    return goals.filter((g: any) => !g.title.startsWith("MIL"));
  };

  const stringSplitter = (str: string) => {
    return str.split(" - ")[1] ?? str.split("-")[1];
  };

  const getHeatMap = (goalTitle: string) => {
    return heatMaps?.find((c: any) => c.title === goalTitle).chart;
  };

  return (
    <div
      className={styles.pageMarginTop}
      style={{ marginTop: "40px", paddingTop: "40px" }}
      ref={Crrmil1Performanceref}
    >
      <div className={styles.containerFluid}>
        <div className={styles.row} ref={ref1}>
          <div className={styles.col1} style={{ width: "15%" }}>
            <div
              className={styles.row}
              style={{ padding: 0, height: "auto", minWidth: "130px" }}
            >
              <div
                className={styles.col3}
                style={{
                  width: "36.67px",
                  padding: "0.1rem",
                  marginRight: "0.3rem",
                  fontSize: "6px",
                }}
              >
                Total number of practices performed
              </div>
              <div
                className={styles.col3}
                style={{
                  width: "36.67px",
                  padding: "0.1rem",
                  marginRight: "0.3rem",
                  fontSize: "6px",
                }}
              >
                Total number of practices incompletely performed
              </div>
              <div
                className={styles.col3}
                style={{
                  width: "36.67px",
                  padding: "0.1rem",
                  marginRight: "0.3rem",
                  fontSize: "6px",
                }}
              >
                Total number of practices not performed
              </div>
            </div>
            <div className={styles.row} style={{ height: "50px" }}>
              <div
                className={styles.col1}
                style={{ position: "relative", padding: 0 }}
              >
                <div
                  style={{ position: "absolute", padding: 0, width: "110px" }}
                  dangerouslySetInnerHTML={createDangerousHTML(
                    mil1FullAnswerDistribChart
                  )}
                ></div>
              </div>
            </div>
            <div className={styles.row}>
              <div className="col" style={{ padding: 0 }}>
                <h5 className={styles.appendixLabel}>CRR MIL-1 Summary</h5>
              </div>
            </div>
          </div>
          <div
            className={styles.col1}
            style={{ width: "85%", padding: 0, position: "relative" }}
          >
            <div
              className={styles.col6}
              style={{ padding: 0, position: "absolute", bottom: 0 }}
            >
              <h3
                className={styles.depictionTitle}
                style={{ paddingBottom: "1rem" }}
              >
                CRR MIL-1 Performance
              </h3>
            </div>
            <div
              ref={ref2}
              className={styles.col6}
              style={{
                padding: 0,
                position: "absolute",
                bottom: 0,
                left: "47%",
              }}
              dangerouslySetInnerHTML={createDangerousHTML(legend)}
            ></div>
          </div>
        </div>
        <div className={styles.row} style={{ borderTop: "solid 1.5px black" }}>
          <div style={{ width: "15%", padding: 0, fontSize: "10px" }}>
            DOMAIN SUMMARY
          </div>
          <div style={{ width: "85%", padding: 0, fontSize: "10px" }}>
            MIL-1 PRACTICE LEVEL PERFORMANCE SUMMARY
          </div>
        </div>
        {/* *ngFor="let domain of model?.structure.Model.Domain; let i = index"  */}
        <div ref={ref4}>
          {crrModel?.Domain?.map((domain: any, i: any) => {
            return (
                <div
                  className={styles.row}
                  style={{ borderTop: "1px solid black" }}
                >
                  <div
                    className={styles.col1}
                    style={{ width: "15%", padding: "0 1.5rem 0 1rem" }}
                  >
                    <div className={styles.row}>
                      <div style={{ fontSize: "0.7rem", fontWeight: "bold" }}>
                        {domain.title.split("(")[0].trim()}
                      </div>
                    </div>
                    <div
                      className={styles.row}
                      dangerouslySetInnerHTML={createDangerousHTML(
                        scoreBarCharts[i]
                      )}
                    ></div>
                  </div>
                  <div
                    className={styles.col1}
                    style={{ width: "85%", padding: "0 0 0 0.75rem" }}
                  >
                    {filterMilDomainGoals(domain.Goal).map(
                      (goal: any, j: any) => {
                        return (
                          <div
                            className={`${styles.row} ${styles.goalrow}`}
                            style={{
                              marginRight: "0.05rem",
                              borderBottom: "0.5px solid black",
                            }}
                          >
                            <div
                              className={styles.col1}
                              style={{
                                fontSize: "8px",
                                width: "36px",
                                padding: 0,
                              }}
                            >
                              <div className="goalrowText">
                                <b>Goal {j + 1} -</b>
                              </div>
                            </div>
                            <div
                              className={styles.col5}
                              style={{
                                fontSize: "8px",
                                padding: 0,
                              }}
                            >
                              <div className="goalrowText">
                                {stringSplitter(goal.title)}
                              </div>
                            </div>
                            <div
                              className={styles.col6}
                              style={{ padding: "0.1rem" }}
                            >
                              <div
                                className="goalrowText"
                                dangerouslySetInnerHTML={createDangerousHTML(
                                  getHeatMap(goal.title)
                                )}
                              ></div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Crrmil1Performance;
