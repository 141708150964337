import React from "react";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import styles from "./LogoutPage.module.css";

export const LogoutPage: React.FC = (props: any) => {
  return (
      <div className={styles.logoutPage}>
        <Paper className={styles.paper}>
          <div className={styles.CCLogoImg}>
            <img src={process.env.PUBLIC_URL + "/RA360.png"} alt="user icon" />
          </div>
          <Typography component="h5" variant="h3">
            You have Successfully Logged out from RA360
          </Typography>
        </Paper>
      </div>
  );
};
export default LogoutPage;
