import React from 'react';
import { Container } from '@mui/material';
export interface ErrorHandlerProps { }

export const ErrorHandler: React.FC<ErrorHandlerProps> = () => {

  return (
    <Container>
      <div data-testid="errorMessage"></div>
    </Container>
  );
};
