import React, { useEffect, useState } from "react";
import * as routeConstants from "../../../common/RouteConstants";
import * as msgConstants from "../../../common/MessageConstants";
import classes from "./Partner.module.css";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import { Button } from "../../../components/UI/Form/Button/Button";
import { DialogBox } from "../../../components/UI/DialogBox/DialogBox";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Alert from "../../../components/UI/Alert/Alert";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import Input from "../../../components/UI/Form/Input/Input";
import ClearIcon from "@mui/icons-material//Clear";
import SearchIcon from "@mui/icons-material/Search";
import { useLazyQuery,useMutation } from '@apollo/client';
import { ADMIN_GET_PARTNER_DELETE_DATA } from '../../../graphql/queries/AdminUI';
import { DELETE_DATA } from '../../../graphql/mutations/Delete';
import { useNavigate } from 'react-router-dom';
import { sentry_error_catch } from "../../../common/sentry_error_catch";

const Partner = () => {
  const [showBackDrop, setShowBackDrop] = useState(true);
  const history = useNavigate();
  const timeFormat = "MM/DD/YYYY hh:mm a";
  const moment = require("moment-timezone");
  const deleteIcon = "/icons/svg-icon/delete.svg";
  const [newData, setNewData] = useState<any>([]);
  const [partnerFilter,setPartnerFilter] = useState<any>("");
  const [searchData,setSearchData] = useState<any>([]);
  const [error,setError] = useState<any>(false);
  const [openDeletePartnerDialogBox,setOpenDeletePartnerDialogBox] = useState<any>(false);
  const [rowData,setRowData] = useState<any>({});
  const [formState, setFormState] = useState({
    isDelete: false,
    isSuccess: false,
    isFailed: false,
    errMessage: "",
  })

  // query for getting all partners.
  const [partnerDelete] = useMutation(DELETE_DATA);
  const [getDeletePartner] = useLazyQuery(ADMIN_GET_PARTNER_DELETE_DATA, {
    fetchPolicy: msgConstants.FETCH_POLICY,
    onCompleted: (data) => {
      setShowBackDrop(false)
      createTableDataObject(data.organizations);
    },
    onError: (err:any) => {
        sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  })

  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    }else {
      return false;
    }
  }

  // runs after every initial render. 
  useEffect(() => {
    getDeletePartner({
      variables: {
        where: { subtype: "Partner", active_status: 3 },
        limit: 1000,
        sort: "id:desc"
      }
    })
  }, [])

  // column array for display table
  const column: any = [
    { title: "Partner Name", key: "partner_name" },
    { title: "Email", key: "email" },
    { title: "Created on", key: "created_at" },
    { title: "Pg Partner Id",key:"partner_pgId"},
    {
      icon: ( <img
          className={classes.EditIcon}
          src={process.env.PUBLIC_URL + deleteIcon}
          alt="delete icon"
      />
      ),
      tooltip: "Delete Partner",
      key: "deletepartner",
      title: "",
      displayIcon: (rowData: any) => true,
    }
  ]
  // function run after action buttion is clicked.
  const onRowClick:any = (rowData: any,val: any) => {
    console.log("Hii",val,rowData)
    if (val === 'deletepartner') {
      setOpenDeletePartnerDialogBox(true);
      setRowData(rowData);
    }
  }
  // function for data to be displayed.
  const createTableDataObject = (orgPartData: any) => {
    const arr: any = [];
    if (orgPartData !== 0) {
      orgPartData.forEach((element: any, i: any) => {
        const obj: any = {};
        obj["org_id"] = element.id ? element.id : "";
        obj["partner_contactId"] = element.contact_id.id ? element.contact_id.id : "";
        obj["partner_pgId"] = element.pg_id ? element.pg_id : "";
        obj["partner_name"] = element.contact_id.name ? element.contact_id.name : "";
        obj['email'] = element.contact_id.email ? element.contact_id.email : "";
        obj["created_at"] = moment(element.contact_id.created_at).tz("US/Eastern").format(
          timeFormat
        );
        arr.push(obj);
      });
    }
    console.log("arr",arr)
    setNewData(arr)
    setSearchData(arr)
  }
  const backToList = () => {
    history(routeConstants.ADMIN_DASHBOARD);
  }
  const handlePartnerFilterChange = (event:any) => {
   setPartnerFilter(event.target.value)
  }
  const searchResetHandler = (event:any) => {
    setPartnerFilter("")
    setSearchData(newData)
  }
  const searchHandler = (event:any) => {
   if(partnerFilter.trim().length > 0){
    console.log("event",event)
      const search:any = []
      newData.forEach((element:any) => {
        console.log("element",element)
        if(element["partner_name"].includes(partnerFilter)){
          search.push(element)
        }
      });
      setSearchData(search)
   }else{
    setError(true)
   }
  }
  const confirmDelete = () => {
   setOpenDeletePartnerDialogBox(false);
   setShowBackDrop(true);
   console.log("rowData",rowData)
    if(rowData && rowData.partner_pgId){
      console.log("rowData",rowData.partner_pgId)
      partnerDelete({
        variables: {
          id:parseInt(rowData.partner_pgId),
          type: "Partner",
        }
      }).then((data:any) => {
        setShowBackDrop(false);
        if(data.data.DeleteContactData.message === "Deleted Successfully!"){
          setFormState((formState) => ({
            ...formState,
            isDelete: false,
            isSuccess: true,
            isFailed: false,
            errMessage: "Partner has been Deleted Successfully!"
          }))
          getDeletePartner({
            variables: {
              where: { subtype: "Partner", active_status: 3 },
              limit: 1000,
              sort: "id:desc"
            }
          })
        }else{
          setFormState((formState) => ({
            ...formState,
            isDelete: false,
            isSuccess: false,
            isFailed: true,
            errMessage: msgConstants.ERROR_MESSAGE
          }))
        }
      }).catch((err:any) => {
        sentry_error_catch(err,setShowBackDrop,setFormState)
      })
    }
  }
  const closeDialogBox = () => {
   setOpenDeletePartnerDialogBox(false)
   setRowData({});
  }
  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isDelete: false,
      isSuccess: false,
      isFailed: false,
      errMessage: ""
    }))
   }

  return <React.Fragment>
    <CssBaseline />
    {showBackDrop ? <SimpleBackdrop /> : null}
    <DialogBox
      open={openDeletePartnerDialogBox}
      handleOk={confirmDelete}
      handleCancel={closeDialogBox}
      buttonOk={"Yes"}
      buttonCancel={"No"}
      classes={{
        root: classes.MainOfficeDialogRoot,
        container: classes.MainOfficeDialogboxContainer,
        paper: classes.MainOfficeDialogboxPaper,
        scrollPaper: classes.MainOfficeScrollPaper,
      }}
    >
      <div className={classes.DialogBoxTitle}>
        <Typography component="h1" variant="h1">
          Please Confirm
        </Typography>
      </div>
      <div className={classes.DialogBoxContext}>
        <p>Are you sure you want to Delete the <b>{rowData.partner_name}</b> Partner ?</p>
      </div>
    </DialogBox>
    <Typography component="h5" variant="h1">
      Delete Partners List
    </Typography>
    <Grid container>
      <Grid item xs={12} sm={12}>
        {formState.isSuccess ? (
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <strong>{formState.errMessage}</strong>
          </Alert>
        ) : null}
        {formState.isFailed ? (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <strong>{formState.errMessage}</strong>
          </Alert>
        ) : null}
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={12} sm={10} className={classes.FilterWrap}>
        <span style={{ width: "250px" }}>
          <Grid item md={12} xs={12}>
            <Input
              id="partner-id"
              type="search"
              name="filterPartner"
              label="Search Partner Name"
              variant="outlined"
              value={partnerFilter}
              onChange={handlePartnerFilterChange}
              // error = {error}
              style={{borderColor: error? "red !important":""}}
            />
          </Grid>
        </span>
        <div className={classes.FilterWrap1}>
          <span>
            <Button id="search" onClick={searchHandler}>
              Search <SearchIcon />
            </Button>
          </span>
        </div>
        <div className={classes.FilterWrap2} onClick={searchResetHandler}>
          <span>
            <Button id="reset" color="secondary">
              RESET <ClearIcon />
            </Button>
          </span>
        </div>
      </Grid>
      <Grid item xs={12} sm={2} className={classes.FilterAddWrap}>
        <div className={classes.ButtonGroup2}>
          <Button
            id="cancel-button"
            className={classes.BackToButton}
            variant={"contained"}
            onClick={backToList}
            color="secondary"
          >
            <img
              src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
              alt="user icon"
            />
            &nbsp; Back to List
          </Button>
        </div>
      </Grid>
    </Grid>
    <Grid>
      <Paper className={classes.paper}>
        <div className={classes.ScrollTable}>
          {searchData.length !== 0 ? (
            <MaterialTable
              data={searchData}
              columns={column}
              pageSize={10}
              onButtonClick={onRowClick}
            />
          ) : !enableBackdrop() ? (
            <Typography component="h5" variant="h3">
              You don't have any partner subscribed for RiskAssessment360
            </Typography>
          ) : null}
        </div>
      </Paper>
    </Grid>
  </React.Fragment>
}

export default Partner
