import React, { useState, useEffect } from "react";
import styles from "./SiteSummaryReport.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Button } from "../../../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router-dom";
import { CSET_URL } from "../../../../../config/index";
import { HorizontalBar, Doughnut } from "react-chartjs-2";
import { saveAs } from "file-saver";
import logout from "../../../../Auth/Logout/Logout";
import * as msgConstants from "../../../../../common/MessageConstants";
import * as docx from "docx";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import GetAppIcon from "@mui/icons-material/GetApp";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { GET_DOCUMENT_BY_ASSESSMENT_ID } from "../../../../../graphql/queries/QuestionDocument";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../../components/UI/Alert/Alert";
import { GET_ORGANIZATION } from "../../../../../graphql/queries/Organization";
import { sentry_error_catch } from "../../../../../common/sentry_error_catch";

export interface SiteSummaryProps {
  from?: string;
  reportBlobdata?: any;
  handleChange?: any;
  props?: any;
  partnerLogo?: any;
  clientLogo?: any;
  clientImageDimension?: any;
  partnerImageDimension?: any;
}

export const SiteSummaryReport: React.FC<SiteSummaryProps> = ({
  reportBlobdata,
  handleChange,
  from,
  props,
  partnerLogo,
  clientLogo,
  clientImageDimension,
  partnerImageDimension,
}: SiteSummaryProps) => {
  const history = useNavigate();
  const location = useLocation();
  const preparedForBy = "preparedFor/By";
  const defaultNoQuestions = "There are no questions with comments to display.";
  const noJustifications =
    "There are no questions with alternate justifications to display. ";
  const noreview = "There are no questions marked for review. ";
  const reportName = "Site_Summary_Report.docx";
  const categoryNumber = "Category And Number";
  const rankedAreas = "Ranked Subject Areas";
  const documentLib = "Document Library";
  const chartRef1 = React.useRef<any>(null);
  const chartRef2 = React.useRef<any>(null);
  const chartRef3 = React.useRef<any>(null);
  const chartRef4: any = React.useRef<any>([]);
  const chartRef5 = React.useRef<any>(null);
  const [rankedQuestions, setRankedQuestions] = useState<any>([]);
  const [rankedQuestionsData, setRankedQuestionsData] = useState<any>([]);
  const [siteInformation, setSiteInformation] = useState<any>({});
  const [salTableData, setSalTableData] = useState<any>({});
  const [
    summaryPercentComplianceChartData,
    setSummaryPercentComplianceChartData,
  ] = useState<any>({});
  const [standardSummaryPieData, setStandardSummaryPieData] = useState<any>({});
  const [standardSummaryBarData, setStandardSummaryBarData] = useState<any>({});
  const [standardsResultsByCategory, setStandardsResultsByCategory] =
    useState<any>({});
  const [standardComplianceBarData, setStandardComplianceBarData] =
    useState<any>([]);
  const [rankedSubjectAreas, setRankedSubjectAreas] = useState<any>({});
  const [questionsWithComments, setQuestionsWithComments] = useState<any>([]);
  const [questionsMarkedForReview, setQuestionsMarkedForReview] = useState<any>(
    []
  );
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [param, setParam] = useState<any>(
    location.state[Object.keys(location.state)[0]]
  );
  const [loading, setLoading] = useState(false);
  const [queryCalling, setQueryCalling] = useState(false);
  const [partnerData, setPartnerData] = useState<any>({});
  const [clientData, setClientData] = useState<any>({});
  const [bannerImage, setBannerImage] = useState<any>();
  const [cpaImage, setCPAImage] = useState<any>();
  const [partnerImage, setPartnerImage] = useState<any>();
  const [cpaImageDimensions, setCPAImageDimensions] = useState<any>("");
  const [partnerImageDimensions, setPartnerImageDimensions] = useState<any>("");
  const [defaultCPAImage, setDefaultCPAImage] = useState<any>("");
  const [assessmentDocument, setAssessmentDocument] = useState<any>([]);
  const envVariables = process.env;
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const serverUrl = envVariables.REACT_APP_SERVER_URL;
  const [getAllDocumentsByAssId] = useLazyQuery(GET_DOCUMENT_BY_ASSESSMENT_ID, {
    fetchPolicy: msgConstants.FETCH_POLICY,
  });
  const rankedQuestionsColumn = [
    { title: "Rank", field: "rank" },
    { title: "Question", field: "question" },
    { title: "Level", field: "level" },
    { title: categoryNumber, field: "CategoryAndNumber" },
    { title: "Answer", field: "answer" },
  ];
  const documentColumn = [
    { title: "Category", field: "category" },
    { title: "Sub-Category", field: "sub-category" },
    { title: "File Name", field: "File Name" },
  ];
  const commentColumn = [
    { title: "Question", field: "question" },
    { title: "Comment", field: "comment" },
    { title: categoryNumber, field: "categoryAndNumber" },
  ];
  const reviewColumn = [
    { title: "Question", field: "question" },
    { title: categoryNumber, field: "categoryAndNumber" },
  ];

  useEffect(() => {
    const controller = new AbortController();
    if (props) {
      setParam(props);
    } else {
      setParam(location.state[Object.keys(location.state)[0]]);
    }
    getDataforSiteSummaryReport();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    GetOrganizationDataOfPartner({
      variables: {
        where: {
          contact_id: param?.clientInfo.partnerId,
        },
      },
    });
    GetOrganizationDataOfClient({
      variables: {
        where: {
          id: param?.clientInfo.clientOrgId,
          subtype: "Client",
        },
      },
    });
    return () => controller.abort();
  }, [param]);

  useEffect(() => {
    const controller = new AbortController();
    createTableDataObject(rankedQuestions);
    return () => controller.abort();
  }, [rankedQuestions]);

  useEffect(() => {
    getAllDocumentsByAssId({
      variables: {
        assId: parseInt(param.ccAssessmentInfo.id),
        featureType: "Document",
      },
    })
      .then((data: any) => {
        setAssessmentDocument(data?.data?.questionDocuments);
      })
      .catch((err:any) => {
        sentry_error_catch(err,setLoading,setFormState)
      });
  }, []);

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: msgConstants.ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const [GetOrganizationDataOfPartner] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.partnerId == element.contact_id.id) {
          setPartnerData(element);
        }
      });
    },
    onError: (err:any) => {
        sentry_error_catch(err,setLoading,setFormState)
    },
  });
  const [GetOrganizationDataOfClient] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.clientOrgId == element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err) => {
      handleError(err);
    },
  });

  const getBannerImages = async () => {
    await fetch(`${process.env.PUBLIC_URL}/images/SiteSummaryReport.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setBannerImage(base64data);
        };
      }).catch((error)=>{
        Sentry.captureException(error)
      })
    await fetch(`${process.env.PUBLIC_URL}/images/blank_img.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setDefaultCPAImage(base64data);
        };
      })
      .catch((error) => {
        console.log("__Error", error);
        Sentry.captureException(error)
      });
    if (from === "result") {
      setPartnerImage(partnerLogo);
      setCPAImage(clientLogo);
      setCPAImageDimensions(clientImageDimension);
      setPartnerImageDimensions(partnerImageDimension);
    } else {
      if (
        partnerData.logo &&
        clientData.logo &&
        clientData.logo[0] &&
        partnerData.logo[0]
      ) {
        const clientLogoUrl = clientData.logo[0].url.slice(1);
        if (clientData.logo && clientData.logo[0]) {
          await fetch(`${serverUrl}${clientLogoUrl}`, {
            method: "GET",
            headers: headerObj,
          })
            .then((response) => response.blob())
            .then((data) => {
              const reader = new FileReader();
              reader.readAsDataURL(data);
              reader.onloadend = function (theFile: any) {
                const base64data = reader.result;
                const image = new Image();
                image.src = theFile.target.result;
                image.onload = () => {
                  setCPAImageDimensions({
                    height: image.height,
                    width: image.width,
                  });
                };

                setCPAImage(base64data);
              };
            })
            .catch((error) => {
              console.log("__Error", error);
              Sentry.captureException(error)
            });
        }
        if (partnerData.logo && partnerData.logo[0]) {
          const partnerLogoUrl = partnerData.logo[0].url.slice(1);
          await fetch(`${serverUrl}${partnerLogoUrl}`, {
            method: "GET",
          })
            .then((response) => response.blob())
            .then((data) => {
              const reader = new FileReader();
              reader.readAsDataURL(data);
              reader.onloadend = function (theFile: any) {
                const base64data = reader.result;
                const image = new Image();
                image.src = theFile.target.result;
                image.onload = () => {
                  setPartnerImageDimensions({
                    height: image.height,
                    width: image.width,
                  });
                };
                setPartnerImage(base64data);
              };
            })
            .catch((error) => {
              console.log("__Error", error);
              Sentry.captureException(error)
            });
        }
      }
    }
  };

  useEffect(() => {
    getBannerImages();
  }, [partnerData, clientData]);

  const getDataforSiteSummaryReport = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/reports/sitesummary`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then(async (data) => {
          if (data) {
            setSiteInformation(data.information);
            setRankedQuestions(data.rankedQuestionsTable);
            setSalTableData(data.salTable);
            setQuestionsWithComments(data.questionsWithComments);
            setQuestionsMarkedForReview(data.questionsMarkedForReview);
          }
          await fetch(`${CSET_URL}api/analysis/Dashboard`, {
            method: "GET",
            headers: headerObj,
          })
            .then((response) => response.json())
            .then(async (chartDataResponse) => {
              setSummaryPercentComplianceChartData({
                labels: chartDataResponse.overallBars.labels,
                datasets: [
                  {
                    label: "SUMMARY PERCENT COMPLIANCE",
                    data: chartDataResponse.overallBars.data,
                    fill: true,
                    backgroundColor: "rgb(11,71,133)",
                    borderColor: "rgb(75,192,192)",
                  },
                ],
              });
              await fetch(`${CSET_URL}api/analysis/StandardsSummary`, {
                method: "GET",
                headers: headerObj,
              })
                .then((response) => response.json())
                .then(async (data) => {
                  setStandardSummaryBarData({
                    labels: data.labels,
                    datasets: data.dataSets,
                    colors: data.colors,
                  });
                  if (data.data.length > 0) {
                    setStandardSummaryPieData({
                      labels: data.labels,
                      datasets: [
                        {
                          data: data.data,
                          backgroundColor: data.colors,
                          borderColor: data.colors,
                          borderWidth: 1,
                        },
                      ],
                    });
                  }
                })
                .catch((error) => {
                  console.log("__Error", error);
                  handleError(error);
                });
              await fetch(
                `${CSET_URL}api/analysis/StandardsResultsByCategory`,
                {
                  method: "GET",
                  headers: headerObj,
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  setStandardsResultsByCategory({
                    labels: data.labels,
                    datasets: data.dataSets,
                    colors: data.colors,
                  });
                  const arr: any[] = [];
                  data.dataSets.forEach((d: any) => {
                    arr.push({
                      labels: d.labels,
                      datasets: [
                        {
                          label: d.label,
                          data: d.data,
                          fill: true,
                          backgroundColor: "rgb(11,71,133)",
                          borderColor: "rgb(75,192,192)",
                        },
                      ],
                    });
                  });
                  setStandardComplianceBarData(arr);
                })
                .catch((error) => {
                  console.log("__Error", error);
                  handleError(error);
                });
              await fetch(`${CSET_URL}api/analysis/OverallRankedCategories`, {
                method: "GET",
                headers: headerObj,
              })
                .then((response) => response.json())
                .then(async (data) => {
                  setRankedSubjectAreas({
                    labels: data.labels,
                    datasets: [
                      {
                        label: rankedAreas,
                        data: data.data,
                        fill: true,
                        backgroundColor: "rgb(163, 0, 14)",
                        borderColor: "rgb(163, 0, 14)",
                      },
                    ],
                  });
                  await fetch(`${CSET_URL}api/analysis/ComponentsSummary`, {
                    method: "GET",
                    headers: headerObj,
                  })
                    .then((response) => response.json())
                    .then((data) => {})
                    .catch((error) => {
                      console.log("__Error", error);
                      handleError(error);
                    });

                  await fetch(`${CSET_URL}api/analysis/ComponentTypes`, {
                    method: "GET",
                    headers: headerObj,
                  })
                    .then((response) => response.json())
                    .then((data) => {})
                    .catch((error) => {
                      console.log("__Error", error);
                      handleError(error);
                    });

                  await fetch(`${CSET_URL}api/analysis/NetworkWarnings`, {
                    method: "GET",
                    headers: headerObj,
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      setLoading(false);
                      setQueryCalling(true);
                    })
                    .catch((error) => {
                      console.log("__Error", error);
                      handleError(error);
                    });
                })
                .catch((error) => {
                  console.log("__Error", error);
                  handleError(error);
                });
            })
            .catch((error) => {
              console.log("__Error", error);
              handleError(error);
            });
        })
        .catch((error) => {
          console.log("__Error", error);
          handleError(error);
        });
    } catch (err:any) {
        sentry_error_catch(err,setLoading,setFormState)
    }
  };
  useEffect(() => {
    if (from && from === "result" && queryCalling) {
      generate();
    }
  }, [queryCalling]);
  const options2 = {
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18,
        fontColor: "black",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontStyle: "bold",
            fontSize: 18,
            fontColor: "black",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontStyle: "bold",
            fontSize: 18,
            fontColor: "black",
          },
        },
      ],
    },
  };

  const options = {
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18,
        fontColor: "black",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            fontStyle: "bold",
            fontSize: 18,
            fontColor: "black",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontStyle: "bold",
            fontSize: 18,
            fontColor: "black",
          },
        },
      ],
    },
  };

  const createTableDataObject = (data: any) => {
    const arr: any = [];
    if (data.length !== 0) {
      data.forEach((element: any) => {
        const obj: any = {};
        obj["rank"] = !element.rank ? "-" : element.rank;
        element.question = element.question.replace(
          /<\s*\/?br\s*[\/]?>/gi,
          "\n"
        );
        obj["question"] = element.question;
        obj["level"] = !element.level ? "-" : element.level;
        obj["CategoryAndNumber"] = !element.categoryAndNumber
          ? "-"
          : element.categoryAndNumber;
        obj["answer"] =
          element.answer === "U"
            ? "Unanswered"
            : element.answer === "Y"
            ? "Yes"
            : element.answer === "N"
            ? "No"
            : "NA";
        arr.push(obj);
      });
      setRankedQuestionsData(arr);
    }
  };

  const handleBack = () => {
    history(routeConstant.REPORTS, { state: { param } });
  };


  const generateTableHeaderCell = (text: string) => {
    return new docx.TableCell({
      children: [new docx.Paragraph({ text, style: "TableHeading" })],
      margins: {
        top: docx.convertInchesToTwip(0.1),
        bottom: docx.convertInchesToTwip(0.1),
        left: docx.convertInchesToTwip(0.1),
        right: docx.convertInchesToTwip(0.1),
      },
      shading: {
        fill: "#d9d2e9",
        color: "auto",
      },
      verticalAlign: docx.VerticalAlign.CENTER,
    });
  };

  const generateTableIndividualCell = (text: string) => {
    let t = text;
    if (typeof text === "number") {
      t = String(text);
    }
    if (t === "N") {
      t = "No";
    }
    if (t === "U") {
      t = "Unanswered";
    }
    if (t === "Y") {
      t = "Yes";
    }
    return new docx.TableCell({
      children: [
        new docx.Paragraph({
          text: t,
          heading: docx.HeadingLevel.HEADING_3,
        }),
      ],
      margins: {
        top: docx.convertInchesToTwip(0.1),
        bottom: docx.convertInchesToTwip(0.1),
        left: docx.convertInchesToTwip(0.1),
        right: docx.convertInchesToTwip(0.1),
      },
      shading: {
        fill: "fcfaff",
        color: "auto",
      },
    });
  };

  const generateTableCells = (data: any, columns: any[]) => {
    let tableRows: any[] = [];
    tableRows = columns.map((c) => {
      return generateTableIndividualCell(data[c.field]);
    });
    return new docx.TableRow({
      children: [...tableRows],
    });
  };

  const generateTableHeaderRow = (columns: any[]) => {
    let tableColumns: any[] = [];
    tableColumns = columns.map((c) => {
      return generateTableHeaderCell(c.title);
    });
    return new docx.TableRow({
      children: [...tableColumns],
    });
  };

  const generateFinalTable = (data: any[], columns: any[]) => {
    const rows: any[] = data.map((d) => {
      return generateTableCells(d, columns);
    });
    return new docx.Table({
      rows: [generateTableHeaderRow(columns), ...rows],
    });
  };

  const fitImg = (targetHeight: any, originalSize: any) => {
    const originalAspectRatio = originalSize.width / originalSize.height;
    let width: number;
    let height: number;
    if (!originalSize) {
      width = targetHeight;
    } else {
      height = targetHeight;
      width = height * originalAspectRatio;
    }
    return width;
  };

  const Doughnutoptions = {
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18,
        fontColor: "black",
      },
    },
  };

  const generate = async () => {
    setLoading(true);
    const doc = new docx.Document({
      styles: {
        default: {
          heading1: {
            run: {
              font: "Helvetica",
              size: 36,
              bold: true,
              color: "#4b0079",
            },
            paragraph: {
              alignment: docx.AlignmentType.LEFT,
              spacing: { line: 340 },
            },
          },
          heading2: {
            run: {
              font: "Helvetica",
              size: 30,
              bold: true,
              color: "#0b4785",
            },
            paragraph: {
              spacing: { line: 340 },
            },
          },
          heading3: {
            run: {
              font: "Helvetica",
              size: 20,
            },
            paragraph: {
              spacing: { line: 276 },
            },
          },
          heading4: {
            run: {
              font: "Helvetica",
              size: 20,
            },
            paragraph: {
              alignment: docx.AlignmentType.JUSTIFIED,
            },
          },
        },
        paragraphStyles: [
          {
            id: "normalPara",
            name: "Normal Para",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              bold: true,
              font: "Helvetica",
              size: 20,
              color: "#245B8D",
            },
            paragraph: {},
          },
          {
            id: "mainHeading",
            name: "Normal Para2",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              font: "Helvetica",
              size: 80,
              color: "#4B0079",
              bold: true,
            },
          },
          {
            id: "date",
            name: "Aside",
            basedOn: "Normal",
            next: "Normal",
            run: {
              font: "Helvetica",
              color: "#4B0079",
              size: 40,
              bold: true,
            },
          },
          {
            id: "bulletText",
            name: "Normal para",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,

            run: {
              font: "Helvetica",
              size: 20,
            },
          },
          {
            id: "miniHeader",
            name: "Normal para",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,

            run: {
              font: "Helvetica",
              size: 24,
              bold: true,
            },
          },
          {
            id: "TableHeading",
            name: "Normal Para2",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,

            run: {
              font: "Helvetica",
              size: 20,
              color: "#4B0079",
              bold: true,
            },
          },
          {
            id: "BoldPara",
            name: "Normal Para3",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,

            run: {
              font: "Helvetica",
              size: 20,
              bold: true,
            },
          },
          {
            id: preparedForBy,
            name: "Aside",
            basedOn: "Normal",
            next: "Normal",
            run: {
              font: "Helvetica",
              color: "#4B0079",
              size: 20,
              bold: true,
            },
          },
          {
            id: "clientPartner",
            name: "Aside",
            basedOn: "Normal",
            next: "Normal",
            run: {
              font: "Helvetica",
              size: 20,
            },
          },
          {
            id: "SiteInformationStyle",
            name: "Aside",
            basedOn: "Normal",
            next: "Normal",
            run: {
              font: "Helvetica",
              color: "#4B0079",
              size: 20,
              bold: true,
            },
          },
          {
            id: "SiteInformationCellStyle",
            name: "Aside",
            basedOn: "Normal",
            next: "Normal",
            run: {
              font: "Helvetica",
              color: "#000",
              size: 20,
            },
          },
        ],
      },
    });
    const imageBase64Data1 = chartRef1.current.chartInstance.toBase64Image();
    const imageBase64Data2 = chartRef2.current.chartInstance.toBase64Image();
    const imageBase64Data3 = chartRef3.current.chartInstance.toBase64Image();
    const imageBase64Data5 = chartRef5.current.chartInstance.toBase64Image();
    const firstchart = imageBase64Data1.split(",")[1];
    const secondchart = imageBase64Data2.split(",")[1];
    const thirdchart = imageBase64Data3.split(",")[1];
    const fifthchart = imageBase64Data5.split(",")[1];

    const imageOfComplianceData: any[] = [];
    const siteSummaryLogo = bannerImage ? bannerImage.split(",")[1] : "";
    const defaultcpaLogo = defaultCPAImage ? defaultCPAImage.split(",")[1] : "";
    const cpaLogo = cpaImage ? cpaImage.split(",")[1] : defaultcpaLogo;
    const msplogo = partnerImage ? partnerImage.split(",")[1] : defaultcpaLogo;
    chartRef4.current.map((s: any, i: any) => {
      const imageBase64 = s.chartInstance.toBase64Image();
      const image = imageBase64.split(",")[1];
      imageOfComplianceData.push(
        docx.Media.addImage(
          doc,
          Uint8Array.from(atob(image), (c) => c.charCodeAt(0)),
          600,
          400
        )
      );
    });
    const footerLogo = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
      fitImg(50, partnerImageDimensions),
      50,
      {
        floating: {
          horizontalPosition: {
            align: docx.HorizontalPositionAlign.RIGHT,
          },
          verticalPosition: {
            align: docx.VerticalPositionAlign.BOTTOM,
          },
        },
      }
    );

    const image1 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(firstchart), (c) => c.charCodeAt(0)),
      600,
      250
    );
    const image2 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(secondchart), (c) => c.charCodeAt(0)),
      600,
      250
    );
    const image3 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(thirdchart), (c) => c.charCodeAt(0)),
      600,
      400
    );

    const image5 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(fifthchart), (c) => c.charCodeAt(0)),
      600,
      400
    );

    const image6 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(siteSummaryLogo), (c) => c.charCodeAt(0)),
      600,
      350
    );

    const image7 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(cpaLogo), (c) => c.charCodeAt(0)),
      fitImg(50, cpaImageDimensions),
      50
    );

    const image8 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
      fitImg(50, partnerImageDimensions),
      50
    );
    const bannertable = new docx.Table({
      rows: [
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  alignment: docx.AlignmentType.LEFT,
                  heading: docx.HeadingLevel.HEADING_1,
                  style: preparedForBy,
                  children: [
                    new docx.TextRun({
                      text: "Prepared for",
                      bold: true,
                    }),
                  ],
                }),
                new docx.Paragraph({
                  text: "",
                }),
                new docx.Paragraph(image7),
                new docx.Paragraph({
                  text: "                                               ",
                }),
                new docx.Paragraph({
                  text: "                                               ",
                }),
                new docx.Paragraph({
                  text: param.clientInfo.name,
                  style: "clientPartner",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.2),
                right: docx.convertInchesToTwip(1.8),
              },
              columnSpan: 5,
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },

                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  alignment: docx.AlignmentType.LEFT,
                  heading: docx.HeadingLevel.HEADING_1,
                  style: preparedForBy,
                  children: [
                    new docx.TextRun({
                      text: "Prepared By",
                      bold: true,
                    }),
                  ],
                }),
                new docx.Paragraph({
                  text: "",
                }),
                new docx.Paragraph(image8),
                new docx.Paragraph({
                  text: "",
                }),
                new docx.Paragraph({
                  text: partnerData?.contact_id?.name,
                  style: "clientPartner",
                }),
                new docx.Paragraph({
                  text: "",
                  style: "clientPartner",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.5),
                right: docx.convertInchesToTwip(2),
              },
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              columnSpan: 5,
              shading: {
                fill: "ffffff",
                color: "auto",
              },
            }),
          ],
        }),
      ],
    });

    doc.addSection({
      children: [
        new docx.Paragraph(image6),
        new docx.Paragraph({
          text: "Site Summary Report",
          style: "mainHeading",
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: moment().format("DD MMMM YYYY"),
          heading: docx.HeadingLevel.HEADING_1,
          style: "date",
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 400,
          },
        }),

        bannertable,
      ],
    });

    const table1 = new docx.Table({
      columnWidths: [3000, 3000],
      rows: [
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({ text: "", style: "TableHeading" }),
              ],
              shading: {
                color: "auto",
              },
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              width: {
                size: 1500,
                type: docx.WidthType.DXA,
              },
              children: [new docx.Paragraph({ text: "" })],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "d9d2e9",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              width: {
                size: 1500,
                type: docx.WidthType.DXA,
              },
              children: [
                new docx.Paragraph({
                  text: "Confidentiality",
                  style: "TableHeading",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "#d9d2e9",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              width: {
                size: 1500,
                type: docx.WidthType.DXA,
              },
              children: [
                new docx.Paragraph({
                  text: "Integrity",
                  style: "TableHeading",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "#d9d2e9",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              width: {
                size: 1500,
                type: docx.WidthType.DXA,
              },
              children: [
                new docx.Paragraph({
                  text: "Availability",
                  style: "TableHeading",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "#d9d2e9",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
          ],
        }),
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({ text: "", style: "TableHeading" }),
              ],
              shading: {
                color: "auto",
              },
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              width: {
                size: 1500,
                type: docx.WidthType.DXA,
              },
              children: [
                new docx.Paragraph({
                  text: "Overall Values",
                  heading: docx.HeadingLevel.HEADING_3,
                  style: "TableHeading",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "#d9d2e9",
                color: "auto",
              },
            }),
            new docx.TableCell({
              width: {
                size: 1500,
                type: docx.WidthType.DXA,
              },
              children: [
                new docx.Paragraph({
                  text: salTableData.q_CV,
                  heading: docx.HeadingLevel.HEADING_3,
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
            }),
            new docx.TableCell({
              width: {
                size: 1500,
                type: docx.WidthType.DXA,
              },
              children: [
                new docx.Paragraph({
                  text: salTableData.q_IV,
                  heading: docx.HeadingLevel.HEADING_3,
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
            }),
            new docx.TableCell({
              width: {
                size: 1500,
                type: docx.WidthType.DXA,
              },
              children: [
                new docx.Paragraph({
                  text: salTableData.q_AV,
                  heading: docx.HeadingLevel.HEADING_3,
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
            }),
          ],
        }),
      ],
    });
    const documentData: any = assessmentDocument.map((doci: any) => {
      const obj: any = {};
      obj["category"] = doci.practise ? doci.practise.split("->")[0] : "Global";
      obj["sub-category"] =
        doci.question_Text === "Global"
          ? "--"
          : doci.practise
          ? `${doci.practise.split("->")[1]} ${
              doci.practise.split("->")[2]
                ? "-> Q." + doci.practise.split("->")[2]
                : ""
            }`
          : "";
      const ar = doci.document_id.map((d: any) => d.name);
      obj["File Name"] = ar.join(", ");
      return obj;
    });

    const table2 = generateFinalTable(documentData, documentColumn);

    const calLevelTable = new docx.Table({
      rows: [
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({ text: "", style: "TableHeading" }),
              ],
              shading: {
                color: "auto",
              },
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              width: {
                size: 100,
                type: docx.WidthType.AUTO,
              },
              children: [
                new docx.Paragraph({
                  text: "CALCULATED LEVEL",
                  style: "TableHeading",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "#d9d2e9",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
          ],
        }),
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({ text: "", style: "TableHeading" }),
              ],
              shading: {
                color: "auto",
              },
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              width: {
                size: 100,
                type: docx.WidthType.AUTO,
              },
              children: [
                new docx.Paragraph({
                  text: salTableData.osv,
                  heading: docx.HeadingLevel.HEADING_3,
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
            }),
          ],
        }),
      ],
    });
    const siteTable = new docx.Table({
      columnWidths: [3000, 3000],
      rows: [
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({ text: "", style: "TableHeading" }),
              ],
              shading: {
                color: "auto",
              },
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: "Assessment Name : ",
                  style: "SiteInformationStyle",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "#d9d2e9",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text:
                    siteInformation?.assessment_Name?.indexOf("%") > -1
                      ? siteInformation?.assessment_Name?.slice(
                          0,
                          siteInformation?.assessment_Name?.indexOf("%")
                        )
                      : siteInformation?.assessment_Name,
                  style: "SiteInformationCellStyle",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
              columnSpan: 2,
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
          ],
        }),
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({ text: "", style: "TableHeading" }),
              ],
              shading: {
                color: "auto",
              },
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: "Assessment Date : ",
                  style: "SiteInformationStyle",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "#d9d2e9",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: siteInformation.assessment_Date,
                  style: "SiteInformationCellStyle",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
              columnSpan: 2,
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
          ],
        }),
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({ text: "", style: "TableHeading" }),
              ],
              shading: {
                color: "auto",
              },
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: "Facility Name : ",
                  style: "SiteInformationStyle",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "#d9d2e9",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: siteInformation.facility_Name,
                  style: "SiteInformationCellStyle",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
              columnSpan: 2,
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
          ],
        }),
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({ text: "", style: "TableHeading" }),
              ],
              shading: {
                color: "auto",
              },
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: "City or Site Name : ",
                  style: "SiteInformationStyle",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "#d9d2e9",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: siteInformation.city_Or_Site_Name,
                  style: "SiteInformationCellStyle",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
              columnSpan: 2,
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
          ],
        }),
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({ text: "", style: "TableHeading" }),
              ],
              shading: {
                color: "auto",
              },
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: "State, Province or Region : ",
                  style: "SiteInformationStyle",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "#d9d2e9",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: siteInformation.state_Province_Or_Region,
                  style: "SiteInformationCellStyle",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
              columnSpan: 2,
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
          ],
        }),
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({ text: "", style: "TableHeading" }),
              ],
              shading: {
                color: "auto",
              },
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: "Principal Assessor Name : ",
                  style: "SiteInformationStyle",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "#d9d2e9",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: siteInformation.assessor_Name,
                  style: "SiteInformationCellStyle",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
              columnSpan: 2,
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
          ],
        }),
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({ text: "", style: "TableHeading" }),
              ],
              shading: {
                color: "auto",
              },
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: "Additional Notes and Comments : ",
                  style: "SiteInformationStyle",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "#d9d2e9",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: siteInformation.additional_Notes_And_Comments,
                  style: "SiteInformationCellStyle",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
              columnSpan: 2,
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
          ],
        }),
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({ text: "", style: "TableHeading" }),
              ],
              shading: {
                color: "auto",
              },
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: "Contact(s) : ",
                  style: "SiteInformationStyle",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "#d9d2e9",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: siteInformation.additional_Contacts
                    ? siteInformation.additional_Contacts[0]
                    : "",
                  style: "SiteInformationCellStyle",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
              columnSpan: 2,
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
          ],
        }),
      ],
    });

    const CommentTable = generateFinalTable(
      questionsWithComments,
      commentColumn
    );

    const ReviewTable = generateFinalTable(
      questionsMarkedForReview,
      reviewColumn
    );

    const table3 = generateFinalTable(
      rankedQuestionsData,
      rankedQuestionsColumn
    );
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Site Information",
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 200,
          },
        }),
        siteTable,
        new docx.Paragraph({
          text: "",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph(image1),
        new docx.Paragraph({
          text: "",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 400,
          },
        }),
      ],
    });
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Evaluation Against Selected Standards and Question Sets",
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 100,
          },
        }),
        new docx.Paragraph({
          text: "STANDARDS SUMMARY",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph(image2),
        new docx.Paragraph({
          text: " ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: "STANDARD OR QUESTION SET",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph(image3),
      ],
    });
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Standards Compliance",
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
            before: 200,
          },
        }),
        ...standardComplianceBarData.map(
          (s: any, i: any) => (
            new docx.Paragraph({
              text: s.datasets[0].label,
              heading: docx.HeadingLevel.HEADING_2,
              alignment: docx.AlignmentType.LEFT,
              spacing: {
                after: 200,
              },
            }),
            new docx.Paragraph(imageOfComplianceData[i])
          )
        ),
      ],
    });
    const tempDoc = {
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Security Assurance Level (SAL)",
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        calLevelTable,
        new docx.Paragraph({
          text: " ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        table1,
        new docx.Paragraph({
          text: " ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
      ],
    };
    if (assessmentDocument.length > 0) {
      tempDoc.children.push(
        new docx.Paragraph({
          text: documentLib,
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        })
      );
      tempDoc.children.push(table2);
    }
    doc.addSection(tempDoc);
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: rankedAreas,
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: "This chart shows subject areas needing the most attention.  Each bar represents the labeled subject area’s weighted contribution so that the combined total always equals 100%. The weighted contribution includes the importance of both the question and the subject area, as well as the percentage of missed questions in that subject area. ",
          heading: docx.HeadingLevel.HEADING_3,
          alignment: docx.AlignmentType.LEFT,
          contextualSpacing: true,
          spacing: {
            after: 200,
          },
          tabStops: [
            {
              type: docx.TabStopType.LEFT,
              position: docx.TabStopPosition.MAX,
            },
          ],
        }),
        new docx.Paragraph(image5),
      ],
    });
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Summary Of Ranked Questions",
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: "Each question that did not meet the required Security Assurance Level (SAL) is shown in ranking  order below. The displayed levels are the SALs applicable to that question. They are: Low (L), Moderate (M), High (H), and Very High (VH). CNSSI levels are for Confidentiality (C), Integrity (I), and Availability (A). DoD Instruction 8500.2 levels are for Confidentiality (Conf) and Mission Assurance Category (MAC). They are: Classified (C),Sensitive (S), and Public (P) for Confidentiality; MAC I, II,and III for Mission Assurance Category. ",
          heading: docx.HeadingLevel.HEADING_3,
          alignment: docx.AlignmentType.LEFT,
          contextualSpacing: true,
          spacing: {
            after: 200,
          },
          tabStops: [
            {
              type: docx.TabStopType.LEFT,
              position: docx.TabStopPosition.MAX,
            },
          ],
        }),
        table3,
      ],
    });
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Question Comments ",
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        CommentTable,

        new docx.Paragraph({
          text: "Alternate Justification Comments ",
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: noJustifications,
          // conditional text
          heading: docx.HeadingLevel.HEADING_3,
          alignment: docx.AlignmentType.LEFT,
          contextualSpacing: true,
          spacing: {
            after: 200,
          },
          tabStops: [
            {
              type: docx.TabStopType.LEFT,
              position: docx.TabStopPosition.MAX,
            },
          ],
        }),
        new docx.Paragraph({
          text: "Questions Marked For Review",
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        ReviewTable,
      ],
    });
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Disclaimer",
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: "The analysis, data, and reports in CSET ® are provided “as is” for informational purposes only. The Department of Homeland Security (DHS) does not provide any warranties of any kind regarding any information contained within. In no event shall the United States Government or its contractors or subcontractors be liable for any damages, including but not limited to, direct, indirect, special, or consequential damages and including damages based on any negligence of the United States Government or its contractors or subcontractors, arising out of, resulting from, or in any way connected with this report, whether based on warranty, contract, tort, or otherwise, whether injury was sustained from, or arose out of the results of, or reliance upon the report. ",
          heading: docx.HeadingLevel.HEADING_3,
          alignment: docx.AlignmentType.LEFT,
          contextualSpacing: true,
          spacing: {
            after: 200,
          },
          tabStops: [
            {
              type: docx.TabStopType.LEFT,
              position: docx.TabStopPosition.MAX,
            },
          ],
        }),
        new docx.Paragraph({
          text: "DHS does not endorse any commercial product or service, including the subject of the assessment or evaluation in this report. Any reference to specific commercial products, processes, or services by trademark, manufacturer, or otherwise, does not constitute or imply its endorsement, recommendation, or favoring by DHS. ",
          heading: docx.HeadingLevel.HEADING_3,
          alignment: docx.AlignmentType.LEFT,
          contextualSpacing: true,
          spacing: {
            after: 200,
          },
          tabStops: [
            {
              type: docx.TabStopType.LEFT,
              position: docx.TabStopPosition.MAX,
            },
          ],
        }),
        new docx.Paragraph({
          text: "The display of the DHS official seal or other DHS visual identities on this report shall not be interpreted to provide the recipient organization authorization to use the official seal, insignia, or other visual identities of DHS. The DHS seal, insignia, or other visual identities shall not be used in any manner to imply endorsement of any commercial product or activity by DHS or the United States Government. Use of the DHS seal without proper authorization violates federal law (e.g., 18 U.S.C. §§ 506, 701, 1017), and is against DHS policies governing usage of the seal. ",
          heading: docx.HeadingLevel.HEADING_3,
          alignment: docx.AlignmentType.LEFT,
          contextualSpacing: true,
          spacing: {
            after: 200,
          },
          tabStops: [
            {
              type: docx.TabStopType.LEFT,
              position: docx.TabStopPosition.MAX,
            },
          ],
        }),
        new docx.Paragraph({
          text: "The report is prepared and intended for internal use by the organization that made the request. The contents of this report may be subject to government or private intellectual property rights. To request distribution of this report outside the organization for which it was prepared, contact the CSET Program Office. The contents of this report may be reproduced or incorporated into other reports, but may not be modified without the prior express written permission of the CSET Program Office. ",
          heading: docx.HeadingLevel.HEADING_3,
          alignment: docx.AlignmentType.LEFT,
          contextualSpacing: true,
          spacing: {
            after: 200,
          },
          tabStops: [
            {
              type: docx.TabStopType.LEFT,
              position: docx.TabStopPosition.MAX,
            },
          ],
        }),
      ],
    });

    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Advisory",
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: "CSET® is only one component of the overall cyber security picture and should be complemented with a robust cyber security program within the organization. A self-assessment with CSET® cannot reveal all types of security weaknesses, and should not be the sole means of determining an organization’s security posture. ",
          heading: docx.HeadingLevel.HEADING_3,
          alignment: docx.AlignmentType.LEFT,
          contextualSpacing: true,
          spacing: {
            after: 200,
          },
          tabStops: [
            {
              type: docx.TabStopType.LEFT,
              position: docx.TabStopPosition.MAX,
            },
          ],
        }),
        new docx.Paragraph({
          text: "The tool will not provide a detailed architectural analysis of the network or a detailed network hardware/software configuration review. It is not a risk analysis tool so it will not generate a complex risk assessment. CSET® is not intended as a substitute for in depth analysis of control system vulnerabilities as performed by trained professionals. Periodic onsite reviews and inspections must still be conducted using a holistic approach including facility walk downs, interviews, and observation and examination of facility practices. Consideration should also be given to additional steps including scanning, penetration testing, and exercises on surrogate, training, or non-production systems, or systems where failures, unexpected faults, or other unexpected results will not compromise production or safety. ",
          heading: docx.HeadingLevel.HEADING_3,
          alignment: docx.AlignmentType.LEFT,
          contextualSpacing: true,
          spacing: {
            after: 200,
          },
          tabStops: [
            {
              type: docx.TabStopType.LEFT,
              position: docx.TabStopPosition.MAX,
            },
          ],
        }),
        new docx.Paragraph({
          text: "CSET® assessments cannot be completed effectively by any one individual. A cross-functional team consisting of representatives from operational, maintenance, information technology, business, and security areas is essential. The representatives must be subject matter experts with significant expertise in their respective areas. No one individual has the span of responsibility or knowledge to effectively answer all the questions.",
          heading: docx.HeadingLevel.HEADING_3,
          alignment: docx.AlignmentType.LEFT,
          contextualSpacing: true,
          spacing: {
            after: 200,
          },
          tabStops: [
            {
              type: docx.TabStopType.LEFT,
              position: docx.TabStopPosition.MAX,
            },
          ],
        }),
        new docx.Paragraph({
          text: "Data and reports generated by the tool should be managed securely and marked, stored, and distributed in a manner appropriate to their sensitivity. ",
          heading: docx.HeadingLevel.HEADING_3,
          alignment: docx.AlignmentType.LEFT,
          contextualSpacing: true,
          spacing: {
            after: 200,
          },
          tabStops: [
            {
              type: docx.TabStopType.LEFT,
              position: docx.TabStopPosition.MAX,
            },
          ],
        }),
      ],
    });

    docx.Packer.toBlob(doc).then(async (blob) => {
      if (from && from === "result") {
        await Promise.resolve()
          .then(() => {
            reportBlobdata = blob;
            reportBlobdata["name"] = reportName;
            handleChange(reportBlobdata);
            setLoading(false);
          })
          .catch((err) => {
            console.log(reportName, err);
            Sentry.captureException(err)
          });
      } else {
        await Promise.resolve(saveAs(blob, reportName)).then(() => {
          setLoading(false);
        }).catch((error)=>{
          Sentry.captureException(error)
        })
      }
    });
  };

  const startLoader = async () => {
    setLoading(true);
    setTimeout(function () {
      generatePdf();
    }, 1000);
  };

  const generatePdf = async () => {
    try {
      setLoading(true);
      const pdf: any = new jsPDF();
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(12);
      const imageBase64Data1 = chartRef1.current.chartInstance.toBase64Image();
      const imageBase64Data2 = chartRef2.current.chartInstance.toBase64Image();
      const imageBase64Data3 = chartRef3.current.chartInstance.toBase64Image();
      const imageBase64Data5 = chartRef5.current.chartInstance.toBase64Image();

      const imageOfComplianceData: any[] = [];

      chartRef4.current.map((s: any, i: any) => {
        const imageBase64 = s.chartInstance.toBase64Image();
        imageOfComplianceData.push(imageBase64);
      });

      //cover page
      pdf.addImage(bannerImage, "JPEG", 10, 10, 190, 125);
      pdf.setDrawColor(0);
      pdf.setTextColor(75, 0, 121);
      pdf.setFontSize(40);
      pdf.setFont("helvetica", "bold");
      pdf.text(msgConstants.SITE_SUMMARY_REPORT, 20, 150);
      pdf.setFontSize(20);
      const date = moment().toDate();
      const formatedDate = moment(date).format("DD MMMM YYYY");
      pdf.text(formatedDate, 20, 165);
      pdf.setFontSize(10);
      pdf.text("Prepared For", 20, 185);
      pdf.text("Prepared By", 110, 185);
      pdf.setDrawColor(75, 0, 121);
      pdf.setLineWidth(0.75);
      pdf.line(100, 185, 100, 225);
      if (partnerImage !== undefined) {
        pdf.addImage(
          partnerImage,
          "JPEG",
          107,
          190,
          fitImg(18, partnerImageDimensions),
          18
        );
      }
      if (cpaImage !== undefined) {
        pdf.addImage(
          cpaImage,
          "JPEG",
          20,
          190,
          fitImg(18, cpaImageDimensions),
          18
        );
      }
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      pdf.text(param.clientInfo.name, 20, 220);
      pdf.text(partnerData.contact_id.name, 110, 220);
      pdf.text("", 110, 225);

      // third page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.text("Site Information", 10, 30);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0); //paragraph black color
      pdf.setFontSize(10);
      autoTable(pdf, {
        html: "#siteTable",
        margin: { top: 40, left: 10, right: 70, bottom: 0 },
        theme: "grid",
        columnStyles: {
          0: {
            fontSize: 10,
            fontStyle: "bold",
            textColor: [75, 0, 121],
            fillColor: [217, 210, 233],
            minCellHeight: 10,
            lineWidth: 0.1,
            lineColor: "#000",
          },
          1: {
            fontSize: 10,
            fontStyle: "normal",
            lineWidth: 0.1,
            lineColor: "#000",
          },
        },
      });
      const imgData1 = imageBase64Data1;
      pdf.addImage(imgData1, "JPEG", 15, 125, 180, 0);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133); //subheading orange color
      // forth page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121); // purple
      pdf.text(
        "Evaluation Against Selected Standards and Question Sets",
        10,
        30
      );
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133); // blue
      pdf.text("STANDARDS SUMMARY", 10, 40);
      const imgData2 = imageBase64Data2;
      pdf.addImage(imgData2, "JPEG", 15, 50, 180, 0);
      pdf.text("STANDARD OR QUESTION SET", 10, 160);
      const imgData3 = imageBase64Data3;
      pdf.addImage(imgData3, "JPEG", 15, 170, 180, 0);

      //fifth page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.text("Standards Compliance", 10, 30);
      let counter = 0;
      standardComplianceBarData.forEach((s: any, i: any) => {
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133); //subheading orange color
        pdf.text(s.datasets[0].label, 10, 40);
        pdf.addImage(imageOfComplianceData[i], "JPEG", 10, 55, 180, 0);
        counter = counter + 1;
        if (standardComplianceBarData.length > counter) {
          pdf.addPage();
        }
      });

      // sixth page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.text("Security Assurance Level (SAL)", 10, 30);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0); //paragraph black color
      pdf.setFontSize(10);
      autoTable(pdf, {
        head: [["CALCULATED LEVEL"]],
        body: [[salTableData.osv]],
        theme: "grid",
        margin: { top: 35, left: 10, right: 160 },
        headStyles: {
          fillColor: [217, 210, 233],
          textColor: [75, 0, 121],
          lineWidth: 0.1,
          lineColor: "#000",
        },
        columnStyles: {
          0: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          1: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
        },
      });
      autoTable(pdf, {
        head: [[" ", "Confidentiality", "Integrity", "Availability"]],
        body: [
          [
            "Overall Values",
            salTableData.q_CV,
            salTableData.q_IV,
            salTableData.q_AV,
          ],
        ],
        margin: { top: 60, left: 10, right: 50 },
        theme: "grid",
        headStyles: {
          fillColor: [217, 210, 233],
          textColor: [75, 0, 121],
          lineWidth: 0.1,
          lineColor: "#000",
        },
        columnStyles: {
          0: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          1: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          2: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          3: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
        },
      });

      if (assessmentDocument.length > 0) {
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(16);
        pdf.setTextColor(75, 0, 121); //heading blue color
        if (assessmentDocument.length < 7) {
          pdf.text(documentLib, 10, 95, { lineHeightFactor: 1.5 });
        } else {
          pdf.addPage("a4", "p");
          pdf.text(documentLib, 10, 20, { lineHeightFactor: 1.5 });
        }
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0); //paragraph black color
        pdf.setFontSize(10);

        autoTable(pdf, {
          html: "#docTable",
          startY:
            assessmentDocument.length < 7
              ? pdf.autoTable.previous.finalY + 25
              : 30,
          margin: { top: 165, left: 10, right: 120, bottom: 50 },
          didParseCell(data) {
            if (data.row.index === 0) {
              data.cell.styles.textColor = [75, 0, 121];
              data.cell.styles.fillColor = [217, 210, 233];
              data.cell.styles.fontStyle = "bold";
              data.cell.styles.font = "helvetica";
            }
          },
          theme: "grid",
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
              cellWidth: "wrap",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
              cellWidth: "wrap",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
              cellWidth: "wrap",
            },
          },
        });
      }
      //seventh page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.text(rankedAreas, 10, 30);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0); //paragraph black color
      pdf.setFontSize(10);
      const rankedSubjectAreasp1 = pdf.splitTextToSize(
        "This chart shows subject areas needing the most attention.  Each bar represents the labeled subject area’s weighted contribution so that the combined total always equals 100%.  The weighted contribution includes the importance of both the question and the subject area, as well as the percentage of missed questions in that subject area. ",
        180
      );
      pdf.text(rankedSubjectAreasp1, 10, 40);
      const imgData5 = imageBase64Data5;
      pdf.addImage(imgData5, "JPEG", 15, 75, 180, 0);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.text("Summary Of Ranked Questions", 10, 200);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0); //paragraph black color
      pdf.setFontSize(10);
      const summaryOfRankedQuestionsp1 = pdf.splitTextToSize(
        "Each question that did not meet the required Security Assurance Level (SAL) is shown in ranking order below. The displayed levels are the SALs applicable to that question. They are: Low (L), Moderate (M), High (H), and Very High (VH). CNSSI levels are for Confidentiality (C), Integrity (I), and Availability (A). DoD Instruction 8500.2 levels are for Confidentiality (Conf) and Mission Assurance Category (MAC). They are: Classified (C), Sensitive (S), and Public (P) for Confidentiality; MAC I, II, and III for Mission Assurance Category. ",
        180
      );
      pdf.text(summaryOfRankedQuestionsp1, 10, 210);

      //eighth page
      pdf.addPage("a4", "p");
      const headers = [["Rank", "Question", "Level", categoryNumber, "Answer"]];
      const rankedQuestionsOrganisedata: any[] = [];
      rankedQuestionsData.forEach((rq: any) => {
        const data = [];
        data.push(rq["rank"]);
        data.push(rq["question"]);
        data.push(rq["level"]);
        data.push(rq["CategoryAndNumber"]);
        data.push(rq["answer"]);
        rankedQuestionsOrganisedata.push(data);
      });
      autoTable(pdf, {
        head: headers,
        body: rankedQuestionsOrganisedata,
        margin: { top: 20, left: 10, bottom: 30 },
        theme: "grid",
        headStyles: {
          fillColor: [217, 210, 233],
          textColor: [75, 0, 121],
          lineWidth: 0.1,
          lineColor: "#000",
        },
        columnStyles: {
          0: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          1: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          2: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          3: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          4: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
        },
      });

      // nineth page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.text("Question Comments", 10, 20);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0); //paragraph black color
      pdf.setFontSize(10);
      const Commentheaders = [
        [categoryNumber, "Question", "Comment"], //"Answer"],
      ];
      const questionsWithCommentsData: any[] = [];
      questionsWithComments.forEach((rq: any) => {
        const data = [];
        data.push(rq["categoryAndNumber"]);
        data.push(rq["question"]);
        data.push(rq["comment"]);
        questionsWithCommentsData.push(data);
      });
      autoTable(pdf, {
        head: Commentheaders,
        body: questionsWithCommentsData,
        margin: { top: 30, left: 10, bottom: 30 },
        theme: "grid",
        headStyles: {
          fillColor: [217, 210, 233],
          textColor: [75, 0, 121],
          lineWidth: 0.1,
          lineColor: "#000",
        },
        columnStyles: {
          0: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          1: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          2: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          3: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          4: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
        },
      });

      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.text("Alternate Justification Comments", 10, 60);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0); //paragraph black color
      pdf.setFontSize(10);
      pdf.text(noJustifications, 10, 70);
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.text("Questions Marked For Review", 10, 20);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0); //paragraph black color
      pdf.setFontSize(10);
      const Reviewheaders = [[categoryNumber, "Question"]]; // "Answer"]];
      const questionsWithReviewData: any[] = [];
      questionsMarkedForReview.forEach((rq: any) => {
        const data = [];
        data.push(rq["categoryAndNumber"]);
        data.push(rq["question"]);
        questionsWithReviewData.push(data);
      });
      autoTable(pdf, {
        head: Reviewheaders,
        body: questionsWithReviewData,
        margin: { top: 30, left: 10, bottom: 30 },
        theme: "grid",
        headStyles: {
          fillColor: [217, 210, 233],
          textColor: [75, 0, 121],
          lineWidth: 0.1,
          lineColor: "#000",
        },
        columnStyles: {
          0: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          1: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          2: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          3: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
          4: {
            lineWidth: 0.1,
            lineColor: "#000",
          },
        },
      });

      pdf.addPage("a4", "p");
      pdf.setFontSize(24);
      pdf.setTextColor(75, 0, 121); // Blue
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.text("Disclaimer", 10, 30);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0); //paragraph black color
      pdf.setFontSize(10); // Black
      const disclaimersp1 = pdf.splitTextToSize(
        "The analysis, data, and reports in CSET ® are provided “as is” for informational purposes only. The Department of Homeland Security (DHS) does not provide any warranties of any kind regarding any information contained within. In no event shall the United States Government or its contractors or subcontractors be liable for any damages, including but not limited to, direct, indirect, special, or consequential damages and including damages based on any negligence of the United States Government or its contractors or subcontractors, arising out of, resulting from, or in any way connected with this report, whether based on warranty, contract, tort, or otherwise, whether injury was sustained from, or arose out of the results of, or reliance upon the report. ",
        180
      );
      pdf.text(disclaimersp1, 10, 40);
      const disclaimersp2 = pdf.splitTextToSize(
        "DHS does not endorse any commercial product or service, including the subject of the assessment or evaluation in this report. Any reference to specific commercial products, processes, or services by trademark, manufacturer, or otherwise, does not constitute or imply its endorsement, recommendation, or favoring by DHS. ",
        180
      );
      pdf.text(disclaimersp2, 10, 75);
      const disclaimersp3 = pdf.splitTextToSize(
        "The display of the DHS official seal or other DHS visual identities on this report shall not be interpreted to provide the recipient organization authorization to use the official seal, insignia, or other visual identities of DHS. The DHS seal, insignia, or other visual identities shall not be used in any manner to imply endorsement of any commercial product or activity by DHS or the United States Government. Use of the DHS seal without proper authorization violates federal law (e.g., 18 U.S.C. §§ 506, 701, 1017), and is against DHS policies governing usage of the seal. ",
        180
      );
      pdf.text(disclaimersp3, 10, 93);
      const disclaimersp4 = pdf.splitTextToSize(
        "The report is prepared and intended for internal use by the organization that made the request. The contents of this report may be subject to government or private intellectual property rights. To request distribution of this report outside the organization for which it was prepared, contact the CSET Program Office. The contents of this report may be reproduced or incorporated into other reports, but may not be modified without the prior express written permission of the CSET Program Office. ",
        180
      );
      pdf.text(disclaimersp4, 10, 120);

      // second page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.text("Advisory", 10, 30);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0); //paragraph black color
      pdf.setFontSize(10); // Black
      const advisoriesp1 = pdf.splitTextToSize(
        "CSET® is only one component of the overall cyber security picture and should be complemented with a robust cyber security program within the organization. A self-assessment with CSET® cannot reveal all types of security weaknesses, and should not be the sole means of determining an organization’s security posture. ",
        180
      );
      pdf.text(advisoriesp1, 10, 40);
      const advisoriesp2 = pdf.splitTextToSize(
        "The tool will not provide a detailed architectural analysis of the network or a detailed network hardware/software configuration review. It is not a risk analysis tool so it will not generate a complex risk assessment. CSET® is not intended as a substitute for in depth analysis of control system vulnerabilities as performed by trained professionals. Periodic onsite reviews and inspections must still be conducted using a holistic approach including facility walk downs, interviews, and observation and examination of facility practices. Consideration should also be given to additional steps including scanning, penetration testing, and exercises on surrogate, training, or non-production systems, or systems where failures, unexpected faults, or other unexpected results will not compromise production or safety. ",
        180
      );
      pdf.text(advisoriesp2, 10, 60);
      const advisoriesp3 = pdf.splitTextToSize(
        "CSET® assessments cannot be completed effectively by any one individual. A cross-functional team consisting of representatives from operational, maintenance, information technology, business, and security areas is essential. The representatives must be subject matter experts with significant expertise in their respective areas. No one individual has the span of responsibility or knowledge to effectively answer all the questions.",
        180
      );
      pdf.text(advisoriesp3, 10, 100);
      const advisoriesp4 = pdf.splitTextToSize(
        "Data and reports generated by the tool should be managed securely and marked, stored, and distributed in a manner appropriate to their sensitivity. ",
        180
      );
      pdf.text(advisoriesp4, 10, 120);

      //header and footer
      const pageCount = pdf.internal.getNumberOfPages();
      for (let i = 0; i <= pageCount; i++) {
        if (i > 1) {
          pdf.setPage(i);
          pdf.setFont("helvetica", "normal");
          pdf.setFontSize(10);
          pdf.setTextColor(0, 0, 0); //paragraph black color
          pdf.text(
            `Page | ${pdf.internal.getCurrentPageInfo().pageNumber - 1}`,
            10,
            290
          );
          const width = pdf.internal.pageSize.getWidth();
          const height = pdf.internal.pageSize.getHeight();
          if (partnerImage !== undefined) {
            pdf.addImage(
              partnerImage,
              "JPEG",
              180,
              270,
              width / 10,
              height / 14
            );
          }
        }
      }

      await Promise.resolve(pdf.save("Site_Summary_Report.pdf"))
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          Sentry.captureException(error)
        });
    } catch (error) {
      setLoading(false);
      console.log("ERR", error);
      Sentry.captureException(error)
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container>
        <Grid item md={12}>
          <Typography component="h5" variant="h1">
            {msgConstants.SITE_SUMMARY_REPORT}
          </Typography>
          <Grid item xs={12} sm={12} className={styles.FooterActions}>
            <div className={styles.prevBtn}>
              <Button
                id="previous-button1"
                variant="contained"
                color="primary"
                onClick={handleBack}>
                {"Previous"}
              </Button>
              <div className={styles.borderButton}>
                <Button
                  id="doc-generate-button1"
                  variant="contained"
                  color="primary"
                  onClick={() => generate()}>
                  <GetAppIcon className={styles.EditIcon} />
                  &nbsp; Doc
                </Button>
                <Button
                  id="pdf-generate-button1"
                  variant="contained"
                  color="primary"
                  onClick={() => startLoader()}>
                  <GetAppIcon className={styles.EditIcon} />
                  &nbsp; PDF
                </Button>
              </div>
            </div>
          </Grid>
          {formState.isFailed ? (
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {formState.errMessage}
            </Alert>
          ) : null}
          <Paper className={styles.paper}>
            {loading ? <SimpleBackdrop /> : null}

            <Grid container>
              <Grid
                item
                xs={8}
                md={"auto"}
                sm={12}
                className={styles.FooterActions}>
                <Grid
                  item
                  xs={8}
                  md={12}
                  sm={12}
                  className={styles.FooterActions}>
                  <h3>Site Information </h3>
                </Grid>
                <Grid
                  item
                  xs={8}
                  md={12}
                  sm={12}
                  className={styles.FooterActions}>
                  <table id="siteTable" className={styles.tableStyle}>
                    <tr>
                      <td className={styles.hedaerCell}>Assessment Name :</td>
                      <td>
                        {siteInformation
                          ? siteInformation?.assessment_Name?.indexOf("%") > -1
                            ? siteInformation?.assessment_Name?.slice(
                                0,
                                siteInformation?.assessment_Name?.indexOf("%")
                              )
                            : siteInformation?.assessment_Name
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.hedaerCell}>Assessment Date :</td>
                      <td>
                        {siteInformation ? siteInformation.assessment_Date : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.hedaerCell}>Facility Name :</td>
                      <td>
                        {siteInformation ? siteInformation.facility_Name : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.hedaerCell}>City or Site Name :</td>
                      <td>
                        {siteInformation
                          ? siteInformation.city_Or_Site_Name
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.hedaerCell}>
                        State, Province or Region :
                      </td>
                      <td>
                        {" "}
                        {siteInformation
                          ? siteInformation.state_Province_Or_Region
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.hedaerCell}>
                        Principal Assessor Name :
                      </td>
                      <td>
                        {siteInformation ? siteInformation.assessor_Name : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.hedaerCell}>
                        Additional Notes and Comments :
                      </td>
                      <td>
                        {siteInformation
                          ? siteInformation.additional_Notes_And_Comments
                          : ""}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.hedaerCell}>Contact(s) :</td>
                      <td>
                        {siteInformation
                          ? siteInformation.additional_Contacts
                            ? siteInformation.additional_Contacts[0]
                            : ""
                          : ""}
                      </td>
                    </tr>
                  </table>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                className={styles.FooterActions}>
                <div>
                  <></>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                className={styles.FooterActions}>
                <HorizontalBar
                  data={summaryPercentComplianceChartData}
                  ref={chartRef1}
                  options={options}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                className={styles.FooterActions}>
                <hr className={styles.ruler} />
              </Grid>
              <Grid item xs={12} md={12} sm={12} className={styles.gridSection}>
                <h3>
                  {" "}
                  Evaluation Against Selected Standards and Question Sets{" "}
                </h3>
                <h4> STANDARDS SUMMARY </h4>
                {standardSummaryPieData?.datasets?.length > 0 ? (
                  <div>
                    <Doughnut
                      data={standardSummaryPieData}
                      ref={chartRef2}
                      options={Doughnutoptions}
                    />
                  </div>
                ) : (
                  <div>
                    <HorizontalBar
                      data={standardSummaryBarData}
                      ref={chartRef2}
                      options={options}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} md={12} sm={12} className={styles.gridSection}>
                <h4>STANDARD OR QUESTION SET</h4>
                <HorizontalBar
                  data={standardsResultsByCategory}
                  ref={chartRef3}
                  options={options}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                className={styles.FooterActions}>
                <hr className={styles.ruler} />
              </Grid>

              <Grid item xs={12} md={12} sm={12} className={styles.gridSection}>
                <h3>Standards Compliance </h3>
                {standardComplianceBarData.map((s: any, i: any) => (
                  <>
                    <h4>{s.datasets[0].label}</h4>
                    <HorizontalBar
                      data={s}
                      ref={(el: any) => (chartRef4.current[i] = el)}
                      options={options}
                    />
                  </>
                ))}
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                className={styles.FooterActions}>
                <hr className={styles.ruler} />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                className={styles.FooterActions}>
                <h3>Security Assurance Level (SAL) </h3>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sm={4}
                  className={styles.FooterActions}>
                  <div className={styles.cal_block}>
                    <div className={styles.cal_head}>
                      <Typography>CALCULATED LEVEL</Typography>
                      <div className={styles.cal_level}>
                        <Typography>{salTableData.osv}</Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  sm={12}
                  className={styles.caclulated_table}>
                  <table>
                    <tr>
                      <th> </th>
                      <th>Confidentiality</th>
                      <th>Integrity</th>
                      <th>Availability</th>
                    </tr>
                    <tr>
                      <td>Overall Values</td>
                      <td>{salTableData.q_CV}</td>
                      <td>{salTableData.q_IV}</td>
                      <td>{salTableData.q_AV}</td>
                    </tr>
                  </table>
                </Grid>
                <hr className={styles.ruler} />
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                sm={12}
                className={styles.caclulated_table}>
                <h3>Document Library </h3>
                <table id="docTable" className={styles.docLibraryTable}>
                  <tr>
                    <th>Category</th>
                    <th>Sub Category</th>
                    <th>File Name</th>
                  </tr>
                  {assessmentDocument && assessmentDocument.length > 0 ? (
                    assessmentDocument.map((assDoc: any) => {
                      const splitArr = assDoc.practise
                        ? assDoc.practise.split("->")
                        : "";
                      return (
                        <tr>
                          <td>{assDoc.practise ? splitArr[0] : "Global"}</td>
                          <td>
                            {assDoc.question_Text === "Global"
                              ? "--"
                              : assDoc.practise
                              ? `${splitArr[1]} ${
                                  splitArr[2] ? "-> Q." + splitArr[2] : ""
                                }`
                              : ""}
                          </td>
                          <td>
                            {assDoc?.document_id.map((doc: any) => {
                              return (
                                <div>
                                  <div style={{ width: "100%" }}>
                                    {doc.name}
                                  </div>
                                  <br />
                                </div>
                              );
                            })}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td></td>
                      <td>No Documents Found</td>
                      <td></td>
                    </tr>
                  )}
                </table>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                className={styles.FooterActions}>
                <hr className={styles.ruler} />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                className={styles.FooterActions}>
                <h3>Ranked Subject Areas </h3>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  className={styles.FooterActions}>
                  <p>
                    This chart shows subject areas needing the most attention.
                    Each bar represents the labeled subject area’s weighted
                    contribution so that the combined total always equals 100%.
                    The weighted contribution includes the importance of both
                    the question and the subject area, as well as the percentage
                    of missed questions in that subject area.{" "}
                  </p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  className={styles.FooterActions}>
                  <HorizontalBar
                    data={rankedSubjectAreas}
                    ref={chartRef5}
                    options={options2}
                  />
                </Grid>
                <hr className={styles.ruler} />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                className={styles.FooterActions}>
                <h3>Summary Of Ranked Questions </h3>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  className={styles.FooterActions}>
                  <p>
                    Each question that did not meet the required Security
                    Assurance Level (SAL) is shown in ranking order below. The
                    displayed levels are the SALs applicable to that question.
                    They are: Low (L), Moderate (M), High (H), and Very High
                    (VH). CNSSI levels are for Confidentiality (C), Integrity
                    (I), and Availability (A). DoD Instruction 8500.2 levels are
                    for Confidentiality (Conf) and Mission Assurance Category
                    (MAC). They are: Classified (C), Sensitive (S), and Public
                    (P) for Confidentiality; MAC I, II, and III for Mission
                    Assurance Category.{" "}
                  </p>
                </Grid>
                <Grid
                item
                xs={12}
                md={8}
                sm={12}
                // className={styles.caclulated_table}
                >
                  {rankedQuestionsData && rankedQuestionsData.length > 0 ?
                  <table>
                    <tr>
                      <th>Rank</th>
                      <th>Question</th>
                      <th>Level</th>
                      <th>Category Number</th>
                      <th>Answer</th>
                    </tr>
                    {
                      rankedQuestionsData.map((rankData:any) => {
                        return (
                           <tr>
                             <td>{rankData.rank}</td>
                             <td>{rankData.question}</td>
                             <td>{rankData.level}</td>
                             <td>{rankData.CategoryAndNumber}</td>
                             <td>{rankData.answer}</td>
                           </tr>
                        )
                      })
                    }
                  </table> : null}
                </Grid>
                <hr className={styles.ruler} />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                className={styles.FooterActions}>
                <h3> Question Comments </h3>
                <p>
                  <table>
                    <tr>
                      <th> </th>
                      <th>Category</th>
                      <th>Question</th>
                      <th>Comment</th>
                    </tr>
                    {questionsWithComments.length > 0
                      ? questionsWithComments.map((element: any) => {
                          return (
                            <tr>
                              <td></td>
                              <td>{element.categoryAndNumber}</td>
                              <td>{element.question}</td>
                              <td>{element.comment}</td>
                            </tr>
                          );
                        })
                      : defaultNoQuestions}
                  </table>
                </p>
                <hr className={styles.ruler} />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                className={styles.FooterActions}>
                <h3> Alternate Justification Comments </h3>
                <p>{noJustifications}</p>
                <hr className={styles.ruler} />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                className={styles.FooterActions}>
                <h3>Questions Marked For Review </h3>
                <p>
                  <table>
                    <tr>
                      <th> </th>
                      <th>Category</th>
                      <th>Question</th>
                    </tr>
                    {questionsMarkedForReview.length > 0
                      ? questionsMarkedForReview.map((element: any) => {
                          return (
                            <tr>
                              <td></td>
                              <td>{element.categoryAndNumber}</td>
                              <td>{element.question}</td>
                            </tr>
                          );
                        })
                      : noreview}
                  </table>
                </p>
                <hr className={styles.ruler} />
                <Grid
                  item
                  xs={8}
                  md={"auto"}
                  sm={12}
                  className={styles.FooterActions}>
                  <h3>Disclaimer</h3>
                  <Grid
                    item
                    xs={8}
                    md={12}
                    sm={12}
                    className={styles.FooterActions}>
                    <p>
                      The analysis, data, and reports in CSET ® are provided “as
                      is” for informational purposes only. The Department of
                      Homeland Security (DHS) does not provide any warranties of
                      any kind regarding any information contained within. In no
                      event shall the United States Government or its
                      contractors or subcontractors be liable for any damages,
                      including but not limited to, direct, indirect, special,
                      or consequential damages and including damages based on
                      any negligence of the United States Government or its
                      contractors or subcontractors, arising out of, resulting
                      from, or in any way connected with this report, whether
                      based on warranty, contract, tort, or otherwise, whether
                      injury was sustained from, or arose out of the results of,
                      or reliance upon the report.{" "}
                    </p>
                    <p>
                      DHS does not endorse any commercial product or service,
                      including the subject of the assessment or evaluation in
                      this report. Any reference to specific commercial
                      products, processes, or services by trademark,
                      manufacturer, or otherwise, does not constitute or imply
                      its endorsement, recommendation, or favoring by DHS.{" "}
                    </p>
                    <p>
                      The display of the DHS official seal or other DHS visual
                      identities on this report shall not be interpreted to
                      provide the recipient organization authorization to use
                      the official seal, insignia, or other visual identities of
                      DHS. The DHS seal, insignia, or other visual identities
                      shall not be used in any manner to imply endorsement of
                      any commercial product or activity by DHS or the United
                      States Government. Use of the DHS seal without proper
                      authorization violates federal law (e.g., 18 U.S.C. §§
                      506, 701, 1017), and is against DHS policies governing
                      usage of the seal.{" "}
                    </p>
                    <p>
                      The report is prepared and intended for internal use by
                      the organization that made the request. The contents of
                      this report may be subject to government or private
                      intellectual property rights. To request distribution of
                      this report outside the organization for which it was
                      prepared, contact the CSET Program Office. The contents of
                      this report may be reproduced or incorporated into other
                      reports, but may not be modified without the prior express
                      written permission of the CSET Program Office.{" "}
                    </p>
                    <hr className={styles.ruler} />
                  </Grid>
                </Grid>
                <h3>Advisory</h3>
                <Grid
                  item
                  xs={8}
                  md={12}
                  sm={12}
                  className={styles.FooterActions}>
                  <p>
                    CSET® is only one component of the overall cyber security
                    picture and should be complemented with a robust cyber
                    security program within the organization. A self-assessment
                    with CSET® cannot reveal all types of security weaknesses,
                    and should not be the sole means of determining an
                    organization’s security posture.{" "}
                  </p>
                  <p>
                    The tool will not provide a detailed architectural analysis
                    of the network or a detailed network hardware/software
                    configuration review. It is not a risk analysis tool so it
                    will not generate a complex risk assessment. CSET® is not
                    intended as a substitute for in depth analysis of control
                    system vulnerabilities as performed by trained
                    professionals. Periodic onsite reviews and inspections must
                    still be conducted using a holistic approach including
                    facility walk downs, interviews, and observation and
                    examination of facility practices. Consideration should also
                    be given to additional steps including scanning, penetration
                    testing, and exercises on surrogate, training, or
                    non-production systems, or systems where failures,
                    unexpected faults, or other unexpected results will not
                    compromise production or safety.{" "}
                  </p>
                  <p>
                    {" "}
                    CSET® assessments cannot be completed effectively by any one
                    individual. A cross-functional team consisting of
                    representatives from operational, maintenance, information
                    technology, business, and security areas is essential. The
                    representatives must be subject matter experts with
                    significant expertise in their respective areas. No one
                    individual has the span of responsibility or knowledge to
                    effectively answer all the questions.
                  </p>
                  <p>
                    Data and reports generated by the tool should be managed
                    securely and marked, stored, and distributed in a manner
                    appropriate to their sensitivity.{" "}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} className={styles.FooterActions}>
          <div className={styles.prevBtn}>
            <Button
              id="previous"
              variant="contained"
              color="primary"
              onClick={handleBack}>
              {"Previous"}
            </Button>
            <div className={styles.borderButton}>
              <Button
                id="doc-generate-button2"
                variant="contained"
                color="primary"
                onClick={() => generate()}>
                <GetAppIcon className={styles.EditIcon} />
                &nbsp; Doc
              </Button>
              <Button
                id="pdf-generate-button2"
                variant="contained"
                color="primary"
                onClick={() => startLoader()}>
                <GetAppIcon className={styles.EditIcon} />
                &nbsp; PDF
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SiteSummaryReport;
