import React, { useState, useEffect } from "react";
import { CSET_URL } from "../../../../../config";
import logout from "../../../../Auth/Logout/Logout";
import DOMPurify from "dompurify";
import styles from "./CRRExecutiveSummary.module.css";

export interface CrrNistCsfSummaryProps {
  data?: any;
  props?: any;
  CrrNistCsfSummaryref?: any;
  CrrNistCsfSummaryData?: any;
  handleChildCompError?: (err: any) => void;
}

export const CrrNistCsfSummary: React.FC<CrrNistCsfSummaryProps> = ({
  data,
  props,
  CrrNistCsfSummaryref,
  CrrNistCsfSummaryData,
  handleChildCompError = () => {}
}: CrrNistCsfSummaryProps) => {
  const [legend, setLegend] = useState<any>(null);
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [chartAll, setChartAll] = useState<any>(null);
  const [bodyData, setBodyData] = useState<any>([]);

  useEffect(() => {
    getNistCsfReportBodyData();
    nistCsfSummaryChart();
    getMil1PerformanceSummaryLegendWidget();
  }, []);

  /**
   * Convert String to html Response
   * @param html
   */
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  useEffect(() => {
    if (legend && chartAll && bodyData.length > 0) {
      CrrNistCsfSummaryData({
        legend,
        chartAll,
        bodyData,
      });
    }
  }, [legend, chartAll, bodyData]);

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const getNistCsfReportBodyData = async () => {
    try{
      const response = await fetch(
      `${CSET_URL}api/reportscrr/getNistCsfReportBodyData`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data = await response.json();
    if (response.status === 200) {
      setBodyData(data);
    }else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  }catch(error){
    handleChildCompError(error)
  }
  };

  const nistCsfSummaryChart = async () => {
    try{
      const response = await fetch(
      `${CSET_URL}api/reportscrr/widget/nistCsfSummaryChart`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data = await response.text();
    if (response.status === 200) {
      setChartAll(data);
    }else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  }catch(error){
    handleChildCompError(error)
  }
  };

  const getMil1PerformanceSummaryLegendWidget = async () => {
    try{
      const response = await fetch(
      `${CSET_URL}api/reportscrr/widget/mil1PerformanceSummaryLegend`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data = await response.text();
    if (response.status === 200) {
      setLegend(data);
    }else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  }catch(error){
    handleChildCompError(error)
  }
  };

  return (
    <div
      className={styles.pageMarginTop}
      ref={CrrNistCsfSummaryref}
      style={{ marginTop: "40px", paddingTop: "85px" }}>
      <div className={styles.containerFluid}>
        <div className={styles.row}>
          <div className={styles.col3} style={{ paddingLeft: "2rem" }}>
            <div dangerouslySetInnerHTML={createDangerousHTML(chartAll)}></div>
            <h5 className={styles.appendixLabel}>NIST CSF Summary</h5>
          </div>
          <div
            className={styles.col9}
            style={{ padding: 0, position: "relative" }}>
            <div
              className={styles.row}
              style={{ position: "absolute", bottom: 0 }}>
              <div className={styles.col6}>
                <h3 className={styles.depictionTitle}>
                  NIST Cybersecurity Framework Summary
                </h3>
              </div>
              <div
                className={`${styles.col6} ${styles.tinytext}`}
                dangerouslySetInnerHTML={createDangerousHTML(legend)}></div>
            </div>
          </div>
        </div>
        <div className={styles.row} style={{ borderTop: "1px solid black" }}>
          <div
            className={styles.col3}
            style={{ fontSize: "10px", paddingLeft: "2rem" }}>
            FUNCTION
          </div>
          <div className={styles.col9} style={{ fontSize: "10px", padding: 0 }}>
            CATEGORY
          </div>
        </div>
        {bodyData.map((e: any) => {
          return (
            <div
              className={styles.row}
              style={{ borderTop: "1px solid black" }}>
              <div className={styles.col3}>
                <div className={styles.row} style={{ height: "95%" }}>
                  <div
                    style={{
                      height: "100%",
                      margin: "0.25rem 0.75rem 0.25rem 0",
                      width: "10%",
                      backgroundColor:
                        "model?.crrScores.csfFunctionColors[e.function.Function.code]",
                    }}>
                    <div style={{ height: "145px" }}></div>
                  </div>
                  <div className={styles.col10}>
                    <div className={styles.row}>
                      <p style={{ fontSize: "0.75rem", fontWeight: "bold" }}>
                        {e.function.Function.name} ({e.function.Function.code})
                      </p>
                    </div>
                    <div
                      className={styles.row}
                      style={{ verticalAlign: "middle" }}
                      dangerouslySetInnerHTML={createDangerousHTML(
                        e.chart
                      )}></div>
                  </div>
                </div>
              </div>
              <div className={`${styles.col9} ${styles.crrNistWrap}`}>
                {e.cats.map((cat: any) => {
                  return (
                    <div
                      className={styles.row}
                      style={{ borderBottom: "0.5px solid black" }}>
                      <div
                        className={styles.col4}
                        style={{ fontSize: "8px", padding: ".25rem 0" }}>
                        <div style={{ fontWeight: "bold" }}>
                          {cat.parentCode}.{cat.code}
                        </div>
                        {cat.name}
                      </div>
                      <div
                        className={styles.col8}
                        style={{ alignItems: "center", padding: "0.25rem" }}
                        dangerouslySetInnerHTML={createDangerousHTML(
                          cat.catChart
                        )}></div>
                    </div>
                  );
                })}
                {/* <div *ngFor="let cat of e.cats; let i = index" className={styles.row}
          [style.border-bottom]="i != (e.cats.length - 1) ? 'solid 0.5px black' : 'none'">
          <div className="col-4" style={{fontSize: "8px", padding: ".25rem 0"}}>
            <div style={{fontWeight: "bold"}}>
              {{ cat.parentCode }}.{{ cat.code }}
            </div>
            {{ cat.name }}
          </div>
          <div className="col-8" style={{alignItems: "center", padding: "0.25rem"}} dangerouslySetInnerHTML={createDangerousHTML(cat.catChart)}>
          </div>
        </div> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CrrNistCsfSummary;
