import React, { ReactNode } from "react";
import { Button } from "../Form/Button/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./DialogBox.module.css";

interface DialogProps {
  open?: boolean;
  title?: string;
  handleOk?: Function | any;
  handleCancel?: Function | any;
  children?: ReactNode;
  buttonOk?: string;
  buttonCancel?: string;
  colorOk?: "inherit" | "primary" | "secondary" | "default";
  colorCancel?: "inherit" | "primary" | "secondary" | "default";
  disabled?: boolean;
  alignButtons?: string;
  skipCancel?: boolean;
  classes?: any;
  dialogBoxMsg?: string;
  pathName?: string;
  closeButtonPath?: string;
}

export const DialogBox: React.FC<DialogProps> = ({
  open = true,
  title,
  handleOk,
  handleCancel,
  children,
  buttonOk,
  buttonCancel,
  colorOk = "primary",
  colorCancel = "secondary",
  disabled = false,
  alignButtons,
  skipCancel = false,
  classes,
}) => {
  let cancelButtonDisplay = null;
  if (!skipCancel) {
    cancelButtonDisplay = (
      <Button
        variant={"contained"}
        onClick={handleCancel}
        color={colorCancel}
        id="cancel-button"
      >
        {buttonCancel}
      </Button>
    );
  }

  return (
    <div>
      <Dialog
        data-testid="dialogBox"
        open={open}
        classes={{
          root: classes?.root ? classes?.root : styles.ReactRootDialog,
          container: classes?.container ? classes?.container : styles.Dialogbox,
          paper: classes?.paper ? classes?.paper : styles.DialogboxPaper,
          scrollPaper: classes?.scrollPaper
            ? classes?.scrollPaper
            : styles.ScrollPaper,
        }}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={styles.DialogTitle}>
          {title}
        </DialogTitle>
        <DialogContent className={styles.DialogBody}>{children}</DialogContent>
        <DialogActions className={`${styles.DialogActions} ${alignButtons}`}>
          <Button
            id="ok-button"
            onClick={handleOk}
            color={colorOk}
            variant={"contained"}
            disabled={disabled}
          >
            {buttonOk}
          </Button>
          {cancelButtonDisplay}
        </DialogActions>
      </Dialog>
    </div>
  );
};
