import React, { useState, useEffect } from "react";
import styles from "./AnalysisDashboard.module.css";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { CSET_URL } from "../../../../config/index";
import { HorizontalBar, Doughnut } from "react-chartjs-2";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import logout from "../../../../containers/Auth/Logout/Logout";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../components/UI/Alert/Alert";
import * as routeConstant from "../../../../common/RouteConstants";
import { sentry_error_catch } from "../../../../common/sentry_error_catch";
import { ERROR_MESSAGE } from "../../../../common/MessageConstants";

export interface AnalysisProps {
  data?: any;
}

export const AnalysisDashboard: React.FC<AnalysisProps> = ({
  data,
}: AnalysisProps) => {
const history = useNavigate();
  const [chartData, setChartData] = useState<any>({});
  const [standardChartData, setStandardChartData] = useState<any>({});
  const [standardPieChartData, setStandardPieChartData] = useState<any>({});
  const [standardBasedScore, setStandardBasedScore] = useState<any>();
  const [overallScore, setOverallScore] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [param, setParam] = useState<any>();
  const [
    summaryPercentComplianceChartData,
    setSummaryPercentComplianceChartData,
  ] = useState<any>({});

  const authToken = sessionStorage.getItem("ra_token") || logout();
  useEffect(() => {
    const controller = new AbortController();
    if (data !== undefined) {
      setParam(data);
    }
    getAnalysisDashboardDetails();
    return () => controller.abort();
  }, []);

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const getAnalysisDashboardDetails = async () => {
  let numAttempts = 0;
  const maxAttempts = 2;
  
  const retryFetch:any = async (url:any, options:any) => {
    numAttempts++;
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (err:any) {
      if (numAttempts < maxAttempts) {
        return retryFetch(url, options);
      } else {
        sentry_error_catch(err,setLoading,setFormState)
      }
    }
  };
  
  try {
    setLoading(true);
    await retryFetch(`${CSET_URL}api/analysis/StandardsSummary`, {
      method: "GET",
      headers: headerObj,
    })
    .then((data:any) => {
      if(data.title === "Unauthorized"){
        history(routeConstant.CLIENT)
      }
      setStandardChartData({
        labels: data.labels,
        datasets: data.dataSets,
        colors: data.colors,
      });
      if (data.data.length > 0) {
        setStandardPieChartData({
          labels: data.labels,
          datasets: [
            {
              data: data.data,
              backgroundColor: data.colors,
              borderColor: data.colors,
              borderWidth: 1,
            },
          ],
        });
      }
    }).catch((err:any)=>{
      console.log("err",err)
    })
    await retryFetch(`${CSET_URL}api/analysis/ComponentsSummary`, {
      method: "GET",
      headers: headerObj,
    })
    .then((data:any) => {});
    await retryFetch(`${CSET_URL}api/analysis/TopCategories?total=5`, {
      method: "GET",
      headers: headerObj,
    })
    .then((data:any) => {
      setChartData({
        labels: data.labels,
        datasets: [
          {
            label: " Top Ranked Categories ",
            data: data.data,
            fill: true,
            backgroundColor: "rgb(163, 0, 14)",
            borderColor: "rgb(163, 0, 14)",
          },
        ],
      });
    });
    await retryFetch(`${CSET_URL}api/analysis/Dashboard`, {
      method: "GET",
      headers: headerObj,
    })
    .then((chartDataResponse:any) => {
      setLoading(false);
      setOverallScore(
        Math.round(chartDataResponse.overallBars.data[0] * 100) / 100
      );
      setStandardBasedScore(
        Math.round(chartDataResponse.overallBars.data[1] * 100) / 100
      );
      setSummaryPercentComplianceChartData({
        labels: chartDataResponse.overallBars.labels,
        datasets: [
          {
            label: "SUMMARY PERCENT COMPLIANCE",
            data: chartDataResponse.overallBars.data,
            fill: true,
            backgroundColor: "rgb(11,71,133)",
            borderColor: "rgb(75,192,192)",
          },
        ],
      });
    })
    .catch((error:any) => {
      console.error('Dashboard API fetch failed twice:', error);
      setLoading(false);
      handleError(error)
    });
  } catch (e) {
    setLoading(false);
    handleError(e);
    console.error("error", e);
  }
};


  const Doughnutoptions = {
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18,
        fontColor: "black",
      },
    },
  };

  const options = {
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18,
        fontColor: "black",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            fontStyle: "bold",
            fontSize: 18,
            fontColor: "black",
          },
        },
      ],
    },
  };

  if (loading) {
    return <SimpleBackdrop />;
  }

  return (
    <React.Fragment>

        <div className="App"></div>
        {formState.isFailed ? (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {formState.errMessage}
          </Alert>
        ) : null}
        <Paper className={styles.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card className={styles.cardPaperScore}>
                <CardContent>
                  <Typography component="h5" variant="h2" gutterBottom>
                    Overall Score
                  </Typography>
                </CardContent>
                <CardActions>
                  <Typography component="h5" variant="h1">
                    {~~overallScore + "%"}
                  </Typography>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card className={styles.cardPaperScore}>
                <CardContent>
                  <Typography component="h5" variant="h2" gutterBottom>
                    Standard Based Score
                  </Typography>
                </CardContent>
                <CardActions>
                  <Typography component="h5" variant="h1">
                    {~~standardBasedScore + "%"}
                  </Typography>
                </CardActions>
              </Card>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} className={styles.FooterActions}>
              <Typography component="h5" variant="h2">
                {"Assessment Compliance"}
              </Typography>
              <Paper className={styles.paper}>
                <HorizontalBar
                  data={summaryPercentComplianceChartData}
                  options={options}
                />
              </Paper>
            </Grid>
            <Divider />
            <Grid container>
              <Grid item xs={12} className={styles.FooterActions}>
                <Typography component="h5" variant="h2">
                  {"Top Ranked Categories"}
                </Typography>
                <Paper className={styles.paper}>
                  <HorizontalBar data={chartData} />
                </Paper>
              </Grid>
            </Grid>
            <Divider />
            <Grid container>
              <Grid item xs={12} className={styles.FooterActions}>
                <Typography component="h5" variant="h2">
                  {"Standards Summary"}
                </Typography>
                {standardPieChartData?.datasets?.length > 0 ? (
                  <Paper className={styles.paper}>
                    <Doughnut
                      data={standardPieChartData}
                      options={Doughnutoptions}
                    />
                  </Paper>
                ) : (
                  <Paper className={styles.paper}>
                    <HorizontalBar data={standardChartData} options={options} />
                  </Paper>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>

    </React.Fragment>
  );
};
export default AnalysisDashboard;
