export const SUCCESS = "has been saved successfully!";
export const DELETE = "has been deleted successfully!";
export const UPDATE = "has been updated successfully!";
export const FAILED = " An Error Occured!";
export const ALERT_MESSAGE_TIMER = 5000;
export const ASSESSMENT_TYPE_WARNING =
  "Change of Assessment Type may delete some or all previously entered data. Are you sure ?";
export const networkFetchPolicy = "network-only";
export const FETCH_POLICY = "network-only";
export const FETCH_POLICY_1 = 'no-cache';
export const RANKED_SUBJECT_AREAS = "Ranked Subject Areas ";
export const EXECUTIVE_SUMMARY = "Executive Summary";
export const COMMENT_AND_REVIEW = "CMMC 2.0 Comments and Marked for Review";
export const SITE_SUMMARY_REPORT = "Site Summary Report";
export const DEFICIENCY_REPORT = "Deficiency Report";
export const CRR_EXECUTIVE_SUMMARY_REPORT = "CRR Executive Summary Report";
export const TIME_FORMAT = "MM/DD/YYYY hh:mm a";
export const ERROR_TRY_AGAIN = "Something Went Wrong, Please try Again Later !.";
export const CONTENT_TYPE = "application/json";
export const REPORT_GENERATION = "Once a Report is Built the Assessment is finalized and cannot be edited. Are you sure ?";
export const CRR_REPORT_GENERATION = "The report has been queued and will be ready for download in 2 to 3 business days.";
export const CREDIT_DEDUCTION = "By continuing you will consume 1 of your Credits.";
export const PRICING = "By continuing you will incur a charge of $";
export const DELETE_MSG = "Risk Assessment has been deleted successfully!"
export const ERROR_MESSAGE = "Something went wrong. Please try again later or contact Partner Support on ps@cyberguard360.com."
export const CANCEL_MSG = "Are you sure you want to cancel ?"