import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from "@apollo/link-error";
import { URI } from '.';
import { setContext } from '@apollo/link-context';

const gqlClient = (authToken: string | null) => {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: authToken ? `Bearer ${authToken}` : '',
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors){
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
    }
  });

  const httpLink:any = createUploadLink({ uri: URI });
  const link:any = authToken ? authLink.concat(httpLink) : httpLink;

  return new ApolloClient({
    link: errorLink.concat(link),
    cache: new InMemoryCache(),
  });
};

export default gqlClient;
