import { gql } from "@apollo/client";

export const GetCSETUserDetails = gql`
  query getCSETUserDetails($where: JSON) {
    csetUserDetails(where: $where){
      id
      contact
      {
        id
      }
      cset_user_id
      cset_username
      contact{id}
      cset_password
    }
  }
`;
