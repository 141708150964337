import React, { useState, useEffect } from "react";
import styles from "./ControlPriorities.module.css";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { CSET_URL } from "../../../../config/index";
import MaterialTable from "../../../../components/UI/Table/MaterialTable";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import logout from "../../../../containers/Auth/Logout/Logout";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../components/UI/Alert/Alert";
import { sentry_error_catch } from "../../../../common/sentry_error_catch";
import { ERROR_MESSAGE } from "../../../../common/MessageConstants";

export interface ControlPrioritiesProps {
  data?: any;
}

export const ControlPriorities: React.FC<ControlPrioritiesProps> = ({
  data,
}: ControlPrioritiesProps) => {
  const [newData, setNewData] = useState([]);
  const [loading, setLoading] = useState(false);
  const column = [
    { title: "Rank", key: "rank" },
    { title: "Standard", key: "standard" },
    { title: "Category", key: "category" },
    { title: "Question", key: "question" },
    { title: "Answer", key: "answer" },
  ];
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [param, setParam] = useState<any>();

  const headerObj = {
    "Content-Type": `application/json`,
    Authorization: authToken,
  };

  useEffect(() => {
    const controller = new AbortController();
    if (data !== undefined) {
      setParam(data);
    }
    getControlPrioritiesDetails();
    return () => controller.abort();
  }, []);


  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const getControlPrioritiesDetails = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/analysis/RankedQuestions`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          createTableDataObject(data);
        })
        .catch((err:any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });
    } catch (e) {
      setLoading(false);
      handleError(e);
      console.error("error", e);
    }
  };

  const createTableDataObject = (data: any) => {
    const arr: any = [];
    data.forEach((element: any) => {
      const obj: any = {};
      obj["rank"] = !element.rank ? "-" : element.rank;
      obj["standard"] = element.standard;
      obj["category"] = !element.category ? "-" : element.category;
      obj["question"] = element.questionText;
      obj["answer"] =
        element.answerText === "U" ? "Unanswered" : element.answerText === "N" ? "No":element.answerText ;
      arr.push(obj);
    });
    setNewData(arr);
  };

  if (loading) {
    return <SimpleBackdrop />;
  }

  return (
    <Grid>
      <React.Fragment>
        {formState.isFailed ? (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {formState.errMessage}
          </Alert>
        ) : null}
        <Paper className={styles.paper}>
          <div className={styles.ScrollTable}>
            <MaterialTable columns={column} data={newData} pageSize={10} />
          </div>
        </Paper>
      </React.Fragment>
    </Grid>
  );
};
export default ControlPriorities;
